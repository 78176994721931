import {
  CreateOrganizationDTO,
  Organization,
  OrganizationsSearchParams,
  OrganizationsSearchResponse,
} from './types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class OrganizationsAPI {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getOrganizations(params?: OrganizationsSearchParams): Promise<OrganizationsSearchResponse> {
    return await this.api
      .get(`/get-list`, {
        params,
      })
      .then(res => res.data);
  }
  //
  async deleteOrganizationByID(id: number) {
    return await this.api.delete(`/delete-organization`, {
      params: {
        id,
      },
    });
  }
  async updateOrganization(payload: Organization) {
    return await this.api.patch(`/update-organization`, payload);
  }
  async createOrganization(payload: CreateOrganizationDTO): Promise<any> {
    // return MicroserviceAxios.post('/organizations-api/create-org', payload).then(res => res.data);
    return this.api
      .post(`/create-organization`, {
        name: payload.orgDto.name,
        description: payload.orgDto.description,
        website: payload.orgDto.website,
      })
      .then(res => res.data);
  }
}
export default OrganizationsAPI;
