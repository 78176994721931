import {TransactionType} from '@/API/Talents/types';
import {useReduceState} from '@/Hooks';
import useSelectOptions from '@/Hooks/SelectOptions/useSelectOptions';
import {Box, Div, ElementHeadline, Input, Modal, Select, Text} from '@/UI';
import {SelectOptionsType} from '@/UI/Select/select.styled';
import {useDeleteWalletTransaction, useUpdateWalletTransaction} from '@/Website/Components/Talent/Hooks';
import {useCallback, useMemo} from 'react';

type TransactionDetailsProps = {
  isShowDetails: boolean;
  onClose: Function;
  onChange: Function;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
  transaction: TransactionType;
};

const TransactionDetails: React.FC<TransactionDetailsProps> = props => {
  const {isShowDetails, setTransaction, onClose, transaction, onChange} = props;
  const paymentTypeOptions = useMemo(
    () =>
      [
        {label: 'INCOME', value: 'INCOME'},
        {label: 'WITHDRAWAL', value: 'WITHDRAWAL'},
      ] as SelectOptionsType,
    []
  );
  const afterChanging = useCallback(() => {
    onChange();
    onClose();
  }, [onChange, onClose]);

  const updateDetails = useUpdateWalletTransaction();
  const deleteTransaction = useDeleteWalletTransaction(afterChanging);
  const handleChangeInputs = useReduceState<TransactionType>(setTransaction);
  const orderOptions = useSelectOptions('orderOptions')();
  return (
    <Modal isShowModal={isShowDetails}>
      <Div width={500} className="TransactionDetails" flexDirection="column">
        <ElementHeadline
          title="Transaction editor"
          onClose={onClose}
          onSave={() => updateDetails({transaction, onSuccess: afterChanging})}
          fontSize="Header4"
          options={[{onSelect: () => deleteTransaction(transaction.id), option: 'Delete'}]}
        />
        <Box backgroundColor="AntiqueWhiteLight">
          <Div justifyContent="space-between" alignItems="center">
            <Div flexDirection="column">
              <Text type="Text3" fontWeight={800}>
                Transaction ID: {transaction.id}
              </Text>
              <Text type="Header4" color="Purple" fontWeight={800}>
                {transaction.transaction_type.toUpperCase()}
              </Text>
              <Div gap={3}>
                <Text type="Text4">Payment info:</Text>
                <Text type="Text4" fontWeight={800} color="AntiqueRed">
                  {transaction.payment_method}
                </Text>
              </Div>
            </Div>
            <Text type="Header2" color="Orange" fontWeight={800}>
              ${transaction.amount}
            </Text>
          </Div>
        </Box>
        <Div gap={20} alignItems="flex-end">
          <Select
            options={paymentTypeOptions}
            value={transaction.transaction_type}
            defaultPlaceholder={transaction.transaction_type}
            label="Transaction type"
            onChange={value => handleChangeInputs('transaction_type', value)}
          />
        </Div>
        <Div gap={20} alignItems="flex-end" margin="5px 0px 0px">
          <Select
            isSearchable={true}
            options={orderOptions}
            label="Order"
            value={transaction.order_id}
            defaultPlaceholder={transaction?.order_id || '-'}
            onChange={value => handleChangeInputs('order_id', value)}
          />
          <Input
            placeholder="Amount"
            type={'number'}
            value={transaction.amount}
            onChange={e => handleChangeInputs('amount', e.target.value)}
          />
        </Div>
      </Div>
    </Modal>
  );
};

export default TransactionDetails;
