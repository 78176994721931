import styled from 'styled-components';
import {memo} from 'react';

const SAnalytics = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;

  .base-analytic-column {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    padding: 5px 0;
  }
`;

export default memo(SAnalytics);
