import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    padding: 5px 10px 3px;
    .label {
      font-size: 0.7rem;
      font-family: ${theme.FontFamily.Outfit};
      margin-bottom: 5px;
    }
    .rc-slider-rail {
      background-color: ${theme.Color.GrayLight};
      height: 2px;
    }
    .rc-slider-handle {
      border: solid 2px #ffffff;
      background-color: ${theme.Color.Orange};
      width: 20px;
      height: 20px;
      margin-top: -8px;
      opacity: 1;
    }
    .rc-slider-handle-dragging {
      border: none !important;
      box-shadow: none !important;
    }
    .rc-slider-track {
      background-color: ${theme.Color.InfoLight};
    }
  `}
`;
