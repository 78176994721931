import {useCallback, useState} from 'react';
import {Component} from './inputFIle.styled';
import {Div, Text} from '@/UI';
const FilesExtensions = {
  all: '.mp3,.mp4,.jpg,.jpeg,.mov,.png,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx',
  excel: '.xls,.xlsx',
};

interface InputFileProps {
  label: string;
  onUpload: (files: File[]) => any;
  multiple?: boolean;
  accept: keyof typeof FilesExtensions;
}

const InputFile: React.FC<InputFileProps> = ({label, onUpload, multiple, accept}) => {
  const allowedExtensions = FilesExtensions[accept].replaceAll('.', '').split(',');
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const handleInputClick = useCallback(() => {
    const input = document.getElementById('upload-input') as HTMLInputElement;
    input?.click();
    input!.value = '';
  }, []);
  const handleDirectoryInputClick = useCallback(() => {
    const input = document.getElementById('upload-directory') as HTMLInputElement;
    input?.click();
    input!.value = '';
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Object.entries(e.target.files || []).map(el => el[1]);
    if (files) {
      const fileList = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.split('.').pop()?.toLowerCase();
        if (extension && allowedExtensions.includes(extension)) {
          fileList.push(file);
        }
      }

      setCurrentFiles(fileList);
      onUpload(fileList);
    }
  };

  return (
    <Component isUploaded={currentFiles.length > 0}>
      <Div gap={10}>
        <button className="button-label" onClick={handleInputClick}>
          {label}
        </button>
        {multiple && (
          <button className="button-label" onClick={handleDirectoryInputClick}>
            Select directory
          </button>
        )}
      </Div>
      <Text type="Text4">Available formats files: {FilesExtensions[accept] || FilesExtensions.all}</Text>
      {currentFiles.length > 0 &&
        currentFiles.map((file, i) => (
          <span className="chooser-file-name" key={i}>
            {i + 1}: {file.webkitRelativePath || file.name}
          </span>
        ))}

      <input
        type="file"
        name="Upload"
        accept={accept}
        className="upload-file"
        id="upload-input"
        onChange={handleInputChange}
        multiple={multiple}
      />
      {multiple && (
        <input
          type="file"
          name="Upload"
          accept={accept}
          className="upload-directory"
          id="upload-directory"
          onChange={handleInputChange}
          multiple={multiple}
          //@ts-ignore
          webkitdirectory="true"
        />
      )}
    </Component>
  );
};

export default InputFile;
