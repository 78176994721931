import {memo, useMemo} from 'react';
import {Box, Div, Grid, Text} from '@/UI';
import {CampaignSummary} from '@/API/Campaign/types';

interface PostStatsProps {
  stats: CampaignSummary['orders_stats'];
}

const PostStats: React.FC<PostStatsProps> = props => {
  const stats = props.stats && props.stats.length ? props.stats[0] : undefined;
  const VALUES = useMemo(
    () => [
      {label: 'Posts', value: stats?.posts},
      {label: 'Impressions', value: stats?.impressions},
      {label: 'Comments', value: stats?.comments},
      {label: 'Clicks', value: stats?.clicks},
      {label: 'Actions', value: stats?.actions},
      {label: 'Likes', value: stats?.likes},
    ],
    [stats]
  );
  return (
    <Grid
      justifyContent="space-between"
      templateColumn={`repeat(${VALUES.length}, 1fr)`}
      columnGap={20}
      templateRow="150px"
    >
      {VALUES.map((stat, i) => (
        <Box key={i}>
          <Div flexDirection="column" gap={20}>
            <Text fontWeight={600} type="Text1">
              {stat.label}
            </Text>
            <Text fontWeight={800} color="Success" type="Header2" textAlign="center">
              {stat.value || 0}
            </Text>
          </Div>
        </Box>
      ))}
    </Grid>
  );
};
export default memo(PostStats);
