import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {CampaignEstimations} from '@/API/Campaign/types';

const useCampaignEstimations = (campaignID: number) => {
  const [estimations, setEstimations] = useState<CampaignEstimations>();
  const [isLoadingEstimations, setIsLoadingEstimations] = useState<boolean>(false);
  const fetch = useApiLoader(
    async () => {
      if (campaignID) {
        setIsLoadingEstimations(true);
        return await API.Campaigns.getCampaignEstimations(campaignID)
          .then(res => setEstimations(res))
          .finally(() => setIsLoadingEstimations(false));
      }
    },
    [campaignID],
    {disableLoader: true}
  );
  useEffect(() => void fetch(), [fetch]);
  return {
    estimations,
    isLoadingEstimations,
  };
};
export default useCampaignEstimations;
