import {Box, Div, Text} from '@/UI';
import {dateToLocalDate} from '@/Services/date.service';
import {useCallback} from 'react';
import {OrderType} from '@/API/Orders/types';
import {useOpenCampaign} from '@/Website/Pages/Campaigns/campaigns.hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const OrderInfoSubHeader: React.FC<{order: OrderType}> = ({order}) => {
  const openCampaign = useOpenCampaign();
  const {setIsShowPortalOrder} = usePortalsStore().actions;
  const handleOpenCampaign = useCallback(() => {
    if (order.campaign_id) {
      openCampaign(order.campaign_id);
      setIsShowPortalOrder(false);
    }
  }, [order]);
  return (
    <Box backgroundColor="Lime">
      <Div justifyContent="space-between">
        <Div flexDirection="column" className="on-hover" onClick={handleOpenCampaign}>
          <Text type="Text3">Campaign:</Text>
          <Text type="Text2" fontWeight={800} color="Purple">
            {order.campaign_id || '-'} : {order?.campaign_title}
          </Text>
        </Div>

        <Div flexDirection="column">
          <Text type="Text3">Date posted:</Text>
          <Text type="Text2" fontWeight={800} color="Success">
            {dateToLocalDate(order.date_posted)}
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text3">Created at:</Text>
          <Text type="Text2" fontWeight={800} color="Success">
            {dateToLocalDate(order.created_at)}
          </Text>
        </Div>
      </Div>
    </Box>
  );
};
export default OrderInfoSubHeader;
