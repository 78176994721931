import {Grid, Input, Select, Switch} from '@/UI';
import {useState} from 'react';
import {useReduceState} from '@/Hooks';
import SDatePicker from './datePicker.styled';
import DatePickerService from '@/UI/DatePicker/datePicker.service';

export interface DatePickerStateType {
  from: string;
  to: string;
}
interface DatePickerProps {
  dateState: DatePickerStateType;
  setDateState: React.Dispatch<React.SetStateAction<DatePickerStateType>>;
}
const DatePicker: React.FC<DatePickerProps> = props => {
  const {dateState, setDateState} = props;
  const [isCustomDates, setIsCustomDates] = useState<boolean>(false);
  const handleChange = useReduceState<DatePickerStateType>(setDateState);

  return (
    <SDatePicker className="DatePicker">
      <Grid alignItems="flex-end" templateColumn="repeat(4,200px)" columnGap={20}>
        <Select
          value={DatePickerService.datePeriodSelect[0].value}
          options={DatePickerService.datePeriodSelect}
          label="Choose period"
          isDisabled={isCustomDates}
          onChange={value => {
            handleChange('to', new Date().toLocaleDateString('en-CA'));
            handleChange('from', value);
          }}
        />
        <Switch isOn={isCustomDates} onToggle={setIsCustomDates} option="Custom dates" />

        <Input
          placeholder="From"
          value={dateState.from}
          type="date"
          disabled={!isCustomDates}
          onChange={e => handleChange('from', e.target.value)}
        />
        <Input
          placeholder="To"
          value={dateState.to}
          type="date"
          disabled={!isCustomDates}
          onChange={e => handleChange('to', e.target.value)}
        />
      </Grid>
    </SDatePicker>
  );
};
export default DatePicker;
