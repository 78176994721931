import {useEffect, useState} from 'react';
import {OrdersLogsResponse, OrdersLogsSearchParams} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useOrdersLogs = (params?: OrdersLogsSearchParams) => {
  const [ordersLogs, setOrdersLogs] = useState<OrdersLogsResponse>({logs: [], count: 0});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(
    async () => {
      if (params) {
        setIsLoading(true);
        return await API.Orders.getOrdersLogs(params)
          .then(res => setOrdersLogs(res))
          .finally(() => setIsLoading(false));
      }
    },
    [params],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    isLoading,
    ordersLogs,
  };
};
export default useOrdersLogs;
