import {useState, useCallback, useMemo} from 'react';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Button, Div, ElementHeadline, Modal, Select, TitledText} from '@/UI';
import useSelectOptions from '@/Hooks/SelectOptions/useSelectOptions';
import {useAddCategoryToUser} from '@/Website/Pages/Categories/Hooks';

const CategorySelector: React.FC = () => {
  const {setIsShowCategoryPortal} = usePortalsStore().actions;
  const {userToCategoryPortal, categoryPortalCallBack} = usePortalsStore().selectors.categories;
  const categoriesOptions = useSelectOptions('categoryOptions')();
  const [selectedCategory, setSelectedCategory] = useState<any>();
  /** Handlers*/
  const handleCloseCategoryPortal = useCallback(() => setIsShowCategoryPortal(false), []);

  const addCategoryHandler = useAddCategoryToUser();

  const submitPayload = useMemo(
    () => ({
      callBack: () => {
        categoryPortalCallBack();
        handleCloseCategoryPortal();
      },
      categoryID: selectedCategory,
      userID: userToCategoryPortal,
    }),
    [selectedCategory]
  );
  return (
    <Modal isShowModal={true}>
      <Div flexDirection="column" width={400} className="CategoryPortal">
        <ElementHeadline title="Add category to talent" onClose={handleCloseCategoryPortal} />
        <TitledText title="User ID" text={userToCategoryPortal} titleColor="Orange" />
        <TitledText title="Category ID" text={selectedCategory} titleColor="Purple" />
        <Select
          isSearchable={true}
          options={categoriesOptions}
          label="Category"
          value={selectedCategory}
          onChange={value => setSelectedCategory(value)}
        />
        <Button
          isDisabled={!selectedCategory}
          color="Success"
          style={{marginTop: 20}}
          onClick={() => addCategoryHandler(submitPayload)}
        >
          ADD
        </Button>
      </Div>
    </Modal>
  );
};

export default CategorySelector;
