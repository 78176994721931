import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {UpdateRevisionDTO} from '@/API/Orders/types';

const useUpdateOrderRevision = (callBack?: Function) =>
  useApiLoader(
    async (revisionDTO: UpdateRevisionDTO) => {
      return await API.Orders.updateOrderRevision(revisionDTO).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Revision updated'}
  );
export default useUpdateOrderRevision;
