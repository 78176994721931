import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useUploadCampaignAttachments = (payload: {
  campaignID: number;
  materials?: File[];
  callBack?: (key: string) => void;
}) => {
  return useApiLoader(
    async () => {
      if (payload.materials) {
        await API.Campaigns.uploadCampaignAttachments(payload.materials, payload.campaignID).then(fileKey => {
          payload.callBack && payload.callBack(fileKey);
        });
      }
    },
    [payload],
    {successMsg: 'Uploaded'}
  );
};
export default useUploadCampaignAttachments;
