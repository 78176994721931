import {ColorType, TextType} from '@/Styles/types';
import {ReactNode} from 'react';
import {Component} from './text.styled';
import {memo} from 'react';
import {Div} from '@/UI';
export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: TextType;
  children?: ReactNode;
  color?: ColorType;
  className?: any;
  fontWeight?: 400 | 600 | 800;
  italic?: boolean;
  textAlign?: 'center' | 'left' | 'right';
  flex?: string;
  lineThrough?: boolean;
  flexBasis?: string;
  noWrap?: boolean;
  cursor?: 'pointer';
  isLoading?: boolean;
  loadingWidth?: number;
  wordBreak?: 'break-all';
  lineHeight?: number;
}

const Text: React.FC<Props> = props => {
  const {children, isLoading, loadingWidth, ...restProps} = props;

  return (
    //@ts-ignore
    <Component className="Text" {...restProps}>
      {isLoading ? <Div className="text-loader" width={loadingWidth || 50} /> : children}
    </Component>
  );
};

export default memo(Text);
