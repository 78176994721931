import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback, useEffect, useState} from 'react';
import {CampaignResponseType, CampaignSearchParams} from '@/API/Campaign/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';

export const useGetCampaigns = (filters?: CampaignSearchParams) => {
  const [campaigns, setCampaigns] = useState<CampaignResponseType>({campaigns: [], count: 0});
  const [isLoading, setIsLoading] = useState(false);

  const getCampaignsSelectOptions = useApiLoader(
    async (search?: string) => {
      // return await API.Campaigns.getAllCampaigns({searchQuery: search, limit: 30}).then(res => {
      //   return res.campaigns.map(el => ({value: el.id, label: `${el.id}: ${el.title}`}));
      // });
      // limit: 30
      return await API.Campaigns.getAllCampaigns({searchQuery: search, limit: 150}).then(res => {
        return res.campaigns.map(el => ({value: el.id, label: `${el.id}: ${el.title}`}));
      });
    },
    [],
    {disableLoader: true}
  );
  const fetch = useApiLoader(
    async () => {
      if (filters) {
        setIsLoading(true);
        await API.Campaigns.getAllCampaigns(filters)
          .then(res => setCampaigns(res))
          .finally(() => setIsLoading(false));
      }
    },
    [filters],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {campaigns, isLoading, refreshCampaigns: fetch, getCampaignsSelectOptions};
};

export const useOpenCampaign = () => {
  const {setCampaignPortalID, setIsShowCampaignPortal} = usePortalsStore().actions;

  return useCallback((campaignID: number) => {
    setCampaignPortalID(campaignID);
    setIsShowCampaignPortal(true);
  }, []);
};
