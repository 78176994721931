import {composeAutomatedSendingFormData} from '@/API/Sender/sender.service';
import {SendOnboardingBase} from '@/API/Sender/sender.types';
import {OnboardingCounter} from '@/API/Statistics/types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AxiosInstance} from 'axios';
import {TalentDetailed} from '../Talents/types';
import {
  ApprovalEmailSending,
  Onboarding,
  OnboardingSearchParams,
  personToApproveDTOType,
  RejectionEmailSending,
} from './types';

class OnboardingApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getOnboardingListByStatus(params: OnboardingSearchParams): Promise<Onboarding> {
    return await this.api
      .get(`/get-onboarding`, {
        params: {...params, sortBy: params.sortBy?.value},
      })
      .then(res => res.data);
  }
  //
  async deleteFromOnboarding(id: number) {
    return await this.api.delete(`/deletePerson/` + id).then(res => res.data);
  }
  //
  async rejectOnboardingPerson(id: number) {
    return await this.api
      .get(`/rejectPerson`, {
        params: {id},
      })
      .then(res => res.data);
  }
  //

  async sendApprovalEmail(data: ApprovalEmailSending) {
    return await this.api.post('/approval-email', data);
  }

  async sentRejectEmail(data: RejectionEmailSending) {
    return await this.api.post('/rejection-email', data);
  }
  //
  async sendOnboarding(sendData: SendOnboardingBase) {
    const formData = composeAutomatedSendingFormData(sendData);
    return await this.api.post('/onboarding-sending', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }
  //
  async approveOnboardingPerson(payload: personToApproveDTOType): Promise<TalentDetailed> {
    return await this.api.post(`/createTalent`, payload).then(res => res.data);
  }

  async getOnboardingCounter(): Promise<OnboardingCounter> {
    return await this.api.get(`/getOnboardingCounters`).then(res => res.data);
  }
}
export default OnboardingApi;
