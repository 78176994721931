import {Component} from './switch.styled';

interface SwitchProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOn: boolean;
  onToggle: (e: boolean) => any;
  option: string;
  secondOption?: string;
  isDisabled?: boolean;
}
const Switch: React.FC<SwitchProps> = props => {
  const {isOn, onToggle, option, secondOption, children, className, ...restProps} = props;
  const handleSwitchClick = () => {
    onToggle(!isOn);
  };
  return (
    //@ts-ignore
    <Component className="Switch" isOn={isOn} {...restProps}>
      {secondOption && <span className="option">{secondOption}</span>}
      <div className="outer-div" onClick={handleSwitchClick}>
        <div className="inner-div"></div>
      </div>

      <span className="option">{option}</span>
    </Component>
  );
};
export default Switch;
