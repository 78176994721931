import {Box} from '@/UI';
import styled from 'styled-components';

export const Component = styled(Box)`
  border-top-left-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 510px;

  .orders-list {
    cursor: pointer;
    margin-top: 20px;
    height: 400px;
  }
`;
