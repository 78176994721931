import {useEffect, useState} from 'react';
import {IQDataSubscriptionPlan, SubscriptionPlanResponse} from '@/API/Subscriptions/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useSubscriptions = () => {
  const [sibPlan, setSibPlan] = useState<SubscriptionPlanResponse>();
  const [iqdataPlan, setIqDataPlan] = useState<IQDataSubscriptionPlan>();
  const [isLoading, setIsLoading] = useState(false);
  const fetchSIBPlan = useApiLoader(
    async () => {
      await API.Subscriptions.getSubscriptionPlanSIB().then(planResponse => setSibPlan(planResponse));
    },
    [],
    {disableLoader: true}
  );
  const fetchIQDataPlan = useApiLoader(
    async () => {
      await API.Subscriptions.getSubscriptionIqData().then(res => setIqDataPlan(res));
    },
    [],
    {disableLoader: true}
  );
  useEffect(() => {
    setIsLoading(true);

    void fetchSIBPlan();
    void fetchIQDataPlan().then(() => setIsLoading(false));
  }, [fetchIQDataPlan, fetchSIBPlan]);
  return {
    sibPlan,
    iqdataPlan,
    isLoading,
  };
};
export default useSubscriptions;
