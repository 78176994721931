import {useBooleanState} from '@/Hooks/useBooleanState';
import {useCallback, useEffect, useMemo} from 'react';
import * as pack from '../../package.json';
import {StorageService} from '@/Services/storage.service';

const useCheckAppVersion = () => {
  const CheckingVersion = useBooleanState();

  const currentVersion = useMemo(() => {
    const packageJson = JSON.parse(JSON.stringify(pack));
    return packageJson.version;
  }, [pack]);
  const check = useCallback(() => {
    StorageService.set('APP-VERSION', currentVersion);
    CheckingVersion.turnOn();
  }, []);
  useEffect(() => {
    const lastCheckedVersion = StorageService.get('APP-VERSION');
    if (lastCheckedVersion && lastCheckedVersion === currentVersion) {
      CheckingVersion.turnOn();
    }
  }, [currentVersion]);
  return {
    isChecked: CheckingVersion.isTurn,
    appVersion: currentVersion,
    check,
  };
};
export default useCheckAppVersion;
