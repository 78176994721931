import {useFirebaseInit} from '@/Hooks';
import {RoutesValues} from '@/Router/router.service';
import {useRootStore} from '@/Store/root/root.selector';
import Layout from '@/Website/Layout/Layout';
import Login from '@/Website/Pages/Login/Login';
import TalentPortfolio from '@/Website/Pages/TalentPortfolio/TalentPortfolio';
import {memo, useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';
import {ROUTES} from './ROUTES';

const Router: React.FC = () => {
  const {isAuth} = useRootStore().selectors;
  const routes = useMemo(() => RoutesValues, []);
  useFirebaseInit();

  return isAuth ? (
    <Routes>
      <Route path={ROUTES.talentPortfolio + '/:talentId'} element={<TalentPortfolio />} />
      <Route path={ROUTES.default} element={<Layout />}>
        {routes.map(({Element, path, index}, i) => (
          <Route path={path} element={<Element />} index={index} key={i} />
        ))}
      </Route>
      <Route path={'*'} element={<Login />} />
    </Routes>
  ) : (
    <Routes>
      {/* Could be opens for external using */}
      {/* <Route path={ROUTES.talentPortfolio} element={<TalentPortfolio />} /> */}
      <Route path={'*'} element={<Login />} />
    </Routes>
  );
};

export default memo(Router);
