import {TalentDetailed, TalentUpdateDTO} from '@/API/Talents/types';
import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useUpdateTalent = (talent: TalentDetailed | undefined) => {
  const {
    storedUser: {onChangeUserCallback},
  } = useCommunityStore().selectors;
  return useApiLoader(
    async () => {
      await API.Talents.updateTalent(talent as TalentUpdateDTO).then(() => {
        onChangeUserCallback();
      });
    },
    [talent, onChangeUserCallback],
    {successMsg: 'Updated'}
  );
};
export default useUpdateTalent;
