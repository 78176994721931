import {Checkbox, CheckboxProps} from '@material-ui/core';
import styled from 'styled-components';

const SCheckBox = styled.div`
  .Mui-checked {
    color: #7fce62 !important;
  }
`;

interface Props extends CheckboxProps {}

const CheckBox: React.FC<Props> = props => {
  //To avoid warning about that the 2 props defaultChecked and checked exists in one time
  const {defaultChecked, ...restProps} = props;
  return (
    <SCheckBox>
      <Checkbox {...restProps} />
    </SCheckBox>
  );
};
export default CheckBox;
