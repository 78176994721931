import {useCommunityStore} from '@/Store/community/community.selector';
import {Button, Div, ElementHeadline, Input, InputFile, Modal} from '@/UI';
import {useUploadFinancialDoc} from '@/Website/Components/Talent/Hooks';
import {memo, useCallback, useState} from 'react';
interface Props {
  isShow: boolean;
  onUpload: Function;
  onClose: Function;
}
const UploadTalentFinancialDoc: React.FC<Props> = props => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [docType, setDocType] = useState<string>('');
  const {isShow, onClose, onUpload} = props;
  const {userID} = useCommunityStore().selectors.storedUser;
  const onSuccessfullyUploading = useCallback(() => {
    onUpload();
    onClose();
  }, []);
  const uploadFiles = useUploadFinancialDoc();

  return (
    <Modal isShowModal={isShow}>
      <Div width={400} flexDirection="column">
        <ElementHeadline title="Upload doc" disableMarker onClose={onClose} />
        <Input placeholder="Something about doc" value={docType} onChange={e => setDocType(e.target.value)} />
        <InputFile accept="all" label="Select file" onUpload={setFilesToUpload} />
        <Button
          color="Success"
          size="medium"
          isDisabled={filesToUpload.length === 0}
          onClick={() =>
            uploadFiles({
              file: filesToUpload[0],
              docType,
              talentID: userID,
              callBack: onSuccessfullyUploading,
            })
          }
        >
          SUBMIT
        </Button>
      </Div>
    </Modal>
  );
};
export default memo(UploadTalentFinancialDoc);
