import {useEmailLogs} from '@/Hooks/Sender';
import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';
import {UtilService} from '@/Services/util.service';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Box, Div, Label, TableContainer, TCell, Text, TRow} from '@/UI';
import SendingLogsService from '@/Website/Pages/SendingLogs/sendingLogs.service';
import {useMemo} from 'react';

const TalentEmails: React.FC<{email: string}> = ({email}) => {
  const params = useMemo(() => ({email}), []);
  const {sendingLogs} = useEmailLogs(params);
  const {openEmailEvents} = usePortalsStore().actions;

  return (
    <Box className="TalentEmails tab-preset">
      <TableContainer isSmall headers={['Sent at', 'Status', 'Email', 'Service']}>
        {sendingLogs.logs.map((log, i) => (
          <TRow
            key={i}
            onClick={() =>
              openEmailEvents({
                email,
                externalId: log.external_id,
              })
            }
          >
            <TCell>
              <Text color="Success" type="Text3">
                {dateToLocalDate(log.created_at)}
              </Text>
            </TCell>
            <TCell>
              <Label backgroundColor={ColorService.getColorTypeByStatus(log.response_status)}>
                {log.response_status}
              </Label>
            </TCell>

            <TCell>
              <Div flexDirection="column">
                <Text type="Text2" fontWeight={800}>
                  {SendingLogsService.extractSubject(log.request)}
                </Text>
                <Text type="Text3">{UtilService.removeHtmlTags(log.message)}</Text>
              </Div>
            </TCell>
            <TCell>
              <Text type="Text3">{log.api_name}</Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default TalentEmails;
