import {
  Advertiser,
  AdvertiserAccessToken,
  AdvertiserCreateDTO,
  AdvertiserGenerateTokenPayload,
  AdvertiserSearchParams,
  AdvertiserSearchResponse,
  AdvertiserUpdateDTO,
} from '@/API/Advertiser/types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AxiosInstance} from 'axios';

class AdvertiserApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async getAdvertisers(params: AdvertiserSearchParams): Promise<AdvertiserSearchResponse> {
    return this.api
      .get(`/get-advertisers`, {
        params,
      })
      .then(res => res.data);
  }
  //

  async createAdvertiser(payload: AdvertiserCreateDTO) {
    return this.api
      .post('/users-api/create-advertiser', payload, {baseURL: URL.ADMIN_MS})
      .then(res => res.data);
  }
  //
  async updateAdvertiser(payload: AdvertiserUpdateDTO): Promise<Advertiser> {
    return this.api.patch('/update-advertiser', payload).then(res => res.data);
  }
  async getAccessTokens(userId: string): Promise<AdvertiserAccessToken[]> {
    return await this.api.get(`/access-token/${userId}`).then(res => res.data);
  }
  async generateAccessToken(payload: AdvertiserGenerateTokenPayload): Promise<AdvertiserAccessToken> {
    return await this.api.post('/access-token', payload).then(res => res.data);
  }
  async deleteAccessToken(tokenId: number, userId: string) {
    return await this.api.delete(`access-token/${tokenId}/${userId}`);
  }
}

export default AdvertiserApi;
