import {Controllers} from '@/API/controllers';
import {
  AdvertiserApi,
  AutomatedCampaignsApi,
  CampaignsApi,
  CategoriesApi,
  HelpRequestApi,
  OnboardingApi,
  OrdersApi,
  StatisticsApi,
  WalletApi,
  OrganizationsAPI,
  TagsApi,
  ProvidersApi,
  TalentInfoApi,
  UsersApi,
  TalentsApi,
  SubscriptionsApi,
} from '@/API/index';
import SenderApi from '@/API/Sender/sender.api';
import OpenaiApi from '@/API/Openai/openai.api';

const OnboardingAPI = new OnboardingApi(Controllers.Admin.onboarding);

export const API = {
  Onboarding: OnboardingAPI,
  Talents: new TalentsApi(Controllers.Admin.talents, OnboardingAPI),
  Users: new UsersApi(Controllers.Admin.users),
  AutomatedCampaign: new AutomatedCampaignsApi(Controllers.Admin.automatedCampaignsLogs),
  TalentInfo: new TalentInfoApi(Controllers.Admin.infoTalent),
  Campaigns: new CampaignsApi(Controllers.Admin.campaigns),
  Orders: new OrdersApi(Controllers.Admin.orders),
  Wallet: new WalletApi(Controllers.Admin.wallet),
  Organizations: new OrganizationsAPI(Controllers.Admin.organizations),
  Tags: new TagsApi(Controllers.Admin.tags),
  Categories: new CategoriesApi(Controllers.Admin.categories),
  Providers: new ProvidersApi(Controllers.Admin.providers),
  Subscriptions: new SubscriptionsApi(Controllers.Discovery.reports),
  Advertiser: new AdvertiserApi(Controllers.Admin.advertisers),
  HelpRequests: new HelpRequestApi(Controllers.Admin.users),
  Statistics: new StatisticsApi(Controllers.Admin.statistics),
  Sender: new SenderApi(Controllers.Admin.sender),
  OpenAI: new OpenaiApi(Controllers.Admin.openai),
};
