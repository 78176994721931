import {useEffect, useState} from 'react';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import {Component} from './radioBox.styled';

interface Props {
  options: Array<{name: string; value: string; description?: string}>;
  onChange: Function;
  value: any;
  title?: string;
}

const RadioBox: React.FC<Props> = ({options, onChange, value, title}) => {
  const [currentSelected, setCurrentSelected] = useState<string>('');

  const clickHandler = (value: string) => {
    onChange(value);
    setCurrentSelected(value);
  };
  useEffect(() => {
    if (!value || value === options[0].value) {
      setCurrentSelected(options[0].value);
    }
  }, [value]);

  return (
    <Component className="RadioBox">
      {title && <Text type="Text3">{title}</Text>}
      <div className="radio-wrapper">
        {options.map(({name, value, description}, i) => (
          <Tooltip title={description || name} key={i}>
            <span
              className={value === currentSelected ? 'selected option' : 'option'}
              onClick={() => clickHandler(value)}
            >
              {name}
            </span>
          </Tooltip>
        ))}
      </div>
    </Component>
  );
};

export default RadioBox;
