import WithPortal from '@/HOC/WithPortal';
import {Box, Curtain, Div, Text} from '@/UI';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useEmailEvents} from '@/Hooks/Sender';
import {useMemo} from 'react';
import {EmailEventsSearchParams} from '@/API/Sender/sender.types';
import ColorService from '@/Services/color.service';

const EmailEvents = () => {
  const {closeEmailEvents} = usePortalsStore().actions;
  const {emailProps} = usePortalsStore().selectors.emailEvents;
  const params: EmailEventsSearchParams = useMemo(
    () => ({email: emailProps.email, externalId: emailProps.externalId}),
    []
  );
  const {events} = useEmailEvents(params);
  return (
    <WithPortal>
      <Curtain
        headLineProps={{
          onClose: closeEmailEvents,
          disableMarker: true,
          title: `Email ${emailProps.email} events: ${events.count}`,
        }}
        curtainConfig={{
          disableAnimation: true,
          disableClickOut: true,
          height: '100vh',
          width: '500px',
          top: '0px',
          left: '0px',
        }}
      >
        <Div flexDirection="column" gap={20}>
          {events.logs.map((event, i) => (
            <Box key={i}>
              <Div gap={5} alignItems="flex-end">
                <Text type="Text3">Event: </Text>
                <Text type="Text1" fontWeight={800} color={ColorService.getColorTypeByStatus(event.event)}>
                  {event.event.toUpperCase()}
                </Text>
              </Div>
              <Div gap={5} alignItems="flex-end">
                <Text type="Text3">Service: </Text>
                <Text type="Text2" color="Purple" fontWeight={800}>
                  {event.service}
                </Text>
              </Div>
              <Div gap={5} alignItems="flex-end">
                <Text type="Text3">Sent at: </Text>
                <Text type="Text2" fontWeight={800} color="Success">
                  {event.sent_at}
                </Text>
              </Div>
            </Box>
          ))}
        </Div>
      </Curtain>
    </WithPortal>
  );
};
export default EmailEvents;
