import {css, DefaultTheme, keyframes} from 'styled-components';
export interface FlexProps {
  flexDirection?: 'row' | 'column';
  flexWrap?: 'wrap' | 'nowrap';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'space-between';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'baseline' | 'stretch';
  flexBasis?: string;
  flexGrow?: number;
  gap?: number;
}
declare module 'styled-components' {
  export interface DefaultTheme {
    Color: {
      White: string;
      Primary: string;
      Green: string;
      Laguna: string;
      LagunaLight: string;
      Red: string;
      Lime: string;
      DarkGray: string;
      DarkBlue: string;
      HardGray: string;
      Blue: string;
      BlueLight: string;
      Gray: string;
      MediumGray: string;
      GrayLight: string;
      Success: string;
      SuccessLight: string;
      Alert: string;
      AlertLight: string;
      Info: string;
      InfoLight: string;
      LightBG: string;
      BlackOcean: string;
      AliceBlue: string;
      YellowLight: string;
      AntiqueWhite: string;
      AntiqueWhiteLight: string;
      AntiqueRed: string;
      Orange: string;
      Purple: string;
      PurpleLight: string;
      Salad: string;
      Disable: string;
      AlertTransparent: string;
      SuccessTransparent: string;
    };
    SVGColor: {
      White: string;
      MediumGray: string;
      Success: string;
      Alert: string;
      Orange: string;
      LightGrey: string;
    };
    TextType: {
      Header1: string;
      Header2: string;
      Header3: string;
      Header4: string;
      Text1: string;
      Text2: string;
      Text3: string;
      Text4: string;
    };
    ButtonSize: {
      small: string;
      medium: string;
      large: string;
    };
    FontFamily: {
      Poppins: string;
      Outfit: string;
    };
    Device: {
      mobileS: string;
      mobileM: string;
      mobileL: string;
      tablet: string;
      laptop: string;
      laptopL: string;
      desktop: string;
      desktopL: string;
    };
    Animations: {
      pulse: any;
      jumpAndFade: any;
      progress: any;
      slideFromTop: any;
      movingGradient: any;
    };
    Presets: {
      scroll: any;
      iconHover: any;
      flexible: any;
    };
  }
}

export const THEME: DefaultTheme = {
  //Colors
  Color: {
    Green: '#00ff84',
    Red: '#ff3232',
    YellowLight: '#f7ffe7',
    Lime: '#eef3e1',
    Laguna: '#55d6d1',
    LagunaLight: '#ebfff3',
    White: '#FFFFFF',
    Primary: '#FFF037',
    DarkGray: '#4f4e4e',
    DarkBlue: '#293042',
    Gray: '#666666',
    HardGray: '#424242',
    MediumGray: '#9fa1a3',
    GrayLight: '#d8d8d8',
    Success: '#6cc99c',
    SuccessLight: '#b3e5cd',
    Blue: '#82c0ff',
    BlueLight: '#c7e1fc',
    Alert: '#ff8282',
    AlertLight: '#FEE2E7',
    Info: '#FFC406',
    InfoLight: '#fce8ab',
    AntiqueRed: '#cf7272',
    LightBG: '#EAFAF2',
    BlackOcean: '#232938',
    AliceBlue: '#ECF1F6',
    AntiqueWhite: '#f7c98c',
    AntiqueWhiteLight: 'antiquewhite',
    Orange: '#edbc2a',
    Purple: '#bf61bf',
    PurpleLight: '#d9d3f3',
    Salad: '#d9f9e6',
    Disable: '#f4f4f4',
    SuccessTransparent: '#edffea',
    AlertTransparent: '#fcf2f2',
  },
  SVGColor: {
    White: `invert(100%) sepia(100%) saturate(0%) hue-rotate(284deg)
    brightness(104%) contrast(101%)`,
    MediumGray: `invert(78%) sepia(0%) saturate(5025%) hue-rotate(211deg)
    brightness(87%) contrast(71%)`,
    Success: `invert(61%) sepia(68%) saturate(444%) hue-rotate(99deg) brightness(94%) contrast(86%)`,
    Alert: `invert(56%) sepia(20%) saturate(1325%) hue-rotate(314deg) brightness(113%) contrast(101%)`,
    Orange: `invert(85%) sepia(30%) saturate(4985%) hue-rotate(354deg) brightness(106%) contrast(102%);`,
    LightGrey: `invert(100%) sepia(55%) saturate(26%) hue-rotate(293deg) brightness(89%) contrast(89%);`,
  },
  TextType: {
    Header1: '3rem',
    Header2: '2rem',
    Header3: '1.5rem',
    Header4: '1.3rem',
    Text1: '1rem',
    Text2: '0.9rem',
    Text3: '0.7rem',
    Text4: '0.6rem',
  },
  ButtonSize: {
    small: 'small',
    medium: 'medium',
    large: 'large',
  },
  FontFamily: {
    Poppins: 'Poppins',
    Outfit: 'Outfit',
  },
  Device: {
    mobileS: `(max-width: 320px)`,
    mobileM: `(max-width: 375px)`,
    mobileL: `(max-width: 425px)`,
    tablet: `(max-width: 768px)`,
    laptop: `(max-width: 1024px)`,
    laptopL: `(max-width: 1296px)`,
    desktop: `(max-width: 1540px)`,
    desktopL: `(max-width: 2560px)`,
  },
  Animations: {
    movingGradient: keyframes`
      0% { background-position: -250px 0; }
      100% { background-position: 250px 0; }
    `,
    pulse: keyframes`
   0% {
       opacity: 0.1;
    }
    50% {
       opacity: 0.28;
    }
    100% {
       opacity: 0.1;
    }`,

    jumpAndFade: keyframes`
   0% {
      opacity: 1;
      transform: translateX(100%);
   }
   10% {
      transform: translateX(0%);
   }
   50% {
      opacity: 1;
      transform: translateX(0%);
   }
 
   100% {
      opacity: 0;
     transform: translateX(100%);
   }`,

    progress: keyframes`
   to {
      width: calc(100% - 10px);
   }`,

    slideFromTop: keyframes`
    0% {
      transform: translateX(30%);
    }
    100% {
      transform: translateX(0%);
    }
  `,
  },

  Presets: {
    flexible: css<FlexProps>`
      display: flex;
      flex-direction: row;
      ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
      ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
      ${props => props.alignItems && `align-items: ${props.alignItems};`}
      ${props => props.gap && `gap: ${props.gap}px;`}
      ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
      ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
      ${props => props.flexBasis && `flex-basis: ${props.flexBasis};`}
    `,
    iconHover: css`
      ${({theme}) => css`
        &:hover {
          background-color: ${theme.Color.GrayLight};
          border-radius: 15px;
        }
      `}
    `,
    scroll: css`
      ${({theme}) => css`
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-left: 0;
          border-right: 0;

          background-color: ${({theme}) => theme.Color.DarkBlue};
          border-radius: 15px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: ${theme.Color.GrayLight};
          border-radius: 15px;
          margin-bottom: 10px;
        }

        &::-webkit-scrollbar-track-piece:start {
          background: ${theme.Color.GrayLight};
          border-radius: 15px;
          margin-top: 50px;
        }
      `}
    `,
  },
};
