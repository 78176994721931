import {API} from '@/API/API';
import {ROUTES} from '@/Router/ROUTES';
import {useRootStore} from '@/Store/root/root.selector';
import useApiLoader from '../useApiLoader';

const useSetAuthUser = () => {
  const {setIsAuth, setUser} = useRootStore().actions;
  return useApiLoader(async (email: string) => {
    const Employee = await API.Users.getEmployeeByEmail(email);
    if (!Employee) throw new Error('Invalid permissions');
    setUser(Employee);
    setIsAuth(true);
    if (window.location.pathname === ROUTES.login) {
      window.location.pathname = ROUTES.default;
    }
  }, []);
};

export default useSetAuthUser;
