import {usePreOrderTalents} from '@/Hooks/Campaigns';
import {Box, ElementHeadline, TableContainer} from '@/UI';
import PreOrderRow from '@/Website/Components/Portals/Campaign/PreOrderTalents/PreOrderRow/PreOrderRow';
import {useMemo} from 'react';

const TalentPreOrder: React.FC<{talentID: string}> = ({talentID}) => {
  const params = useMemo(() => ({userID: talentID}), []);
  const {preOrders, reloadPreOrders} = usePreOrderTalents(params);
  const headers = useMemo(() => ['Talent', 'Price', 'Adv price', 'Campaign', '', ''], []);
  return (
    <Box className="TalentPreOrder tab-preset">
      <ElementHeadline title={`Total preorder: ${preOrders.length}`} disableMarker />
      <TableContainer headers={headers} isLoading={false} isSmall>
        {preOrders.map((preOrderItem, i) => (
          <PreOrderRow talent={preOrderItem} afterChangePreOrder={reloadPreOrders} key={i} />
        ))}
      </TableContainer>
    </Box>
  );
};
export default TalentPreOrder;
