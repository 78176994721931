import {ColorType} from '@/Styles/types';
import {THEME} from '@/Styles/theme';

class ColorService {
  static getColorByStatus(status: string): string {
    const color = this.getColorTypeByStatus(status);
    return THEME.Color[color];
  }
  static getColorTypeByStatus(incomingStatus?: string): ColorType {
    let color: ColorType = 'MediumGray';
    if (!incomingStatus) return color;
    let status = incomingStatus.toLowerCase();

    if (['pending', 'dispute', 'lead', 'mid-tier', 'pending-payment'].includes(status)) color = 'Purple';
    if (['offered', 'reminder', 'approved for campaign'].includes(status)) color = 'Laguna';

    if (
      ['notification', 'tracking', 'macro', 'onboarding', 'delivered', 'running', 'campaign full'].includes(
        status
      )
    )
      color = 'Blue';
    if (
      [
        'reject',
        'rejection',
        'rejected',
        'rejected by user',
        'nano',
        'suspended',
        'hard_bounce',
        'soft_bounce',
        'canceled',
      ].includes(status)
    )
      color = 'Alert';

    if (
      [
        'approve',
        'approval',
        'successfully',
        'click',
        'approved',
        'open',
        'mega',
        'active',
        'submitted',
        'successfully',
      ].includes(status)
    )
      color = 'Success';

    if (['micro', 'hidden', 'campaign', 'order', 'completed'].includes(status)) color = 'Orange';

    if (['inactive', 'posted', 'opened', 'draft', 'campaign-full'].includes(status)) color = 'AntiqueWhite';

    if (['incomplete', 'sending', 'click'].includes(status)) color = 'MediumGray';

    if (['suspended', 'failed', 'accepted', 'unsubscribed', 'blocked', 'deferred'].includes(status))
      color = 'AntiqueRed';
    return color;
  }
}
export default ColorService;
