import {useRootStore} from '@/Store/root/root.selector';
import {useCallback} from 'react';

const useConfirmation = (passCallBack: Function, message: string) => {
  const {setConfirmation} = useRootStore().actions;
  return useCallback(
    async () =>
      setConfirmation({
        confirmationCallbackPass: async () => {
          await passCallBack();
        },
        confirmationMsg: message,
      }),
    [message, passCallBack, setConfirmation]
  );
};
export default useConfirmation;
