import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useEffect, useState} from 'react';
import {AnalyticsResponse} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useCampaignAnalytic = () => {
  const {campaignPortalID} = usePortalsStore().selectors.campaignPortal;
  const [campaignAnalytic, setCampaignAnalytic] = useState<AnalyticsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = useApiLoader(
    async () => {
      setIsLoading(true);
      if (campaignPortalID)
        await API.Campaigns.getCampaignAnalytic(campaignPortalID)
          .then(res => setCampaignAnalytic(res))
          .finally(() => setIsLoading(false));
    },
    [campaignPortalID],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    campaignAnalytic,
    isLoading,
    reloadCampaignAnalytic: fetch,
  };
};
export default useCampaignAnalytic;
