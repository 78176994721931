import styled, {css} from 'styled-components';

export const SPaginationNumber = styled.div<{isActiveNumber: boolean}>`
  ${({theme, isActiveNumber}) => css`
    border: 1px solid ${theme.Color.GrayLight};
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.Color.MediumGray};
    ${isActiveNumber &&
    css`
      background-color: ${theme.Color.BlackOcean};
      border: 1px solid ${theme.Color.BlackOcean};
      color: ${theme.Color.White};
    `}
  `}
`;
export const SPagination = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 5px;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .pagination-input {
      margin-left: 20px;
      padding: 3px 10px;
      width: 45px;
      text-align: center;
      border-radius: 15px 0 0 15px;
      border: 1px solid #bcbaba;
      color: ${theme.Color.MediumGray};
      font-family: ${theme.FontFamily.Outfit};
    }
    .pagination-button {
      border-radius: 0 15px 15px 0;
    }

    .pagination-number {
      border-radius: 15px;
      &:hover {
        cursor: pointer;
        border: 1px solid ${theme.Color.MediumGray};
      }
    }
    .pagination-arrow {
      height: 25px;
      width: 25px;
      fill: ${theme.Color.DarkGray};
    }
    .pagination-left-arrow {
      transform: rotate(90deg);
    }

    .pagination-right-arrow {
      transform: rotate(-90deg);
    }
  `}
`;
