import {useCategorySelectOptions, useOrderOptions} from '@/Hooks/SelectOptions/index';
type OptionsKeys = 'categoryOptions' | 'orderOptions' | 'organizationOptions';
/**@return a function that generates the desired options */
const useSelectOptions = (option: OptionsKeys): any => {
  switch (option) {
    case 'categoryOptions':
      return useCategorySelectOptions;
    case 'orderOptions':
      return useOrderOptions;
  }
};

export default useSelectOptions;
