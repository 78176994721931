import {Box, Grid, Select, SocialIcon, TableContainer, TCell, Text, TRow} from '@/UI';
import {memo, useMemo, useState} from 'react';
import {SocialService, SocialsType} from '@/Services/social.service';
import {dateToLocalDate} from '@/Services/date.service';
import {useOrderInsights} from '../Hooks';

interface Props {
  orderID: number;
}
const OrderInsights: React.FC<Props> = ({orderID}) => {
  const [social, setSocial] = useState<SocialsType>();
  const params = useMemo(() => ({order_id: orderID, social}), [social]);
  const {insights} = useOrderInsights(params);
  const tableHeaders = useMemo(() => ['', 'Comments', 'Impressions', 'Likes', 'Created at'], []);
  return (
    <Box className="OrderInsight order-tab-preset">
      <Grid templateColumn="200px">
        <Select
          options={SocialService.SOCIALS_SELECT_OPTIONS}
          value={social}
          label="Social"
          onChange={value => setSocial(value)}
        />
      </Grid>
      <TableContainer isSmall headers={tableHeaders}>
        {insights.map((insight, i) => (
          <TRow key={i}>
            <TCell>
              <SocialIcon isActive social={insight.social_type} />
            </TCell>
            <TCell>
              <Text type="Text3">{insight.comments_number}</Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Blue">
                {insight.impressions_number}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Orange">
                {insight.likes_number}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success">
                {dateToLocalDate(insight.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default memo(OrderInsights);
