import {Box} from '@/UI';
import styled, {css} from 'styled-components';

export const Component = styled(Box)`
  ${({theme}) => css`
    border-top-left-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    .pre-order-list {
      height: 400px;
    }

    .adv {
      color: ${theme.Color.Alert};
    }
  `}
`;
