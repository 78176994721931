import {useOpenTalent} from '@/Hooks';
import {useCommunityStore} from '@/Store/community/community.selector';
import {FavoriteUserType} from '@/Store/community/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Avatar, Div, ElementHeadline, SocialIcon, Text} from '@/UI';
import {memo, useCallback, useMemo} from 'react';
import {calculateSum, updateTalentPrice} from './favorites.service';
import {Component} from './favorites.styled';

const Favorites: React.FC = () => {
  const {
    actions: {clearFavorites, deleteUserFromFavorite, setFavorites},
    selectors: {favorites},
  } = useCommunityStore();
  const {addUsersToTagPortal, addUsersToPreOrderPortal} = usePortalsStore().actions;

  const options = useMemo(() => {
    return favorites.length > 0
      ? [
          {onSelect: () => addUsersToTagPortal(favorites), option: 'To tag'},
          {
            onSelect: () => addUsersToPreOrderPortal(favorites),
            option: 'To campaign',
          },
          {
            onSelect: clearFavorites,
            option: 'Clear',
          },
        ]
      : [];
  }, [favorites, clearFavorites, addUsersToTagPortal]);

  const calculatedExpenseSum = useMemo(() => calculateSum(favorites), [favorites]);
  const handleOpenTalent = useOpenTalent();
  const handleChangePrice = useCallback(
    (user: FavoriteUserType, value: any) => {
      setFavorites(updateTalentPrice(favorites, user, value));
    },
    [favorites]
  );
  return (
    <>
      <Component className="Favorites">
        <ElementHeadline
          title={`Favorites: ${favorites?.length || 0}`}
          disableMarker
          options={options}
          fontSize="Text2"
        />
        <div className="favorites-list">
          {favorites.length > 0 ? (
            favorites.map((user, i) => (
              <div key={i} className="email-item">
                <Div gap={10} alignItems="center">
                  <div onClick={() => deleteUserFromFavorite({email: user.email})} className="delete-item">
                    <Text fontWeight={800}>-</Text>
                  </div>
                  {user.socialType && <SocialIcon social={user.socialType} isActive={true} />}
                  <Avatar image={user.photo} size="medium" onClick={() => handleOpenTalent(user.userID)} />
                  <Div flexDirection="column">
                    <Text italic type="Text3">
                      {user.name}
                    </Text>
                    <Text type="Text4" color="Success">
                      {user.email}
                    </Text>
                  </Div>
                </Div>
                <input
                  className="price-input"
                  value={user.price || 0}
                  type={'number'}
                  onChange={e => handleChangePrice(user, e.target.value)}
                />
              </div>
            ))
          ) : (
            <Text italic>no one has been added yet</Text>
          )}
        </div>
      </Component>
      <Div justifyContent="space-between" margin="7px 0px 0px" alignItems="flex-end">
        <Text type="Text3" fontWeight={600} color="MediumGray">
          Expense amount:
        </Text>
        <Text type="Text2" fontWeight={800} color="Alert">
          ${calculatedExpenseSum}
        </Text>
      </Div>
    </>
  );
};

export default memo(Favorites);
