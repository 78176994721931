import {ReactComponent as OrderIcon} from '@/Assets/Icons/talent/order.svg';
import {ReactComponent as StatsIcon} from '@/Assets/Icons/talent/stats.svg';
import {ReactComponent as AnalyticIcon} from '@/Assets/Icons/campaign/monitoring.svg';
import {ReactComponent as TrackIcon} from '@/Assets/Icons/order/track.svg';
import {ReactComponent as InsightsIcon} from '@/Assets/Icons/talent/stats.svg';
import {ReactComponent as MaterialsIcon} from '@/Assets/Icons/materials.svg';
import {ReactComponent as TalkIcon} from '@/Assets/Icons/talk.svg';
import {OrderStatType} from '@/API/Orders/types';

export const OrderService = {
  calculateAdvertiserPrice(price?: number | string): number {
    try {
      Number(price);
    } catch (err) {
      return 0;
    }
    if (!price || price <= 0) return 0;
    return Math.ceil((+price * 100) / 40);
  },
  tabs: [
    {label: 'Order', icon: OrderIcon},
    {label: 'Materials', icon: MaterialsIcon},
    {label: 'UTM', icon: TrackIcon},
    {label: 'Dispute & Revision', icon: TalkIcon},
    {label: 'Stats', icon: StatsIcon},
    {label: 'Analytics', icon: AnalyticIcon},
    {label: 'Insights', icon: InsightsIcon},
  ],
};
export const defaultCreateStatState: OrderStatType = {
  order_id: 0,
  impressions_num: 0,
  likes_num: 0,
  comments_num: 0,
  clicks_num: 0,
  actions_num: 0,
  saves_num: 0,
  shares_num: 0,
  created_at: '',
};
