import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';
import {
  CustomEmailSending,
  EmailEventsResponse,
  EmailEventsSearchParams,
  LogsResponse,
  LogsSearchParams,
  SendingLogsResponse,
  SendingLogsSearchParams,
} from '@/API/Sender/sender.types';

class SenderApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }

  async sentCustomEmail(data: CustomEmailSending) {
    return await this.api.post('/custom-email', data);
  }

  async getEmailLogs(params: LogsSearchParams): Promise<LogsResponse> {
    return await this.api
      .get('/email-logs', {
        params,
      })
      .then(res => res.data);
  }
  //
  async getSendingLogs(params: SendingLogsSearchParams): Promise<SendingLogsResponse> {
    return await this.api
      .get('/sending-logs', {
        params: {...params, sortBy: params.sortBy?.value},
      })
      .then(res => res.data);
  }
  //
  async getEmailEvents(params: EmailEventsSearchParams): Promise<EmailEventsResponse> {
    return await this.api
      .get(`/email-events`, {
        params,
      })
      .then(res => res.data);
  }
  //

  async getLogEventsCount(
    externalID: string | number
  ): Promise<{serviceProvider: string; events: Array<{count: number; event: string}>}> {
    return await this.api.get(`/log-events-count/${externalID}`).then(res => res.data);
  }
}
export default SenderApi;
