import WithPortal from '@/HOC/WithPortal';
import {SocialService} from '@/Services/social.service';
import {useCommunityStore} from '@/Store/community/community.selector';
import {Avatar, Box, Div, Grid, SocialIcon, Text} from '@/UI';
import {useFindReportByUserIDAndSocial} from '@/Website/Components/Portals/TalentReport/Hooks';
import TalentReportCurtain from '@/Website/Components/Portals/TalentReport/TalentReportCurtain/TalentReportCurtain';
import {AudienceReport} from '@/Website/Components/Portals/TalentReport/index';
import {GET_INFO} from '@/Website/Components/Portals/TalentReport/talentReport.service';
import {memo, useMemo} from 'react';

const TalentReport: React.FC = () => {
  const {talentReportStore} = useCommunityStore().selectors;
  const {report, reloadReport} = useFindReportByUserIDAndSocial(
    talentReportStore.talentID,
    talentReportStore.socialType
  );
  const {TAGS, SECTIONS, MAIN} = useMemo(() => GET_INFO(report), [report]);

  const contacts = useMemo(() => report?.contacts_collection || [], [report]);

  return report ? (
    <WithPortal>
      <TalentReportCurtain report={report} onReportChange={reloadReport}>
        <Grid templateColumn="300px 1fr ">
          <Avatar
            borderColor={report.active ? 'Success' : 'AntiqueRed'}
            size="xxxl"
            image={report.profile_photo}
          />
          <Grid templateColumn="1fr 1fr">
            {MAIN.map((value, i) => (
              <Div flexDirection="column" key={i}>
                <Text type="Text2">{value.label}</Text>
                <Text type="Header3" color={value.color} fontWeight={800}>
                  {value.value}
                </Text>
              </Div>
            ))}
          </Grid>
        </Grid>
        <Box label="Bio" backgroundColor="YellowLight">
          <Div flexDirection="column">
            <Div gap={5} alignItems="center" onClick={() => SocialService.handlePlatformClick(report.url)}>
              <SocialIcon size="large" isActive social={report.social_platform} />
              <Text fontWeight={800} type="Text1">
                {report.url}
              </Text>
            </Div>
            {report.bio_description}
          </Div>
        </Box>
        {SECTIONS.length > 0 &&
          SECTIONS?.map((section, i) => (
            <Box label={section.label} backgroundColor={section.background} key={i}>
              <Div flexWrap="wrap" gap={50}>
                {section.data?.length > 0 &&
                  section.data?.map((el, i) => (
                    <Box className="talent-info-column" key={i}>
                      <Div flexDirection="column">
                        <Text type="Text3">{el.label}</Text>
                        <Text type="Header3" fontWeight={800} color={el.color}>
                          {el.value}
                        </Text>
                      </Div>
                    </Box>
                  ))}
              </Div>
            </Box>
          ))}
        <AudienceReport
          audience={{
            audience_ages: report.audience_ages,
            audience_countries: report.audience_countries,
            audience_ethnicity: report.audience_ethnicity,
            audience_genders: report.audience_genders,
            audience_languages: report.audience_languages,
          }}
        />
        {TAGS?.map((el, i) =>
          el.data.length > 0 ? (
            <Box key={i} label={el.label} backgroundColor={el.background}>
              <Div flexWrap="wrap" gap={20}>
                {el.data?.map((tag, i) => (
                  <Box key={i} className="social-info-tag">
                    <Text type="Text3">{tag}</Text>
                  </Box>
                ))}
              </Div>
            </Box>
          ) : (
            <></>
          )
        )}

        {contacts && contacts.length > 0 && (
          <Box label="Other contacts" backgroundColor="SuccessTransparent">
            {contacts.map((contact, i) => (
              <Div flexDirection="column" margin="10px 0" key={i}>
                <Text type="Text2" fontWeight={800}>
                  {contact.type}
                </Text>
                <Text type="Text3" color="Blue">
                  {contact.formatted_value}
                </Text>
              </Div>
            ))}
          </Box>
        )}
      </TalentReportCurtain>
    </WithPortal>
  ) : null;
};

export default memo(TalentReport);
