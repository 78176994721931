import {ColorType} from '@/Styles/types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import Div from '../Div/Div';
import Text from '../Text/Text';
import {Component, SLevelItem} from './talentLevel.styled';
import {Tooltip} from '@/UI';

type LevelType = {
  level: string;
  value: any;
  color: ColorType;
};
type LevelProps = {
  value: string;
  onChange: Function;
};

const TalentLevel: React.FC<LevelProps> = ({onChange, value}) => {
  const [selectedLevel, setSelectedLevel] = useState<LevelType>({
    level: 'All',
    value: '',
    color: 'GrayLight',
  });
  const levels = useMemo(
    () =>
      [
        {level: 'All', value: '', color: 'GrayLight'},
        {level: 'None', value: 'None', color: 'MediumGray'},
        {level: 'Nano', value: 'Nano', color: 'Alert'},
        {level: 'Micro', value: 'Micro', color: 'Orange'},
        {level: 'Mid-Tier', value: 'Mid-Tier', color: 'Purple'},
        {level: 'Macro', value: 'Macro', color: 'Blue'},
        {level: 'Mega', value: 'Mega', color: 'Success'},
      ] as LevelType[],
    []
  );
  const selectLevel = useCallback(
    (level: LevelType) => {
      setSelectedLevel(level);
      onChange(level.value);
    },
    [onChange]
  );

  useEffect(() => {
    const findLevel = levels.find(el => el.value === value);
    setSelectedLevel(findLevel || (levels[0] as LevelType));
  }, [levels, value]);
  return (
    <Component className="Level">
      <Div gap={6} alignItems="baseline" justifyContent="center">
        <Text type="Text3">Talent level:</Text>
        <Text type="Text2" color={selectedLevel.color} fontWeight={800}>
          {selectedLevel.level}
        </Text>
      </Div>
      <Div gap={4} margin="5px 0px 0px">
        {levels.map((level, i) => (
          <Tooltip title={level.level} key={i}>
            <SLevelItem
              backColor={level.color}
              onClick={() => selectLevel(level)}
              isSelected={level.level === selectedLevel.level}
            />
          </Tooltip>
        ))}
      </Div>
    </Component>
  );
};

export default TalentLevel;
