import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {CategoryType} from '@/API/Categories/types';
import {useCommunityStore} from '@/Store/community/community.selector';

const useGetUsersCategories = () => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const {
    storedUser: {userID},
  } = useCommunityStore().selectors;
  const fetch = useApiLoader(async () => {
    await API.Categories.getUserCategories(userID).then(res => setCategories(res));
  }, [userID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    categories,
    reloadCategories: fetch,
  };
};
export default useGetUsersCategories;
