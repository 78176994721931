import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {UserTagType} from '@/API/Tags/types';

const useGetTagsByUser = (userID: string) => {
  const [tags, setTags] = useState<UserTagType[]>([]);

  const fetch = useApiLoader(async () => {
    await API.Tags.getTagsByUserID(userID).then(res => {
      setTags(res);
    });
  }, [userID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    tags,
    reloadTags: fetch,
  };
};
export default useGetTagsByUser;
