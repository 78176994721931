import {CompletionDTO} from '@/API/Openai/openai.types';

export const OpenAIDialogService = {
  defaultPromptState: {
    prompt: '',
    maxTokens: 10,
    temperature: 1,
  } as CompletionDTO,
  maxTokensRange: [
    ['10', '10'],
    ['30', '30'],
    ['50', '50'],
    ['70', '70'],
    ['100', '100'],
    ['150', '150'],
    ['200', '200'],
  ],
  temperatureRange: Array(10)
    .fill(' ')
    .map((_, i) => [`${i + 1}`, `${i + 1}`]),
};
