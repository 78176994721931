import {dateToLocalDate} from '@/Services/date.service';
import {Box, ElementHeadline, TCell, TRow, TableContainer, Text} from '@/UI';
import useGetLastTalentActivity from '@/Website/Components/Talent/Hooks/useGetLastTalentActivity';
import {memo, useMemo} from 'react';

const TalentLastActivity = () => {
  const {lastActivity} = useGetLastTalentActivity();
  const headers = useMemo(() => ['IP', 'Platform', 'Agent', 'Created at'], []);
  return (
    <Box className="TalentLastActivity tab-preset">
      <ElementHeadline title="Join history" disableMarker />
      <TableContainer headers={headers} isSmall isLoading={false}>
        {lastActivity.map((activity, i) => (
          <TRow key={i}>
            <TCell>
              <Text fontWeight={800} type="Text3" color="Orange">
                {activity.ip_address}
              </Text>
            </TCell>
            <TCell>
              <Text fontWeight={800} type="Text3" color="Blue">
                {activity.client_platform}
              </Text>
            </TCell>
            <TCell>
              <Text style={{width: 750}} fontWeight={800} type="Text3" color="Purple">
                {activity.user_agent}
              </Text>
            </TCell>
            <TCell>
              <Text fontWeight={800} type="Text3" color="Success">
                {dateToLocalDate(activity.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default memo(TalentLastActivity);
