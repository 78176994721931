import styled, {css} from 'styled-components';

export const Component = styled.div<{isOpen: boolean}>`
  ${({theme, isOpen}) => css`
    position: relative;
    width: 100%;

    .folder-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 3px 15px;
      max-height: ${isOpen ? '2000px' : '28px'};
      overflow: ${isOpen ? 'visible' : 'hidden'};
      transition: max-height 0.1s;

      .header {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        border-bottom: 1px solid ${theme.Color.MediumGray};
        cursor: pointer;
        .title {
          font-size: 0.7rem;
          font-family: ${theme.FontFamily.Outfit};
          font-weight: 800;
        }
        .arrow {
          height: 20px;
          transition: transform 0.3s ease;
          ${isOpen &&
          css`
            transform: rotate(180deg);
          `}
        }
      }
      .children {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
      }
      ${isOpen &&
      css`
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: 0 1px 5px ${theme.Color.MediumGray};
        border-radius: 15px;
        padding: 5px;
        .header {
          border: none;
          text-decoration: underline;
          div {
            font-size: 1rem !important;
        }

      `}
    }
  `}
`;
