import {INFLUENCERS_CONSTANTS} from '@/Website/Pages/Influencers/influencers.service';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import ExportService from '@/Services/excel.service';

const useExportTalents = (filterState: typeof INFLUENCERS_CONSTANTS.defaultFilterState) => {
  return useApiLoader(async () => {
    await API.Talents.getTalents({
      statuses: [{label: 'pending', value: 'pending'}],
      ...filterState,
      limit: 999999,
      skip: 0,
    }).then(res => {
      ExportService.exportToExl(res.talents);
    });
  }, [filterState]);
};
export default useExportTalents;
