import {useEffect, useState} from 'react';
import {TalentInsight} from '@/API/Talents/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useTalentInsights = (params: {userID: string; social?: string}) => {
  const [insights, setInsights] = useState<TalentInsight[]>([]);
  const fetch = useApiLoader(async () => {
    return await API.Talents.getTalentInsight(params).then(res => setInsights(res));
  }, [params]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    insights,
  };
};
export default useTalentInsights;
