import {useEffect, useState} from 'react';
import {TaggedUsers} from '@/API/Tags/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useGetUsersByTag = (params: {tagID?: any; searchValue?: string}) => {
  const [{users, count}, setUsers] = useState<{users: TaggedUsers[]; count: number}>({count: 0, users: []});
  const fetch = useApiLoader(async () => {
    if (params.tagID && params.searchValue !== undefined) {
      await API.Tags.getUsersByTagID(params.tagID, params.searchValue).then(res => setUsers(res));
    }
  }, [params.searchValue]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    users,
    count,
    reloadTagUsers: fetch,
  };
};
export default useGetUsersByTag;
