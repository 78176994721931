import {ReactComponent as DetailsIcon} from '@/Assets/Icons/talent/details.svg';
import {ReactComponent as SocialsIcon} from '@/Assets/Icons/talent/socials.svg';
import {ReactComponent as WalletIcon} from '@/Assets/Icons/talent/wallet.svg';
import {ReactComponent as FinancialIcon} from '@/Assets/Icons/talent/financial.svg';
import {ReactComponent as OrderIcon} from '@/Assets/Icons/talent/order.svg';
import {ReactComponent as SendIcon} from '@/Assets/Icons/sidebar/send.svg';
import {ReactComponent as PreOrderIcon} from '@/Assets/Icons/campaign/preorder.svg';
import {ReactComponent as ConnectIcon} from '@/Assets/Icons/talent/connect.svg';
import {ReactComponent as AnalyticIcon} from '@/Assets/Icons/campaign/monitoring.svg';
import {ReactComponent as InsightIcon} from '@/Assets/Icons/talent/stats.svg';
import {SocialService, SocialsType} from '@/Services/social.service';

export const TALENT_CONSTANTS = {
  tabs: [
    {label: 'Details', icon: DetailsIcon},
    {label: 'Socials', icon: SocialsIcon},
    {label: 'Wallet', icon: WalletIcon},
    {label: 'Orders', icon: OrderIcon},
    {label: 'PreOrder', icon: PreOrderIcon},
    {label: 'Analytics', icon: AnalyticIcon},
    {label: 'Last activity', icon: ConnectIcon},
    {label: 'Financial docs', icon: FinancialIcon},
    {label: 'Emails', icon: SendIcon},
    {label: 'Insights', icon: InsightIcon},
  ],
  statuses: [
    {label: 'Active', value: 'active'},
    {label: 'Pending', value: 'pending'},
    {label: 'Incomplete', value: 'incomplete'},
    {label: 'Inactive', value: 'inactive'},
    {label: 'Hidden', value: 'hidden'},
    {label: 'Suspended', value: 'suspended'},
  ],
  socials: [
    {label: 'youtube', value: 'youtube'},
    {label: 'instagram', value: 'instagram'},
    {label: 'facebook', value: 'facebook'},
    {label: 'tiktok', value: 'tiktok'},
    {label: 'twitter', value: 'twitter'},
    {label: 'twitch', value: 'twitch'},
    {label: 'linkedin', value: 'linkedin'},
    {label: 'discord', value: 'discord'},
  ] as Array<{label: string; value: SocialsType}>,
  prefferedPlatforms: [
    ...SocialService.SOCIALS_SELECT_OPTIONS,
    {label: 'phone', value: 'phone'},
    {label: 'email', value: 'email'},
    {label: 'telegram', value: 'telegram'},
    {label: 'whatsapp', value: 'whatsapp'},
  ],
};
