import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {OrderStatDetailedType} from '@/API/Orders/types';

const useUpdateOrderStat = (stat: OrderStatDetailedType, onChange: Function) => {
  return useApiLoader(
    async () => {
      await API.Orders.updateOrderStat(stat).then(() => {
        onChange();
      });
    },
    [stat, onChange],
    {successMsg: 'Stat updated'}
  );
};
export default useUpdateOrderStat;
