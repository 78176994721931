import {CompletionDTO} from '@/API/Openai/openai.types';
import {useOpenAIDialogStore} from '@/Store/openaiDialog/openaiDialog.selector';
import {useEffect, useState} from 'react';
import {OpenAIDialogService} from '../openAIDialog.service';

const useCompletePromptState = () => {
  const {dialogSettings} = useOpenAIDialogStore().selectors;
  const [completePrompt, setCompletePrompt] = useState<CompletionDTO>(OpenAIDialogService.defaultPromptState);
  useEffect(() => {
    setCompletePrompt({
      prompt: dialogSettings.prompt,
      maxTokens: dialogSettings?.maxTokens,
      temperature: dialogSettings?.temperature,
    });
  }, [dialogSettings]);
  return {
    completePrompt,
    setCompletePrompt,
  };
};
export default useCompletePromptState;
