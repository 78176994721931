export type StorageKeys =
  | 'TOKEN'
  | 'APP-VERSION'
  | 'IQDATA'
  | 'TEMPLATE-CAMPAIGN'
  | 'TEMPLATE-ONBOARDING'
  | 'USER';
export class StorageService {
  static set(key: StorageKeys, value: string) {
    return localStorage.setItem(key, value);
  }
  static get(key: StorageKeys) {
    return localStorage.getItem(key);
  }
  static delete(key: StorageKeys) {
    const token = localStorage.getItem(key);
    token && localStorage.removeItem(key);
  }
  static deleteAll() {
    localStorage.clear();
  }
}
