import {Button, Div, ElementHeadline, Modal, Text} from '@/UI';
import {memo, useState} from 'react';
import {GenericSelect, SubStatusSelect} from '../../Common';
import {OrderStatusType} from '@/Website/Pages/Orders/orders.service';
import useCloseOrdersUpdater from './Hooks/useCloseOrdersUpdater';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import useUpdateOrderStatuses from './Hooks/useUpdateOrdersStatuses';

const OrdersUpdater = () => {
  const {
    ordersUpdater: {ordersToUpdate},
  } = usePortalsStore().selectors;
  const updateStatuses = useUpdateOrderStatuses();
  const closeModal = useCloseOrdersUpdater();
  const [selectedStatuses, setSelectedStatuses] = useState<{status: OrderStatusType; subStatus?: string}>({
    status: 'offered',
  });
  return (
    <Modal isShowModal={true}>
      <ElementHeadline onClose={closeModal} title={`Orders to update: ${ordersToUpdate?.length || 0}`} />
      <Div width={400} flexDirection="column" gap={10} justifyContent="center" alignItems="center">
        {ordersToUpdate && ordersToUpdate?.length > 0 ? (
          <>
            <GenericSelect
              optionsType="orderStatuses"
              label="Order status"
              value={selectedStatuses.status}
              defaultPlaceholder={selectedStatuses.status}
              onChange={value => setSelectedStatuses(prev => ({...prev, status: value}))}
            />
            <SubStatusSelect
              mainStatus={selectedStatuses.status}
              defaultPlaceholder={selectedStatuses.subStatus}
              label="Sub status"
              onChange={value => setSelectedStatuses(prev => ({...prev, subStatus: value}))}
              value={selectedStatuses.subStatus}
            />
            <Button
              onClick={async () =>
                await updateStatuses({orders: ordersToUpdate, ...selectedStatuses}).then(closeModal)
              }
              color="Purple"
              disabled={!selectedStatuses.status}
            >
              UPDATE
            </Button>
          </>
        ) : (
          <Text type="Text2">Nothing to update</Text>
        )}
      </Div>
    </Modal>
  );
};
export default memo(OrdersUpdater);
