import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {PreOrderUpdatePayload} from '@/API/Campaign/types';

const useUpdatePreOrder = (payload: PreOrderUpdatePayload, callBack?: Function) => {
  return useApiLoader(
    async () => {
      await API.Campaigns.updatePreOrderTalent(payload).then(() => callBack && callBack());
    },
    [payload, callBack],
    {successMsg: 'Updated'}
  );
};
export default useUpdatePreOrder;
