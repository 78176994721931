import {useReduceState} from '@/Hooks';
import {dateInputAdapter} from '@/Services/date.service';
import {SelectService} from '@/Services/select.service';
import {Box, Div, Input, Phone, Select, Switch, TextArea} from '@/UI';
import {UTIL_CONSTANTS} from '@/Util/constants';
import TalentCategoriesTags from '@/Website/Components/Talent/TalentCategoriesTags/TalentCategoriesTags';
import AboutTalent from '@/Website/Components/Talent/TalentDetail/AboutTalent';
import TalentMediaFiles from '@/Website/Components/Talent/TalentDetail/TalentMediaFiles';
import {useMemo} from 'react';
import {TALENT_CONSTANTS} from '../talent.service';
import {TalentDetailProps} from '../types';
import {Component} from './talentDetail.styled';

const TalentDetail: React.FC<TalentDetailProps> = ({talent, setTalent}) => {
  const {statuses} = useMemo(() => TALENT_CONSTANTS, []);
  const handleInputs = useReduceState(setTalent);
  return (
    <Component className="TalentDetail tab-preset">
      <AboutTalent talent={talent} />
      <Box backgroundColor="AliceBlue" label="Details">
        <Div flexDirection="column">
          <Select
            options={statuses}
            value={talent.status}
            defaultPlaceholder={talent.status}
            label="Status"
            onChange={value => handleInputs('status', value)}
          />
          <Input
            placeholder="Second email"
            type="email"
            value={talent.secondary_email || ''}
            onChange={e => handleInputs('secondary_email', e.target.value)}
          />

          <Phone value={talent.phone_number} onChange={(e: any) => handleInputs('phone_number', e)} />

          <Select
            isSearchable={true}
            options={UTIL_CONSTANTS.COUNTRIES}
            label="Country"
            value={talent.country}
            onChange={value => handleInputs('country', value)}
            defaultPlaceholder={talent.country}
          />
          <Select
            label="Preferred platform"
            value={talent.preferred_comm_platform}
            onChange={value => handleInputs('preferred_comm_platform', value)}
            options={TALENT_CONSTANTS.prefferedPlatforms}
            defaultPlaceholder={talent.preferred_comm_platform}
          />
          <Input
            placeholder="Full name"
            value={talent.full_name || ''}
            onChange={e => handleInputs('full_name', e.target.value)}
          />

          <Input
            placeholder="Birthdate"
            value={dateInputAdapter(talent.birth_date)}
            type={'date'}
            onChange={(e: any) => handleInputs('birth_date', e.target.value)}
          />
          <Select
            isSearchable={true}
            options={UTIL_CONSTANTS.LANGUAGES}
            label="Languages"
            value={SelectService.convertLanguagesCodeArrayToSelectValue(talent.languages)}
            onChange={(e: any) =>
              handleInputs('languages', SelectService.convertSelectOptionsToValuesArray(e))
            }
            isMulti
          />
        </Div>
      </Box>
      <Box backgroundColor="AliceBlue" label="Bio" flexDirection="column">
        <Switch
          isOn={talent.is_ugc_creator}
          option="UGC Creator"
          onToggle={value => handleInputs('is_ugc_creator', value)}
        />
        <TextArea
          value={talent.short_bio || ''}
          onChange={e => handleInputs('short_bio', e.target.value)}
          placeholder="Short bio"
          row={5}
        />
        <TextArea
          value={talent.bio || ''}
          onChange={e => handleInputs('bio', e.target.value)}
          placeholder="Bio"
          row={18}
        />
      </Box>
      <Box backgroundColor="AlertTransparent" label="Comments" flexDirection="column">
        <TextArea
          value={talent.address || ''}
          onChange={e => handleInputs('address', e.target.value)}
          placeholder="Address"
          row={10}
        />
        <TextArea
          value={talent.notes || ''}
          onChange={e => handleInputs('notes', e.target.value)}
          placeholder="Notes"
          row={16}
        />
      </Box>
      <TalentCategoriesTags talent={talent} />
      <TalentMediaFiles talent={talent} setTalent={setTalent} />
    </Component>
  );
};
export default TalentDetail;
