import {useCallback} from 'react';
import {Avatar, Box, Button, ContextMenu, Curtain, Div, Grid, SocialIcon, Text} from '@/UI';
import {useTalentReports} from '@/Website/Components/Talent/Hooks';
import WithPortal from '@/HOC/WithPortal';
import {ITalentInfoCheckSocial} from '@/API/TalentInfo/types';
import {useCommunityStore} from '@/Store/community/community.selector';
import {usePortalsStore} from '@/Store/portals/portals.selector';

interface SelectSocialToLikeProps {
  onClose: Function;
  talentInitials: {
    userID: string;
    email: string;
  };
}

const SelectSocialToLike: React.FC<SelectSocialToLikeProps> = props => {
  const {onClose, talentInitials} = props;
  const {reports} = useTalentReports(talentInitials.userID);
  const {
    actions: {addUserToFavorite},
  } = useCommunityStore();
  const {addUsersToPreOrderPortal} = usePortalsStore().actions;
  const handleSelectSocial = useCallback((report: ITalentInfoCheckSocial) => {
    addUserToFavorite({
      user: {
        userID: talentInitials.userID,
        social: report.url,
        socialType: report.social_platform,
        photo: report.profile_photo,
        name: report.full_name,
        talentInfoID: report.id,
        price: report.price_value_score,
        email: talentInitials.email,
        advertiserPrice: report.advertiser_price,
        phoneNumber: report.phone_number,
      },
    });
    onClose();
  }, []);
  const menuOptions = useCallback(
    (report: ITalentInfoCheckSocial) => [
      {
        option: 'To campaign',
        onSelect: () =>
          addUsersToPreOrderPortal([
            {
              price: report.price_value_score,
              advertiserPrice: report.advertiser_price,
              userID: report.user_id,
              email: report.email,
              name: report.full_name || report.email,
              talentInfoID: report.id,
            },
          ]),
      },
    ],
    []
  );
  return (
    <WithPortal>
      <Curtain
        curtainConfig={{height: '100vh', width: '400px', disableAnimation: true}}
        headLineProps={{onClose, disableMarker: true, title: 'Select talent social'}}
      >
        <Div flexDirection="column" gap={20}>
          {reports.map((report, i) => (
            <Box key={i} flexDirection="column">
              <Grid templateColumn="100px 1fr 20px">
                <Avatar image={report.profile_photo} size="big+" />
                <Div flexDirection="column">
                  <Div gap={5} alignItems="center">
                    <Text type="Text3">Report id: </Text>
                    <Text type="Text2" color="Success" fontWeight={800}>
                      {report.id}
                    </Text>
                  </Div>
                  <Div gap={5} alignItems="center">
                    <Text type="Text3">Price: </Text>
                    <Text type="Text2" color="Orange" fontWeight={800}>
                      {report.price_value_score}
                    </Text>
                  </Div>
                  <Div gap={5} alignItems="center">
                    <Text type="Text3">Adv Price: </Text>
                    <Text type="Text2" color="Alert" fontWeight={800}>
                      {report.advertiser_price}
                    </Text>
                  </Div>
                </Div>
                <ContextMenu options={menuOptions(report)} />
              </Grid>
              <Div gap={10} alignItems="center">
                <SocialIcon isActive social={report.social_platform} />
                <Text wordBreak="break-all" type="Text4">
                  {report.url}
                </Text>
              </Div>
              <Button color="Success" size="medium" onClick={() => handleSelectSocial(report)}>
                ADD TO FAVORITE
              </Button>
            </Box>
          ))}
        </Div>
      </Curtain>
    </WithPortal>
  );
};
export default SelectSocialToLike;
