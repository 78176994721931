import {ColorType} from '@/Styles/types';
import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    .level-item {
      height: 10px;
      flex: 1;
    }
    .level-item:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    .level-item:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  `}
`;
export const SLevelItem = styled.div<{backColor: ColorType; isSelected: boolean}>`
  ${props => css`
    height: 10px;
    cursor: pointer;
    position: relative;
    flex: 1;
    display: flex;
    background-color: ${props.theme.Color[props.backColor]};
    &:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
    ${props.isSelected &&
    css`
      &::after {
        content: '';
        font-size: 0.7rem;
        position: absolute;
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-right-width: 4px;
        border-left-width: 4px;
        border-bottom: 7px solid ${props.theme.Color[props.backColor]};
        margin-top: 12px;
        margin-left: 12px;
      }
    `}
  `}
`;
