import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeletePreOrder = (params: {campaignID: number; talentInfoId: number}, callBack?: Function) => {
  return useApiLoader(
    async () => {
      await API.Campaigns.deletePreOrderTalent(params.campaignID, params.talentInfoId).then(
        () => callBack && callBack()
      );
    },
    [params],
    {successMsg: 'Deleted'}
  );
};
export default useDeletePreOrder;
