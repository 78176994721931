import styled, {css} from 'styled-components';
import {BoxProps} from '@/UI/Box/Box';

const SBox = styled.div<BoxProps>`
  ${props => css`
    position: relative;
    max-width: 100%;
    box-shadow: rgb(34 34 34 / 20%) 0 5px 15px;
    border-radius: 15px;
    padding: 15px 20px 15px;
    background-color: white;

    ${props.flexDirection === 'column' &&
    `
         display: flex;
         flex-direction: column;
         gap: 10px;
      `}
    ${props.flexDirection === 'row' &&
    `
         display: flex;
         flex-direction: row;
      `} 
    ${props.justifyContent &&
    `
         justify-content: ${props.justifyContent};
      `}  
    ${props.flexGrow &&
    `
         flex: ${props.flexGrow};
      `}${props.flexBasis &&
    `
         flex-basis: ${props.flexBasis};
      `} 
    ${props.borderColor &&
    `
         border: 1px solid ${props.theme.Color[props.borderColor]};
      `}
    ${props.backgroundColor &&
    `
         background-color: ${props.theme.Color[props.backgroundColor]};
      `}
      .boxLabel {
      position: absolute;
      background-color: #ddf3dd;
      color: black;
      border: 1px solid #dedede;
      font-family: ${props.theme.FontFamily.Outfit};
      font-size: 0.7rem;
      font-weight: 600;
      border-radius: 15px;
      padding: 0 13px;
      top: -8px;
    }
  `}
`;
export default SBox;
