import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useClearTag = (tagID: number, onClear: Function) => {
  return useApiLoader(
    async () =>
      await API.Tags.clearTag(tagID).then(() => {
        onClear();
      }),
    [onClear, tagID]
  );
};
export default useClearTag;
