import {Box} from '@/UI';
import {memo} from 'react';
import styled from 'styled-components';

const Component = styled(Box)`
  border-top-left-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  position: relative;
  .open-summary-link-button {
    text-decoration: none;
  }
  .col {
    margin: 5px;
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    justify-content: space-between;
  }
  .first {
    background-color: #eff5ff;
  }
  .second {
    background-color: #fff4ef;
  }
  .third {
    background-color: #f7f7f7;
  }

  .campaign-details {
    &-body {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default memo(Component);
