import {ReactElement} from 'react';
import {
  Tooltip as MaterialTooltip,
  TooltipProps,
  createTheme,
  withStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import {memo} from 'react';
import {THEME} from '@/Styles/theme';
const defaultTheme = createTheme();

const DefaultTooltip = withStyles({
  tooltip: {
    color: THEME.Color.HardGray,
    backgroundColor: THEME.Color.White,
    fontSize: '1rem',
    fontFamily: 'Outfit',
    boxShadow: `1px 1px 6px ${THEME.Color.MediumGray}`,
    position: 'relative',
  },
})(MaterialTooltip);
const CustomTooltip = withStyles({
  tooltip: {
    color: THEME.Color.White,
    backgroundColor: THEME.Color.HardGray,
    fontSize: '0.6rem',
    fontFamily: 'Outfit',
    position: 'relative',
    // marginLeft: '-50px',
    marginTop: '-45px',
    zIndex: 10000,
  },
})(MaterialTooltip);

interface Props extends TooltipProps {
  children: ReactElement<any, any>;
  tooltipType?: 'tip';
}

const Tooltip: React.FC<Props> = props => {
  const {tooltipType, ...restProps} = props;
  return (
    <MuiThemeProvider theme={defaultTheme}>
      {tooltipType === 'tip' ? (
        <CustomTooltip disableFocusListener disableTouchListener {...restProps}>
          {restProps.children}
        </CustomTooltip>
      ) : (
        <DefaultTooltip disableFocusListener disableTouchListener {...restProps}>
          {restProps.children}
        </DefaultTooltip>
      )}
    </MuiThemeProvider>
  );
};

export default memo(Tooltip);
