import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useMarkCampaignAsDeleted = (callBack?: Function) => {
  return useApiLoader(
    async (campaignID: number) => {
      return await API.Campaigns.toggleCampaignAsDeleted(campaignID).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Campaign deleted'}
  );
};
export default useMarkCampaignAsDeleted;
