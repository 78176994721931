import {ContextMenu, ElementHeadline, Grid, Input, Modal, Text} from '@/UI';
import {useCallback} from 'react';
import styled, {css} from 'styled-components';
import {CampaignDetailsType} from '@/API/Campaign/types';

interface BrandLinksProps {
  links?: CampaignDetailsType['links'];
  setLinks: (links?: CampaignDetailsType['links']) => void;
  isShow: boolean;
  onClose: Function;
}
const SBrandLinks = styled.div`
  ${({theme}) => css`
    height: 500px;
    min-width: 400px;
    overflow: hidden;
    ${theme.Presets.scroll}
  `}
`;
const BrandLinks: React.FC<BrandLinksProps> = props => {
  const {onClose, isShow, links, setLinks} = props;

  const handleChangeURL = useCallback(
    (value: string, index: number, prop: 'name' | 'url') => {
      const res = links ? [...links] : [];
      if (prop === 'url') {
        res[index] = {
          url: value,
          name: res[index].name,
        };
      } else if (prop === 'name') {
        res[index] = {
          url: res[index].url,
          name: value,
        };
      }

      setLinks(res);
    },
    [setLinks, links]
  );

  const createNewLink = useCallback(() => {
    const res = links ? [...links, {name: 'Link', url: ''}] : [{name: 'Link', url: ''}];
    setLinks(res);
  }, [setLinks]);

  const contextOptions = useCallback(
    (link: {url: string; name: string}, index: number) => {
      return [
        {
          option: 'Remove',
          onSelect: () => {
            const tempLinks = links?.length ? [...links] : [];
            tempLinks.splice(index, 1);
            setLinks(tempLinks);
          },
        },
      ];
    },
    [links]
  );
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline onClose={onClose} onCreateNew={createNewLink} disableMarker title="Brand links" />
      <SBrandLinks className="SBrandLinks">
        {links &&
          links?.length > 0 &&
          links.map((link, i) => (
            <Grid columnGap={10} templateColumn="40px 200px 400px 40px" alignItems="baseline" key={i}>
              <Text type="Text3">{i + 1}</Text>
              <Input
                placeholder="Name"
                value={links[i].name}
                onChange={e => handleChangeURL(e.target.value, i, 'name')}
              />
              <Input
                placeholder="URL"
                value={links[i].url}
                onChange={e => handleChangeURL(e.target.value, i, 'url')}
              />
              <ContextMenu options={contextOptions(link, i)} />
            </Grid>
          ))}
      </SBrandLinks>
    </Modal>
  );
};
export default BrandLinks;
