import {useCallback, useState} from 'react';

export const useBooleanState = () => {
  const [isTurn, setIsTurn] = useState<boolean>(false);
  const turnOn = useCallback(() => setIsTurn(true), [isTurn]);
  const turnOff = useCallback(() => setIsTurn(false), [isTurn]);
  const toggle = useCallback(() => setIsTurn(!isTurn), [isTurn]);

  return {
    isTurn,
    turnOn,
    turnOff,
    toggle,
  };
};
