export const URL = {
  REPORTS_API_BASE: 'https://reports-api.letstok.com/v1/',
  AWS_AUTH: 'https://ubvefhi4el.execute-api.eu-west-1.amazonaws.com/admin/login',
  MEDIA_ROUTE: 'https://ik.imagekit.io/raqwvm0o0/',
  WEBSITE_TALENT_ROUTE: 'https://letstok.com/talent/',

  BASE_ADMIN: process.env['REACT_APP_BASE_ADMIN'] as string,
  // BASE_ADMIN: 'http://localhost:8586',
  DISCOVERY_MS: process.env['REACT_APP_DISCOVERY_MS'] as string,
  ADMIN_MS: process.env['REACT_APP_ADMIN_MS'] as string,
};
