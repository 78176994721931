import {API} from '@/API/API';
import {ITalentReportDTO} from '@/API/TalentInfo/types';
import {useApiLoader, useReduceState} from '@/Hooks';
import {Box, Div, ElementHeadline, Grid, Input, Modal, Select, TextArea} from '@/UI';
import {UTIL_CONSTANTS} from '@/Util/constants';
import {useEffect, useState} from 'react';

interface TalentInfoEditorProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
  info: ITalentReportDTO;
}
const TalentReportEditor: React.FC<TalentInfoEditorProps> = props => {
  const {info, onClose, isShow, onChange} = props;
  const [payloadState, setPayloadState] = useState<ITalentReportDTO>();
  const handleChangeInfo = useReduceState<ITalentReportDTO>(
    setPayloadState as React.Dispatch<React.SetStateAction<ITalentReportDTO>>
  );
  const handleUpdateProfile = useApiLoader(
    async () => {
      payloadState &&
        (await API.TalentInfo.updateTalentInfo(payloadState).then(() => {
          onChange();
          onClose();
        }));
    },
    [payloadState],
    {successMsg: 'Updated'}
  );
  useEffect(() => {
    setPayloadState(info);
  }, [info]);
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline onClose={onClose} title={info.user_name} onSave={handleUpdateProfile} />
      <Grid templateColumn="400px 250px" columnGap={30} style={{marginTop: 20}}>
        <Box label="Main info" backgroundColor="Lime" className="talent-info-col">
          <Div flexDirection="column">
            <Select
              isSearchable={true}
              options={UTIL_CONSTANTS.COUNTRIES}
              label="Country"
              value={payloadState?.country || ''}
              onChange={value => handleChangeInfo('country', value)}
              defaultPlaceholder={payloadState?.country || ''}
            />
            <Select
              options={UTIL_CONSTANTS.AGE_GROUPS}
              label="Age group"
              value={payloadState?.age_group || ''}
              defaultPlaceholder={payloadState?.age_group || ''}
              onChange={value => handleChangeInfo('age_group', value)}
            />

            <Input
              placeholder="Language"
              value={payloadState?.top_language || ''}
              onChange={(e: any) => handleChangeInfo('top_language', e.target.value)}
            />

            <TextArea
              value={payloadState?.bio_description || ''}
              row={16}
              placeholder="Bio"
              onChange={(e: any) => handleChangeInfo('bio_description', e.target.value)}
            />
          </Div>
        </Box>
        <Box label="Additional" backgroundColor="AntiqueWhiteLight" className="talent-info-col">
          <Div flexDirection="column">
            <Input
              placeholder="Followers"
              type="number"
              value={payloadState?.followers || 0}
              onChange={(e: any) => handleChangeInfo('followers', e.target.value)}
            />
            <Input
              placeholder="Ops price"
              type="number"
              value={payloadState?.ops_price || 0}
              onChange={(e: any) => handleChangeInfo('ops_price', e.target.value)}
            />
            <Input
              placeholder="Price margin"
              type="number"
              value={payloadState?.price_margin || 0}
              onChange={(e: any) => handleChangeInfo('price_margin', e.target.value)}
            />
            <Input
              placeholder="Price"
              type="number"
              value={payloadState?.price_value_score || 0}
              onChange={(e: any) => handleChangeInfo('price_value_score', e.target.value)}
            />
            <Input
              placeholder="Advertiser price"
              type="number"
              value={payloadState?.advertiser_price || 0}
              onChange={(e: any) => handleChangeInfo('advertiser_price', e.target.value)}
            />
            <Input
              placeholder="System price"
              type="number"
              value={payloadState?.system_price || 0}
              onChange={(e: any) => handleChangeInfo('system_price', e.target.value)}
            />
            <Input
              placeholder="ER"
              type="number"
              value={payloadState?.er || 0}
              onChange={(e: any) => handleChangeInfo('er', e.target.value)}
            />
            <Input
              placeholder="Credibility"
              type="number"
              value={payloadState?.credibility_score || 0}
              onChange={(e: any) => handleChangeInfo('credibility_score', e.target.value)}
            />
          </Div>
        </Box>
      </Grid>
    </Modal>
  );
};

export default TalentReportEditor;
