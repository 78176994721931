import {Grid, Input, Select} from '@/UI';
import {useMemo} from 'react';
import {useReduceState} from '@/Hooks';
import {OrdersService, OrderStatusType} from '@/Website/Pages/Orders/orders.service';
import {CampaignOrderFilters} from '@/Website/Components/Portals/Campaign/CampaignOrders/CampaignOrders';
import {GenericSelect} from '@/Website/Components/Common';

interface Props {
  ordersFilter: CampaignOrderFilters;
  setOrdersFilter: React.Dispatch<React.SetStateAction<CampaignOrderFilters>>;
}
const CampaignOrdersFilters: React.FC<Props> = ({setOrdersFilter, ordersFilter}) => {
  const handleFilterChange = useReduceState<CampaignOrderFilters>(setOrdersFilter);
  const subStatuses = useMemo(
    () => OrdersService.getOrderSubStatuses(ordersFilter.status as OrderStatusType),
    [ordersFilter.status]
  );
  return (
    <Grid columnGap={20} templateColumn="160px repeat(3, 230px)">
      <Input
        autoFocus
        searchIcon
        placeholder="Order ID"
        type="number"
        value={ordersFilter.orderID}
        onChange={e => handleFilterChange('orderID', e.target.value)}
      />
      <Input
        searchIcon
        placeholder="Email / Name"
        value={ordersFilter.searchedValue}
        onChange={e => handleFilterChange('searchedValue', e.target.value)}
      />

      <GenericSelect
        optionsType="orderStatuses"
        value={ordersFilter.status}
        defaultPlaceholder={ordersFilter.status}
        onChange={(e: any) => handleFilterChange('status', e)}
        label="Current status"
      />
      {subStatuses.length > 0 && (
        <Select
          options={subStatuses}
          value={ordersFilter.subStatus}
          onChange={value => handleFilterChange('subStatus', value)}
          label="Sub status"
          defaultPlaceholder={ordersFilter.subStatus}
        />
      )}
    </Grid>
  );
};
export default CampaignOrdersFilters;
