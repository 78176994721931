import styled, {css} from 'styled-components';
import {memo} from 'react';

const SPhone = styled.div`
  ${({theme}) => css`
    display: flex;
    gap: 3px;
    margin: 4px;
    flex-direction: column;
    position: relative;
    width: 100%;
    .phone-label {
      color: #a1a1a1;
      font-size: 0.8rem;
    }
    .form-control {
      position: relative;
      font-size: 0.7rem;
      width: 100%;
      font-weight: 400;
      border: none;
      border-radius: 15px;
      color: #000000;
      background-color: #f9f9f9;
      outline: 1px solid #e7e6e6;
    }
    .react-tel-input {
      font-family: 'Outfit', serif;
      width: 100%;
      padding-right: 5px;
    }
    .flag-dropdown {
      border-radius: 15px;
      border: none;
      &:hover {
        border-radius: 15px;
      }
      .country-list {
        border-radius: 15px;
        border: none;
        background-color: white;

        ${theme.Presets.scroll};
        .search-emoji {
          display: none;
        }
        .search-box {
          border-radius: 15px;
          width: 250px;
          font-size: 0.7rem;
          font-weight: 400;
          font-family: ${theme.FontFamily.Outfit};
          border: none;
          color: #000000;
          background-color: #f9f9f9;
          outline: 1px solid #e7e6e6;
        }
      }
    }
    .flag-dropdown.open {
      border-radius: 15px;
    }
  `}
`;
export default memo(SPhone);
