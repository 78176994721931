import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';
import {SocialsType} from '@/Services/social.service';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Box, ElementHeadline, Label, SocialIcon, TableContainer, TCell, Text, TRow} from '@/UI';
import {useOpenOrder} from '@/Website/Components/Portals/Order/Hooks';
import {useGetOrders} from '@/Website/Pages/Orders/Hooks';
import {memo, useEffect, useMemo} from 'react';

const TalentOrders: React.FC<{talentID: string}> = ({talentID}) => {
  const {setPortalOrderCallBack} = usePortalsStore().actions;
  const filter = useMemo(() => ({userID: talentID, skip: 0}), []);
  const {orders, reloadOrders} = useGetOrders(filter);
  const openOrder = useOpenOrder();
  const headers = useMemo(() => ['ID', '', 'Status', 'Sub status', 'Price', 'Campaign', 'Created at'], []);
  useEffect(() => {
    setPortalOrderCallBack(reloadOrders);
  }, [reloadOrders]);

  return (
    <Box flexDirection="column" className="TalentOrders tab-preset">
      <ElementHeadline title={`Total orders: ${orders.length}`} disableMarker />
      <TableContainer headers={headers} isLoading={false} isSmall>
        {orders.map((order, i) => (
          <TRow key={i} onClick={() => openOrder(order)}>
            <TCell>
              <Text type="Text3">{order.id}</Text>
            </TCell>
            <TCell>
              {order.social_platform && <SocialIcon isActive social={order.social_platform as SocialsType} />}
            </TCell>
            <TCell>
              <Label backgroundColor={ColorService.getColorTypeByStatus(order.status)}>{order.status}</Label>
            </TCell>
            <TCell>
              <Label backgroundColor={ColorService.getColorTypeByStatus(order.sub_status)}>
                {order.sub_status}
              </Label>
            </TCell>
            <TCell>
              <Text type="Text3" color="Orange" fontWeight={800}>
                ${order.talent_price}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" fontWeight={800} noWrap>
                {order.campaign_id ? `${order.campaign_id + ' : ' + order.campaign_title}` : '-'}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success" fontWeight={800}>
                {dateToLocalDate(order.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default memo(TalentOrders);
