import styled from 'styled-components';
import {memo} from 'react';

const Component = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: cornflowerblue;
  color: WHITE;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  z-index: 99;
`;

const ErrorPage = () => {
  return (
    <Component className="ErrorPage">
      <h1>oops 😐</h1>
    </Component>
  );
};
export default memo(ErrorPage);
