import {memo} from 'react';
import {Analytics} from '@/Website/Components';
import {useTalentAnalytics} from '@/Website/Components/Talent/Hooks';
import {Box} from '@/UI';

const TalentAnalytics = () => {
  const {talentAnalytic, isLoading} = useTalentAnalytics();
  return (
    <Box className="TalentAnalytics tab-preset">
      <Analytics isLoading={isLoading} analytics={talentAnalytic} />
    </Box>
  );
};
export default memo(TalentAnalytics);
