import {PreOrderTalentType} from '@/API/Campaign/types';
import {Button, ClipboardText, ContextMenu, Div, SocialIcon, TCell, Text} from '@/UI';
import {useState, useEffect, memo, useCallback, useMemo} from 'react';

import {useDeletePreOrder, useUpdatePreOrder} from '@/Hooks/Campaigns';
import SPreOrderRow from './preOrderRow.styled';
import {SocialsType} from '@/Services/social.service';
import {useCreateOrder} from '@/Website/Components/Portals/Order/Hooks';

type PreOrderRowProps = {
  talent: PreOrderTalentType;
  afterChangePreOrder: Function;
};

const PreOrderRow: React.FC<PreOrderRowProps> = ({afterChangePreOrder: onChange, talent}) => {
  const defaultPriceState = useMemo(() => ({price: 0, advertiserPrice: 0}), []);
  const [prices, setPrices] = useState<typeof defaultPriceState>(defaultPriceState);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const deletePreOrderTalent = useDeletePreOrder(
    {talentInfoId: talent.talent_info_id, campaignID: talent.campaign_id},
    onChange
  );
  /** */
  const updatePreOrderTalent = useUpdatePreOrder(
    {
      campaignID: talent.campaign_id,
      talentID: talent.talent_id,
      ...prices,
    },
    () => {
      setIsChanged(false);
      onChange();
    }
  );
  const handleChangedPrice = useCallback(
    (prop: keyof typeof defaultPriceState, value: any) => {
      if (!isChanged) setIsChanged(true);
      setPrices(prev => ({...prev, [prop]: Number(value)}));
    },
    [isChanged]
  );

  const createOrder = useCreateOrder(
    {
      talent: talent.talent_id,
      advertiser_price: talent.advertiser_price,
      status: 'offered',
      campaign_id: talent.campaign_id,
      talent_price: talent.talent_price,
      talent_info_id: talent.talent_info_id,
    },
    deletePreOrderTalent
  );

  useEffect(() => {
    setPrices({advertiserPrice: talent.advertiser_price, price: talent.talent_price});
  }, [talent]);

  return (
    <SPreOrderRow>
      <TCell>
        {talent.social_platform && <SocialIcon isActive social={talent.social_platform as SocialsType} />}
      </TCell>
      <TCell>
        <Div flexDirection="column">
          <Text type="Text3" fontWeight={800} color="BlackOcean">
            {talent.username}
          </Text>
          <Text type="Text3" color="MediumGray">
            {talent.email}
          </Text>
        </Div>
      </TCell>
      <TCell>
        <Text type="Text3" color="Success">
          {talent.followers}
        </Text>
      </TCell>
      <TCell>
        <input
          className="price-input"
          value={prices.price || 0}
          type={'number'}
          onChange={e => handleChangedPrice('price', e.target.value)}
        />
      </TCell>

      <TCell>
        <input
          className="price-input adv"
          value={prices.advertiserPrice || 0}
          type={'number'}
          onChange={e => handleChangedPrice('advertiserPrice', e.target.value)}
        />
      </TCell>
      <TCell>
        <ClipboardText textToCopy={talent.email}>
          <Text type="Text3" color="Blue">
            {talent.campaign_title}
          </Text>
        </ClipboardText>
      </TCell>
      <TCell>
        <Button isDisabled={!isChanged} color="Success" size="small" onClick={updatePreOrderTalent}>
          Update
        </Button>
      </TCell>
      <TCell>
        <ContextMenu
          options={[
            {option: 'Create order', onSelect: createOrder},
            {option: 'Delete', onSelect: deletePreOrderTalent},
          ]}
        />
      </TCell>
    </SPreOrderRow>
  );
};

export default memo(PreOrderRow);
