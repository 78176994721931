import {API} from '@/API/API';
import {useApiLoader, useOpenMedia} from '@/Hooks';
import {useUploadCampaignAttachments} from '@/Hooks/Campaigns';
import {Button, Div, InputFile, Text} from '@/UI';
import {useState} from 'react';
import Component from './attachments.styled';

type AttachmentsProps = {
  attachments?: Array<string>;
  setAttachments: Function;
  campaignID: number;
};

const Attachments: React.FC<AttachmentsProps> = props => {
  const {attachments, campaignID, setAttachments} = props;
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const openMedia = useOpenMedia();
  const handleUploadFiles = useUploadCampaignAttachments({
    campaignID,
    materials: filesToUpload,
    callBack: (key: string) => {
      setAttachments(key);
      setFilesToUpload([]);
    },
  });

  const handleDeleteAttachment = useApiLoader(
    async (att: string) => {
      await API.Campaigns.deleteAttachment(campaignID, att).then(() =>
        setAttachments(attachments?.filter(el => el !== att))
      );
    },
    [campaignID, attachments],
    {successMsg: 'Attachment deleted'}
  );

  return (
    <Component className="Attachments">
      <Div flexDirection="column" flexGrow={1}>
        <Text type="Text2" fontWeight={800} className="label" color="Orange">
          Attachments
        </Text>
        <div className="attachment-list">
          {attachments &&
            attachments?.length > 0 &&
            attachments?.map(
              (att, i) =>
                att && (
                  <div key={i} className="attachment">
                    <Text color="Success" type="Text3" onClick={() => openMedia(att)}>
                      {att}
                    </Text>
                    <Text
                      color="Alert"
                      fontWeight={800}
                      className="x-delete"
                      type="Text4"
                      onClick={() => handleDeleteAttachment(att)}
                    >
                      X
                    </Text>
                  </div>
                )
            )}
        </div>
      </Div>
      <Div flexDirection="column" className="upload" alignItems="center" justifyContent="center">
        <Text type="Text2" fontWeight={800} className="label" color="DarkGray">
          Add new attachments
        </Text>

        <InputFile accept="all" multiple label="Select files" onUpload={setFilesToUpload} />
        <Button
          color="Success"
          size="medium"
          isDisabled={filesToUpload.length === 0}
          onClick={handleUploadFiles}
        >
          Upload
        </Button>
      </Div>
    </Component>
  );
};

export default Attachments;
