import {ColorType} from '@/Styles/types';
import {ReactNode} from 'react';
import {Component} from './div.styled';
import {FlexProps} from '@/Styles/theme';

export interface DivProps extends FlexProps {
  className?: string;
  children?: ReactNode;
  margin?: string;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  padding?: string;
  width?: number;
  height?: number;
  onClick?: any;
  borderRadius?: number;
}
const Div: React.FC<DivProps> = props => {
  const {children, ...restProps} = props;
  return <Component {...restProps}>{children}</Component>;
};
export default Div;
