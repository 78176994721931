import styled from 'styled-components';
import {memo} from 'react';

const SScrollUp = styled.div`
  position: fixed;
  display: none;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 1;
  height: 40px;
  width: 40px;
  background-color: rgba(29, 32, 51, 0.8);
  border: 1px solid #cbcbcb;
  border-radius: 100%;

  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }

  svg {
    height: 30px;
    fill: #c5c5c5;
  }
`;
export default memo(SScrollUp);
