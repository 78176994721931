import {memo, useEffect} from 'react';
import arrowIcon from '@/Assets/Icons/arrow.svg';
import {Component} from './folder.styled';
import {useBooleanState} from '@/Hooks/useBooleanState';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  isFolderOpen?: boolean;
}

const Folder: React.FC<Props> = props => {
  const {children, title, isFolderOpen, ...restProps} = props;
  const ShowFolder = useBooleanState();

  useEffect(() => {
    isFolderOpen && ShowFolder.turnOn();
  }, []);

  return (
    <Component className="Folder" isOpen={ShowFolder.isTurn}>
      <div className="folder-wrap" {...restProps}>
        <div className="header" onClick={ShowFolder.toggle}>
          <div className="title">{title}</div>
          <img src={arrowIcon} alt="" className="arrow" />
        </div>
        <div className="children">{children}</div>
      </div>
    </Component>
  );
};

export default memo(Folder);
