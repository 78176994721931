import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {SendCampaignBase} from '@/API/Campaign/types';

const useSendCampaign = () => {
  return useApiLoader(
    async (sendData: SendCampaignBase, callBack?: Function) => {
      return await API.Campaigns.sendCampaign(sendData).then(() => callBack && callBack());
    },
    [],
    {successMsg: 'Successfully sent'}
  );
};
export default useSendCampaign;
