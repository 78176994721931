import {
  IQDataReportCreateDTO,
  BaseCreateReportDTO,
  GetTalentsInfoResponseType,
  ITalentInfoCheckSocial,
  ITalentReportDTO,
  ITalentsInfoParams,
} from './types';
import {IQDataReport} from '@/API/TalentInfo/iqData.types';
import {URL} from '@/API/urls';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';

class TalentInfoApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getTalentsInfo(params: ITalentsInfoParams): Promise<GetTalentsInfoResponseType> {
    let {rangeFollowers, localTag, sortBy, ...restParams} = params;
    rangeFollowers = rangeFollowers[1] === 1000000 ? [rangeFollowers[0], 99000000] : rangeFollowers;
    localTag = localTag?.length ? localTag.map(el => el.value) : undefined;
    return await this.api
      .get(`/get-talents`, {
        params: {
          ...restParams,
          rangeFollowers,
          localTag,
          sortBy: sortBy.value,
        },
      })
      .then(res => res.data);
  }
  //
  async checkTalentInfoSocials(talentID: string): Promise<ITalentInfoCheckSocial[]> {
    return await this.api
      .get(`/check-socials`, {
        params: {talentID},
      })
      .then(res => res.data);
  }
  //
  async deleteReport(reportID: number): Promise<any> {
    return await this.api.delete(`delete-report/${reportID}`);
  }
  //
  async checkExistsReport(
    socialType: string,
    url: string
  ): Promise<Pick<ITalentReportDTO, 'user_id' | 'social_platform'>> {
    return await this.api
      .get(`/check-exists-report`, {
        params: {
          socialType,
          url,
        },
      })
      .then(res => res.data);
  }
  //
  async findByUserIDAndSocial(talentID: string, social?: string): Promise<ITalentReportDTO> {
    return await this.api
      .get(`/report`, {
        params: {talentID, social},
      })
      .then(res => res.data);
  }
  //
  async createTalentReport(createDTO: IQDataReportCreateDTO) {
    return await this.api.post(`/create-talent-report`, createDTO).then(res => res.data);
  }
  //
  async createTalentReportManually(createInfoDTO: BaseCreateReportDTO) {
    return await this.api.post(`/create-report-manually`, createInfoDTO).then(res => res.data);
  }
  //
  async updateTalentInfo(payload: ITalentReportDTO) {
    return await this.api.patch(`/update-talent-info`, payload);
  }
  //
  async toggleIsActiveTalent(talentInfoID: number, isActive: boolean) {
    return await this.api
      .get(`/toggle-talent-active`, {
        params: {
          talentInfoID,
          isActive,
        },
      })
      .then(res => res.data);
  }
  async findIQDataTalentReport(params: {url: string; socialPlatform: string; userID: string}): Promise<any> {
    return await this.api
      .post<IQDataReport>(
        `reports/full`,
        {
          id: params.url,
          platform: params.socialPlatform,
        },
        {baseURL: URL.DISCOVERY_MS}
      )
      .then(async ({data}) => {
        return await this.createTalentReport({report: data, userID: params.userID});
      });
  }
  /** @description if there is no report in IQData, then create it manually */
  async generateTalentReport(payload: BaseCreateReportDTO): Promise<any> {
    const {url, social, user_id} = payload;
    this.findIQDataTalentReport({url, userID: user_id, socialPlatform: social}).catch(async () => {
      return await this.createTalentReportManually(payload);
    });
  }
}
export default TalentInfoApi;
