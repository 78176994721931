import {API} from '@/API/API';
import {OrderType} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useOrderUpdate = (order: OrderType) => {
  const {
    orders: {orderPortalCallBack},
  } = usePortalsStore().selectors;
  return useApiLoader(
    async () => {
      await API.Orders.updateOrder({
        id: order.id,
        notes: order.notes || '',
        status: order.status,
        sub_status: order.sub_status,
        talent_price: order.talent_price || 0,
        linkPost: order.link_post || '',
        userID: order.talent,
        promo_code: order.promo_code,
      }).then(orderPortalCallBack);
    },
    [order, orderPortalCallBack],
    {successMsg: 'Updated'}
  );
};
export default useOrderUpdate;
