import {API} from '@/API/API';
import {CampaignDetailsType} from '@/API/Campaign/types';
import {URL} from '@/API/urls';
import {useApiLoader} from '@/Hooks';
import {Box, Div, InputMedia} from '@/UI';
import styled from 'styled-components';
import Attachments from '../CampaignMaterials/Attachments/Attachments';

const Component = styled(Box)`
  border-top-left-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
interface BrandProps {
  campaign: CampaignDetailsType;
  onChange: (props: keyof CampaignDetailsType, value: any) => void;
}
const CampaignMaterials: React.FC<BrandProps> = ({campaign, onChange}) => {
  /** */
  const handleUploadLogo = useApiLoader(
    async (file: any) => {
      await API.Campaigns.uploadCampaignLogo(file, campaign.id).then(logo => onChange('campaign_logo', logo));
    },
    [campaign],
    {successMsg: 'Logo uploaded'}
  );
  /** */
  const handleDeleteLogo = useApiLoader(
    async () => {
      await API.Campaigns.deleteCampaignLogo(campaign.id).then(() => onChange('campaign_logo', ''));
    },
    [campaign],
    {successMsg: 'Deleted successfully'}
  );
  /** */
  const handleDeleteVideo = useApiLoader(
    async () => {
      await API.Campaigns.deleteCampaignVideo(campaign.id).then(() => onChange('influencers_video', ''));
    },
    [campaign],
    {successMsg: 'Deleted successfully'}
  );
  /** */
  const handleUploadVideo = useApiLoader(
    async (file: any) => {
      await API.Campaigns.uploadCampaignVideo(file, campaign.id).then(logo =>
        onChange('influencers_video', logo)
      );
    },
    [campaign],
    {successMsg: 'Logo uploaded'}
  );
  return (
    <Component className="Brand campaign-tab">
      <Box label="Files">
        <Div flexDirection="row" gap={10}>
          <Div flexDirection="column" gap={5}>
            <InputMedia
              type="photo"
              label="Logo"
              mediaFile={campaign.campaign_logo ? URL.MEDIA_ROUTE + campaign.campaign_logo : ''}
              onDelete={handleDeleteLogo}
              onUpload={handleUploadLogo}
            />
            <InputMedia
              type="video"
              label="Influencer video"
              mediaFile={campaign.influencers_video ? URL.MEDIA_ROUTE + campaign.influencers_video : ''}
              onDelete={handleDeleteVideo}
              onUpload={handleUploadVideo}
            />
          </Div>
          <Attachments
            attachments={campaign.brand_attachments}
            setAttachments={(value: []) => onChange('brand_attachments', value)}
            campaignID={campaign.id}
          />
        </Div>
      </Box>
    </Component>
  );
};

export default CampaignMaterials;
