import {OrdersSearchParams} from '@/API/Orders/types';
import useDebouncedValue from '@/Hooks/useDebouncedValue';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Pagination} from '@/UI';
import CampaignOrdersFilters from '@/Website/Components/Portals/Campaign/CampaignOrders/CampaignOrdersFilters/CampaignOrdersFilters';
import CampaignOrdersTable from '@/Website/Components/Portals/Campaign/CampaignOrders/CampaignOrdersTable/CampaignOrdersTable';
import SummaryOrders from '@/Website/Components/Portals/Campaign/CampaignOrders/SummaryOrders/SummaryOrders';
import {useGetOrders} from '@/Website/Pages/Orders/Hooks';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {CAMPAIGN_ORDER_CONST} from './campaignOrders.service';
import {Component} from './campaignOrders.styled';

export type CampaignOrderFilters = Omit<OrdersSearchParams, 'userID'>;
interface Props {
  campaignID: any;
  isCampaignDeleted: boolean;
}
const CampaignOrders: React.FC<Props> = ({campaignID, isCampaignDeleted}) => {
  const defaultFilterState = useMemo(() => {
    return {
      ...CAMPAIGN_ORDER_CONST.defaultFilterState,
      includeDeletedCampaigns: isCampaignDeleted ? 'include' : undefined,
    };
  }, [isCampaignDeleted]);
  const {
    actions: {setPortalOrderCallBack},
  } = usePortalsStore();
  const [ordersFilter, setOrdersFilter] = useState<CampaignOrderFilters>({...defaultFilterState, campaignID});
  const {debouncedValue} = useDebouncedValue(ordersFilter, 500);
  const {orders, reloadOrders, orderCount, exportOrderToExcel, isLoading} = useGetOrders(debouncedValue);

  const skipHandler = useCallback(
    (skip: number) => setOrdersFilter(prev => ({...prev, skip})),
    [setOrdersFilter]
  );
  useEffect(() => {
    setPortalOrderCallBack(reloadOrders);
  }, [reloadOrders]);

  return (
    <Component className="CampaignOrders campaign-tab">
      <SummaryOrders
        exportOrdersToExcel={exportOrderToExcel}
        onChange={reloadOrders}
        campaignID={campaignID}
        setOrdersFilter={setOrdersFilter}
      />

      <CampaignOrdersFilters ordersFilter={ordersFilter} setOrdersFilter={setOrdersFilter} />
      <CampaignOrdersTable orders={orders} isLoading={isLoading} />
      <Pagination pagination={{setSkip: skipHandler, count: orderCount, limit: ordersFilter.limit || 30}} />
    </Component>
  );
};

export default CampaignOrders;
