import SLabel from './label.styled';
import {memo} from 'react';
import {ColorType} from '@/Styles/types';

export interface LabelProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  backgroundColor?: ColorType;
  fontColor?: ColorType;
}
const Label: React.FC<LabelProps> = props => {
  const {children, backgroundColor, fontColor, ...restProps} = props;
  return (
    //@ts-ignore
    <SLabel className="Label" {...restProps} backgroundColor={backgroundColor} fontColor={fontColor}>
      {children}
    </SLabel>
  );
};

export default memo(Label);
