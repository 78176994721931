import {useCallback} from 'react';

const useReduceState = <T>(setState: React.Dispatch<React.SetStateAction<T>>) => {
  return useCallback(
    (prop: keyof T, value: any) => {
      setState(prev => ({...prev, [prop]: value}));
    },
    [setState]
  );
};

export type ReduceStateReturnType<T> = (prop: keyof T, value: any) => void;

export default useReduceState;
