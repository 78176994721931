import {memo} from 'react';
import {CampaignSummary} from '@/API/Campaign/types';
import {Avatar, Box, Div, ElementHeadline, Grid, Text} from '@/UI';
import styled from 'styled-components';
import {URL} from '@/API/urls';
import {dateToLocalDate} from '@/Services/date.service';

interface CampaignPostsProps {
  posts: CampaignSummary['talent_posts'];
}
const SCampaignPosts = styled.div`
  .campaign-post {
    width: 300px;
    height: 230px;
    padding: 10px;
  }
  .campaign-post-video {
    border-radius: 15px;
    position: relative;
    height: 211px;
    width: 128px;
  }
`;
const CampaignPosts: React.FC<CampaignPostsProps> = ({posts}) => {
  return (
    <SCampaignPosts className="SCampaignPosts">
      <ElementHeadline title="Posts" disableMarker fontSize="Header4" />
      <Div flexDirection="row" justifyContent="space-evenly" gap={20} flexWrap="wrap">
        {posts?.map((post, i) => (
          <Box className="campaign-post" key={i}>
            <Grid templateColumn="1fr 1fr">
              <video
                className="campaign-post-video"
                src={URL.MEDIA_ROUTE + post.material_video}
                controls
              ></video>
              <Div flexDirection="column" justifyContent="space-between">
                <Div flexDirection="column" gap={5}>
                  <Avatar image={post.avatar} size="medium" />
                  <Text color="HardGray" type="Text3">
                    {post.full_name}
                  </Text>
                  <Text color="Success" type="Text3">
                    {dateToLocalDate(post.material_created_at)}
                  </Text>
                </Div>

                <Grid rowGap={5} justifyContent="space-between" templateColumn="1fr 1fr">
                  <Text type="Text3">Likes</Text>
                  <Text type="Text3" textAlign="right">
                    {post.likes_num || 0}
                  </Text>
                  <Text type="Text3">Comments</Text>
                  <Text type="Text3" textAlign="right">
                    {post.comments_num || 0}
                  </Text>
                  <Text type="Text3">Clicks</Text>
                  <Text type="Text3" textAlign="right">
                    {post.clicks_num || 0}
                  </Text>
                  <Text type="Text3">Actions</Text>
                  <Text type="Text3" textAlign="right">
                    {post.actions_num || 0}
                  </Text>
                  <Text type="Text3">Price</Text>
                  <Text type="Text3" textAlign="right" fontWeight={800} color="Orange">
                    {post.talent_price || 0}
                  </Text>
                </Grid>
              </Div>
            </Grid>
          </Box>
        ))}
      </Div>
    </SCampaignPosts>
  );
};
export default memo(CampaignPosts);
