import {Box} from '@/UI';
import TalentCategories from '@/Website/Components/Talent/TalentCategoriesTags/TalentCategories/TalentCategories';
import TalentTags from '@/Website/Components/Talent/TalentCategoriesTags/TalentTags/TalentTags';

import {TalentDetailed} from '@/API/Talents/types';
import styled from 'styled-components';

const STalentCategoriesTags = styled(Box)`
  width: 200px;
  display: flex;
  flex-direction: column;
`;
const TalentCategoriesTags: React.FC<{talent: TalentDetailed}> = ({talent}) => {
  return (
    <STalentCategoriesTags label="Tags & Categories" backgroundColor="LagunaLight">
      <TalentCategories talentID={talent.user_id} />
      <TalentTags talent={talent} />
    </STalentCategoriesTags>
  );
};
export default TalentCategoriesTags;
