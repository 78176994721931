import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {ITalentReportDTO} from '@/API/TalentInfo/types';

const useFindReportBySocial = (selectedSocial: string) => {
  const [existsSocial, setExistsSocial] = useState<Pick<ITalentReportDTO, 'user_id' | 'social_platform'>>();
  const search = useApiLoader(async () => {
    if (selectedSocial) {
      const SocialUsername = selectedSocial?.split(',');
      await API.TalentInfo.checkExistsReport(SocialUsername[0], SocialUsername[1]).then(res =>
        setExistsSocial(res)
      );
    }
  }, [selectedSocial]);
  useEffect(() => {
    void search();
  }, [search]);
  return {
    existsSocial,
  };
};
export default useFindReportBySocial;
