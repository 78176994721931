import {SelectOptionsType} from '@/UI/Select/select.styled';
import {SelectService} from '@/Services/select.service';

const OrderStatuses = {
  offered: ['campaign-full', 'campaign-ended', 'not-responsive'],
  rejected: ['price', 'content', 'delivery-due-date', 'not-responsive'],
  'waiting-list': [
    'waiting-for-available-spot',
    'pending-review by-brand',
    'pending-review-by-ops',
    'price-negotiation',
  ],
  'approved-for-campaign': ['pending-to-receive-video', 'not-responsive'],
  'campaign-full': ['late-submission', 'not-matching', 'rejected-by-brand', 'rejected-by-ops'],

  submitted: ['pending-review-by-brand', 'pending-review-by-Ops', 'incorrect-file', 'not-responsive'],
  'approved-video': ['not-responsive'],
  revision: ['1st-revision', '2nd-revision', '3rd-revision', 'not-responsive'],

  posted: ['link-not-visible', 'missing-tags-or-hashtags', 'missing-description'],
  tracking: [
    'missing-live-video-after-7-days',
    'missing-live-video-after-14-days',
    'missing-live-video-after-21-days',
    'not-responsive',
  ],
  completed: ['30-days-campaign-tracking-over', 'payment-received'],
  ended: ['successful-tracking', 'no-participants', 'rejected-offer', 'tracking-not-completed'],
  dispute: [
    'video-content-not-eligible',
    'more-than-3-revisions',
    'resolved',
    'not-responsive',
    'not-able-to-follow-guidelines',
  ],
  'approved-after-revision': [],
};

export type OrderStatusType = keyof typeof OrderStatuses;

export const OrdersService = {
  HEADERS: ['', 'ID', '', '', 'Status', 'Talent', 'Campaign', 'Adv price', 'Price', 'Ordered At'],
  DEFAULT_FILTER_STATE: {
    searchedValue: '',
    status: '',
    subStatus: '',
    skip: 0,
    // limit: 30,
    limit: 150,
    campaignID: undefined,
    organizationID: undefined,
    orderID: undefined,
    social: undefined,
  },
  ORDER_STATUSES: SelectService.convertObjectKeysToSelectOption(OrderStatuses),
  getOrderSubStatuses: (orderStatus: OrderStatusType): SelectOptionsType => {
    if (!orderStatus) return [];
    const subStatuses = Object.values(OrderStatuses[orderStatus]);
    if (!subStatuses.length) return [];
    return [{value: '', label: '-'}, ...subStatuses.map(el => ({label: el, value: el}))];
  },
};
