import {useCallback} from 'react';
import {useRootStore} from '@/Store/root/root.selector';
import MediaService from '@/Services/media.service';
import {UtilService} from '@/Services/util.service';
import {URL} from '@/API/urls';

const useOpenMedia = () => {
  const {openMediaPlayer} = useRootStore().actions;
  return useCallback((media: string) => {
    if (MediaService.isMedia(media)) {
      const link = MediaService.getImageKitLink(URL.MEDIA_ROUTE + media);
      link && openMediaPlayer(link);
    } else UtilService.openMedia(media);
  }, []);
};
export default useOpenMedia;
