import {EmployeeBase} from '@/API/Security/Employees/types';
import {useLogout} from '@/Hooks';
import {Avatar, Div, ElementHeadline, Modal, Text} from '@/UI';
import useShowResetPassword from '@/Website/Components/Talent/Hooks/useShowResetPassword';
import {memo, useMemo} from 'react';

interface UserDetailProps {
  isShow: boolean;
  onClose: Function;
  user: EmployeeBase;
}
const UserDetail: React.FC<UserDetailProps> = props => {
  const {isShow, onClose, user} = props;
  const changePassword = useShowResetPassword(user.cognito_id);
  const logout = useLogout();
  const options = useMemo(
    () => [
      {option: 'Change password', onSelect: changePassword},
      {option: 'Logout', onSelect: logout},
    ],
    [changePassword, logout, user.cognito_id]
  );
  return (
    <Modal isShowModal={isShow} onClose={onClose} fadeColor="rgba(0,0,0,0)">
      <ElementHeadline title="Profile" disableMarker options={options} />
      <Div flexDirection="column" padding="20px 0 0" width={250}>
        <Avatar image={''} size="large" />
        <Text type="Header2" fontWeight={800} style={{marginTop: 15}}>
          {user.full_name}
        </Text>
        <Text type="Text3">{user.email}</Text>
      </Div>
    </Modal>
  );
};
export default memo(UserDetail);
