import {memo, useState} from 'react';
import {Component} from './hamburger.styled';

const Hamburger: React.FC<{setOpenedNavbar: Function}> = ({setOpenedNavbar}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleClick = (e: any) => {
    setIsOpen(!isOpen);
    setOpenedNavbar((prev: boolean) => !prev);
  };
  return (
    <Component className={`Hamburger`} onClick={handleClick} isOpen={isOpen}>
      <div id="icon" className={`${!isOpen ? ' open' : 'close'}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Component>
  );
};
export default memo(Hamburger);
