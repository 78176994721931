import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import Router from './Router/Router';
import {STORE} from './Store/store';
import {THEME} from './Styles/theme';
import {initializeApp} from 'firebase/app';
import {firebaseConfig} from './Config/firebase.config';
import {getAuth} from 'firebase/auth';

const FirebaseApplication = initializeApp(firebaseConfig);
export const FirebaseAuthentication = getAuth(FirebaseApplication);

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={STORE}>
        <ThemeProvider theme={THEME}>
          <Router />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};
