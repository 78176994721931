import {TableRow, TableRowProps} from '@material-ui/core';
import styled from 'styled-components';
import {CheckBox} from '../CheckBox';
import TCell from './TCell';

const STRow = styled(TableRow)``;

interface Props extends TableRowProps {
  onCheck?: Function;
  isChecked?: boolean;
}

const TRow: React.FC<Props> = props => {
  const {onCheck, isChecked, ...restProps} = props;
  return (
    <STRow {...restProps}>
      {onCheck && (
        <TCell onClick={e => e.stopPropagation()}>
          <CheckBox
            onChange={() => {
              onCheck();
            }}
            checked={isChecked}
          />
        </TCell>
      )}
      {restProps.children}
    </STRow>
  );
};
export default TRow;
