import {FavoriteUserType} from '@/Store/community/types';

const updateTalentPrice = (
  favorites: FavoriteUserType[],
  user: FavoriteUserType,
  value: any
): FavoriteUserType[] => {
  let tmpArr = [...favorites];
  const index = tmpArr.findIndex(e => e.email === user.email);
  tmpArr[index] = {...user, price: value};
  return tmpArr;
};
const calculateSum = (favorites: FavoriteUserType[]): number => {
  let sum = 0;
  favorites.forEach(el => (sum += Number(el.price) || 0));
  return sum;
};
export {updateTalentPrice, calculateSum};
