import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {TransactionType} from '@/API/Talents/types';
import {useCallback} from 'react';

const useUpdateWalletTransaction = () => {
  const update = useApiLoader(
    async (payload: {onSuccess: Function; transaction: TransactionType}) => {
      await API.Wallet.updateTransaction(payload.transaction).then(() => {
        payload.onSuccess();
      });
    },
    [],
    {successMsg: 'Updated successfully'}
  );
  return useCallback(
    async (payload: {onSuccess: Function; transaction: TransactionType}) => await update(payload),
    [update]
  );
};
export default useUpdateWalletTransaction;
