import {useCallback, useMemo} from 'react';
import {Box, Div, ElementHeadline, Label, Text} from '@/UI';
import ColorService from '@/Services/color.service';
import {useCampaignOrdersSummary} from '@/Hooks/Campaigns';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {OrdersStatusesUpdater} from '@/Website/Components/Portals/Campaign/CampaignOrders';
import {useConfirmation} from '@/Hooks';
import {useResetCampaignOrdersPrices} from '@/Website/Components/Portals/Campaign/Hooks';
import {CampaignOrderFilters} from '@/Website/Components/Portals/Campaign/CampaignOrders/CampaignOrders';
import {OrderStatusType} from '@/Website/Pages/Orders/orders.service';

interface Props {
  campaignID: number;
  onChange: Function;
  setOrdersFilter: React.Dispatch<React.SetStateAction<CampaignOrderFilters>>;
  exportOrdersToExcel: Function;
}
const SummaryOrders: React.FC<Props> = props => {
  const {exportOrdersToExcel, setOrdersFilter, campaignID, onChange} = props;
  const {ordersSummary} = useCampaignOrdersSummary(campaignID);
  const ShowOrderStatusUpdater = useBooleanState();
  const resetPrices = useResetCampaignOrdersPrices(onChange);

  const handleCreate = useConfirmation(
    () => resetPrices(campaignID),
    'Reset all orders talent prices in campaign?'
  );

  const menuOptions = useMemo(() => {
    let res = [];
    if (ordersSummary.orders > 0) {
      res.push({option: 'Update all statuses', onSelect: ShowOrderStatusUpdater.turnOn});
      res.push({option: 'Reset prices', onSelect: handleCreate});
      res.push({option: 'Export to excel', onSelect: exportOrdersToExcel});
    }
    return res;
  }, [ordersSummary.orders, exportOrdersToExcel]);

  const handleStatusClick = useCallback((status: OrderStatusType) => {
    setOrdersFilter(prev => ({...prev, skip: 0, status}));
  }, []);
  return (
    <Box borderColor="GrayLight">
      <ElementHeadline
        options={menuOptions}
        disableMarker
        fontSize="Text2"
        title={`Total orders: ${ordersSummary.orders}`}
      />
      <Div gap={5}>
        {ordersSummary.orders_statuses?.map((el, i) => (
          <Label
            style={{cursor: 'pointer'}}
            backgroundColor={ColorService.getColorTypeByStatus(el.status)}
            key={i}
            onClick={() => handleStatusClick(el.status)}
          >
            <Div gap={5} flexDirection="column" alignItems="center">
              <Text fontWeight={600} type="Text4">
                {el.status}
              </Text>
              <Text fontWeight={800} type="Text3">
                {el.count}
              </Text>
            </Div>
          </Label>
        ))}
      </Div>
      {ShowOrderStatusUpdater.isTurn && (
        <OrdersStatusesUpdater onClose={ShowOrderStatusUpdater.turnOff} onChange={onChange} />
      )}
    </Box>
  );
};
export default SummaryOrders;
