import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SPhone from './phone.styled';

interface PhoneProps {
  value: any;
  onChange: (value: string) => void;
}
const Phone: React.FC<PhoneProps> = props => {
  const {value, onChange} = props;
  return (
    <SPhone>
      <div className="phone-label">Phone</div>
      <PhoneInput
        prefix="+"
        enableSearch
        placeholder="Phone"
        country={'us'}
        countryCodeEditable={false}
        value={value}
        onChange={e => {
          onChange(e ? `+${e}` : e);
        }}
      />
    </SPhone>
  );
};
export default Phone;
