import {SocialsType} from '@/Services/social.service';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FavoriteUserType, InitialStateType} from './types';

const initialState: InitialStateType = {
  badges: {
    approvals: 0,
    onboarding: 0,
  },
  storedUser: {
    isShowStoredUser: false,
    onChangeUserCallback: () => console.log(''),
    userID: '',
  },
  talentReportStore: {
    isShowTalentReport: false,
    talentID: undefined,
    socialType: undefined,
    callBack: undefined,
  },
  isShowResetPassword: false,
  favorites: [],
};

const slice = createSlice({
  name: 'community',
  initialState: initialState as InitialStateType,
  reducers: {
    setTalentReport(
      state,
      action: PayloadAction<{social: SocialsType; talentID: string; callBack?: Function}>
    ) {
      state.talentReportStore.talentID = action.payload.talentID;
      state.talentReportStore.socialType = action.payload.social;
      state.talentReportStore.callBack = action.payload.callBack;
      state.talentReportStore.isShowTalentReport = true;
    },
    closeTalentReport(state) {
      state.talentReportStore = initialState.talentReportStore;
    },
    setBadges(state, action: PayloadAction<{badget: 'approvals' | 'onboarding'; value: number}>) {
      state.badges[action.payload.badget] = action.payload.value;
    },
    setStoredTalent(state, action: PayloadAction<{userID: string}>) {
      state.storedUser.userID = action.payload.userID;
    },
    setStoredTalentOnChange(state, action: PayloadAction<{onChange: Function}>) {
      state.storedUser.onChangeUserCallback = action.payload.onChange;
    },
    setIsShowStoredTalent(state, action: PayloadAction<{isShow: boolean}>) {
      state.storedUser.isShowStoredUser = action.payload.isShow;
    },
    setFavorites(state, action: PayloadAction<FavoriteUserType[]>) {
      state.favorites = [...action.payload];
    },
    setIsShowResetPassword(state, action: PayloadAction<boolean>) {
      state.isShowResetPassword = action.payload;
    },
    clearFavorites(state) {
      state.favorites = [];
    },
    deleteUserFromFavorite(state, action: PayloadAction<{email: string}>) {
      state.favorites = state.favorites.filter(el => el.email !== action.payload.email);
    },
    addUserToFavorite(
      state,
      action: PayloadAction<{
        user?: FavoriteUserType;
      }>
    ) {
      const user = action.payload.user;

      if (user) {
        if (state.favorites.some(el => el.email === user.email)) {
          state.favorites = state.favorites.filter(el => el.email !== user.email);
        } else {
          state.favorites.push(user);
        }
      }
    },
  },
});
export const communityReducer = slice.reducer;
export const communityActions = slice.actions;
