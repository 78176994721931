import {THEME} from '@/Styles/theme';
import {ColorType} from '@/Styles/types';
import {Component} from './button.styled';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color: ColorType;
  fontColor?: ColorType;
  size?: keyof typeof THEME.ButtonSize;
  isDisabled?: boolean;
}

const Button: React.FC<Props> = props => {
  const {color, children, size, fontColor, isDisabled, ...restProps} = props;
  return (
    <Component className="Button" color={color} fontColor={fontColor} size={size} isDisabled={isDisabled}>
      <button {...restProps}>{children}</button>
    </Component>
  );
};

export default Button;
