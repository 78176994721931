import {PreOrderSearchParams} from '@/API/Campaign/types';
import {useReduceState} from '@/Hooks';
import {usePreOrderTalents} from '@/Hooks/Campaigns';
import useDebouncedValue from '@/Hooks/useDebouncedValue';
import {Box, Div, ElementHeadline, Grid, Input, TableContainer, Text} from '@/UI';
import {GenericSelect} from '@/Website/Components/Common';
import {useMemo, useState} from 'react';
import PreOrderRow from './PreOrderRow/PreOrderRow';
import {Component} from './preOrderTalents.styled';
import {useCreateOrderByPreOrders} from '../Hooks';

type PreOrderTalentsProps = {campaignID: number};

const PreOrderTalents: React.FC<PreOrderTalentsProps> = ({campaignID}) => {
  const [searchState, setSearchState] = useState<PreOrderSearchParams>({campaignID, email: ''});
  const {debouncedValue} = useDebouncedValue(searchState, 500);
  const {preOrders: talents, reloadPreOrders} = usePreOrderTalents(debouncedValue);
  const headers = useMemo(() => ['', 'Talent', 'Followers', 'Price', 'Adv price', 'Campaign', '', ''], []);

  const totalTalentPrice = useMemo(() => {
    let sum = 0;
    talents.forEach(el => (sum = sum + el.talent_price || 0));
    return sum;
  }, [talents]);

  const totalAdvPrice = useMemo(() => {
    let sum = 0;
    talents.forEach(el => (sum = sum + el.advertiser_price || 0));
    return sum;
  }, [talents]);

  const handleInputsChange = useReduceState(setSearchState);
  const convertToOrders = useCreateOrderByPreOrders(campaignID, reloadPreOrders);

  return (
    <Component className="PreOrderTalents campaign-tab">
      <ElementHeadline
        options={[{option: 'Convert all to orders', onSelect: convertToOrders, isDisabled: !talents.length}]}
        disableMarker
        fontSize="Text2"
        title="Pending talent orders"
      />
      <Div gap={20}>
        <Div gap={10} alignItems="center">
          <Text type="Text3" fontWeight={600}>
            Total pending talents:
          </Text>
          <Text type="Text1" fontWeight={800} color="Success">
            {talents.length}
          </Text>
        </Div>
        <Div gap={10} alignItems="center">
          <Text type="Text3" fontWeight={600}>
            Total talent price:
          </Text>
          <Text type="Text1" fontWeight={800} color="Orange">
            ${totalTalentPrice}
          </Text>
        </Div>
        <Div gap={10} alignItems="center">
          <Text type="Text3" fontWeight={600}>
            Total adv price:{' '}
          </Text>
          <Text type="Text1" fontWeight={800} color="Alert">
            ${totalAdvPrice}
          </Text>
        </Div>
      </Div>

      <Grid templateColumn="repeat(2, 250px)" columnGap={10}>
        <Input
          placeholder="Email / Name"
          value={searchState.email}
          onChange={e => handleInputsChange('email', e.target.value)}
        />
        <GenericSelect
          optionsType="socials"
          value={searchState.social}
          label="Social"
          onChange={value => handleInputsChange('social', value)}
        />
      </Grid>
      <div className="pre-order-list">
        <TableContainer stickyHeader headers={headers} isLoading={false} isSmall>
          {talents.length > 0 &&
            talents.map((talent, i) => (
              <PreOrderRow talent={talent} afterChangePreOrder={reloadPreOrders} key={i} />
            ))}
        </TableContainer>
      </div>
    </Component>
  );
};

export default PreOrderTalents;
