import {useEffect, useState} from 'react';
import {AnalyticsResponse} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCommunityStore} from '@/Store/community/community.selector';

const useTalentAnalytics = () => {
  const {userID} = useCommunityStore().selectors.storedUser;
  const [talentAnalytic, setTalentAnalytic] = useState<AnalyticsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = useApiLoader(
    async () => {
      setIsLoading(true);
      if (userID)
        await API.Talents.getTalentAnalytics(userID)
          .then(res => setTalentAnalytic(res))
          .finally(() => setIsLoading(false));
    },
    [userID],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    talentAnalytic,
    isLoading,
    reloadTalentAnalytic: fetch,
  };
};
export default useTalentAnalytics;
