import {ContextMenu, Div, ElementHeadline, TableContainer, TCell, Text, TRow} from '@/UI';
import {useCallback, useMemo, useState} from 'react';
import {dateToLocalDate} from '@/Services/date.service';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {OrderDispute} from '@/API/Orders/types';
import {useQuickResponse} from '@/Website/Components/Portals/QuickResponse';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useOrderDisputes} from '@/Website/Components/Portals/Order/Hooks';
import DisputeEditor from '@/Website/Components/Portals/Order/OrderDisputeRevision/DisputesTable/DisputeEditor';
import NewDispute from '@/Website/Components/Portals/Order/OrderDisputeRevision/DisputesTable/NewDispute';

const DisputesTable: React.FC<{orderID: number}> = ({orderID}) => {
  const {setRecipient} = useQuickResponse();
  const {email, username} = usePortalsStore().selectors.orders.orderInPortal;
  const headers = useMemo(() => ['Reason', 'Details', 'Created at', ''], []);
  const [selectedDispute, setSelectedDispute] = useState<OrderDispute>();
  const {disputes, reloadDisputes} = useOrderDisputes(orderID);
  const ShowNewDispute = useBooleanState();
  const ShowDisputeEditor = useBooleanState();

  const handleSelectDispute = useCallback((dispute: OrderDispute) => {
    ShowDisputeEditor.turnOn();
    setSelectedDispute(dispute);
  }, []);

  const sendAnswerHandler = useCallback(() => {
    setRecipient({
      name: username,
      email,
      subject: 'Dispute',
      sendType: 'dispute',
    });
  }, []);
  return (
    <Div flexDirection="column">
      <ElementHeadline title="Disputes" disableMarker onCreateNew={ShowNewDispute.turnOn} />
      <TableContainer headers={headers} isSmall isLoading={false}>
        {disputes.length > 0 &&
          disputes.map((dispute, i) => (
            <TRow key={i}>
              <TCell>
                <Text type="Text3" color="Alert" fontWeight={800}>
                  {dispute.reason}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3" fontWeight={800}>
                  {dispute.dissatisfaction_details}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3" color="Success" fontWeight={800}>
                  {dateToLocalDate(dispute.created_at)}
                </Text>
              </TCell>
              <TCell>
                <ContextMenu
                  options={[
                    {option: 'Edit', onSelect: () => handleSelectDispute(dispute)},
                    {option: 'Send answer', onSelect: sendAnswerHandler},
                  ]}
                />
              </TCell>
            </TRow>
          ))}
      </TableContainer>
      {selectedDispute && ShowDisputeEditor.isTurn && (
        <DisputeEditor
          isShow={ShowDisputeEditor.isTurn}
          onClose={ShowDisputeEditor.turnOff}
          onChange={reloadDisputes}
          dispute={selectedDispute}
        />
      )}
      {ShowNewDispute.isTurn && (
        <NewDispute
          isShow={ShowNewDispute.isTurn}
          onClose={ShowNewDispute.turnOff}
          onChange={reloadDisputes}
        />
      )}
    </Div>
  );
};
export default DisputesTable;
