import {TransactionType, WalletDetails} from '../Talents/types';
import {CreateTransactionDTO, FinancialDoc} from './types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class WalletApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async getTalentWalletById(userID: string): Promise<WalletDetails> {
    return await this.api.get(`/talent/${userID}`).then(res => res.data);
  }
  //
  async createWalletOperation(operationPayload: CreateTransactionDTO): Promise<any> {
    return await this.api.post(`/create-transaction`, operationPayload);
  }
  //
  async updateTransaction(transaction: TransactionType): Promise<any> {
    return await this.api.patch(`/transaction`, transaction);
  }
  //
  async getTalentFinancialDocs(talentID: string): Promise<FinancialDoc[]> {
    return await this.api.get(`financial-docs/${talentID}`).then(res => res.data);
  }
  //
  async deleteTransaction(transactionID: number): Promise<any> {
    return await this.api.delete(`/transaction/${transactionID}`);
  }
  async uploadFinancialDoc(talentID: string, file: File, docType: string): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('docType', docType || '');
    return await this.api.post(`financial-doc/${talentID}`, formData);
  }
  async deleteFinancialDoc(params: {docID: number; talentId: string}): Promise<any> {
    return await this.api.delete(`financial-doc`, {
      params,
    });
  }
}
export default WalletApi;
