import {ReactComponent as BrandIcon} from '@/Assets/Icons/campaign/brand.svg';
import {ReactComponent as DetailsIcon} from '@/Assets/Icons/campaign/details.svg';
import {ReactComponent as AnalyticIcon} from '@/Assets/Icons/campaign/monitoring.svg';
import {ReactComponent as PreOrderIcon} from '@/Assets/Icons/campaign/preorder.svg';
import {ReactComponent as MaterialsIcon} from '@/Assets/Icons/materials.svg';
import {ReactComponent as OrderIcon} from '@/Assets/Icons/talent/order.svg';
import {SelectOptionsType} from '@/UI/Select/select.styled';

export const CampaignService = {
  getPromptForCampaign: (
    campaignDescription?: string
  ) => `For the following brand overview, write a TikTok Reel script for your influencers on what they need to focus on or instruct them on the videos they need to create for this campaign. In your points specify video structure, higher conversion videos tips and things that might help a campaign for this brand.
  Do not give any points regarding the technical side of tracking the conversion - focus only on how the video should be, looks and feel.
  Overview: ${campaignDescription}`,
  tabs: [
    {label: 'Campaign details', icon: DetailsIcon},
    {label: 'Brand', icon: BrandIcon},
    {label: 'Materials', icon: MaterialsIcon},
    {label: 'Orders', icon: OrderIcon},
    {label: 'Pre order', icon: PreOrderIcon},
    {label: 'Analytics', icon: AnalyticIcon},
  ],
  audienceLeftRange: [
    {label: '13', value: 13},
    {label: '18', value: 18},
    {label: '25', value: 25},
    {label: '35', value: 35},
    {label: '45', value: 45},
    {label: '65', value: 65},
  ] as SelectOptionsType,
  audienceRightRange: [
    {label: '17', value: 17},
    {label: '24', value: 24},
    {label: '34', value: 34},
    {label: '44', value: 44},
    {label: '64', value: 64},
    {label: '65', value: 65},
  ] as SelectOptionsType,

  statuses: [
    {label: 'Draft', value: 'draft'},
    {label: 'Pending payment', value: 'pending-payment'},
    {label: 'Campaign paid', value: 'campaign-paid'},
    {label: 'On-hold', value: 'on-hold'},
    {label: 'Running', value: 'running'},
    {label: 'Active', value: 'active'},
    {label: 'Tracking', value: 'tracking'},
    {label: 'Completed', value: 'completed'},
    {label: 'Ended', value: 'ended'},
  ],

  goals: [
    {label: '-', value: ''},
    {label: 'Performance', value: 'Performance'},
    {label: 'brandAwareness', value: 'brandAwareness'},
    {label: 'UGC', value: 'UGC'},
  ],
  genders: [
    {label: '-', value: ''},
    {label: 'Both', value: 'both'},
    {label: 'Male', value: 'male'},
    {label: 'Female', value: 'female'},
  ],
};
