import styled, {css} from 'styled-components';
import {NotificationType} from '@/Store/root/types';

export const Component = styled.div<{Type: NotificationType['type']}>`
  ${({theme, Type}) => css`
    z-index: 99999;
    cursor: pointer;
    .notify {
      .notify-body {
        border-radius: 0 0 15px 15px;
      }
      .notify-header {
        .warningIcon {
          height: 20px;
          width: 20px;
          fill: ${theme.Color.Alert};
        }
        .successIcon {
          height: 20px;
          width: 20px;
          fill: ${theme.Color.Success};
        }
        padding: 10px 5px 10px 17px;
        background-color: transparent;
      }
      position: absolute;
      top: 5%;
      padding: 0;
      min-height: 50px;
      right: 0;
      max-width: 350px;
      width: 300px;
      ${Type === 'success' &&
      css`
        background-color: ${theme.Color.SuccessLight};
      `}
      ${Type === 'failed' &&
      css`
        background-color: ${theme.Color.AlertLight};
      `}

      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.9rem;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    .progress {
      position: absolute;
      left: 5px;
      bottom: 5px;
      width: 0;
      height: 3px;
      background-image: linear-gradient(
        to right,
        #ffffff,
        ${Type === 'success' ? `${theme.Color.Success}` : `${theme.Color.Alert}`}
      );
      border-radius: 4px;

      animation: ${theme.Animations.progress} 7s infinite linear forwards;
    }
  `}
`;
