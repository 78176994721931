import {URL} from '@/API/urls';
import {Box, InputMedia} from '@/UI';
import {
  useDeleteTalentPhoto,
  useDeleteTalentVideo,
  useUploadTalentPhoto,
  useUploadTalentVideo,
} from '@/Website/Components/Talent/Hooks';
import {TalentDetailProps} from '@/Website/Components/Talent/types';
import {useCallback} from 'react';

const TalentMediaFiles: React.FC<TalentDetailProps> = ({talent, setTalent}) => {
  const deleteTalentPhoto = useDeleteTalentPhoto(talent.avatar);
  const deleteTalentVideo = useDeleteTalentVideo(talent.avatar_video);
  const uploadTalentVideo = useUploadTalentVideo(talent.avatar_video);
  const uploadTalentPhoto = useUploadTalentPhoto(talent.avatar);

  const handleDeleteTalentVideo = useCallback(
    async () =>
      await deleteTalentVideo().then(() => {
        setTalent(prev => ({...prev, avatar_video: ''}));
      }),
    [deleteTalentVideo, setTalent]
  );
  const handleUploadTalentVideo = useCallback(
    async (video: any) =>
      await uploadTalentVideo(video).then((res: any) => {
        setTalent(prev => ({...prev, avatar_video: res.avatar_video}));
      }),
    [setTalent, uploadTalentVideo]
  );
  const handleUploadTalentPhoto = useCallback(
    async (newPhoto: any) =>
      await uploadTalentPhoto(newPhoto).then((res: any) => {
        setTalent(prev => ({...prev, avatar: res.avatar}));
      }),
    [setTalent, uploadTalentPhoto]
  );
  const handleDeleteTalentPhoto = useCallback(
    async () =>
      await deleteTalentPhoto().then(() => {
        setTalent(prev => ({...prev, avatar: ''}));
      }),
    [deleteTalentPhoto, setTalent]
  );
  return (
    <Box backgroundColor="BlueLight" label="Media" flexDirection="column" justifyContent="space-evenly">
      <InputMedia
        onDelete={handleDeleteTalentPhoto}
        onUpload={handleUploadTalentPhoto}
        type="photo"
        mediaFile={talent.avatar ? URL.MEDIA_ROUTE + talent.avatar : ''}
      />
      <InputMedia
        onDelete={handleDeleteTalentVideo}
        onUpload={handleUploadTalentVideo}
        type="video"
        mediaFile={talent.avatar_video ? URL.MEDIA_ROUTE + talent.avatar_video : ''}
      />
    </Box>
  );
};
export default TalentMediaFiles;
