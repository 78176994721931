import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCommunityStore} from '@/Store/community/community.selector';
import {useDeleteTalentPhoto} from '@/Website/Components/Talent/Hooks';

const useUploadTalentPhoto = (currentAvatar?: string) => {
  const {userID} = useCommunityStore().selectors.storedUser;
  const deleteTalentVideo = useDeleteTalentPhoto(currentAvatar);

  return useApiLoader(
    async (uploadedAvatar: any) => {
      if (currentAvatar) await deleteTalentVideo();
      return await API.Users.uploadPhoto(userID, uploadedAvatar);
    },
    [userID, currentAvatar]
  );
};
export default useUploadTalentPhoto;
