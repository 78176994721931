import {Select} from '@/UI';
import {SelectProps} from '@/UI/Select/Select';
import {OrderStatusType, OrdersService} from '@/Website/Pages/Orders/orders.service';
import {useMemo} from 'react';

interface ISubStatusSelect extends Omit<SelectProps, 'options'> {
  mainStatus: OrderStatusType;
  subStatusType?: 'order' | 'campaign';
}

const SubStatusSelect: React.FC<ISubStatusSelect> = props => {
  const {mainStatus, ...selectProps} = props;

  const subStatuses = useMemo(() => OrdersService.getOrderSubStatuses(mainStatus), [mainStatus]);
  return subStatuses.length > 0 ? <Select options={subStatuses} {...selectProps} /> : null;
};
export default SubStatusSelect;
