import {useCommunityStore} from '@/Store/community/community.selector';
import {useCallback} from 'react';

const useCloseTalent = () => {
  const {
    actions: {setIsShowStoredTalent, setStoredTalent},
  } = useCommunityStore();
  return useCallback(() => {
    setIsShowStoredTalent({isShow: false});
    setStoredTalent({userID: ''});
  }, [setIsShowStoredTalent, setStoredTalent]);
};

export default useCloseTalent;
