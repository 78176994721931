import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteOrderMaterials = (orderID: number, onSuccess: () => Promise<any>) => {
  return useApiLoader(
    async (key: string) => {
      await API.Orders.deleteVideoOrder({id: orderID, key}).then(async () => await onSuccess());
    },
    [orderID],
    {successMsg: 'Deleted'}
  );
};
export default useDeleteOrderMaterials;
