import styled, {css} from 'styled-components';
import {memo} from 'react';

const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    button {
      margin: 10px 0;
    }
    footer {
      margin-top: 30px;
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.6rem;
      text-align: right;
    }

    .wrapper {
      max-width: 330px;
      display: flex;
      flex-direction: column;
      .greeting {
        display: flex;
        flex-direction: column;
        div:last-child {
          margin: 10px 0 20px 0;
        }
      }
      .form {
        flex-basis: 70%;
        display: flex;
        flex-direction: column;
      }
    }
  `}
`;

export default memo(Component);
