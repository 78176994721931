import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import useApiLoader from '../useApiLoader';

const useCategorySelectOptions = () => {
  const [categoryOptions, setCategoryOptions] = useState<Array<{value: any; label: string}>>([
    {value: '', label: '-'},
  ]);
  const fetchCategories = useApiLoader(async () => {
    return await API.Categories.getCategories('')
      .then(res => {
        return res.map(el => ({value: el.id, label: el.id + ':' + el.category_name || ''}));
      })
      .then(res => setCategoryOptions([{value: '', label: '-'}, ...res]));
  }, []);

  useEffect(() => {
    void fetchCategories();
  }, []);
  return categoryOptions;
};

export default useCategorySelectOptions;
