import {TalentDetailed} from '@/API/Talents/types';
import {useReduceState} from '@/Hooks';
import {Button, ElementHeadline, Grid, Input, Modal, Select, TextArea} from '@/UI';
import {UTIL_CONSTANTS} from '@/Util/constants';
import {useCreateManuallyTalentReport} from '@/Website/Components/Portals/TalentReport/Hooks';
import {NewTalentInfoService} from '@/Website/Components/Talent/TalentSocial/NewTalentInfo/newTalentInfo.service';
import {useEffect, useMemo, useState} from 'react';

interface NewTalentInfoProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
  //@example {label:tiktok, value: tiktok, url: @bestTiktok.com
  socials: Array<{label: string; value: string; url: string | undefined}>;
  talent: TalentDetailed;
}
const NewTalentInfo: React.FC<NewTalentInfoProps> = props => {
  const {onClose, isShow, onChange, socials, talent} = props;
  const {defaultCreateState} = useMemo(() => NewTalentInfoService, []);
  const [createState, setCreateState] = useState<typeof defaultCreateState>(defaultCreateState);
  const handleChangeCreateState = useReduceState<typeof createState>(setCreateState);

  const handleCreateInfo = useCreateManuallyTalentReport(
    {
      ...createState,
      email_contact: talent.email,
      profile_photo: talent.avatar || '',
      user_name: talent.full_name || talent.username || talent.email,
      user_id: talent.user_id,
    },
    () => {
      onChange();
      onClose();
    }
  );

  useEffect(() => {
    if (createState.social) {
      const url = socials.find(el => el.value === createState.social)?.url || '';
      handleChangeCreateState('url', url);
    }
    handleChangeCreateState('bio', talent.bio);
  }, [createState.social]);
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline onClose={onClose} title="Create talent report manually" />
      <Grid templateColumn="repeat(3, 250px)" alignItems="flex-end" columnGap={10}>
        <Select
          isSearchable={true}
          options={socials}
          label="Platform"
          value={createState.social}
          onChange={value => handleChangeCreateState('social', value)}
        />
        <Input
          placeholder="Social url"
          autoFocus
          value={createState.url}
          onChange={e => handleChangeCreateState('url', e.target.value)}
        />
        <Input
          placeholder="Language"
          autoFocus
          value={createState.language}
          onChange={e => handleChangeCreateState('language', e.target.value)}
        />
        <Select
          isSearchable={true}
          options={UTIL_CONSTANTS.COUNTRIES}
          label="Country"
          value={createState?.country || ''}
          onChange={value => handleChangeCreateState('country', value)}
        />
        <Input
          placeholder="ER"
          type="number"
          value={createState.er}
          onChange={e => handleChangeCreateState('er', e.target.value)}
        />
        <Input
          placeholder="OPS Price"
          type="number"
          value={createState.opsPrice}
          onChange={e => handleChangeCreateState('opsPrice', e.target.value)}
        />

        <Select
          options={UTIL_CONSTANTS.AGE_GROUPS}
          label="Age group"
          value={createState?.age_group || ''}
          onChange={value => handleChangeCreateState('age_group', value)}
        />

        <Input
          placeholder="Followers"
          type="number"
          value={createState.followers}
          onChange={e => handleChangeCreateState('followers', e.target.value)}
        />
        <Input
          placeholder="Margin price"
          type="number"
          value={createState.marginPrice}
          onChange={e => handleChangeCreateState('marginPrice', e.target.value)}
        />
      </Grid>
      <TextArea
        value={createState?.bio || ''}
        row={7}
        placeholder="Bio"
        onChange={(e: any) => handleChangeCreateState('bio', e.target.value)}
      />
      <Button color="Success" style={{marginTop: 20}} onClick={handleCreateInfo}>
        CREATE
      </Button>
    </Modal>
  );
};
export default NewTalentInfo;
