import {OrdersService} from '@/Website/Pages/Orders/orders.service';

export const CAMPAIGN_ORDER_CONST = {
  orderStatuses: OrdersService.ORDER_STATUSES,
  ordersTableHeaders: ['ID', '', 'Status', 'Sub status', 'Email', 'Price', 'Adv price', '', 'Created at'],
  defaultFilterState: {
    // orderStatus: '',
    orderID: '',
    skip: 0,
    searchQuery: '',
    limit: 30,
  },
};
