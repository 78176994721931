import {useEffect, useState} from 'react';
import {Monitor} from '@/API/Campaign/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useCampaignMonitor = () => {
  const [monitor, setMonitor] = useState<Monitor>();
  const fetch = useApiLoader(async () => {
    await API.Campaigns.getMonitor().then(res => setMonitor(res));
  }, []);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    monitor,
    reloadMonitor: fetch,
  };
};
export default useCampaignMonitor;
