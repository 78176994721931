import {useEffect, useState} from 'react';
import {WalletDetails} from '@/API/Talents/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCommunityStore} from '@/Store/community/community.selector';

const useGetTalentWallet = () => {
  const {
    storedUser: {userID},
  } = useCommunityStore().selectors;
  const [wallet, setWallet] = useState<WalletDetails>({transactions: []});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchWallet = useApiLoader(
    async () => {
      setIsLoading(true);
      await API.Wallet.getTalentWalletById(userID)
        .then(res => {
          setWallet(res);
        })
        .finally(() => setIsLoading(false));
    },
    [userID],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetchWallet();
  }, [fetchWallet]);
  return {
    wallet,
    isLoading,
    reloadWallet: fetchWallet,
  };
};
export default useGetTalentWallet;
