import {useApiLoader, useFireBaseAuth} from '@/Hooks';
import {useRootStore} from '@/Store/root/root.selector';
import {Box, Button, Input, Loader, Notification, Text} from '@/UI';
import {useEffect, useState} from 'react';
import Component from './login.styled';

const Login: React.FC = () => {
  const {firebaseLogin} = useFireBaseAuth();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const {
    notification: {isShowNotification},
  } = useRootStore().selectors;
  const loginHandler = useApiLoader(
    async () => await firebaseLogin({email: username, password}),
    [firebaseLogin, password, username]
  );
  /** Hot enter key listener */
  useEffect(() => {
    const EnterKeyEvent = (e: KeyboardEvent) => e.key === 'Enter' && loginHandler();
    window.addEventListener('keydown', EnterKeyEvent);
    return () => window.removeEventListener('keydown', EnterKeyEvent);
  }, [loginHandler]);
  /** */

  return (
    <Component className="Login">
      {isShowNotification && <Notification />}
      <Loader />

      <Box className="wrapper">
        <div className="greeting">
          <Text type="Header1" fontWeight={800}>
            Sign In
          </Text>
          <Text type="Text1">Enter your username and password to access admin panel</Text>
        </div>

        <div className="form">
          <Input
            placeholder="Username"
            autoFocus
            type={'email'}
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <Input
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            type={'password'}
            style={{marginBottom: 20}}
          />
        </div>
        <Button
          isDisabled={password.length < 6 || username.length < 6}
          color="DarkBlue"
          onClick={loginHandler}
        >
          Enter
        </Button>
        <Text type="Text4" textAlign="right">
          ©LetsTok 2023 prod
        </Text>
      </Box>
    </Component>
  );
};

export default Login;
