import {useApiLoader, useOpenTalent} from '@/Hooks';
import {API} from '@/API/API';
import {CreateTalentPayload} from '@/API/Talents/types';

const useCreateTalent = (onCreate: Function) => {
  const openTalent = useOpenTalent();
  return useApiLoader(
    async (payload: CreateTalentPayload) => {
      return await API.Talents.createTalent(payload).then(talent => {
        onCreate();
        openTalent(talent.user_id, onCreate);
      });
    },
    [],
    {successMsg: 'Talent created'}
  );
};
export default useCreateTalent;
