import { API } from "@/API/API"
import { OrderType, UpdateSelectedOrderStatuses } from "@/API/Orders/types"
import { useApiLoader } from "@/Hooks"
import { usePortalsStore } from "@/Store/portals/portals.selector"

const useUpdateOrderStatuses = () => {
    const {ordersUpdater: {callBackOnUpdate}} = usePortalsStore().selectors
    return useApiLoader(async (payload: {
        orders: OrderType[], status: string, subStatus?: string
    }) => {
        const toUpdate = {
            orders: payload.orders.map(order => ({id: order.id})),
            status: payload.status,
            subStatus: payload.subStatus
        } as UpdateSelectedOrderStatuses
        await API.Orders.updateSelectedOrdersStatuses(toUpdate).then(async () => {
           callBackOnUpdate && await callBackOnUpdate()
        })
    },[], {successMsg: 'Updated'})
}
export default useUpdateOrderStatuses