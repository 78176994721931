import styled, {css} from 'styled-components';
import {memo} from 'react';

const SOrderMaterials = styled.div`
  ${({theme}) => css`
    width: 1000px;
    height: 100%;
    padding: 20px 15px 15px;
    background-color: ${theme.Color.White};
    border-radius: 0 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .files {
      width: 100%;
      border-top: 1px solid ${theme.Color.GrayLight};
      padding-top: 15px;
      height: 250px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  `}
`;

export default memo(SOrderMaterials);
