import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteTalent = (handleCloseModal: Function) => {
  const {
    storedUser: {userID, onChangeUserCallback},
  } = useCommunityStore().selectors;
  return useApiLoader(
    async () => {
      await API.Users.deleteTalentByID(userID).then(() => {
        onChangeUserCallback();
        handleCloseModal();
      });
    },
    [userID, onChangeUserCallback],
    {successMsg: 'Successfully deleted'}
  );
};
export default useDeleteTalent;
