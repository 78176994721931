import {URL} from '@/API/urls';

export class UtilService {
  static openMedia(file: string) {
    file && window.open(URL.MEDIA_ROUTE + file, '_blank');
  }
  static removeHtmlTags(text?: string): string {
    return !text ? '' : text.replace(/<[^>]*>|&nbsp;|&amp;|&lt;|&gt;/g, '');
  }

  static checkIsUuid(value: string) {
    if (value === '') return true;
    const uuidRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    return uuidRegex.test(value) ? value : '';
  }
  static spaceReplaceToUnderScore(value: string) {
    return value ? value.replaceAll(' ', '_') : '';
  }
  static hundredSeparator(enteredNumber?: any) {
    if (enteredNumber) {
      enteredNumber = Math.ceil(Number(enteredNumber));
      if (enteredNumber < 1000) return enteredNumber;
      let res = '';
      let arr = enteredNumber.toString().split('');
      let counter = 0;
      arr.reverse().forEach((el: any) => {
        if (counter === 3) {
          counter = 0;
          res = ',' + res;
        }
        res = el + res;
        counter++;
      });
      return res;
    } else {
      return 0;
    }
  }
}
