import {API} from '@/API/API';
import {CompletionDTO} from '@/API/Openai/openai.types';
import {useApiLoader} from '@/Hooks';
import {useState} from 'react';

const useOpenAIFreeDialog = () => {
  const [generatedResult, setGeneratedResult] = useState<string>();

  const generate = useApiLoader(async (params: CompletionDTO) => {
    return await API.OpenAI.freeDialog(params).then(result => setGeneratedResult(result));
  }, []);

  return {
    generatedResult,
    generate,
    setGeneratedResult,
  };
};
export default useOpenAIFreeDialog;
