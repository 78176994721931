import styled, {css} from 'styled-components';

export const Component = styled.div<{isOpened: boolean}>`
  ${({theme, isOpened}) => css`
    position: relative;
    max-width: ${isOpened ? '500px' : '50px'};
    min-width: ${isOpened ? '270px' : 'auto'};
    margin: 11px 0;
    border-radius: 0 15px 15px 0;
    ${isOpened &&
    css`
      overflow-y: scroll;
    `};

    padding-bottom: 50px;

    &::-webkit-scrollbar {
      width: 0;
    }

    background-color: ${theme.Color.DarkBlue};
    box-shadow: 1px 1px 14px ${theme.Color.MediumGray};

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.Color.MediumGray};
      }
    }

    .header {
      height: ${isOpened ? `58px` : `180px`};
      background-color: ${theme.Color.BlackOcean};
      position: sticky;
      border-bottom: 1px solid ${theme.Color.DarkGray};
      top: 0;
      border-radius: 0 15px 0 0;
      z-index: 1;
    }

    .home-icon {
      position: absolute;
      cursor: pointer;
      height: 25px;

      &:hover {
        filter: ${theme.SVGColor.White};
      }
    }

    .home-icon {
      ${isOpened
        ? css`
            top: 18px;
            right: 40px;
          `
        : css`
            top: 78px;
            left: 12px;
          `}
    }
  `}
`;
