import {useMemo} from 'react';
import {Label, SocialIcon, TableContainer, TCell, Text, TRow} from '@/UI';
import ColorService from '@/Services/color.service';
import {dateToLocalDate} from '@/Services/date.service';
import {OrderType} from '@/API/Orders/types';
import {SocialsType} from '@/Services/social.service';
import {Like} from '@/Website/Components';
import {useOpenOrder} from '@/Website/Components/Portals/Order/Hooks';
import {CAMPAIGN_ORDER_CONST} from '../campaignOrders.service';
interface Props {
  orders: OrderType[];
  isLoading: boolean;
}
const CampaignOrdersTable: React.FC<Props> = ({orders, isLoading}) => {
  const handleOpenOrder = useOpenOrder();
  const headers = useMemo(() => CAMPAIGN_ORDER_CONST.ordersTableHeaders, []);

  return (
    <TableContainer isSmall headers={headers} isLoading={isLoading}>
      {orders.map((order, i) => (
        <TRow key={i} onClick={() => handleOpenOrder(order)}>
          <TCell>
            <Text type="Text3">{order.id}</Text>
          </TCell>
          <TCell>
            {order.social_platform && <SocialIcon isActive social={order.social_platform as SocialsType} />}
          </TCell>
          <TCell>
            <Label backgroundColor={ColorService.getColorTypeByStatus(order.status)}>{order.status}</Label>
          </TCell>
          <TCell>
            <Label backgroundColor={ColorService.getColorTypeByStatus(order.sub_status)}>
              {order.sub_status}
            </Label>
          </TCell>
          <TCell>
            <Text type="Text3" style={{flexBasis: '300px'}}>
              {order.email}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" fontWeight={800} color="Orange" flex="1">
              ${order.talent_price}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3" fontWeight={800} color="Purple" flex="1">
              ${order.advertiser_price || 0}
            </Text>
          </TCell>
          <TCell>
            {order.talent_info_id && (
              <Like
                talent={{
                  price: order.talent_price,
                  email: order.email,
                  userID: order.talent,
                  name: order.username,
                  photo: order.avatar,
                  advertiserPrice: order.advertiser_price,
                  talentInfoID: order.talent_info_id,
                }}
              />
            )}
          </TCell>
          <TCell>
            <Text type="Text3" color="Success" fontWeight={800} style={{flexBasis: '200px'}}>
              {dateToLocalDate(order.created_at)}
            </Text>
          </TCell>
        </TRow>
      ))}
    </TableContainer>
  );
};
export default CampaignOrdersTable;
