import {FC, useCallback, useEffect, useState} from 'react';
import {Props} from './modal.interface';
import {Component, FormBox} from './modal.styled';
import closeIcon from '@/Assets/Icons/close.svg';

const Modal: FC<Props> = props => {
  const {isShowModal, onClose, children, fadeColor, style, label, backgroundColor} = props;
  const [showModalState, setShowModalState] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const closingHandler = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setShowModalState(false);
      onClose && onClose(false);
      setIsClosing(false);
    }, 400);
  }, []);

  const handleClickClose = useCallback(() => {
    onClose && onClose(false);
  }, [onClose]);

  useEffect(() => (isShowModal === true ? setShowModalState(isShowModal) : closingHandler()), [isShowModal]);

  return showModalState ? (
    <Component className="Modal-window" fadeColor={fadeColor}>
      <div className="ref-wrapper">
        <FormBox
          backgroundColor={backgroundColor}
          {...props}
          className={isClosing ? 'close' : ''}
          style={style}
          label={label}
        >
          {children}
          {onClose && <img src={closeIcon} alt="" className="close-icon" onMouseDown={handleClickClose} />}
        </FormBox>
      </div>
    </Component>
  ) : null;
};

export default Modal;
