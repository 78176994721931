import styled, {css} from 'styled-components';

const SProjectVersion = styled.div<{isChecked: boolean}>`
  ${({theme, isChecked}) => css`
    .app-version {
      position: absolute;
      right: 470px;
      font-weight: 400;
      letter-spacing: 1px;
      ${isChecked
        ? css`
            background-color: transparent;
            color: ${theme.Color.MediumGray};
          `
        : css`
            background-color: ${theme.Color.Alert};
            color: ${theme.Color.BlackOcean};
          `}
      margin-top: 3px;
      padding: 2px 5px;
      border-radius: 15px;

      transition: transform 0.3s ease;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }

    .description-text {
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 500px;
      width: 500px;
      ${theme.Presets.scroll}
    }
  `}
`;
export default SProjectVersion;
