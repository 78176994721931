import {TransactionType} from '@/API/Talents/types';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {dateToLocalDate} from '@/Services/date.service';
import {Div, TableContainer, TCell, Text, TRow} from '@/UI';
import {useCallback, useMemo, useState} from 'react';
import TransactionDetails from './TransactionDetails/TransactionDetails';

interface TransactionsProps {
  transactions: TransactionType[];
  refreshWallet: Function;
  isLoading: boolean;
}
const Transactions: React.FC<TransactionsProps> = ({isLoading, transactions, refreshWallet}) => {
  const [selectedTransaction, setSelectedTransaction] = useState<TransactionType>();
  const headers = useMemo(() => ['ID', 'Type', 'Order ID', 'Amount', 'Created at'], []);
  const ShowTransactionDetails = useBooleanState();
  const handleOpenDetailsModal = useCallback((trn: TransactionType) => {
    setSelectedTransaction(trn);
    ShowTransactionDetails.turnOn();
  }, []);
  return (
    <Div className="Transactions" height={430}>
      <TableContainer headers={headers} isSmall isLoading={isLoading}>
        {transactions.length > 0 &&
          transactions.map((transaction, i) => (
            <TRow key={i} onClick={() => handleOpenDetailsModal(transaction)}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {transaction.id}
                </Text>
              </TCell>
              <TCell>
                <Text
                  fontWeight={800}
                  type="Text3"
                  color={transaction.transaction_type.toLowerCase() === 'withdrawal' ? 'Blue' : 'Purple'}
                >
                  {transaction.transaction_type.toUpperCase()}
                </Text>
              </TCell>
              <TCell>
                <Text fontWeight={800} type="Text3" color="Orange">
                  {transaction.order_id || '-'}
                </Text>
              </TCell>
              <TCell>
                <Text
                  type="Text3"
                  color={transaction.transaction_type.toLowerCase() === 'withdrawal' ? 'Alert' : 'Success'}
                  fontWeight={800}
                >
                  {transaction.amount}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3" fontWeight={800}>
                  {dateToLocalDate(transaction.created_at)}
                </Text>
              </TCell>
            </TRow>
          ))}
      </TableContainer>

      {selectedTransaction && ShowTransactionDetails.isTurn && (
        <TransactionDetails
          onChange={refreshWallet}
          isShowDetails={ShowTransactionDetails.isTurn}
          onClose={ShowTransactionDetails.turnOff}
          transaction={selectedTransaction}
          setTransaction={setSelectedTransaction as React.Dispatch<React.SetStateAction<TransactionType>>}
        />
      )}
    </Div>
  );
};
export default Transactions;
