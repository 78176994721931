import {ReactComponent as Spinner} from '@/Assets/Images/spinner.svg';
import styled from 'styled-components';
import {memo} from 'react';

const Component = styled.div`
  .loader-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    svg {
      height: 150px;
    }
  }
  .show-loader {
    visibility: visible;
  }
`;

const Loader: React.FC = () => {
  return (
    <Component className="Loader">
      <div className="loader-wrapper">
        <Spinner />
      </div>
    </Component>
  );
};

export default memo(Loader);
