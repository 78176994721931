import {API} from '@/API/API';
import {TagResponseType, TagSearchParams} from '@/API/Tags/types';
import {useApiLoader} from '@/Hooks';
import {useEffect, useState} from 'react';

const useTags = (filterState?: TagSearchParams) => {
  const [tags, setTags] = useState<TagResponseType>({tags: [], count: 0});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTagsSelectOptions = useApiLoader(
    async (searchValue?: string) => {
      return await API.Tags.getAllTags({tagName: searchValue, limit: 30}).then(res => {
        return res.tags.map(el => ({value: el.id, label: `${el.tag_title}`}));
      });
    },
    [filterState],
    {disableLoader: true}
  );

  const fetch = useApiLoader(async () => {
    setIsLoading(true);
    if (filterState) {
      await API.Tags.getAllTags(filterState)
        .then(res => setTags(res))
        .finally(() => setIsLoading(false));
    }
  }, [filterState]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    tags,
    reloadTags: fetch,
    isLoading,
    getTagsSelectOptions,
  };
};
export default useTags;
