import {useApiLoader} from '@/Hooks';
import {DeleteDisputeRevisionParams} from '@/API/Orders/types';
import {API} from '@/API/API';

const useDeleteOrderRevision = (callBack?: Function) =>
  useApiLoader(
    async (params: DeleteDisputeRevisionParams) => {
      return await API.Orders.deleteOrderRevision(params).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Deleted'}
  );
export default useDeleteOrderRevision;
