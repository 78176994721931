import {useSubscriptions} from '@/Hooks/Subscriptions';
import {dateToLocalDate} from '@/Services/date.service';
import {UtilService} from '@/Services/util.service';
import {Div, Text} from '@/UI';
import {memo} from 'react';

const SubscriptionPlan: React.FC = () => {
  const {isLoading, sibPlan, iqdataPlan} = useSubscriptions();
  return (
    <Div className="StatusPlan" flexDirection="column" gap={20}>
      <Div flexDirection="column">
        <Text type="Header3" color="Purple" fontWeight={800}>
          SendinBlue
        </Text>
        <Text isLoading={isLoading} type="Header4">
          {UtilService.hundredSeparator(sibPlan?.plan[0].credits)}
        </Text>
        <Text type="Text3" color="Alert">
          End: {dateToLocalDate(sibPlan?.plan[0].endDate)}
        </Text>
      </Div>
      <Div flexDirection="column">
        <Text isLoading={isLoading} type="Header3" color="Blue" fontWeight={800}>
          IQData tokens
        </Text>
        <Text type="Header4">{UtilService.hundredSeparator(iqdataPlan?.token)}</Text>
        <Text type="Text3" color="Alert">
          End: {iqdataPlan?.subscriptions ? dateToLocalDate(iqdataPlan?.subscriptions[0].end) : '-'}
        </Text>
      </Div>
      <Div flexDirection="column">
        <Text isLoading={isLoading} type="Header3" color="Orange" fontWeight={800}>
          MailChimp
        </Text>
        <Text type="Header4">0</Text>
      </Div>
      <Div flexDirection="column">
        <Text isLoading={isLoading} type="Header3" color="Success" fontWeight={800}>
          Sendgrid
        </Text>
        <Text type="Header4">0</Text>
      </Div>
      <Div flexDirection="column">
        <Text isLoading={isLoading} type="Header3" color="Alert" fontWeight={800}>
          PinPoint
        </Text>
        <Text type="Header4">0</Text>
      </Div>
    </Div>
  );
};
export default memo(SubscriptionPlan);
