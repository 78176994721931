import axios, {AxiosInstance} from 'axios';
import AxiosRequestInterceptor from '@/Config/Axios/request.interceptor';
import {getAuth, getIdToken} from 'firebase/auth';
import {StorageService} from '@/Services/storage.service';
import {HttpHelper} from '@/Config/Axios/http.statuses';

class FactoryAxios {
  private readonly instance: AxiosInstance;
  constructor(baseURL: string, controller?: string) {
    this.instance = axios.create({
      baseURL: baseURL + '/' + controller,
    });
    this.instance.interceptors.request.use(AxiosRequestInterceptor);
    this.instance.interceptors.response.use(
      config => config,
      async rejectedResponse => {
        const originalRequest = rejectedResponse.config;
        const responseStatus = rejectedResponse.response.status;

        if (HttpHelper.isAuthError(responseStatus) && originalRequest && !originalRequest._isRetry) {
          originalRequest._isRetry = true;
          const user: any = getAuth().currentUser;

          if (getIdToken && user) {
            return getIdToken(user, true).then(token => {
              StorageService.set('TOKEN', token);
              originalRequest.headers = {
                Authorization: token,
                apiKey: token,
                'x-access-token': token,
              };
              return this.instance.request(originalRequest);
            });
          }
        } else {
          if (HttpHelper.isAuthError(responseStatus)) {
            StorageService.delete('TOKEN');
            StorageService.delete('USER');
            window.location.href = '/login';
          }
          throw rejectedResponse;
        }
      }
    );
  }
  getInstance() {
    return this.instance;
  }
}

export default FactoryAxios;
