import {OrderStatDetailedType} from '@/API/Orders/types';
import {dateToLocalDate} from '@/Services/date.service';
import {Div, Label, TableContainer, TCell, Text, TRow} from '@/UI';
import {useMemo} from 'react';

interface StatsTableProps {
  stats: OrderStatDetailedType[];
  onOpenEditor: (stat: OrderStatDetailedType) => void;
}
const StatsTable: React.FC<StatsTableProps> = props => {
  const {stats, onOpenEditor} = props;
  const headers = useMemo(
    () => ['id', 'Likes', 'Comments', 'Impressions', 'Shares', 'Saves', 'Actions', 'Clicks', 'Date'],
    []
  );
  return (
    <Div flexDirection="column" height={500}>
      <TableContainer headers={headers} isSmall isLoading={false}>
        {stats.map((stat, i) => (
          <TRow key={i} onClick={() => onOpenEditor(stat)}>
            <TCell>{stat.active && <Label backgroundColor="Success">Active</Label>}</TCell>
            <TCell>
              <Text type="Text3" color="Alert" fontWeight={800}>
                {stat.likes_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Orange" fontWeight={800}>
                {stat.comments_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Purple" fontWeight={800}>
                {stat.impressions_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Orange" fontWeight={800}>
                {stat.shares_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Laguna" fontWeight={800}>
                {stat.saves_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Blue" fontWeight={800}>
                {stat.actions_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="DarkBlue" fontWeight={800}>
                {stat.clicks_num}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success" fontWeight={800}>
                {dateToLocalDate(stat.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Div>
  );
};
export default StatsTable;
