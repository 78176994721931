import {ReactComponent as LikeIcon} from '@/Assets/Icons/heart.svg';
import {useCommunityStore} from '@/Store/community/community.selector';
import {FavoriteUserType} from '@/Store/community/types';
import SLike from './like.styled';
import {useBooleanState} from '@/Hooks/useBooleanState';
import SelectSocialToLike from './SelectSocialToLike/SelectSocialToLike';
import {memo} from 'react';

export interface TalentLikeType extends FavoriteUserType {}
interface LikeCellProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  talent?: TalentLikeType;
  talentInitials?: {
    userID: string;
    email: string;
  };
}
/**
 * @param talent use a prop talent, you need to specify an existing report
 * @param talentInitials to fetching exists reports with 'Add to favorites' continues ability
 * @param restProps
 * */
const Like: React.FC<LikeCellProps> = ({talent, talentInitials, ...restProps}) => {
  const talentEmail = talent ? talent.email : talentInitials ? talentInitials.email : '';
  const ShowSelectSocial = useBooleanState();
  const {
    actions: {addUserToFavorite, deleteUserFromFavorite},
    selectors: {favorites},
  } = useCommunityStore();

  const isLiked = favorites.some(el => el.email === talentEmail);

  const handleLikeClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (isLiked) {
      deleteUserFromFavorite({email: talentEmail});
    } else {
      talent && talentEmail ? addUserToFavorite({user: talent}) : ShowSelectSocial.turnOn();
    }
  };

  return (
    // @ts-ignore
    <SLike isLiked={isLiked} {...restProps}>
      <LikeIcon onClick={handleLikeClick} />
      {ShowSelectSocial.isTurn && talentInitials && (
        <SelectSocialToLike onClose={ShowSelectSocial.turnOff} talentInitials={talentInitials} />
      )}
    </SLike>
  );
};
export default memo(Like);
