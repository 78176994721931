import {Div, Label} from '@/UI';

export interface SelectedDataItemsProps {
  countOfSelectedItems?: number;
  onClearSelectedItems?: Function;
}
const SelectedDataItems: React.FC<SelectedDataItemsProps> = ({
  countOfSelectedItems,
  onClearSelectedItems,
}) => {
  return countOfSelectedItems && countOfSelectedItems > 0 ? (
    <Div justifyContent="center" gap={5}>
      <Label backgroundColor="Success">Selected: {countOfSelectedItems}</Label>
      {onClearSelectedItems && countOfSelectedItems !== 0 && (
        <Label style={{cursor: 'pointer'}} onClick={() => onClearSelectedItems()} backgroundColor="Alert">
          X
        </Label>
      )}
    </Div>
  ) : null;
};
export default SelectedDataItems;
