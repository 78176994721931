import {Modal, Tabs} from '@/UI';
import {useState, useMemo, Dispatch, SetStateAction} from 'react';
import {CampaignService} from './campaignEditor.service';
import Component from './campaign.styled';
import CampaignDetails from './CampaignDetails/CampaignDetails';
import CampaignOrders from './CampaignOrders/CampaignOrders';
import {CampaignDetailsType} from '@/API/Campaign/types';
import {Panel} from '@/UI/Tabs/Tabs';
import Brand from './Brand/Brand';
import PreOrderTalents from './PreOrderTalents/PreOrderTalents';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useReduceState} from '@/Hooks';
import {useStoredCampaign} from '@/Website/Components/Portals/Campaign/campaign.hooks';
import CampaignTitleBar from '@/Website/Components/Portals/Campaign/CampaignTitleBar/CampaignTitleBar';
import CampaignMaterials from '@/Website/Components/Portals/Campaign/CampaignMaterials/CampaignMaterials';
import CampaignAnalytic from '@/Website/Components/Portals/Campaign/CampaignAnalytic/CampaignAnalytic';

const Campaign = () => {
  const {
    campaignPortal: {campaignPortalID, isShowCampaignPortal},
  } = usePortalsStore().selectors;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {campaign, setCampaign, reloadCampaign} = useStoredCampaign();
  const {tabs} = useMemo(() => CampaignService, []);

  const handleInputsChange = useReduceState<CampaignDetailsType>(
    setCampaign as Dispatch<SetStateAction<CampaignDetailsType>>
  );

  return (
    <Modal
      isShowModal={isShowCampaignPortal}
      style={{backgroundColor: !campaign?.is_deleted ? '#ECF1F6' : '#FEE2E7'}}
    >
      <Component className="CampaignPortal">
        {campaign && (
          <>
            <CampaignTitleBar campaign={campaign} onChange={reloadCampaign} />

            <Tabs index={tabIndex} setIndex={setTabIndex} tabsHeaders={tabs}>
              <Panel>
                <CampaignDetails campaign={campaign} onChange={handleInputsChange} />
              </Panel>
              <Panel>
                <Brand campaign={campaign} onChange={handleInputsChange} />
              </Panel>
              <Panel>
                <CampaignMaterials campaign={campaign} onChange={handleInputsChange} />
              </Panel>
              <Panel>
                <CampaignOrders campaignID={campaignPortalID} isCampaignDeleted={campaign.is_deleted} />
              </Panel>
              <Panel>
                <PreOrderTalents campaignID={campaignPortalID as number} />
              </Panel>
              <Panel>
                <CampaignAnalytic />
              </Panel>
            </Tabs>
          </>
        )}
      </Component>
    </Modal>
  );
};

export default Campaign;
