import {OnboardingApi} from '@/API';
import {AnalyticsResponse} from '@/API/Orders/types';
import {ApprovementCounter} from '@/API/Statistics/types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AxiosInstance} from 'axios';
import {
  CreateTalentPayload,
  SearchUgcCreatorsParams,
  TalentDetailed,
  TalentFetchParamsType,
  TalentInsight,
  TalentLastActivity,
  TalentPortfolioType,
  TalentsResponseType,
  TalentUpdateDTO,
  UgcCreator,
} from './types';

class TalentsApi {
  readonly api: AxiosInstance;

  constructor(controller: string, private onboardingAPI: OnboardingApi) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async getUgcCreators(params: SearchUgcCreatorsParams): Promise<{creators: UgcCreator[]; count: number}> {
    const sortByValue = params.sortBy?.value;
    const socials = params.socials?.map(el => el.value);
    return await this.api
      .get('/ugc-creators', {
        params: {...params, sortBy: sortByValue, socials},
      })
      .then(res => res.data);
  }
  //
  async getTalents<T extends TalentFetchParamsType>(params: T): Promise<TalentsResponseType> {
    const sortByValue = params.sortBy?.value;
    const socials = params.socials?.map(el => el.value);
    const categories = params.categories?.map(el => el.value);
    const countries = params.countries?.map(el => el.value);
    const tags = params.tags?.map(el => el.value);
    const statuses = params.statuses?.map(el => el.value);

    return await this.api
      .get(`/`, {
        params: {...params, sortBy: sortByValue, statuses, socials, categories, tags, countries},
      })
      .then(res => res.data);
  }
  //
  async getTalentById(talentId: string): Promise<TalentDetailed> {
    return await this.api.get('/talent/' + talentId).then(res => res.data);
  }
  async getTalentLastActivity(talentId: string): Promise<TalentLastActivity[]> {
    return await this.api.get(`/talent-activity/${talentId}`).then(res => res.data);
  }
  //
  async updateTalent(payload: TalentUpdateDTO): Promise<any> {
    return await this.api.patch('/talent/' + payload.user_id, payload).then(res => res.data);
  }
  async rejectTalent(talentID: string, email: string, name: string): Promise<any> {
    return await this.api.patch('/reject/' + talentID).then(async () => {
      return await this.onboardingAPI.sentRejectEmail({email, name});
    });
  }
  //
  async approveTalent(talentID: string, email: string, name: string): Promise<any> {
    return await this.api.patch('/approve/' + talentID).then(async () => {
      return await this.onboardingAPI.sendApprovalEmail({
        recipient: email,
        name,
        password: 'password not changed',
      });
    });
  }
  async getApprovementCounter(): Promise<ApprovementCounter> {
    return await this.api.get(`/candidates/count`).then(res => res.data);
  }
  //
  async getTalentInsight(params: {userID: string; social?: string}): Promise<TalentInsight[]> {
    return await this.api
      .get(`/talent-insights`, {
        params,
      })
      .then(res => res.data);
  }
  //
  async createTalent(payload: CreateTalentPayload): Promise<TalentDetailed> {
    const {username, email, password, ...restProps} = payload;

    return await this.api
      .post('/create', {
        username,
        email,
        password,
        onboardingDTO: restProps,
      })
      .then(res => res.data);
  }
  //
  async getTalentAnalytics(talentID: string): Promise<AnalyticsResponse> {
    return await this.api.get(`/get-talent-analytics/${talentID}`).then(res => res.data);
  }
  async getTalentPortfolio(talentId: string): Promise<TalentPortfolioType> {
    return await this.api.get(`/portfolio/${talentId}`).then(res => res.data);
  }
}
export default TalentsApi;
