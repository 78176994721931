import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback} from 'react';

type DeleteTagFromUserParams = {userID: string; tagID?: any; callBack?: Function};

const useDeleteTagFromUser = () => {
  const deleteTag = useApiLoader(async (args: DeleteTagFromUserParams) => {
    if (args.tagID) {
      await API.Tags.deleteTagFromUsers({
        usersID: [args.userID],
        tagID: args.tagID,
      }).then(() => args.callBack && args.callBack());
    }
  }, []);
  return useCallback(async (params: DeleteTagFromUserParams) => await deleteTag(params), [deleteTag]);
};
export default useDeleteTagFromUser;
