import styled from 'styled-components';

const SAvatarCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
  align-items: center;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-3px);
  }
  .talent-name {
    width: 88px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;
export default SAvatarCard;
