import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback} from 'react';

const useDeleteWalletTransaction = (onSuccess: Function) => {
  const deleteTransaction = useApiLoader(
    async (transactionID: number) => {
      await API.Wallet.deleteTransaction(transactionID).then(() => {
        onSuccess();
      });
    },
    [],
    {successMsg: 'Transaction deleted'}
  );
  return useCallback(async (transactionID: number) => await deleteTransaction(transactionID), [
    deleteTransaction,
  ]);
};
export default useDeleteWalletTransaction;
