import {useDispatch} from 'react-redux';
import {AppDispatchType} from '@/Store/types';
import {quickResponseActions} from '@/Store/quickResponse/quickResponse.slice';
import {useAppSelector} from '@/Hooks';
import {bindActionCreators} from 'redux';

const useQuickResponseSelector = () => {
  return useAppSelector(state => state.quickResponse);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(quickResponseActions, dispatch);
};

export const useQuickResponseStore = () => {
  return {
    actions: useActions(),
    selectors: useQuickResponseSelector(),
  };
};
