import {useState} from 'react';
import {Button, Div, ElementHeadline, Input, Modal, TextArea} from '@/UI';
import {CreateRevisionDTO} from '@/API/Orders/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useReduceState} from '@/Hooks';
import {useCreateOrderRevision} from '../../Hooks';

interface NewRevisionProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
}
const NewRevision: React.FC<NewRevisionProps> = props => {
  const {onClose, onChange, isShow} = props;
  const {id} = usePortalsStore().selectors.orders.orderInPortal;
  const [revision, setRevision] = useState<CreateRevisionDTO>({
    reason: '',
    order_id: id,
    changes: '',
  });
  const handleInputs = useReduceState(setRevision);
  const createRevision = useCreateOrderRevision(() => {
    onChange();
    onClose();
  });

  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline title="Create revision" onClose={onClose} />
      <Div width={400} flexDirection="column" gap={20}>
        <Input
          placeholder="Reason"
          value={revision.reason}
          onChange={e => handleInputs('reason', e.target.value)}
        />
        <TextArea
          placeholder="Changes"
          value={revision.changes}
          row={15}
          onChange={e => handleInputs('changes', e.target.value)}
        />
        <Button
          isDisabled={!revision.changes || !revision.reason}
          color="BlackOcean"
          onClick={() => createRevision(revision)}
        >
          CREATE
        </Button>
      </Div>
    </Modal>
  );
};
export default NewRevision;
