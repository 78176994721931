import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {ITalentReportDTO} from '@/API/TalentInfo/types';

const useFindReportByUserIDAndSocial = (userID?: string, social?: string) => {
  const [socialReport, setSocialReport] = useState<ITalentReportDTO>();
  const fetch = useApiLoader(async () => {
    if (userID && social) {
      await API.TalentInfo.findByUserIDAndSocial(userID, social).then(res => setSocialReport(res));
    }
  }, [userID, social]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    report: socialReport,
    reloadReport: fetch,
  };
};
export default useFindReportByUserIDAndSocial;
