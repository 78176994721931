import {SendOnboardingBase} from '@/API/Sender/sender.types';

export const composeAutomatedSendingFormData = (data: SendOnboardingBase) => {
  const formData = new FormData();

  formData.append('subject', data.subject);
  formData.append('from', data.from);
  formData.append('sendType', data.sendType);
  formData.append('additionalSending', JSON.stringify(data.additionalSending));
  formData.append('service_provider', data.service_provider);
  if (data.emailDescription) formData.append('emailDescription', data.emailDescription);
  if (data.internalProvider) formData.append('internalProvider', data.internalProvider);
  if (data.file) formData.append('file', data.file);
  if (data.recipientList) formData.append('recipientList', JSON.stringify(data.recipientList));
  if (data.registerUsers) formData.append('registerUsers', data.registerUsers);
  if (data.html) formData.append('html', data.html);
  if (data.buttonText) formData.append('buttonText', data.buttonText);
  if (data.buttonLink) formData.append('buttonLink', data.buttonLink);

  return formData;
};
