import logoMiniIcon from '@/Assets/Icons/logo_mini.svg';
import {useSetOnboardingCounters} from '@/Website/Components/Talent/Hooks';
import User from '@/Website/Layout/SideBar/User/User';
import {memo, useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Hamburger from './Hamburger/Hamburger';
import Section from './Section/Section';
import {sections} from './constants';
import {Component} from './sideBar.styled';

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState<boolean>(true);
  const menu: typeof sections = useMemo(() => sections, []);
  const homeClickHandler = useCallback(() => navigate('/'), [navigate]);

  useSetOnboardingCounters();
  return (
    <Component className="SideBar" isOpened={isNavOpen}>
      <div className="header">
        <User isNavOpen={isNavOpen} />
        <img src={logoMiniIcon} alt="" className="home-icon" onClick={homeClickHandler} />
        <Hamburger setOpenedNavbar={setIsNavOpen} />
      </div>
      {menu.map(({icon, links, section, isOpen, hasBudgedUnread}, i) => (
        <Section
          icon={icon}
          key={i}
          label={section}
          links={links}
          isNavOpen={isNavOpen}
          isSectionOpen={isOpen}
          hasBudgedUnread={hasBudgedUnread}
        />
      ))}
    </Component>
  );
};
export default memo(SideBar);
