import {LogsSearchParams} from '@/API/Sender/sender.types';
import {SelectOptionsType} from '@/UI/Select/select.styled';

const SendingLogsService = {
  defaultFilterState: {
    email: undefined,
    limit: 50,
    sortBy: ['created_at', 'DESC'],
    skip: 0,
    status: undefined,
  } as LogsSearchParams,
  sendingStatuses: [
    {label: '-', value: ''},
    {
      label: 'successfully',
      value: 'successfully',
    },
    {
      label: 'failed',
      value: 'failed',
    },
  ] as SelectOptionsType,
  tableHeaders: ['Status', 'Subject', 'Email', 'Recipient', 'Service', 'Created at'],
  extractSubject: (request?: string) => {
    if (!request) return '-';
    let parsed = undefined;
    try {
      parsed = JSON.parse(request);
    } catch (error) {}
    return parsed?.subject || '';
  },
};
export default SendingLogsService;
