import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useAddCategoryToUser = () => {
  return useApiLoader(
    async (payload: {userID: string; categoryID: number; callBack?: Function}) => {
      await API.Categories.addCategoryToUser(payload.userID, payload.categoryID).then(() => {
        payload.callBack && payload.callBack();
      });
    },
    [],
    {successMsg: 'Category added'}
  );
};
export default useAddCategoryToUser;
