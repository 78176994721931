import {useCommunityStore} from '@/Store/community/community.selector';
import {useCallback} from 'react';

const useOpenTalent = () => {
  const {setIsShowStoredTalent, setStoredTalentOnChange, setStoredTalent} = useCommunityStore().actions;
  return useCallback(
    (userID: string, callBack?: Function) => {
      if (userID) {
        setStoredTalent({userID});
        setIsShowStoredTalent({isShow: true});
      }
      if (callBack) {
        setStoredTalentOnChange({onChange: callBack});
      }
    },
    [setIsShowStoredTalent, setStoredTalent, setStoredTalentOnChange]
  );
};
export default useOpenTalent;
