import {ReactComponent as CoinIcon} from '@/Assets/Icons/coin.svg';
import {ReactComponent as FavoriteIcon} from '@/Assets/Icons/favorite.svg';
import {ReactComponent as SecurityIcon} from '@/Assets/Icons/sidebar/security.svg';
import {useClickOut} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {ROUTES} from '@/Router/ROUTES';
import {useCommunityStore} from '@/Store/community/community.selector';
import ProjectVersion from '@/Website/Layout/TopBar/ProjectVersion/ProjectVersion';
import SubscriptionCurtain from '@/Website/Layout/TopBar/SubscriptionPlan/SubscriptionCurtain';
import {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Favorites from './Favorites/Favorites';
import STopBar from './topBar.styled';

const TopBar = () => {
  const {favorites} = useCommunityStore().selectors;

  const navigate = useNavigate();
  const ShowSubscriptions = useBooleanState();
  const toggleClass = useCallback(() => {
    document.querySelector('.bar')?.classList.toggle('collapsed-bar');
  }, []);
  const closeBar = useCallback(() => {
    document.querySelector('.bar')?.classList.add('collapsed-bar');
  }, []);
  const {ref} = useClickOut(closeBar);
  const handleSecurityClick = useCallback(() => navigate(ROUTES.security), [navigate]);
  return (
    <STopBar className="TopBar" ref={ref}>
      <ProjectVersion />
      <div className="bar collapsed-bar">
        <Favorites />
        <div className="buttons-panel">
          <div>
            <FavoriteIcon className="top-bar-heart-icon" onClick={toggleClass} />
            {favorites.length > 0 && <div className="likes-badge">{favorites.length}</div>}
          </div>
          <SecurityIcon className="top-bar-security-icon" onClick={handleSecurityClick} />
          <CoinIcon className="top-bar-coin-icon" onClick={ShowSubscriptions.turnOn} />
        </div>
      </div>
      {ShowSubscriptions.isTurn && <SubscriptionCurtain onClose={ShowSubscriptions.turnOff} />}
    </STopBar>
  );
};

export default memo(TopBar);
