import {OrderStatDetailedType} from '@/API/Orders/types';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {ElementHeadline} from '@/UI';
import {useCallback, useState} from 'react';
import {useGetOrderStats} from '../Hooks';
import {EditStat, NewStat, StatsTable} from './index';
import SOrderStats from './orderStats.styled';

type OrderStatsProps = {
  orderID: number;
};
const OrderStats: React.FC<OrderStatsProps> = ({orderID}) => {
  const {stats, reloadStats} = useGetOrderStats(orderID);
  const ShowStatEditor = useBooleanState();
  const ShowNewStat = useBooleanState();
  const [selectedStat, setSelectedStat] = useState<OrderStatDetailedType>();

  const handleOpenEditor = useCallback(
    (stat: OrderStatDetailedType) => {
      setSelectedStat(stat);
      ShowStatEditor.turnOn();
    },
    [ShowStatEditor]
  );

  return (
    <SOrderStats className="OrderStats">
      <ElementHeadline
        title={`Total stats: ${stats.length}`}
        disableMarker
        onCreateNew={ShowNewStat.turnOn}
      />
      <StatsTable stats={stats} onOpenEditor={handleOpenEditor} />

      {ShowNewStat.isTurn && (
        <NewStat
          isShow={ShowNewStat.isTurn}
          onClose={ShowNewStat.turnOff}
          onChange={reloadStats}
          orderID={orderID}
        />
      )}
      {ShowStatEditor.isTurn && (
        <EditStat
          setStat={setSelectedStat}
          isShow={ShowStatEditor.isTurn}
          onChange={reloadStats}
          onClose={ShowStatEditor.turnOff}
          stat={selectedStat as OrderStatDetailedType}
        />
      )}
    </SOrderStats>
  );
};

export default OrderStats;
