import {Select} from '@/UI';
import {useMemo} from 'react';
import {SelectProps} from '@/UI/Select/Select';
import {OrdersService} from '@/Website/Pages/Orders/orders.service';
import {CampaignService} from '@/Website/Components/Portals/Campaign/campaignEditor.service';
import {SocialService} from '@/Services/social.service';
import {WhatsAppTemplatesSelectOptions} from '@/API/Sender/sender.types';

type Props = Omit<SelectProps, 'options'> & {
  optionsType: 'orderStatuses' | 'socials' | 'campaignStatuses' | 'whatsAppTemplates';
};

const GenericSelect: React.FC<Props> = ({optionsType, ...props}) => {
  const options = useMemo(() => {
    switch (optionsType) {
      case 'orderStatuses':
        return OrdersService.ORDER_STATUSES;
      case 'campaignStatuses':
        return CampaignService.statuses;
      case 'socials':
        return SocialService.SOCIALS_SELECT_OPTIONS;
      case 'whatsAppTemplates':
        return WhatsAppTemplatesSelectOptions;
    }
  }, []);
  return options ? <Select options={[{value: '', label: '-'}, ...options]} {...props} /> : null;
};
export default GenericSelect;
