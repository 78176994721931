import {useEffect, useState} from 'react';
import {CategoryDetailsType} from '@/API/Categories/types';
import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';

const useCategoryDetails = (categoryID: number | undefined) => {
  const [category, setCategory] = useState<CategoryDetailsType>();
  const fetch = useApiLoader(async () => {
    categoryID && (await API.Categories.getCategory(categoryID).then(res => setCategory(res)));
  }, [categoryID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    category,
  };
};
export default useCategoryDetails;
