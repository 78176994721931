import {Box} from '@/UI';
import styled from 'styled-components';

export const Component = styled(Box)`
  .input-group {
    margin: 8px 0 0 0;
  }

  .report-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
    fill: #7b7b7b;
    transition: fill 0.3s ease;

    &:hover {
      fill: chocolate;
    }
  }

  .non-active-report {
    pointer-events: none;
    fill: #e2e4e7;
  }
`;
