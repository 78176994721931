import {Button, Div, ElementHeadline, Modal} from '@/UI';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useCallback, useState} from 'react';
import {useUpdateCampaignOrderStatuses} from '@/Website/Pages/Orders/Hooks';
import {GenericSelect} from '@/Website/Components/Common';

interface Props {
  onClose: Function;
  onChange: Function;
}
const OrdersStatusesUpdater: React.FC<Props> = ({onChange, onClose}) => {
  const {campaignPortalID} = usePortalsStore().selectors.campaignPortal;
  const [currentStatus, setCurrentStatus] = useState<string>();
  const [nextStatus, setNextStatus] = useState<string>();
  const updateStatuses = useUpdateCampaignOrderStatuses();

  const handleUpdate = useCallback(async () => {
    if (currentStatus && nextStatus && campaignPortalID) {
      await updateStatuses({
        currentStatus,
        nextStatus,
        campaignID: campaignPortalID,
      }).then(() => {
        onClose();
        onChange();
      });
    }
  }, [campaignPortalID, currentStatus, nextStatus]);
  return (
    <Modal isShowModal={true}>
      <Div gap={20} flexDirection="column" width={400}>
        <ElementHeadline onClose={onClose} title="Campaign orders status updater" />
        <GenericSelect
          optionsType="orderStatuses"
          value={currentStatus}
          onChange={(value: any) => setCurrentStatus(value)}
          label="Current status"
        />
        <GenericSelect
          optionsType="orderStatuses"
          value={nextStatus}
          onChange={(value: any) => setNextStatus(value)}
          label="Next status"
        />
        <Button onClick={() => handleUpdate()} isDisabled={!currentStatus || !nextStatus} color="Orange">
          UPDATE
        </Button>
      </Div>
    </Modal>
  );
};
export default OrdersStatusesUpdater;
