import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useUploadOrderMaterials = (
  materialPayload: {orderID: number; materials?: File[]},
  onSuccess: () => Promise<any>
) => {
  return useApiLoader(
    async () => {
      if (materialPayload.materials?.length) {
        await API.Orders.uploadVideoOrder(materialPayload.orderID, materialPayload.materials).finally(
          async () => await onSuccess()
        );
      }
    },
    [onSuccess, materialPayload],
    {successMsg: 'Uploaded successfully'}
  );
};
export default useUploadOrderMaterials;
