import {useState} from 'react';
import {Button, Div, ElementHeadline, Input, Modal, TextArea} from '@/UI';
import {CreateDisputeDTO} from '@/API/Orders/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useReduceState} from '@/Hooks';
import {useCreateOrderDispute} from '../../Hooks';

interface NewDisputeProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
}
const NewDispute: React.FC<NewDisputeProps> = props => {
  const {onClose, onChange, isShow} = props;
  const {id} = usePortalsStore().selectors.orders.orderInPortal;
  const [dispute, setDispute] = useState<CreateDisputeDTO>({
    reason: '',
    order_id: id,
    dissatisfaction_details: '',
  });
  const handleInputs = useReduceState(setDispute);
  const createDispute = useCreateOrderDispute(() => {
    onChange();
    onClose();
  });

  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline title="Create dispute" onClose={onClose} />
      <Div width={400} flexDirection="column" gap={20}>
        <Input
          placeholder="Reason"
          value={dispute.reason}
          onChange={e => handleInputs('reason', e.target.value)}
        />
        <TextArea
          placeholder="Dissatisfaction details"
          value={dispute.dissatisfaction_details}
          row={15}
          onChange={e => handleInputs('dissatisfaction_details', e.target.value)}
        />
        <Button
          isDisabled={!dispute.dissatisfaction_details || !dispute.reason}
          color="BlackOcean"
          onClick={() => createDispute(dispute)}
        >
          CREATE
        </Button>
      </Div>
    </Modal>
  );
};
export default NewDispute;
