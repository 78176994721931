import {memo} from 'react';
import styled from 'styled-components';

const STalentPortfolio = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 50px;
  overflow: auto;
  .portfolio {
    width: 800px;
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;
export default memo(STalentPortfolio);
