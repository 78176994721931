import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader, useOpenTalent} from '@/Hooks';
import {API} from '@/API/API';
import {TalentPassage} from '@/Website/Components/Talent/types';
import {SocialsType} from '@/Services/social.service';

const useApproveTalent = (talent: TalentPassage, callBack?: Function) => {
  const {
    storedUser: {onChangeUserCallback},
  } = useCommunityStore().selectors;

  const openTalent = useOpenTalent();
  return useApiLoader(
    async () => {
      return await API.TalentInfo.checkTalentInfoSocials(talent.user_id).then(async talentSocials => {
        const ExistsSocialsReports = talentSocials ? talentSocials.map(el => el.social_platform) : [];
        const SocialsEntries = Object.entries(talent.socials);
        /** @description filter if key not exists in CreatedReports and if an url is not null then add*/
        const NotCreatedReports = SocialsEntries.filter(([social, url]) => {
          if (!ExistsSocialsReports.includes(social as SocialsType) && url) return [social, url];
        });

        await API.Talents.approveTalent(
          talent.user_id,
          talent.email_contact || '',
          talent.full_name || ''
        ).then(() => {
          if (callBack) callBack();
          openTalent(talent.user_id);
          onChangeUserCallback();
        });
        if (NotCreatedReports?.length > 0) {
          //** Create report for each not exists social yet */
          for (let reports of NotCreatedReports) {
            await API.TalentInfo.generateTalentReport({
              ...talent,
              url: reports[1] as string,
              social: reports[0] as SocialsType,
            });
          }
        }
      });
    },
    [talent, onChangeUserCallback],
    {successMsg: 'Approved'}
  );
};

export default useApproveTalent;
