import axios, {AxiosInstance} from 'axios';
import {IQDataSubscriptionPlan, SubscriptionPlanResponse} from '@/API/Subscriptions/types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';

class SubscriptionsApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.ADMIN_MS, controller).getInstance();
  }
  async getSubscriptionPlanSIB(): Promise<SubscriptionPlanResponse> {
    return await axios
      .get('https://api.sendinblue.com/v3/account', {
        headers: {
          accept: 'application/json',
          'api-key': process.env.REACT_APP_API_KEY_SIB as string,
        },
      })
      .then(res => res.data)
      .catch(err => {
        console.log(err);
        throw Error('');
      });
  }
  async getSubscriptionIqData(): Promise<IQDataSubscriptionPlan> {
    return await this.api
      .get(`reports/account/info`, {
        baseURL: URL.DISCOVERY_MS,
      })
      .then(res => res.data)
      .catch(err => {
        console.log(err);
        throw Error('IQ DATA ');
      });
  }
}
export default SubscriptionsApi;
