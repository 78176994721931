import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';
import {useTags} from '@/Hooks/Tags';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Box, Button, ElementHeadline, Modal, SelectAsync} from '@/UI';
import NewTag from '@/Website/Components/TagPortal/NewTag/NewTag';
import {useCallback, useState} from 'react';
import styled from 'styled-components';
import {extractId} from './tagPortal.service';

const STagPortal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
  .tag-patcher {
    min-width: 300px;
  }
`;

const TagPortal: React.FC = () => {
  const [selectedTags, setSelectedTags] = useState<Array<{value: any}>>([]);
  const {getTagsSelectOptions} = useTags();

  const {
    actions: {setIsShowTagPortal, setEmptyTagPortal},
    selectors: {
      tagPortal: {toTagPortal, tagPortalCallBack, isShowTagPortal},
    },
  } = usePortalsStore();

  /** Adding a tag click handler */
  const addHandler = useApiLoader(
    async () => {
      await API.Tags.addTagsToUsers({
        usersID: extractId(toTagPortal),
        tagsID: selectedTags.map(el => el.value),
      }).then(() => {
        setIsShowTagPortal(false);
        setEmptyTagPortal();
        tagPortalCallBack && tagPortalCallBack();
      });
    },
    [selectedTags, tagPortalCallBack, toTagPortal],
    {successMsg: 'Added'}
  );

  const onClosePortal = useCallback(() => {
    setIsShowTagPortal(false);
    setEmptyTagPortal();
  }, [setEmptyTagPortal, setIsShowTagPortal]);

  return (
    <Modal isShowModal={isShowTagPortal}>
      <ElementHeadline title={`Tag management`} onClose={onClosePortal} />
      <STagPortal className="TagPortal">
        {toTagPortal.length > 0 && (
          <Box className="tag-patcher" label={`${toTagPortal.length} user/s are ready to apply the tag`}>
            <SelectAsync
              isSearchable={true}
              loadOptions={getTagsSelectOptions}
              label="Tag"
              focus={true}
              value={selectedTags}
              onChange={value => setSelectedTags(value)}
              isMulti
            />

            <Button
              color="Success"
              style={{marginTop: 20}}
              onClick={addHandler}
              isDisabled={selectedTags.length === 0}
            >
              ADD
            </Button>
          </Box>
        )}
        <Box label="Create new tag" className="tag-creator">
          <NewTag />
        </Box>
      </STagPortal>
    </Modal>
  );
};
export default TagPortal;
