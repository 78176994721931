import styled, {css} from 'styled-components';
import {TableRow} from '@material-ui/core';
import {memo} from 'react';

const SPreOrderRow = styled(TableRow)`
  ${({theme}) => css`
    .price-input {
      border: none;
      width: 50px;
      font-family: ${theme.FontFamily.Outfit};
      font-weight: 800;
      font-size: 0.8rem;
      color: ${theme.Color.Orange};
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
  `}
`;
export default memo(SPreOrderRow);
