import SOrderDisputeRevision from './orderDisputeRevision.styled';
import {Grid} from '@/UI';
import RevisionsTable from './RevisionsTable/RevisionsTable';
import DisputesTable from './DisputesTable/DisputesTable';
interface OrderDisputeRevisionProps {
  orderID: number;
}
const OrderDisputeRevision: React.FC<OrderDisputeRevisionProps> = ({orderID}) => {
  return (
    <SOrderDisputeRevision className="OrderDisputeRevision order-tab-preset">
      <Grid templateColumn="1fr 1fr">
        <RevisionsTable orderID={orderID} />
        <DisputesTable orderID={orderID} />
      </Grid>
    </SOrderDisputeRevision>
  );
};
export default OrderDisputeRevision;
