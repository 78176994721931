import {useCommunityStore} from '@/Store/community/community.selector';
import {useEffect, useMemo, useRef, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import Component from './section.styled';

interface Props {
  label?: string;
  icon?: string;
  links?: Array<{title: string; href: string; icon?: any}>;
  isNavOpen: boolean;
  isSectionOpen: boolean;
  hasBudgedUnread?: boolean;
}

const Section: React.FC<Props> = props => {
  const {icon, label, links, isNavOpen, isSectionOpen, hasBudgedUnread} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const [isHasUnread, setIsHasUnread] = useState<boolean>(false);
  const {badges} = useCommunityStore().selectors;
  const [isActiveSection, setIsActiveSection] = useState<boolean>(false);
  const SectionRef = useRef(null);

  const handleOpenSection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target instanceof HTMLAnchorElement) return;
    isNavOpen && setIsOpen(!isOpen);
  };
  const calcIsHasUnread = useMemo(() => badges.approvals + badges.onboarding > 0, [badges]);

  //set init state on mount
  useEffect(() => setIsOpen(isSectionOpen), []);

  useEffect(() => {
    if (hasBudgedUnread) setIsHasUnread(calcIsHasUnread);
  }, [badges]);

  useEffect(() => {
    //@ts-ignore
    if (SectionRef.current.querySelector('.active')) setIsActiveSection(true);
    else setIsActiveSection(false);
  }, [location.pathname]);

  return (
    <Component
      className="SectionComponent"
      isNavOpen={isNavOpen}
      isSectionOpened={isOpen}
      isActiveSection={isActiveSection}
    >
      {hasBudgedUnread && isHasUnread && <div className="red-dot"></div>}

      <div className="label" onClick={handleOpenSection}>
        <div className="label-head">
          <img src={icon} alt="" className="label-icon" />

          {isNavOpen && (
            <>
              <span>{label}</span>
              <div className="arrow">
                <div></div>
                <div></div>
              </div>
            </>
          )}
        </div>
        <div>
          <div className={isNavOpen ? 'links-nav-opened' : 'links-nav-closed'} ref={SectionRef}>
            {links?.map(({href, title, icon}, i) => (
              <div key={i}>
                <NavLink to={href}>
                  <div className="dash"></div>
                  {title}
                </NavLink>
                {/* {icon && <img src={icon} alt='' className='link-icon' onClick={handleClick}/>} */}

                {title === 'Onboarding' && badges.onboarding > 0 && (
                  <div className={`badged`}>{badges.onboarding}</div>
                )}
                {title === 'Approvals' && badges.approvals > 0 && (
                  <div className={`badged`}>{badges.approvals}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Component>
  );
};

export default Section;
