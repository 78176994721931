import styled, {css} from 'styled-components';
import {memo} from 'react';

const STalentTags = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    height: 50%;
    .tags-list {
      height: 100%;
      position: relative;
      overflow: auto;
      display: flex;
      flex-direction: column;
      ${theme.Presets.scroll}
      .tags-item {
        display: flex;
        align-items: center;
        padding: 3px;
        border-bottom: 1px solid ${theme.Color.GrayLight};
        &:hover {
          background-color: #efefef;
          img {
            visibility: visible;
            cursor: pointer;
          }
        }
        img {
          height: 13px;
          width: 13px;
          visibility: hidden;
          margin-right: 3px;
        }
      }
    }
  `}
`;
export default memo(STalentTags);
