import styled, {css} from 'styled-components';

const SElementHeadline = styled.div<{disableMarker: boolean}>`
  ${({disableMarker, theme}) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 5px;

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 1px;
      background-color: #dee2e5;
      bottom: 0;
    }

    .icons {
      display: flex;
      align-items: center;
      opacity: 0.6;
      cursor: pointer;
      .tooltip {
        width: 5px;
        height: 23px;
        position: absolute;

        background-color: #3dbeed;
        border-radius: 15px;
      }
      .headline-icon {
        height: 18px;
        padding: 0 10px;

        &:active {
          transform: scale(0.9);
        }

        &:hover {
          background-color: ${theme.Color.GrayLight};
          border-radius: 15px;
        }
      }
    }

    &::before {
      content: '';
      width: ${disableMarker === true ? '0px' : '5px'};
      position: absolute;
      height: 23px;
      left: -16px;
      bottom: 3px;
      background-color: #3dbeed;
      border-radius: 15px;
    }

    .context-menu {
      position: absolute;
      right: 0;
      display: flex;
      margin-top: 40px;
      margin-right: -2px;
      flex-direction: column;

      background-color: white;
      z-index: 9999;
      gap: 5px;
      cursor: pointer;
      border-radius: 15px;
      box-shadow: 0 10px 18px 4px rgba(34, 60, 80, 0.2);
      opacity: 1;
      animation: onFade 0.3s ease;
      @keyframes onFade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .context-menu-option {
        padding: 5px 20px;

        &:hover {
          border-radius: 15px;
          background-color: #ecf1f6;
        }
      }
      .disable-context-item {
        pointer-events: none;
        text-decoration: line-through;
      }
    }
  `}
`;

export default SElementHeadline;
