import {useApiLoader} from '@/Hooks';
import {DeleteDisputeRevisionParams} from '@/API/Orders/types';
import {API} from '@/API/API';

const useDeleteOrderDispute = (callBack?: Function) =>
  useApiLoader(
    async (params: DeleteDisputeRevisionParams) => {
      return await API.Orders.deleteOrderDispute(params).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Deleted'}
  );
export default useDeleteOrderDispute;
