import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback, useState} from 'react';
import {useCommunityStore} from '@/Store/community/community.selector';

const useGenerateTags = (text: string, callBack: Function) => {
  const [generatedTags, setGeneratedTags] = useState<Array<{tag: string; isIncludeToCreate: boolean}>>([]);
  const {userID} = useCommunityStore().selectors.storedUser;
  //
  const handleToggleTags = useCallback(
    (position: number) => {
      const tmp = [...generatedTags];
      tmp[position].isIncludeToCreate = !tmp[position].isIncludeToCreate;
      setGeneratedTags(tmp);
    },
    [setGeneratedTags, generatedTags]
  );
  //
  const handleChangeTagText = useCallback(
    (position: number, value: any) => {
      const tmp = [...generatedTags];
      tmp[position].tag = value;
      setGeneratedTags(tmp);
    },
    [setGeneratedTags, generatedTags]
  );
  //
  const generateTags = useApiLoader(async () => {
    await API.OpenAI.generateTags(text).then(res => {
      if (res.length > 0) {
        const tags = res.map(el => ({tag: el, isIncludeToCreate: true}));
        setGeneratedTags(tags);
      }
    });
  }, [text]);

  const addGeneratedTags = useApiLoader(
    async () => {
      const tags = generatedTags
        .filter(el => {
          if (el.isIncludeToCreate && el.tag) return el;
        })
        .map(el => el.tag);
      await API.Tags.addTagsBatchToTalent(userID, tags).then(() => callBack());
    },
    [generatedTags],
    {successMsg: 'Tags added'}
  );

  return {
    generatedTags,
    generate: generateTags,
    handleToggleTags,
    addGeneratedTags,
    handleChangeTagText,
  };
};
export default useGenerateTags;
