import {Box, Line, Loader} from '@/UI';
import {useParams} from 'react-router-dom';
import useTalentPortfolio from './Hooks/useTalentPortfolio';
import TalentPortfolioAbout from './TalentPortfolioAbout/TalentPortfolioAbout';
import TalentPortfolioVideos from './TalentPortfolioVideos/TalentPortfolioVideos';
import STalentPortfolio from './talentPortfolio.styled';

const TalentPortfolio = () => {
  const {talentId} = useParams();
  const {portfolio} = useTalentPortfolio(talentId);

  return (
    <STalentPortfolio>
      <Loader />
      {talentId && portfolio && (
        <Box className="portfolio">
          <TalentPortfolioAbout bio={portfolio.bio} avatar={portfolio.avatar} fullname={portfolio.fullname} />
          <Line />
          <TalentPortfolioVideos videos={portfolio.materials} />
        </Box>
      )}
    </STalentPortfolio>
  );
};
export default TalentPortfolio;
