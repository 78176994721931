import {useReduceState} from '@/Hooks';
import {useOpenAIDialogStore} from '@/Store/openaiDialog/openaiDialog.selector';
import {Button, Curtain, Div, Grid, InputRange, Line, Text, TextArea} from '@/UI';
import {useCallback} from 'react';
import useCompletePromptState from './Hooks/useCompletePromtState';
import useOpenAIFreeDialog from './Hooks/useOpenAIFreeDialog';
import {OpenAIDialogService} from './openAIDialog.service';

const OpenAIDialog = () => {
  const {closeOpenAIDialog} = useOpenAIDialogStore().actions;
  const {dialogSettings} = useOpenAIDialogStore().selectors;
  const {completePrompt, setCompletePrompt} = useCompletePromptState();

  const handleChangeInputs = useReduceState(setCompletePrompt);
  const {generate, generatedResult} = useOpenAIFreeDialog();

  const onAccept = useCallback(() => {
    generatedResult && dialogSettings.acceptResult(generatedResult);
    closeOpenAIDialog();
  }, [generatedResult]);
  return (
    <Curtain
      curtainConfig={{
        height: '100vh',
        width: 'auto',
        right: '0px',
        top: '0px',
        disableAnimation: true,
        disableClickOut: true,
      }}
      headLineProps={{
        onClose: closeOpenAIDialog,
        title: 'Open AI dialog',
      }}
    >
      <Grid templateColumn="repeat(2, 450px)" columnGap={10}>
        <Div flexDirection="column" gap={10}>
          <Text type="Text2" fontWeight={800} color="BlackOcean">
            {dialogSettings.titleBarText}
          </Text>
          <Line />
          <InputRange
            title="Max tokens"
            options={OpenAIDialogService.maxTokensRange}
            value={completePrompt.maxTokens}
            onChangeRange={(e: any) => handleChangeInputs('maxTokens', e)}
          />
          <InputRange
            title="Temperature"
            options={OpenAIDialogService.temperatureRange}
            value={completePrompt.temperature}
            onChangeRange={(e: any) => handleChangeInputs('temperature', e)}
          />

          <TextArea
            value={completePrompt?.prompt}
            onChange={e => handleChangeInputs('prompt', e.target.value)}
            placeholder="Prompt"
            row={26}
          />

          <Button color="Success" size="small" onClick={async () => generate(completePrompt)}>
            GENERATE
          </Button>
        </Div>

        <Div flexDirection="column" gap={10}>
          <Text type="Text2" fontWeight={800} color="BlackOcean">
            Generated result:
          </Text>
          <Line />
          {generatedResult && (
            <>
              <Text lineHeight={24} type="Text3">
                ${generatedResult}
              </Text>
              <Button onClick={onAccept} color="Orange" size="small">
                ACCEPT
              </Button>
            </>
          )}
        </Div>
      </Grid>
    </Curtain>
  );
};

export default OpenAIDialog;
