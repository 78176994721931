import {TaggedUsers, TagResponseType, TagSearchParams, TagType, UserTagType} from './types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class TagsApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getAllTags(params?: TagSearchParams): Promise<TagResponseType> {
    return await this.api
      .get(`/get-tags`, {
        params: {
          tagName: params?.tagName || '',
          sortBy: params?.sortBy?.value,
        },
      })
      .then(res => res.data);
  }
  async createTag(tagPayload: Omit<TagType, 'id'>) {
    return await this.api.post(`/add-tag`, tagPayload);
  }
  async deleteTag(tagID: number) {
    return await this.api.delete(`/delete-tag`, {
      params: {tagID},
    });
  }
  async updateTag(tag: TagType) {
    return await this.api.patch(`/update-tag`, tag);
  }
  async addTagsToUsers(payload: {usersID: Array<string>; tagsID: Array<number>}) {
    return await this.api.post(`/add-users`, payload);
  }
  async deleteTagFromUsers(payload: {usersID: Array<string>; tagID: number}) {
    return await this.api.post(`/delete-users`, payload);
  }
  async getUsersByTagID(
    tagID: number | string,
    search?: string
  ): Promise<{users: Array<TaggedUsers>; count: number}> {
    if (tagID) {
      return await this.api
        .get(`/get-users/${tagID}`, {
          params: {
            search: search || '',
          },
        })
        .then(res => res.data);
    } else {
      return {users: [], count: 0};
    }
  }
  //
  async clearTag(tagID: number | string): Promise<any> {
    return await this.api.get(`/clear-users/${tagID}`);
  }
  //
  async getTagsByUserID(userID: string): Promise<UserTagType[]> {
    return await this.api.get(`/tags-by-user/${userID}`).then(res => res.data);
  }
  //
  async addTagsBatchToTalent(userId: string, tags: Array<string>) {
    return await this.api.post('/add-batch-tags', {tags, userId}).then(res => res.data);
  }
}
export default TagsApi;
