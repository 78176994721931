import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CreateRevisionDTO} from '@/API/Orders/types';

const useCreateOrderRevision = (callBack?: Function) =>
  useApiLoader(
    async (revisionDTO: CreateRevisionDTO) => {
      return await API.Orders.createOrderRevision(revisionDTO).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Revision created'}
  );

export default useCreateOrderRevision;
