import {BlockListResponseType, AutoNotifyResponseType} from './types';

import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class AutomatedCampaignsApi {
  readonly api: AxiosInstance;
  readonly apiAutoNotify: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
    this.apiAutoNotify = new FactoryAxios(URL.BASE_ADMIN, 'autonotification').getInstance();
  }

  async getBlockList(searchValue?: string): Promise<BlockListResponseType[]> {
    return await this.api
      .get(`/block-list`, {
        params: {
          searchValue,
        },
      })
      .then(res => res.data);
  }

  async getAutoNotify(): Promise<AutoNotifyResponseType[]> {
    return await this.apiAutoNotify
      .get(`/all`, {
        params: {
        },
      })
      .then(res => res.data);
  }

  //
  async removeEmailFromBlockList(email: string): Promise<any> {
    return await this.api.delete(`/block-list/${email}`).then(res => res.data);
  }
  //
  async addEmailToBlockList(email: string): Promise<any> {
    return await this.api.post(`/block-list`, {email}).then(res => res);
  }
}
export default AutomatedCampaignsApi;
