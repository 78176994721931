import styled, {css} from 'styled-components';
import {CurtainConfig} from './Curtain';

const SCurtain = styled.div<CurtainConfig>`
  ${props => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    .curtain-window {
      position: fixed;

      width: ${props.width};
      height: ${props.height};

      ${props.bottom ? `bottom: ${props.bottom};` : ''}
      ${props.top ? `top: ${props.top};` : ''}
      ${props.left ? `left: ${props.left};` : ''}
      ${props.right ? `right: ${props.right};` : ''}
      background-color: #f7f7f7;
      display: flex;
      flex-direction: column;
      padding: 25px;
      ${!props.disableAnimation
        ? `animation: ${
            props.slideFrom === 'bottom'
              ? 'slideFromDownToUp'
              : props.slideFrom === 'top'
              ? 'slideFromTopToDown'
              : props.slideFrom === 'right'
              ? 'slideFromRightToLeft'
              : props.slideFrom === 'left'
              ? 'slideFromLeftToRight'
              : 'slideFromDownToUp'
          } 0.2s ease;`
        : ``}
      .curtain-body {
        padding: 25px;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        ${props.theme.Presets.scroll}
      }
      @keyframes slideFromDownToUp {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0%);
        }
      }
      @keyframes slideFromTopToDown {
        0% {
          transform: translateY(0%);
        }
        100% {
          transform: translateY(100%);
        }
      }
      @keyframes slideFromLeftToRight {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0%);
        }
      }
      @keyframes slideFromRightToLeft {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(100%);
        }
      }
    }
  `}
`;
export default SCurtain;
