import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    position: relative;
    justify-content: center;
    .context-menu-icon {
      cursor: pointer;
      height: 18px;
      padding: 0 3px;
      ${theme.Presets.iconHover}
    }
    .context-menu-wrapper {
      position: absolute;
      right: 0;
      display: flex;
      margin-top: 24px;
      margin-right: 4px;
      flex-direction: column;

      background-color: white;
      z-index: 9999;
      gap: 5px;
      border-radius: 15px;
      box-shadow: 0 4px 18px 4px rgba(53, 53, 53, 0.2);
      opacity: 1;
      animation: onFade 0.3s ease;
      @keyframes onFade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .context-menu-item {
        font-size: 0.7rem;
        padding: 7px 20px;
        white-space: nowrap;
        font-family: ${theme.FontFamily.Outfit};
        &:hover {
          background-color: #ecf1f6;
          cursor: pointer;
          border-radius: 15px;
        }
      }
      .disable-menu-item {
        pointer-events: none;
        text-decoration: line-through;
      }
    }
  `}
`;
