import styled, {css} from 'styled-components';
import {memo} from 'react';

const SOrderDisputeRevision = styled.div`
  ${({theme}) => css`
    position: relative;
    padding: 15px;
    background-color: ${theme.Color.White};
    border-radius: 0 15px 15px;
    display: flex;
    flex-direction: column;
    width: 1000px;
  `}
`;
export default memo(SOrderDisputeRevision);
