import {CampaignDetailsType} from '@/API/Campaign/types';
import {ElementHeadline} from '@/UI';
import {useMemo} from 'react';
import {useCampaignUpdate} from '@/Website/Components/Portals/Campaign/campaign.hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useCloseCampaign, useMarkCampaignAsDeleted} from '@/Website/Components/Portals/Campaign/Hooks';

interface Props {
  campaign: CampaignDetailsType;
  onChange: Function;
}
const CampaignTitleBar: React.FC<Props> = props => {
  const {campaign, onChange} = props;
  const {
    campaignPortal: {campaignPortalCallBack},
  } = usePortalsStore().selectors;
  const closeCampaign = useCloseCampaign();
  const deleteCampaign = useMarkCampaignAsDeleted(() => {
    closeCampaign();
    onChange();
    campaignPortalCallBack && campaignPortalCallBack();
  });

  // const deleteCampaign = useCampaignDelete(closeCampaignPortal);
  const updateCampaign = useCampaignUpdate(campaign, onChange);

  const options = useMemo(
    () => [
      {
        onSelect: () => deleteCampaign(campaign.id),
        option: campaign.is_deleted ? 'Restore' : 'Delete campaign',
      },
    ],
    [deleteCampaign]
  );
  return (
    <ElementHeadline
      title={`#${campaign.id}:  ${campaign.title}`}
      onSave={updateCampaign}
      onClose={closeCampaign}
      options={options}
      fontSize={'Header4'}
    />
  );
};
export default CampaignTitleBar;
