import {createRef, useEffect} from 'react';

const Exception = ['headline-icon', 'notify-body'];

const useClickOut = (onClickOut?: (e: MouseEvent) => void) => {
  const ref = createRef<any>();

  const clickOut = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (!Exception.some(el => JSON.stringify(e.target.classList).includes(el))) {
        onClickOut?.(e);
      }
      // if (!JSON.stringify(e.target.classList).includes('notify-body')) {
      //   onClickOut?.(e);
      // }
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    window.addEventListener('click', clickOut);
    return () => {
      window.removeEventListener('click', clickOut);
    };
  }, [ref]);

  return {
    ref,
  };
};

export default useClickOut;
