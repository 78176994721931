import {useMemo} from 'react';
import {Div, Select} from '@/UI';
import {CommunicationServiceProviders} from '@/API/Sender/sender.types';

interface Props {
  label: string;
  service?: CommunicationServiceProviders;
  onChange: (service: CommunicationServiceProviders) => void;
}

const ServiceProviderPicker: React.FC<Props> = ({service, label, onChange}) => {
  const providers = useMemo(
    () => [
      // {value: '', label: '-'},
      {value: 'sendgrid', label: 'sendgrid'},
      {value: 'mailchimp', label: 'mailchimp'},
      {value: 'sendinblue', label: 'sendinblue'},
      {value: 'sendgrid', label: 'sendgrid'},
      {value: 'pinpoint', label: 'pinpoint'},
    ],
    []
  );

  return (
    <Div width={200} className="ServiceProviderPicker">
      <Select options={providers} label={label} value={service} onChange={onChange} />
    </Div>
  );
};
export default ServiceProviderPicker;
