import {UtilService} from '@/Services/util.service';
import {Button, Curtain, Div, Grid, Line, Text, TextArea} from '@/UI';
import useGenerateCampaignEmail from '@/Website/Components/Common/EmailTemplate/GenerateCampaignEmail/Hooks/useGenerateCampaignEmail';
import {useCallback, useEffect, useState} from 'react';

interface Props {
  onClose: Function;
  onUpdateEmail: (email: string) => void;
  text?: string;
}
const GenerateCampaignEmail: React.FC<Props> = props => {
  const {onClose, text, onUpdateEmail} = props;
  const {generateEmail, generatedEmail} = useGenerateCampaignEmail();
  const [prompt, setPrompt] = useState<string>('');
  const handleUpdateEmail = useCallback(() => {
    if (generatedEmail) {
      onUpdateEmail(generatedEmail);
      onClose();
    }
  }, [generatedEmail]);

  useEffect(() => {
    setPrompt(UtilService.removeHtmlTags(text));
  }, [text]);

  return (
    <Curtain
      headLineProps={{title: 'Generate campaign email: ', onClose}}
      curtainConfig={{
        disableAnimation: true,
        disableClickOut: true,
        width: '1000px',
        height: '100vh',
        top: '0px',
        right: '0px',
      }}
    >
      <Grid templateColumn="repeat(2, 450px)" columnGap={10}>
        <Div flexDirection="column" gap={10}>
          <Text type="Text2" fontWeight={800} color="BlackOcean">
            Based on campaign description:
          </Text>
          <Line />
          <TextArea
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            placeholder="Description"
            row={30}
          />

          <Button color="Success" size="small" onClick={async () => generateEmail(prompt)}>
            GENERATE
          </Button>
        </Div>

        <Div flexDirection="column" gap={10}>
          <Text type="Text2" fontWeight={800} color="BlackOcean">
            Generated result:
          </Text>
          <Line />
          {generatedEmail && (
            <>
              <Text lineHeight={24} type="Text3">
                ${generatedEmail}
              </Text>
              <Button onClick={handleUpdateEmail} color="Orange" size="small">
                UPDATE EMAIL
              </Button>
            </>
          )}
        </Div>
      </Grid>
    </Curtain>
  );
};
export default GenerateCampaignEmail;
