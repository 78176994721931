import {Button, Curtain, Div, Input, Line, Switch, Text} from '@/UI';
import {useGenerateTags} from '@/Website/Pages/Tags/';
import {memo, useCallback, useMemo} from 'react';

interface Props {
  textToSearchTags: string;
  afterUpdateTags: Function;
  onClose: Function;
}
const OpenaiTags: React.FC<Props> = props => {
  const {afterUpdateTags, onClose, textToSearchTags} = props;

  const onSuccess = useCallback(() => {
    afterUpdateTags();
    onClose();
  }, [afterUpdateTags, onClose]);

  const {generatedTags, handleChangeTagText, generate, addGeneratedTags, handleToggleTags} = useGenerateTags(
    textToSearchTags,
    onSuccess
  );

  const isReadyToAdd = useMemo(() => generatedTags.some(el => el.isIncludeToCreate), [generatedTags]);
  return (
    <Curtain
      headLineProps={{onClose, title: 'Generate new tags'}}
      curtainConfig={{
        disableClickOut: true,
        disableAnimation: true,
        right: '0px',
        top: '0px',
        width: '450px',
        height: '100vh',
      }}
    >
      <Div flexDirection="column" gap={20}>
        <Text type="Text3">AI will generate a tags based on this text:</Text>
        <Line />
        <Text italic type="Text3" fontWeight={800}>
          {textToSearchTags}
        </Text>
        <Line />
        <Text type="Text4" color="MediumGray">
          if the length is less than 10 characters, the search is not available
        </Text>
        <Button onClick={generate} size="small" isDisabled={textToSearchTags.length < 10} color="Laguna">
          GENERATE
        </Button>
      </Div>
      {generatedTags.length > 0 && (
        <>
          <Line />
          <Div flexDirection="column" gap={20}>
            <Text type="Text3">Here are the results. select required tags: </Text>
            {generatedTags.map((tag, i) => (
              <Div alignItems="flex-end" gap={5}>
                <Switch
                  key={i}
                  isOn={tag.isIncludeToCreate}
                  onToggle={() => handleToggleTags(i)}
                  option={''}
                />
                <Input
                  placeholder="Tag"
                  disabled={!tag.isIncludeToCreate}
                  value={generatedTags[i].tag}
                  onChange={e => handleChangeTagText(i, e.target.value)}
                />
              </Div>
            ))}
            <Button onClick={addGeneratedTags} isDisabled={!isReadyToAdd} color="Purple" size="small">
              ADD TAGS
            </Button>{' '}
            <Text type="Text4">At least 1 tag required</Text>
          </Div>
        </>
      )}
    </Curtain>
  );
};
export default memo(OpenaiTags);
