import {SocialsType} from '@/Services/social.service';
import {BaseCreateReportDTO} from '@/API/TalentInfo/types';

export namespace NewTalentInfoService {
  export const defaultCreateState: Omit<
    BaseCreateReportDTO,
    'email_contact' | 'profile_photo' | 'user_name' | 'user_id'
  > = {
    social: 'instagram' as SocialsType,
    url: '',
    bio: '',
    language: 'EN',
    country: 'INT',
    age_group: '',
    followers: 0,
    marginPrice: 40,
    er: 0,
    opsPrice: 0,
  };
}
