import {useCallback} from 'react';
import eyeOpenedIcon from '@/Assets/Icons/eye-opened.svg';
import eyeClosedIcon from '@/Assets/Icons/eye-closed.svg';
import mailIcon from '@/Assets/Icons/mail.svg';
import passwordIcon from '@/Assets/Icons/password.svg';
import searchImg from '@/Assets/Icons/search.svg';
import {Props} from './input.interface';
import {Component} from './input.styled';
import {Text} from '@/UI';
import {useBooleanState} from '@/Hooks/useBooleanState';

/**@type Date wrap incoming value with Function - dateAdapter from date service  */

const Input: React.FC<Props> = props => {
  const ShowPassword = useBooleanState();
  const {placeholder, isError, disabled, type, searchIcon, errorMessage, value, ...propsRest} = props;
  const eyeClickHandler = useCallback(() => {
    type === 'password' && ShowPassword.toggle();
  }, [type, ShowPassword]);
  const handleClearInput = useCallback(() => {
    const event = {
      target: {
        value: '',
      },
    } as React.ChangeEvent<HTMLInputElement>;

    propsRest.onChange && propsRest.onChange(event);
  }, [propsRest]);
  const isHasValue = value ? value.toString().length > 0 : false;
  return (
    <Component
      isDisabled={disabled || false}
      isError={isError || false}
      className="Input-Wrap"
      withoutIcon={!type && !searchIcon}
      error={errorMessage}
    >
      <div className="input-group">
        <input
          className="input"
          placeholder={placeholder}
          required
          disabled={disabled}
          value={value ?? ''}
          {...propsRest}
          type={
            //to avoid 'email' default validation
            ShowPassword.isTurn || type === 'email' ? 'text' : type
          }
          name="password"
        />

        <label className="input-label">{placeholder}</label>
        {type === 'password' && (
          <>
            <img
              src={ShowPassword.isTurn ? eyeOpenedIcon : eyeClosedIcon}
              className="eye"
              onClick={eyeClickHandler}
              alt=""
            />
            <img src={passwordIcon} className="password" alt="" />
          </>
        )}
        {type === 'email' && <img src={mailIcon} className="mail" alt="" />}
        {searchIcon && <img src={searchImg} className="mail" alt="" />}
        {isHasValue ? (
          <div className="clear-input" onClick={handleClearInput}>
            x
          </div>
        ) : null}
      </div>
      {isError && errorMessage && (
        <Text type="Text3" color="Alert" className="error-msg">
          Invalid input email
        </Text>
      )}
    </Component>
  );
};

export default Input;
