import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {EmailEventsResponse, EmailEventsSearchParams} from '@/API/Sender/sender.types';
import {useEffect, useState} from 'react';

const useEmailEvents = (params: EmailEventsSearchParams) => {
  const [emailEvents, setEmailEvents] = useState<EmailEventsResponse>({count: 0, logs: []});
  const fetch = useApiLoader(async () => {
    await API.Sender.getEmailEvents(params).then(res => setEmailEvents(res));
  }, [params]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    events: emailEvents,
  };
};
export default useEmailEvents;
