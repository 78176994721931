import {useBooleanState} from '@/Hooks/useBooleanState';
import {useCommunityStore} from '@/Store/community/community.selector';
import {Box, ElementHeadline} from '@/UI';
import {useGetTalentFinancialDocs} from '@/Website/Components/Talent/Hooks';
import FinancialDocsTable from '@/Website/Components/Talent/TalentFinancialDocs/FinancialDocsTable/FinancialDocsTable';
import UploadTalentFinancialDoc from '@/Website/Components/Talent/TalentFinancialDocs/UploadTalentFinancialDoc/UploadTalentFinancialDoc';

const TalentFinancialDocs = () => {
  const {userID} = useCommunityStore().selectors.storedUser;
  const {docs, reloadDocs} = useGetTalentFinancialDocs(userID);
  const ShowFinancialDocUpload = useBooleanState();
  return (
    <Box className="TalentLastActivity tab-preset">
      <ElementHeadline
        onCreateNew={ShowFinancialDocUpload.turnOn}
        title={`Total docs: ${docs.length}`}
        disableMarker
      />
      <FinancialDocsTable docs={docs} onChange={reloadDocs} />
      {ShowFinancialDocUpload.isTurn && (
        <UploadTalentFinancialDoc
          isShow={ShowFinancialDocUpload.isTurn}
          onUpload={reloadDocs}
          onClose={ShowFinancialDocUpload.turnOff}
        />
      )}
    </Box>
  );
};
export default TalentFinancialDocs;
