import {CategoryDetailsType, CategoryType, CategoryUsers} from './types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class CategoriesApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getCategories(search?: string): Promise<CategoryType[]> {
    return await this.api
      .get(`/get-categories`, {
        params: {
          searchValue: search || '',
        },
      })
      .then(res => res.data);
  }
  async getCategory(id: number): Promise<CategoryDetailsType> {
    return await this.api
      .get(`/get-category-details`, {
        params: {
          categoryID: id,
        },
      })
      .then(res => res.data);
  }
  //
  async getUserCategories(talentID: string): Promise<CategoryType[]> {
    return await this.api
      .get(`/get-user-categories`, {
        params: {
          userID: talentID,
        },
      })
      .then(res => res.data);
  }
  //
  async getCategoryUsers(categoryID: number, searchedValue?: string): Promise<CategoryUsers> {
    return await this.api
      .get(`/get-category-users`, {
        params: {
          categoryID,
          searchedValue: searchedValue || '',
        },
      })
      .then(res => res.data);
  }
  //
  async deleteTalentFromCategory(talentID: string, categoryID: number) {
    return await this.api.delete(`/delete-from-category`, {
      params: {
        talentID,
        categoryID,
      },
    });
  }
  //
  async addCategoryToUser(talentID: string, categoryID: number) {
    return await this.api.post(`/add-user`, {userID: talentID, categoryID});
  }
}
export default CategoriesApi;
