import {useApiLoader} from '@/Hooks';
import {useState} from 'react';
import {API} from '@/API/API';

const useGenerateCampaignEmail = () => {
  const [generatedEmail, setGeneratedEmail] = useState<string>();

  const generateEmail = useApiLoader(async (prompt: string) => {
    await API.OpenAI.generateEmail(prompt).then(res => setGeneratedEmail(res));
  }, []);
  return {
    generatedEmail,
    generateEmail,
  };
};
export default useGenerateCampaignEmail;
