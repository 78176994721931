import {useEffect, useState} from 'react';
import {CategoryType} from '@/API/Categories/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useCategories = (searchedValue?: string) => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const fetch = useApiLoader(async () => {
    await API.Categories.getCategories(searchedValue).then(res => {
      setCategories(res);
    });
  }, [searchedValue]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    categories,
    reloadCategories: fetch,
  };
};
export default useCategories;
