import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    width: max-content;
    margin-top: 10px;
    margin-bottom: 1px;
    width: max-content;

    outline: 1px solid #e7e6e6;
    border-radius: 15px;
    background-color: #f9f9f9;

    padding: 2px 5px;

    .radio-wrapper {
      display: flex;
      width: 100%;

      .option {
        font-family: ${theme.FontFamily.Outfit};
        font-size: 0.8rem;
        padding: 4px 8px;
        margin-right: 5px;
        border-radius: 15px;
        white-space: nowrap;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: ${theme.Color.GrayLight};
          color: black;
        }
        &:active {
          transform: scale(0.9);
        }
      }

      .selected {
        background-color: ${theme.Color.Success};
        color: white;
      }
    }
  `}
`;
