import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useRejectTalent = (
  talent: {user_id: string; email: string; full_name: string},
  callBack?: Function
) => {
  const {
    storedUser: {onChangeUserCallback},
  } = useCommunityStore().selectors;
  const {setIsShowStoredTalent} = useCommunityStore().actions;

  return useApiLoader(
    async () => {
      if (talent) {
        await API.Talents.rejectTalent(talent.user_id, talent?.email || '', talent.full_name || '').then(
          () => {
            callBack && callBack();
            setIsShowStoredTalent({isShow: false});
            onChangeUserCallback();
          }
        );
      }
    },
    [talent, onChangeUserCallback],
    {successMsg: 'Rejected'}
  );
};

export default useRejectTalent;
