import {CreateTransactionDTO} from '@/API/Wallet/types';
import {useReduceState} from '@/Hooks';
import useSelectOptions from '@/Hooks/SelectOptions/useSelectOptions';
import {Button, Div, ElementHeadline, Input, Modal, RadioBox, Select} from '@/UI';
import {useCreateWalletOperation} from '@/Website/Components/Talent/Hooks';
import {useCallback, useMemo, useState} from 'react';

interface OperationProps {
  isShow: boolean;
  onClose: Function;
  refreshWallet: Function;
}

const NewOperation: React.FC<OperationProps> = ({isShow, onClose, refreshWallet}) => {
  const defaultState: Omit<CreateTransactionDTO, 'userID'> = useMemo(
    () => ({
      transaction_type: 'income',
      income: 0,
      orderID: '',
      payment_method: '',
      created_at: '',
    }),
    []
  );
  const [CreatePayload, setCreatePayload] = useState<Omit<CreateTransactionDTO, 'userID'>>(defaultState);
  const orderOptions = useSelectOptions('orderOptions')();
  const onSuccessCreate = useCallback(() => {
    refreshWallet();
    onClose();
  }, [onClose, refreshWallet]);

  const submitCreateHandler = useCreateWalletOperation(CreatePayload, onSuccessCreate);
  const handleChangeInputs = useReduceState<typeof CreatePayload>(setCreatePayload);

  return (
    <Modal isShowModal={isShow}>
      <Div flexDirection="column" width={300} className="Operation">
        <ElementHeadline title="Create operation" onClose={onClose} />
        <RadioBox
          options={[
            {name: 'Income', value: 'income'},
            {name: 'Withdrawal', value: 'withdrawal'},
          ]}
          value={CreatePayload.transaction_type}
          onChange={(value: any) => handleChangeInputs('transaction_type', value)}
        />
        <Input
          placeholder="Income"
          value={CreatePayload.income}
          onChange={e => handleChangeInputs('income', e.target.value)}
          type={'number'}
        />

        <Select
          isSearchable={true}
          options={orderOptions}
          label="Order"
          value={CreatePayload.orderID}
          onChange={value => handleChangeInputs('orderID', value)}
        />
        <Input
          placeholder="Create date"
          value={CreatePayload.created_at}
          type="date"
          onChange={e => handleChangeInputs('created_at', e.target.value)}
        />
        {CreatePayload.transaction_type === 'withdrawal' && (
          <Input
            placeholder="Payment info"
            value={CreatePayload.payment_method}
            onChange={e => handleChangeInputs('payment_method', e.target.value)}
          />
        )}

        <Div flexDirection="column" margin="25px 0px 10px" gap={10}>
          <Button
            color="Success"
            size="medium"
            onClick={submitCreateHandler as React.MouseEventHandler<HTMLButtonElement> | undefined}
          >
            Create
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

export default NewOperation;
