import {memo} from 'react';
import styled from 'styled-components';

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  .campaign-tab {
    width: 1100px;
    height: 650px;
  }
`;

export default memo(Component);
