import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback} from 'react';
type DeleteCategoryFromUserParams = {userID: string; categoryID?: number; callback?: Function};

const useDeleteCategoryFromUser = () => {
  const deleteUser = useApiLoader(
    async (args: DeleteCategoryFromUserParams) => {
      if (args.categoryID) {
        await API.Categories.deleteTalentFromCategory(args.userID, args.categoryID).then(
          args.callback && args.callback()
        );
      }
    },
    [],
    {successMsg: 'Deleted'}
  );
  return useCallback(async (params: DeleteCategoryFromUserParams) => await deleteUser(params), [deleteUser]);
};
export default useDeleteCategoryFromUser;
