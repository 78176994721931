import {useAppSelector} from '@/Hooks';
import {AppDispatchType} from '@/Store/types';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import { checkBoxActions } from './checkBox.slice';

const useCheckBoxSelector = () => {
  return useAppSelector(state => state.checkBox);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(checkBoxActions, dispatch);
};

export const useCheckBoxStore = () => {
  return {
    actions: useActions(),
    selectors: useCheckBoxSelector(),
  };
};
