import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Button, Div, ElementHeadline, Modal, SelectAsync} from '@/UI';
import {useState} from 'react';
import {useGetCampaigns} from '@/Website/Pages/Campaigns/campaigns.hooks';

const AddPreOrder: React.FC = () => {
  const {
    preOrderPortal: {isShowPreOrderPortal, toPreOrderPortal},
  } = usePortalsStore().selectors;
  const {closePreOrderPortal} = usePortalsStore().actions;
  const [selectedCampaignID, setSelectedCampaignID] = useState<number>();
  const {getCampaignsSelectOptions} = useGetCampaigns();
  const addToCampaignHandler = useApiLoader(
    async () => {
      await API.Campaigns.addPreOrderTalentsToCampaign({
        campaignID: selectedCampaignID as number,
        talents: toPreOrderPortal.map(el => ({
          talentID: el.userID,
          price: el?.price || 0,
          advertiserPrice: el.advertiserPrice || 0,
          talentInfoID: el.talentInfoID as number,
        })),
      }).then(() => {
        closePreOrderPortal();
      });
    },
    [selectedCampaignID, toPreOrderPortal],
    {successMsg: 'Successfully added'}
  );

  return (
    <Modal isShowModal={isShowPreOrderPortal}>
      <Div flexDirection="column" width={300} className="PreOrderPortal">
        <ElementHeadline
          onClose={() => closePreOrderPortal()}
          title={`Add Pre order talent: ${toPreOrderPortal.length}`}
        />

        <SelectAsync
          isSearchable={true}
          value={selectedCampaignID}
          label="Campaign"
          loadOptions={getCampaignsSelectOptions}
          onChange={setSelectedCampaignID}
        />

        <Button color="Success" style={{marginTop: 20}} onClick={addToCampaignHandler}>
          ADD
        </Button>
      </Div>
    </Modal>
  );
};

export default AddPreOrder;
