import {useAppSelector} from '@/Hooks';
import {AppDispatchType} from '@/Store/types';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {openAIActions} from './openaiDialog.slice';

const useOpenAIDialogSelector = () => {
  return useAppSelector(state => state.openAIDialog);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(openAIActions, dispatch);
};

export const useOpenAIDialogStore = () => {
  return {
    actions: useActions(),
    selectors: useOpenAIDialogSelector(),
  };
};
