import {TalentDetailed} from '@/API/Talents/types';
import {ROUTES} from '@/Router/ROUTES';
import {ElementHeadline} from '@/UI';
import {useDeleteTalent, useUpdateTalent} from '@/Website/Components/Talent/Hooks';
import useShowResetPassword from '@/Website/Components/Talent/Hooks/useShowResetPassword';
import {useCallback, useMemo} from 'react';

const TalentHeader: React.FC<{talent: TalentDetailed; onClose: Function}> = ({talent, onClose}) => {
  const openResetPassword = useShowResetPassword(talent.user_id);

  const openPortfolio = useCallback(() => {
    const url = `${window.location.protocol}//${window.location.host}${ROUTES.talentPortfolio}/${talent.cognito_id}`;
    window.open(url, '_blank');
  }, [talent.user_id]);

  const handleSave = useUpdateTalent(talent);
  const handleDeleteTalent = useDeleteTalent(onClose);
  const menuOptions = useMemo(
    () => [
      // {option: 'Delete user', onSelect: handleDeleteTalent},
      {option: 'Open portfolio', onSelect: openPortfolio, isDisabled: !talent.has_portfolio},
      {option: 'Change password', onSelect: openResetPassword},
    ],

    [handleDeleteTalent, openResetPassword, openPortfolio]
  );
  return (
    <ElementHeadline
      title={talent?.full_name || ''}
      options={menuOptions}
      onClose={onClose}
      fontSize="Header3"
      onSave={handleSave}
      disableMarker
    />
  );
};
export default TalentHeader;
