import {CampaignType} from '@/API/Campaign/types';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {Box, ElementHeadline, TextEditor} from '@/UI';
import {GenerateCampaignEmail} from '@/Website/Components/Common';
import {EmailTemplateService} from './emailTemplate.service';

interface Props {
  value?: string;
  setValue: (value: string) => void;
  campaign?: CampaignType;
  type: 'campaign' | 'reminder';
}
const EmailTemplate: React.FC<Props> = props => {
  const {campaign, setValue, value, type} = props;
  const ShowGenerateEmail = useBooleanState();
  return (
    <Box flexDirection="column">
      <ElementHeadline
        options={campaign && [{onSelect: ShowGenerateEmail.turnOn, option: 'Generate email with AI'}]}
        title={`Email template`}
      />

      <TextEditor height="400px" value={value} onChange={(e: any) => setValue(e)} />
      {ShowGenerateEmail.isTurn && (
        <GenerateCampaignEmail
          onClose={ShowGenerateEmail.turnOff}
          onUpdateEmail={(e: any) => setValue(e)}
          text={EmailTemplateService.generateEmail({description: campaign?.brand_description, type})}
        />
      )}
    </Box>
  );
};
export default EmailTemplate;
