import {useMemo, useState} from 'react';
import {Div, ElementHeadline, Input, Modal, TextArea} from '@/UI';
import {OrderRevision, UpdateRevisionDTO} from '@/API/Orders/types';
import {useReduceState} from '@/Hooks';
import {useDeleteOrderRevision, useUpdateOrderRevision} from '../../Hooks';

interface RevisionEditorProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
  revision: OrderRevision;
}
const RevisionEditor: React.FC<RevisionEditorProps> = props => {
  const {isShow, onClose, onChange, revision} = props;
  const [revisionDTO, setRevisionDTO] = useState<UpdateRevisionDTO>(revision);
  const deleteRevision = useDeleteOrderRevision(() => {
    onClose();
    onChange();
  });
  const updateRevision = useUpdateOrderRevision(onChange);
  const handleInputs = useReduceState(setRevisionDTO);
  const options = useMemo(
    () => [
      {option: 'Delete', onSelect: () => deleteRevision({id: revision.id, order_id: revision.order_id})},
    ],
    []
  );
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline
        onClose={onClose}
        options={options}
        title="Edit revision"
        onSave={() => updateRevision(revisionDTO)}
      />
      <Div width={400} flexDirection="column" gap={20}>
        <Input
          placeholder="Reason"
          value={revisionDTO.reason}
          onChange={e => handleInputs('reason', e.target.value)}
        />
        <TextArea
          placeholder="Changes"
          value={revisionDTO.changes}
          row={15}
          onChange={e => handleInputs('changes', e.target.value)}
        />
      </Div>
    </Modal>
  );
};
export default RevisionEditor;
