import {CampaignDetailsType} from '@/API/Campaign/types';
import {useCampaignEstimations} from '@/Hooks/Campaigns';
import useSelectOptions from '@/Hooks/SelectOptions/useSelectOptions';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {dateInputAdapter, dateToLocalDate} from '@/Services/date.service';
import {SocialService} from '@/Services/social.service';
import {UtilService} from '@/Services/util.service';
import {Box, Button, Div, Input, Select, Switch, TalentLevel, TextArea, TitledText} from '@/UI';
import {UTIL_CONSTANTS} from '@/Util/constants';
import CampaignSummary from '@/Website/Components/Portals/Campaign/CampaignSummary/CampaignSummary';
import {useMemo} from 'react';
import {CampaignService} from '../campaignEditor.service';
import Component from './details.styled';

interface CampaignDetailsProps {
  campaign: CampaignDetailsType;
  onChange: (props: keyof CampaignDetailsType, value: any) => void;
}
const CampaignDetails: React.FC<CampaignDetailsProps> = ({campaign, onChange}) => {
  const ShowCampaignSummary = useBooleanState();
  const {statuses, goals, genders, audienceRightRange, audienceLeftRange} = useMemo(
    () => CampaignService,
    []
  );
  const categoriesOptions = useSelectOptions('categoryOptions')();
  const {estimations, isLoadingEstimations} = useCampaignEstimations(campaign.id);
  return (
    <Component className="CampaignDetails campaign-tab">
      {ShowCampaignSummary.isTurn && <CampaignSummary onClose={ShowCampaignSummary.turnOff} />}

      <Div justifyContent="space-between">
        <Box className="col second">
          <Switch
            isDisabled
            isOn={campaign.auto_approved}
            onToggle={value => onChange('auto_approved', value)}
            option="Auto approve orders"
          />
          <TitledText title="Organization: " textColor="Orange" text={campaign.org_name} />
          <TitledText
            title="Accepted at: "
            textColor="Alert"
            text={dateToLocalDate(campaign.accepted_at) || '-'}
          />
          <TitledText title="Updated at: " textColor="Purple" text={dateToLocalDate(campaign.created_at)} />
          <TitledText title="Created at: " textColor="Success" text={dateToLocalDate(campaign.created_at)} />
          <TitledText
            title="Avg comments: "
            textColor="Blue"
            isLoading={isLoadingEstimations}
            text={UtilService.hundredSeparator(estimations?.sumAvgComments)}
          />
          <TitledText
            title="Avg likes: "
            textColor="Blue"
            isLoading={isLoadingEstimations}
            text={UtilService.hundredSeparator(estimations?.sumAvgLikes)}
          />
          <TitledText
            title="Avg views: "
            textColor="Blue"
            isLoading={isLoadingEstimations}
            text={UtilService.hundredSeparator(estimations?.sumAvgViews)}
          />
          <TitledText
            title="Responsive: "
            textColor="Blue"
            isLoading={isLoadingEstimations}
            text={UtilService.hundredSeparator(estimations?.sumResponsive)}
          />
          <Button color="BlackOcean" size="medium" onClick={ShowCampaignSummary.turnOn}>
            open summary
          </Button>
        </Box>
        <Box className="first col" label="Campaign">
          <Input
            placeholder="Campaign"
            value={campaign.title}
            onChange={(e: any) => onChange('title', e.target.value)}
          />
          <Select
            options={statuses}
            label="Status"
            value={campaign.status}
            onChange={value => onChange('status', value)}
            defaultPlaceholder={campaign.status}
          />

          <Select
            options={goals}
            label="Goal"
            value={campaign.campaign_goal}
            onChange={value => onChange('campaign_goal', value)}
            defaultPlaceholder={campaign.campaign_goal}
          />
          <Input
            placeholder="Budget"
            value={campaign.budget}
            type={'number'}
            onChange={(e: any) => onChange('budget', e.target.value)}
          />
          <Switch
            isOn={campaign.hide_prices}
            onToggle={value => onChange('hide_prices', value)}
            option="Restrict prices visibility"
          />

          <Input
            placeholder="Commencing date"
            value={dateInputAdapter(campaign.commencing_date)}
            type={'date'}
            onChange={(e: any) => onChange('commencing_date', e.target.value)}
          />
          <Input
            placeholder="Deadline"
            value={dateInputAdapter(campaign.deadline_date)}
            type={'date'}
            onChange={(e: any) => onChange('deadline_date', e.target.value)}
          />
          <Input
            placeholder="Stripe intent id"
            value={campaign.stripe_intent_id}
            onChange={(e: any) => onChange('stripe_intent_id', e.target.value)}
          />
        </Box>

        <Box className="col third" label="Audience">
          <Select
            isSearchable={true}
            options={genders}
            label="Gender"
            value={campaign.gender}
            onChange={value => onChange('gender', value)}
            defaultPlaceholder={campaign.gender || ''}
          />
          <Select
            isSearchable={true}
            options={categoriesOptions}
            label="Category"
            value={campaign.category_id}
            onChange={value => onChange('category_id', value)}
            defaultPlaceholder={campaign.category_name || ''}
          />
          <Div gap={5}>
            <Select
              options={audienceLeftRange}
              label="Age From"
              value={campaign?.audience ? campaign.audience[0] : 13}
              onChange={value =>
                onChange('audience', [value, campaign?.audience ? campaign.audience[1] : 65])
              }
              defaultPlaceholder={campaign?.audience ? campaign.audience[0] : 13}
            />
            <Select
              options={audienceRightRange}
              label="To"
              value={campaign?.audience ? campaign.audience[1] : 65}
              onChange={value =>
                onChange('audience', [campaign?.audience ? campaign.audience[0] : 13, value])
              }
              defaultPlaceholder={campaign?.audience ? campaign.audience[1] : 65}
            />
          </Div>
          <TalentLevel
            value={campaign.talent_level || ''}
            onChange={(e: any) => onChange('talent_level', e)}
          />
          <Select
            isSearchable={true}
            options={UTIL_CONSTANTS.COUNTRIES}
            label="Countries"
            value={campaign.countries}
            onChange={(e: any) => onChange('countries', e)}
            isMulti
          />
          <Select
            isSearchable={true}
            options={SocialService.SOCIALS_SELECT_OPTIONS}
            label="Socials"
            value={campaign.social_networks}
            onChange={(e: any) => onChange('social_networks', e)}
            isMulti
          />
        </Box>
      </Div>
      <TextArea
        placeholder="Notes"
        row={4}
        value={campaign.notes || ''}
        onChange={(e: any) => onChange('notes', e.target.value)}
      />
    </Component>
  );
};

export default CampaignDetails;
