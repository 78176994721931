import {Paginated} from '@/UI/Pagination/Pagination';
import {SmsWhatsAppPushValues} from '@/Website/Components/Common/SmsWhatsAppPush/SmsWhatsAppPush';

export type CommunicationServiceProviders = 'mailchimp' | 'sendinblue' | 'sendgrid' | 'pinpoint';
export const SenderSending = {
  campaign: 'campaign',
  onboarding: 'onboarding',
  reminder: 'reminder',
  approval: 'approval',
  rejection: 'rejection',
  dispute: 'dispute',
  revision: 'revision',
};
export type SenderSendingType = keyof typeof SenderSending;

const enum WHATSAPP_TEMPLATES {
  hello_world = 'hello_world',
  welcome_to_letstok = 'welcome_to_letstok',
  campaign_waiting_approval = 'campaign_waiting_approval',
  dont_forget_approve = 'dont_forget_approve',
  hurry_up_full = 'hurry_up_full',
  final_call = 'final_call'
}
export const WhatsAppTemplatesSelectOptions = [
  {label: 'hello_world', value: 'hello_world'}, 
  {label: 'welcome_to_letstok', value: 'welcome_to_letstok'},
  {label: 'campaign_waiting_approval', value: 'campaign_waiting_approval'},
  {label: 'dont_forget_approve', value: 'dont_forget_approve'},
  {label: 'hurry_up_full', value: 'hurry_up_full'},
  {label: 'final_call', value: 'final_call'}
];
export type WhatsAppTemplates = keyof typeof WHATSAPP_TEMPLATES;

export type Recipient = {
  email: string;
  name?: string;
  price?: number;
};
interface Log {
  api_name: string;
  receipient: string;
  message: string;
  response: string;
  request: string;
  response_status: string;
  internal_id: string;
  external_id: string;
  created_at: string;
}
export interface LogsResponse {
  count: number;
  logs: Log[];
}
export interface EmailEvent {
  service: string;
  event: string;
  is_bounce: boolean;
  sent_at: string;
  subject: string;
  email: string;
  external_id: string;
}
export interface EmailEventsResponse {
  count: number;
  logs: EmailEvent[];
}
export interface LogsSearchParams {
  status?: string;
  event?: string;
  externalId?: string | number;
  email?: string;
  limit?: number;
  skip?: number;
  sortBy?: [string, string];
}
export interface EmailEventsSearchParams {
  email?: string;
  status?: string;
  externalId?: string | number;
  limit?: number;
  skip?: number;
  sortBy?: [string, string];
}

export interface SendOnboardingBase extends SendBase {
  registerUsers?: 'exclude';
  internalProvider?: string;
  file?: any;
}

export interface Communicable {
  mailingDate?: string;
  service_provider: CommunicationServiceProviders;
  additionalSending?: SmsWhatsAppPushValues;
}
export interface SendBase extends Communicable {
  subject: string;
  from: string;
  sendType: SenderSendingType;
  recipientList?: Array<Recipient>;
  emailDescription?: string;
  html?: string;
  buttonText?: string;
  buttonLink?: string;
}

export interface CustomEmailSending extends Communicable {
  from: string;
  recipient: string;
  name: string;
  sendType: SenderSendingType;
  html: string;
  subject: string;
}

export interface SendingLogsSearchParams extends Paginated {
  sendType?: SenderSendingType;
}

export interface SendingLog {
  id: number;
  sending_type: SenderSendingType;
  sender: string;
  recipients_count: number;
  description?: string;
  subject: string;
  template: string;
  campaign_id?: number;
  provider_id?: string;
  external_id?: string;
  campaign_title?: string;
  created_at?: string;
}
export interface SendingLogsResponse {
  count: number;
  logs: SendingLog[];
}
