import styled, {css} from 'styled-components';

export const Component = styled.div<{isOpen: boolean}>`
  ${({theme, isOpen}) => css`
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    ${isOpen ? `right: 0px` : `right: 10px`};
    cursor: pointer;
    z-index: 1;
    #icon {
      width: 23px;
      position: absolute;
      top: 34%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    #icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: grey;
      border-radius: 0px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      &:hover {
        background-color: white;
      }
    }

    #icon span:nth-child(1) {
      top: 0px;
      width: 80%;
    }

    #icon span:nth-child(2) {
      top: 8px;
    }

    #icon span:nth-child(3) {
      top: 16px;
      width: 60%;
    }

    #icon.open span:nth-child(1) {
      transform: rotate(-90deg);
      top: 8px;
      margin-left: 9px;
    }

    #icon.open span:nth-child(2) {
      top: 8px;
      transform: rotate(-90deg);
    }

    #icon.open span:nth-child(3) {
      margin-left: -2px;
      top: 8px;
      transform: rotate(-90deg);
    }
  `}
`;
