import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {CampaignSummary} from '@/API/Campaign/types';

const useCampaignSummary = (campaignID?: string | number) => {
  const [summary, setSummary] = useState<CampaignSummary>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(async () => {
    if (campaignID) {
      setIsLoading(true);
      await API.Campaigns.getCampaignSummary(Number(campaignID))
        .then(res => setSummary(res))
        .finally(() => setIsLoading(false));
    }
  }, [campaignID]);
  useEffect(() => {
    void fetch();
  }, []);
  return {
    isLoading,
    summary,
  };
};
export default useCampaignSummary;
