import {ROUTES} from '@/Router/ROUTES';
import incomingIcon from '@/Assets/Icons/sidebar/arrived.svg';
import peopleIcon from '@/Assets/Icons/sidebar/people.svg';
import commercialIcon from '@/Assets/Icons/sidebar/commercial.svg';
import campaignIcon from '@/Assets/Icons/sidebar/campaign.svg';
import logIcon from '@/Assets/Icons/sidebar/log.svg';
import sendIcon from '@/Assets/Icons/sidebar/send.svg';
import categoryIcon from '@/Assets/Icons/sidebar/category.svg';

export const sections = [
  {
    section: 'Incoming',
    icon: incomingIcon,
    isOpen: true,
    hasBudgedUnread: true,
    links: [
      {title: 'Approvals', href: ROUTES.approvals},
      {title: 'Onboarding', href: ROUTES.onboarding},
    ],
  },
  {
    section: 'Community',
    icon: peopleIcon,
    isOpen: true,
    links: [
      {title: 'Influencers', href: ROUTES.influencers},
      {title: 'UGC Creators', href: ROUTES.ugcCreators},
      {title: 'Searchy', href: ROUTES.searchy},
    ],
  },
  {
    section: 'Commercial',
    icon: commercialIcon,
    isOpen: true,
    links: [
      {title: 'Advertisers', href: ROUTES.advertisers},
      {title: 'Organizations', href: ROUTES.organizations},
      {title: 'Help requests', href: ROUTES.helpRequests},
    ],
  },
  {
    section: 'Campaigns',
    icon: campaignIcon,
    isOpen: true,
    links: [
      {title: 'Monitor', href: ROUTES.monitor},
      {title: 'Campaigns', href: ROUTES.campaigns},
      {title: 'Orders', href: ROUTES.orders},
      {title: 'Orders logs', href: ROUTES.ordersLogs},
    ],
  },
  {
    section: 'Automated sending',
    icon: sendIcon,
    isOpen: true,
    links: [
      {title: 'Campaign sending', href: ROUTES.automatedCampaignOrder},
      {title: 'Onboarding sending', href: ROUTES.automatedCampaignNotification},
      {title: 'Reminder', href: ROUTES.reminder},
      {title: 'Providers', href: ROUTES.providers},
      {
        title: 'History',
        href: ROUTES.sendingLogs,
      },
      {title: 'Blocklist', href: ROUTES.blocklist},
      {title: 'Automatic Notifications', href: ROUTES.automatics},
    ],
  },
  {
    section: 'Categories & Tags',
    icon: categoryIcon,
    isOpen: false,
    links: [
      {title: 'Categories', href: ROUTES.categories},
      {title: 'Tags', href: ROUTES.tags},
    ],
  },
  {
    section: 'Sending logs',
    icon: logIcon,
    isOpen: false,
    links: [{title: 'Emails', href: ROUTES.emailsLogs}],
  },
];
