import removeIcon from '@/Assets/Icons/remove.svg';
import {useDeleteCategoryFromUser, useGetUsersCategories} from '@/Hooks/Categories';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {ElementHeadline, Text} from '@/UI';
import {useCallback, useEffect} from 'react';
import {STalentCategories} from './talentCategories.styled';

const TalentCategories: React.FC<{
  talentID: string;
}> = ({talentID}) => {
  const {categories, reloadCategories} = useGetUsersCategories();
  const {setIsShowCategoryPortal, setUserToPortalCategory, setPortalCategoryCallBack} =
    usePortalsStore().actions;

  const handleDeleteUserFromCategory = useDeleteCategoryFromUser();
  /** setting callBack for categoryPortal */
  useEffect(() => {
    setPortalCategoryCallBack(reloadCategories);
  }, []);

  const handleAddCategory = useCallback(() => {
    setIsShowCategoryPortal(true);
    setUserToPortalCategory(talentID);
  }, [talentID]);
  return (
    <STalentCategories className="TalentCategories">
      <ElementHeadline
        title="Categories"
        disableMarker
        onCreateNewMsg="Add categories"
        onCreateNew={handleAddCategory}
      />
      <div className="categories-list">
        {categories?.map((cat, i) => (
          <div className="categories-item" key={i}>
            <img
              src={removeIcon}
              alt=""
              onClick={() =>
                handleDeleteUserFromCategory({
                  userID: talentID,
                  categoryID: cat.id,
                  callback: reloadCategories,
                })
              }
            />
            <Text type="Text3" color="Purple">
              {cat.category_name}
            </Text>
          </div>
        ))}
      </div>
    </STalentCategories>
  );
};

export default TalentCategories;
