import {ContextMenu, Div, ElementHeadline, TableContainer, TCell, Text, TRow} from '@/UI';
import {useCallback, useMemo, useState} from 'react';
import {dateToLocalDate} from '@/Services/date.service';
import {useOrderRevisions} from '../../Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {OrderRevision} from '@/API/Orders/types';
import {useQuickResponse} from '@/Website/Components/Portals/QuickResponse';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import NewRevision from '@/Website/Components/Portals/Order/OrderDisputeRevision/RevisionsTable/NewRevision';
import RevisionEditor from '@/Website/Components/Portals/Order/OrderDisputeRevision/RevisionsTable/RevisionEditor';

const RevisionsTable: React.FC<{orderID: number}> = ({orderID}) => {
  const headers = useMemo(() => ['Reason', 'Changes', 'Created at', ''], []);
  const {email, username} = usePortalsStore().selectors.orders.orderInPortal;
  const {revisions, reloadRevisions} = useOrderRevisions(orderID);
  const [selectedRevision, setSelectedRevision] = useState<OrderRevision>();
  const {setRecipient} = useQuickResponse();
  const ShowNewRevision = useBooleanState();
  const ShowRevisionEditor = useBooleanState();
  const handleSelectRevision = useCallback((revision: OrderRevision) => {
    ShowRevisionEditor.turnOn();
    setSelectedRevision(revision);
  }, []);
  const sendAnswerHandler = useCallback(() => {
    setRecipient({
      name: username,
      email,
      subject: 'Revision',
      sendType: 'revision',
    });
  }, []);
  return (
    <Div flexDirection="column">
      <ElementHeadline title="Revisions" disableMarker onCreateNew={ShowNewRevision.turnOn} />
      <TableContainer headers={headers} isLoading={false} isSmall>
        {revisions.length > 0 &&
          revisions.map((rev, i) => (
            <TRow key={i}>
              <TCell>
                <Text type="Text3" color="Alert" fontWeight={800}>
                  {rev.reason}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3" fontWeight={800}>
                  {rev.changes}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3" color="Success" fontWeight={800}>
                  {dateToLocalDate(rev.created_at)}
                </Text>
              </TCell>
              <TCell>
                <ContextMenu
                  options={[
                    {option: 'Edit', onSelect: () => handleSelectRevision(rev)},
                    {option: 'Send answer', onSelect: sendAnswerHandler},
                  ]}
                />
              </TCell>
            </TRow>
          ))}
      </TableContainer>
      {ShowNewRevision.isTurn && (
        <NewRevision
          isShow={ShowNewRevision.isTurn}
          onClose={ShowNewRevision.turnOff}
          onChange={reloadRevisions}
        />
      )}
      {selectedRevision && ShowRevisionEditor.isTurn && (
        <RevisionEditor
          isShow={ShowRevisionEditor.isTurn}
          onClose={ShowRevisionEditor.turnOff}
          onChange={reloadRevisions}
          revision={selectedRevision}
        />
      )}
    </Div>
  );
};
export default RevisionsTable;
