import {API} from '@/API/API';
import {TalentPortfolioType} from '@/API/Talents/types';
import {useApiLoader} from '@/Hooks';
import {useEffect, useState} from 'react';

const useTalentPortfolio = (talentId?: string) => {
  const [portfolio, setPortfolio] = useState<TalentPortfolioType>();
  const getPortfolio = useApiLoader(async () => {
    if (talentId) {
      await API.Talents.getTalentPortfolio(talentId).then(res => setPortfolio(res));
    }
  }, [talentId]);
  useEffect(() => {
    void getPortfolio();
  }, [getPortfolio]);
  return {
    portfolio,
  };
};
export default useTalentPortfolio;
