import {PromoCode} from '@/API/Orders/types';
import {useReduceState} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {Button, Div, ElementHeadline, Input, Modal, Select} from '@/UI';
import {useEffect, useMemo, useState} from 'react';

interface Props {
  promoCode?: PromoCode;
  onUpdate: (promoCode?: PromoCode) => void;
}
const OrderPromoCode: React.FC<Props> = ({promoCode, onUpdate}) => {
  const ShowPromoCode = useBooleanState();
  const [promoCodeState, setPromoCodeState] = useState<PromoCode>({code: 'Code', discount: 0, type: '$'});
  const discountTypeOptions = useMemo(
    () => [
      {label: '$', value: '$'},
      {label: '%', value: '%'},
    ],
    []
  );
  const handleInputsChange = useReduceState(setPromoCodeState);
  useEffect(() => {
    if (promoCode) setPromoCodeState(promoCode);
  }, [promoCode]);

  return (
    <Div margin="20px 0">
      <Button size="medium" color={promoCode?.code ? 'Success' : 'Alert'} onClick={ShowPromoCode.turnOn}>
        {promoCode?.code ? `PROMO CODE: ${promoCode?.code}` : 'ADD PROMO CODE'}
      </Button>
      {ShowPromoCode.isTurn && (
        <Modal isShowModal={true}>
          <ElementHeadline
            onClose={ShowPromoCode.turnOff}
            onSave={() => {
              onUpdate(promoCodeState);
              ShowPromoCode.turnOff();
            }}
            title="Promo code: "
          />
          <Div flexDirection="column" width={250}>
            <Input
              placeholder="Promo code"
              value={promoCodeState.code}
              onChange={e => handleInputsChange('code', e.target.value)}
            />
            <Input
              placeholder="Discount"
              type={'number'}
              value={promoCodeState.discount}
              onChange={e => handleInputsChange('discount', e.target.value)}
            />
            <Select
              label="Discount type"
              options={discountTypeOptions}
              value={promoCodeState.type}
              onChange={value => handleInputsChange('type', value)}
              defaultPlaceholder={promoCodeState.type}
            />
          </Div>
        </Modal>
      )}
    </Div>
  );
};
export default OrderPromoCode;
