import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';
import {Employee, EmployeeBase, EmployeeCreatePayload} from '@/API/Security/Employees/types';

class UsersApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async deleteTalentByID(talentID: string): Promise<any> {
    return await this.api.delete('/delete/' + talentID).then(res => res.data);
  }
  async getEmployees(): Promise<Employee[]> {
    return await this.api.get('/employees').then(res => res.data);
  }
  async resetUserPassword(resetParams: {userId: string; password: string}) {
    return await this.api
      .post('users-api/reset-password', resetParams, {
        baseURL: URL.ADMIN_MS,
      })
      .then(res => res.data);
  }
  async createEmployee(payload: EmployeeCreatePayload): Promise<any> {
    return await this.api
      .post(
        'users-api/create-employee',
        {
          userDto: payload,
        },
        {baseURL: URL.ADMIN_MS}
      )
      .then(res => {
        return res;
      });
  }
  async getEmployeeByEmail(email: string): Promise<EmployeeBase> {
    return await this.api
      .get('/employee', {
        params: {
          email,
        },
      })
      .then(res => res.data);
  }
  async deleteVideo(talentID: string, key?: string) {
    if (key) {
      return await this.api.delete('/Video', {
        params: {
          id: talentID,
          key: key,
        },
      });
    }
  }
  async uploadVideo(talentId: string, video: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', video);
    return await this.api
      .post('/Video/?id=' + talentId, formData)
      .then(res => res.status === 201 && res.data);
  }
  async uploadPhoto(talentId: string, photo: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', photo);
    return await this.api
      .post('/Avatar', formData, {
        params: {
          id: talentId,
        },
      })
      .then(res => res.data);
  }
  async deletePhoto(talentID: string, key?: string): Promise<any> {
    if (key) {
      return await this.api
        .delete(`/Avatar`, {
          params: {
            id: talentID,
            key: key,
          },
        })
        .then(res => res.data);
    }
  }
}
export default UsersApi;
