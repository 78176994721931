import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CreateTransactionDTO} from '@/API/Wallet/types';
import {useCommunityStore} from '@/Store/community/community.selector';

const useCreateWalletOperation = (payload: Omit<CreateTransactionDTO, 'userID'>, onCreate: Function) => {
  const {
    storedUser: {userID},
  } = useCommunityStore().selectors;
  return useApiLoader(
    async () => {
      await API.Wallet.createWalletOperation({
        ...payload,
        userID: userID,
      }).finally(() => onCreate());
    },
    [payload, userID],
    {successMsg: 'Successfully Created'}
  );
};
export default useCreateWalletOperation;
