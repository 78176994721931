import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {PreOrderSearchParams, PreOrderTalentType} from '@/API/Campaign/types';
import {useEffect, useState} from 'react';

const usePreOrderTalents = (params?: PreOrderSearchParams) => {
  const [preOrders, setPreOrders] = useState<PreOrderTalentType[]>([]);
  const fetch = useApiLoader(async () => {
    if (params) {
      await API.Campaigns.getPreOrderTalents(params).then(res => setPreOrders(res));
    }
  }, [params]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    reloadPreOrders: fetch,
    preOrders,
  };
};
export default usePreOrderTalents;
