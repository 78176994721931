import {TalentPortfolioType} from '@/API/Talents/types';
import {Avatar, Div, Grid, Text} from '@/UI';
import {memo} from 'react';

type Props = Omit<TalentPortfolioType, 'materials'> & {};
const TalentPortfolioAbout: React.FC<Props> = props => {
  const {fullname, avatar, bio} = props;
  return (
    <Grid columnGap={20} templateColumn="200px 1fr">
      <Avatar image={avatar} size="xxl" borderColor="BlueLight" />
      <Div flexDirection="column" gap={20}>
        <Div flexDirection="column">
          <Text type="Text1" color="MediumGray">
            Full name:
          </Text>
          <Text color="BlackOcean" fontWeight={800} type="Header3">
            {fullname}
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text1" color="MediumGray">
            Bio:
          </Text>
          <Text color="BlackOcean" type="Text2">
            {bio}
          </Text>
        </Div>
      </Div>
    </Grid>
  );
};
export default memo(TalentPortfolioAbout);
