import WithPortal from '@/HOC/WithPortal';
import {Curtain} from '@/UI';
import {memo} from 'react';
import SubscriptionPlan from './SubscriptionPlan';
interface Props {
  onClose: Function;
}
const SubscriptionCurtain: React.FC<Props> = ({onClose}) => {
  return (
    <WithPortal>
      <Curtain
        headLineProps={{disableMarker: true, onClose, title: 'Subscriptions'}}
        curtainConfig={{
          width: '300px',
          height: '100vh',
          top: '0px',
          right: '0px',
          disableClickOut: true,
          disableAnimation: true,
        }}
      >
        <SubscriptionPlan />
      </Curtain>
    </WithPortal>
  );
};
export default memo(SubscriptionCurtain);
