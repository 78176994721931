import {Avatar, Text} from '@/UI';
import {AvatarProps} from '@/UI/Avatar/Avatar';
import {memo} from 'react';
import SAvatarCard from './avatarCard.styled';

interface Props {
  avatar: AvatarProps;
  onAvatarClick?: any;
  talentName: string;
}
const AvatarCard: React.FC<Props> = ({avatar, onAvatarClick, talentName}) => {
  return (
    <SAvatarCard className="AvatarCard" onClick={onAvatarClick}>
      <Avatar lazy={true} size="big" borderColor="SuccessLight" {...avatar} />
      <Text type="Text3" className="talent-name">
        {talentName}
      </Text>
    </SAvatarCard>
  );
};
export default memo(AvatarCard);
