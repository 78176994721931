import styled, {css} from 'styled-components';
import {memo} from 'react';

const SOrderInfo = styled.div`
  ${({theme}) => css`
    position: relative;
    padding: 15px;
    background-color: ${theme.Color.White};
    border-radius: 0 15px 15px;
    display: flex;
    flex-direction: column;

    .utm-icon {
      height: 20px;
      fill: ${theme.Color.Success};
      cursor: pointer;
    }
    .on-hover {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .order-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .order-description {
        height: 90px;
        position: relative;
        overflow-y: auto;
        min-width: 100%;
        word-break: break-all;
        ${theme.Presets.scroll}
      }
    }
  `}
`;

export default memo(SOrderInfo);
