const AudienceReportService = {
  convertWeight: (number: number | string) => {
    let res = '0';
    try {
      res = (Number(number) * 100).toFixed(1);
    } catch (err) {}
    return res;
  },
};
export default AudienceReportService;
