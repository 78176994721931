import {createCustomDate} from '@/Services/date.service';

const DatePickerService = {
  datePeriodSelect: [
    {label: '-', value: ''},
    {label: 'Today', value: createCustomDate({date: new Date()})},
    {label: 'Last 7 days', value: createCustomDate({date: new Date(), days: -7})},
    {label: 'Last 30 days', value: createCustomDate({date: new Date(), days: -30})},
    {label: 'Last 90 days', value: createCustomDate({date: new Date(), days: -90})},
  ],
};

export default DatePickerService;
