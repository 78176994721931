import {HelpRequest, HelpRequestSearchParams} from '@/API/HelpRequests/types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class HelpRequestApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getHelpRequests(params: HelpRequestSearchParams): Promise<HelpRequest[]> {
    return await this.api
      .get('/help-requests', {
        params,
      })
      .then(res => res.data);
  }
  async updateHelpRequest(helpRequest: HelpRequest) {
    return await this.api.put('/help-requests', helpRequest).then(res => res.data);
  }
}

export default HelpRequestApi;
