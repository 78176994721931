import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteFinancialDoc = (talentId: string, onDeleteCallback: Function) => {
  return useApiLoader(
    async (docID: number) => {
      await API.Wallet.deleteFinancialDoc({docID, talentId}).then(() => onDeleteCallback());
    },
    [],
    {successMsg: 'Deleted'}
  );
};
export default useDeleteFinancialDoc;
