import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CampaignDetailsType} from '@/API/Campaign/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useEffect, useState} from 'react';
import {SelectService} from '@/Services/select.service';

export const useCampaignUpdate = (campaign: CampaignDetailsType | undefined, callBack?: Function) => {
  const {
    campaignPortal: {campaignPortalCallBack},
  } = usePortalsStore().selectors;
  return useApiLoader(
    async () => {
      await API.Campaigns.updateCampaign({
        ...campaign,
        countries: SelectService.convertSelectOptionsToValuesArray(campaign?.countries),
        social_networks: SelectService.convertSelectOptionsToLabelsArray(campaign?.social_networks),
        audience: campaign?.audience || [13, 65],
      } as CampaignDetailsType).then(() => {
        callBack && callBack();
        campaignPortalCallBack && campaignPortalCallBack();
      });
    },
    [campaign],
    {successMsg: 'Campaign updated'}
  );
};

export const useStoredCampaign = () => {
  const [campaign, setCampaign] = useState<CampaignDetailsType>();
  const {
    campaignPortal: {campaignPortalID},
  } = usePortalsStore().selectors;
  const fetchCampaign = useApiLoader(
    async () =>
      campaignPortalID &&
      (await API.Campaigns.getCampaign(campaignPortalID).then(res => {
        setCampaign({
          ...res,
          countries: SelectService.convertCountryCodeArrayToSelectValue(res.countries),
          social_networks: SelectService.convertStringArrayToSelectValue(res.social_networks),
          age_range: SelectService.convertStringArrayToSelectValue(res.age_range),
        });
      })),
    [campaignPortalID]
  );
  useEffect(() => {
    void fetchCampaign();
  }, [campaignPortalID]);
  return {campaign, setCampaign, reloadCampaign: fetchCampaign};
};
