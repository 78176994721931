import {Curtain, Grid} from '@/UI';
import {memo} from 'react';
import styled from 'styled-components';
import {useCampaignSummary} from '@/Hooks/Campaigns';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import BudgetSpent from '@/Website/Components/Portals/Campaign/CampaignSummary/BudgetSpent/BudgetSpent';
import InvitationsChart from '@/Website/Components/Portals/Campaign/CampaignSummary/InvitationsChart/InvitationsChart';
import PostStats from '@/Website/Components/Portals/Campaign/CampaignSummary/PostsStats/PostStats';
import CampaignPosts from '@/Website/Components/Portals/Campaign/CampaignSummary/CampaignPosts/CampaignPosts';

const SCampaignSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

interface CampaignSummaryProps {
  onClose: Function;
}
const CampaignSummary: React.FC<CampaignSummaryProps> = ({onClose}) => {
  const {campaignPortalID} = usePortalsStore().selectors.campaignPortal;
  const {summary} = useCampaignSummary(campaignPortalID);
  return (
    <Curtain
      curtainConfig={{
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        disableAnimation: true,
        disableClickOut: true,
      }}
      headLineProps={{title: `Campaign: ${summary?.title}`, onClose: onClose}}
    >
      {summary && (
        <SCampaignSummary>
          <Grid templateColumn="250px 1fr" columnGap={20} templateRow="250px">
            <BudgetSpent totalBudget={summary.budget || 0} spentBudget={summary.budget_spent || 0} />
            <InvitationsChart orders={summary.orders_statuses} />
          </Grid>

          <PostStats stats={summary.orders_stats} />
          <CampaignPosts posts={summary.talent_posts} />
        </SCampaignSummary>
      )}
    </Curtain>
  );
};
export default memo(CampaignSummary);
