import styled, {css} from 'styled-components';

export const Component = styled.div<{isUploadedMedia: boolean}>`
  ${({theme, isUploadedMedia}) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 150px;
    height: 225px;
    background-color: #000000;
    gap: 0px;
    overflow: hidden;
    border-radius: 15px;
    .label {
      font-size: 0.6rem;
      position: absolute;
      font-family: ${theme.FontFamily.Outfit};
      z-index: 1;
      font-weight: 600;
      margin-top: 3px;
      margin-left: 8px;
    }
    .media-wrapper {
      width: 150px;
      height: 200px;
      video {
        height: 100%;
        width: 100%;
        background-color: #000000;
      }
      .uploaded-photo {
        object-fit: contain;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }
    .buttons-group {
      display: flex;
      justify-content: space-between;
      div:first-child {
        border-bottom-left-radius: 4px;
      }
      .upload-container {
        border-bottom-right-radius: 4px;
        span {
          font-family: ${theme.FontFamily.Outfit};
        }
        ${!isUploadedMedia &&
        css`
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 0;
          height: 100%;
          width: 100%;
        `}
      }
      div {
        flex-basis: 50%;
        background-color: #444444;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        cursor: pointer;
        transition: background-color 0.2s ease;
        &:hover {
          color: white;
          background-color: black;
          .icons-control {
            filter: ${theme.SVGColor.White};
          }
        }
        .icons-control {
          height: ${!isUploadedMedia ? '40px' : '20px'};
          filter: ${theme.SVGColor.MediumGray};
        }
      }
    }
    .upload-input-media {
      position: absolute;
      visibility: hidden;
    }
  `}
`;
