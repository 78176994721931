import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 5px 10px 20px;
    position: relative;
    width: 100%;
    height: 100%;
    //animation: ${theme.Animations.slideFromTop} 0.2s linear;
    .header-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      svg {
        height: 50px;
        width: 50px;
        margin-right: 10px;
      }
    }
    .page-outlet {
      position: relative;
      height: 100%;
      .table-page {
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-shadow: none !important;
      }
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border-left: 0;
        border-right: 0;
        background-color: ${theme.Color.MediumGray};
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 0;
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 0;
      }
    }
  `}
`;
