import {memo, useCallback} from 'react';
import {Div, ElementHeadline, Modal, Text} from '@/UI';
import {useBooleanState} from '@/Hooks/useBooleanState';
import versions from './versions.json';
import SProjectVersion from './projectVersion.styled';
import {useCheckAppVersion} from '@/Hooks';

const ProjectVersion = () => {
  const {appVersion, isChecked, check} = useCheckAppVersion();
  const ShowDescriptions = useBooleanState();

  const handleOpen = useCallback(() => {
    check();
    ShowDescriptions.toggle();
  }, [check, ShowDescriptions]);
  return (
    <SProjectVersion className="ProjectVersion" isChecked={isChecked}>
      <Text cursor="pointer" type="Text3" className="app-version" onClick={handleOpen}>
        {appVersion}
      </Text>
      <Modal isShowModal={ShowDescriptions.isTurn} onClose={ShowDescriptions.turnOff}>
        <ElementHeadline title={`History of changes`} disableMarker />
        <div className="description-text">
          {versions.map((version, i) => (
            <Div flexDirection="column" key={i} gap={10}>
              <Text color="Success" type="Text2" italic fontWeight={800}>
                # {version.app_version}
              </Text>
              {version.changes.map((change, i) => (
                <Div flexDirection="column" key={i}>
                  <Text type="Text3" color="Orange" fontWeight={800}>
                    - {change.title}
                  </Text>
                  <Text type="Text3">{change.text}</Text>
                </Div>
              ))}
            </Div>
          ))}
        </div>
      </Modal>
    </SProjectVersion>
  );
};
export default memo(ProjectVersion);
