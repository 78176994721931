import {memo} from 'react';
import styled, {css} from 'styled-components';

const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-self: stretch;
    flex: 1;
    .attachment-list {
      height: 140px;
      overflow: auto;
      ${theme.Presets.scroll}
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    .attachment {
      padding: 3px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid ${theme.Color.GrayLight};
      &:hover {
        cursor: pointer;
      }
      .x-delete {
        padding: 0 4px;
        border: 1px solid white;
        &:hover {
          border: 1px solid ${theme.Color.Alert};
        }
      }
    }
    .upload {
      border-left: 1px solid ${theme.Color.GrayLight};
      padding: 0 10px;
      flex-basis: 200px;
    }
  `}
`;

export default memo(Component);
