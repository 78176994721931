export const EmailTemplateService = {
  generateEmail: (params: {description?: string; type: 'campaign' | 'reminder'}) => {
    const {type, description} = params;
    if (type === 'campaign') {
      return `Act as an influencers manager. For the following brand overview generate an email I will send to influencers inviting them to work on the brand's campaign. 
      When introducing yourself, say you are from the influencers department at Letstok. 
      Overview: ${description}`;
    }
    if (type === 'reminder') {
      return `Act as an influencers manager. For the following brand overview generate a reminder email I will send to influencers that I already invited to work on the brand's campaign but didn't replied back yet. 
      When introducing yourself, say you are from the influencers department at Letstok. 
      Overview: ${description}`;
    }
  },
};
