import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useAppSelector} from '@/Hooks';
import {AppDispatchType} from '../types';
import {communityActions} from './community.slice';

const useCommunitySelector = () => {
  return useAppSelector(state => state.community);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(communityActions, dispatch);
};

export const useCommunityStore = () => {
  return {
    actions: useActions(),
    selectors: useCommunitySelector(),
  };
};
