import {Button, ElementHeadline, Input, Modal} from '@/UI';
import {useCallback, useState} from 'react';
import {useCommunityStore} from '@/Store/community/community.selector';
import {useChangeUserPassword} from '@/Website/Components/Talent/Hooks/useChangeUserPassword';
import useConfirmation from '../../../../Hooks/useConfirmation';

const ChangeUserPassword = () => {
  const {
    storedUser: {userID},
    isShowResetPassword,
  } = useCommunityStore().selectors;
  const {setIsShowResetPassword} = useCommunityStore().actions;
  const [password, setPassword] = useState<string>('');
  const handleChangeInput = useCallback((e: any) => setPassword(e.target.value), [setPassword]);
  const resetPassword = useChangeUserPassword(userID, password);
  const handleUpdatePassword = useConfirmation(resetPassword, 'Change password?');

  return (
    <Modal isShowModal={isShowResetPassword}>
      <ElementHeadline title="Changing password" onClose={() => setIsShowResetPassword(false)} />
      <Input placeholder="New password" value={password} onChange={handleChangeInput} />
      <Button
        color="BlackOcean"
        style={{marginTop: 20}}
        onClick={() => handleUpdatePassword()}
        isDisabled={password.length < 6}
      >
        CHANGE
      </Button>
    </Modal>
  );
};
export default ChangeUserPassword;
