import {ReactComponent as ReportIcon} from '@/Assets/Icons/doc.svg';
import {useReduceState} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {SocialService, SocialsType} from '@/Services/social.service';
import {ElementHeadline, Input, SocialIcon, Switch, TableContainer, TCell, TRow} from '@/UI';
import {SelectOptionsType} from '@/UI/Select/select.styled';
import useOpenTalentReport from '@/Website/Components/Portals/TalentReport/Hooks/useOpenTalentReport';
import {useTalentReports} from '@/Website/Components/Talent/Hooks';
import NewTalentInfo from '@/Website/Components/Talent/TalentSocial/NewTalentInfo/NewTalentInfo';
import SearchInIQData from '@/Website/Components/Talent/TalentSocial/SearchInIQData/SearchInIQData';
import {useCallback, useMemo} from 'react';
import {TALENT_CONSTANTS} from '../talent.service';
import {TalentDetailProps} from '../types';
import {Component} from './talentSocial.styled';

const TalentSocial: React.FC<TalentDetailProps> = ({talent, setTalent}) => {
  const {socials} = useMemo(() => TALENT_CONSTANTS, []);
  const ShowNewTalentInfo = useBooleanState();
  const ShowSearchInIQData = useBooleanState();
  const openReport = useOpenTalentReport();

  const {iqReportsState, reloadIqReports} = useTalentReports(talent.user_id);

  const inputChangeHandler = useReduceState(setTalent);
  /** IS CREATOR HANDLING */
  const handleSwitchIsSocialCreator = useCallback(
    (isCreator: boolean, social: SocialsType) => {
      const platforms = talent.creation_platforms?.length ? [...talent.creation_platforms] : [];
      if (isCreator && !platforms.includes(social)) platforms.push(social);
      if (!isCreator && platforms.includes(social)) {
        platforms.splice(platforms.indexOf(social), 1);
      }
      inputChangeHandler('creation_platforms', platforms);
    },
    [talent.creation_platforms]
  );
  const isCreator = useCallback(
    (social: SocialsType) => !!talent.creation_platforms?.includes(social),
    [talent.creation_platforms]
  );
  /** */
  const socialsToCreateNewTalentInfo = useMemo(() => {
    const existsSocials = iqReportsState ? Object.keys(iqReportsState) : [];
    const availableSocialsToCreate = socials.filter(el => !existsSocials.includes(el.value));
    /** Build select options with value = talent socials, based on talent info socials that not created yet */
    return availableSocialsToCreate.map(el => ({
      label: el.label,
      value: el.label,
      url: talent[el.value],
    }));
  }, [iqReportsState, socials, talent]);
  /** */
  const socialsToIQDataSearch = useMemo(() => {
    const options: SelectOptionsType = [{label: '_', value: ''}];
    socialsToCreateNewTalentInfo.forEach(el => {
      if (el.url)
        options.push({
          label: el.label,
          value: el.value + ',' + el.url,
        });
    });
    return options;
  }, [socialsToCreateNewTalentInfo]);
  const isHasReport = useCallback(
    (socialType: string) => {
      if (!iqReportsState || !Object.hasOwn(iqReportsState, socialType)) return false;
      return true;
    },
    [iqReportsState]
  );

  return (
    <Component className="TalentSocial tab-preset">
      <ElementHeadline
        title="Talent Socials"
        disableMarker
        options={[{option: 'Refresh', onSelect: reloadIqReports}]}
        onCreateNew={ShowNewTalentInfo.turnOn}
        onSearch={socialsToIQDataSearch.length > 1 ? ShowSearchInIQData.turnOn : undefined}
      />
      <TableContainer headers={['Social', 'Report', 'Username', 'Is Creator']} isSmall>
        {socials.map(({value: social, label}, i) => (
          <TRow key={i}>
            <TCell>
              <SocialIcon
                onClick={() =>
                  talent[social] &&
                  window.open(SocialService.validateSocialLink(social, talent[social]), '_blank')
                }
                size="big"
                isActive
                social={social}
              />
            </TCell>
            <TCell>
              <ReportIcon
                onClick={() =>
                  openReport({
                    social: social,
                    talentID: talent.user_id,
                  })
                }
                className={`report-icon ${!isHasReport(social) ? `non-active-report` : ``}`}
              />
            </TCell>
            <TCell>
              <Input
                placeholder={social}
                value={talent[social] || ''}
                // if the social already has a report , then need to disable input
                // disabled={iqReportsState && Object.hasOwn(iqReportsState, socialsType)}
                onChange={e => inputChangeHandler(social, e.target.value)}
              />
            </TCell>
            <TCell>
              <Switch
                isOn={isCreator(social)}
                onToggle={value => handleSwitchIsSocialCreator(value, social)}
                option=""
              />
            </TCell>
          </TRow>
        ))}
      </TableContainer>

      {ShowNewTalentInfo.isTurn && (
        <NewTalentInfo
          isShow={ShowNewTalentInfo.isTurn}
          onChange={reloadIqReports}
          onClose={ShowNewTalentInfo.turnOff}
          socials={socialsToCreateNewTalentInfo}
          talent={talent}
        />
      )}
      {ShowSearchInIQData.isTurn && (
        <SearchInIQData
          isShow={ShowSearchInIQData.isTurn}
          onClose={ShowSearchInIQData.turnOff}
          onChange={reloadIqReports}
          options={socialsToIQDataSearch}
          talentID={talent.user_id}
        />
      )}
    </Component>
  );
};
export default TalentSocial;
