import {memo, useCallback, useMemo, useState} from 'react';
import SOrderMaterials from './orderMaterials.styled';
import {Box, Button, Div, ElementHeadline, InputFile, TableContainer, TCell, Text, TRow} from '@/UI';
import {useDeleteOrderMaterials, useOrderMaterials, useUploadOrderMaterials} from '../Hooks';
import {useOpenMedia} from '@/Hooks';
import {dateToLocalDate} from '@/Services/date.service';

const OrderMaterials: React.FC<{orderID: number}> = ({orderID}) => {
  const {materialsList, refreshMaterials} = useOrderMaterials(orderID);
  const openMedia = useOpenMedia();
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const headers = useMemo(() => ['id', 'File', 'Created', 'Status', ''], []);

  const handleSelectFile = useCallback((files: File[]) => setUploadedFile(files), []);
  const handleUploadFile = useUploadOrderMaterials({orderID, materials: uploadedFile}, refreshMaterials);
  const handleDeleteVideo = useDeleteOrderMaterials(orderID, refreshMaterials);

  return (
    <SOrderMaterials className="OrderMaterials order-tab-preset">
      <ElementHeadline title="List materials" disableMarker />
      <Div gap={20} alignItems="baseline">
        <InputFile label={'Select file'} accept="all" multiple onUpload={handleSelectFile} />
        {uploadedFile.length > 0 && (
          <Button color="Success" size="small" onClick={handleUploadFile}>
            Upload
          </Button>
        )}
      </Div>

      {materialsList && materialsList.length > 0 && (
        <Box className="files" label="Files">
          <TableContainer headers={headers} isSmall isLoading={false}>
            {materialsList.map((file, i) => (
              <TRow key={i}>
                <TCell>{i + 1}</TCell>
                <TCell>
                  <Text color="Blue" type="Text3" cursor="pointer" onClick={() => openMedia(file.video)}>
                    {file.video}
                  </Text>
                </TCell>

                <TCell>
                  <Text type="Text3" color="Success" fontWeight={800}>
                    {dateToLocalDate(file.created_at)}
                  </Text>
                </TCell>
                <TCell>
                  <Text type="Text3" color="Orange" fontWeight={800}>
                    {file.active && 'Active'}
                  </Text>
                </TCell>
                <TCell>
                  {' '}
                  <Button color="Alert" size="small" onClick={() => handleDeleteVideo(file.video)}>
                    Delete
                  </Button>
                </TCell>
              </TRow>
            ))}
          </TableContainer>
        </Box>
      )}
    </SOrderMaterials>
  );
};
export default memo(OrderMaterials);
