//TABLE TO EXCEL with table ID arg

// export const exportToExl = (table: any) => {
//   const wb = XLSX.utils.table_to_book(document.getElementById(table));
//   XLSX.writeFile(wb, "SheetJSTable.xlsx");
// };

//OBJECT TO EXL
import * as XLSX from 'xlsx';

class ExportService {
  static exportToExl(data: any) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, 'UnnamedExcel.xlsx');
  }
}

export default ExportService;
