import {ITalentReportDTO} from '@/API/TalentInfo/types';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {useCommunityStore} from '@/Store/community/community.selector';
import {Curtain, Div} from '@/UI';
import {
  useDeleteReport,
  useDownloadIQDataReport,
  useToggleReportActive,
} from '@/Website/Components/Portals/TalentReport/Hooks';
import TalentReportEditor from '@/Website/Components/Portals/TalentReport/TalentReportEditor/TalentReportEditor';
import {ReactNode, useMemo} from 'react';

interface Props {
  children: ReactNode;
  report: ITalentReportDTO;
  onReportChange: Function;
}
const TalentReportCurtain: React.FC<Props> = props => {
  const {children, report, onReportChange} = props;
  const ShowEditor = useBooleanState();
  const {closeTalentReport} = useCommunityStore().actions;
  const isActive = report.active === null || report.active === true;
  const toggleIsActiveTalent = useToggleReportActive(report.id, !isActive, onReportChange);
  const deleteReport = useDeleteReport(report.id);
  const downloadReport = useDownloadIQDataReport(
    `${report.social_platform},${report.url}`,
    report.user_id,
    onReportChange
  );
  const headingOptions = useMemo(
    () => [
      {option: 'Edit', onSelect: ShowEditor.turnOn},

      {
        option: isActive ? 'Deactivate' : 'Activate',
        onSelect: toggleIsActiveTalent,
      },
      {option: 'Refresh page', onSelect: onReportChange},
      {option: 'Update from IQData', onSelect: downloadReport},
      {option: 'Delete report', onSelect: deleteReport},
    ],
    [isActive, toggleIsActiveTalent]
  );
  return (
    <Curtain
      headLineProps={{
        disableMarker: true,
        onClose: closeTalentReport,
        title: `${report.full_name}`,
        options: headingOptions,
      }}
      curtainConfig={{
        disableAnimation: true,
        disableClickOut: true,
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
      }}
    >
      <Div flexDirection="column" gap={45}>
        {children}
      </Div>
      <TalentReportEditor
        isShow={ShowEditor.isTurn}
        onClose={ShowEditor.turnOff}
        onChange={onReportChange}
        info={report}
      />
    </Curtain>
  );
};
export default TalentReportCurtain;
