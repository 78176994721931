import {useRootStore} from '@/Store/root/root.selector';
import {useCallback} from 'react';
import LoaderService from '@/Services/loader.service';
import {HttpHelper} from '@/Config/Axios/http.statuses';
import useLogout from '@/Hooks/Auth/useLogout';
interface Options {
  successMsg?: string;
  errorMsg?: string;
  disableLoader?: boolean;
}
/** @async WORKS ON useCallBack basis */
/** @returns Promise */

const useApiLoader = <TArgs extends any[], TResult = unknown>(
  promise: (...args: TArgs) => Promise<TResult>,
  depends: Array<any>,
  options?: Options
): ((...args: TArgs) => Promise<TResult>) => {
  const {setNotification} = useRootStore().actions;
  const isDisableLoader = !!options?.disableLoader;
  const logout = useLogout();

  type CallbackType = (...args: TArgs) => Promise<TResult>;

  return useCallback<CallbackType>(
    //@ts-ignore
    async (...args: TArgs) => {
      if (!isDisableLoader) LoaderService.setLoading(true);

      return await promise(...args)
        .then(res => {
          options?.successMsg && setNotification({message: options.successMsg});
          return res;
        })

        .catch(err => {
          console.log(err);
          if (HttpHelper.isAuthError(err?.response?.status)) {
            logout();
          } else {
            const errMsg = JSON.stringify(err?.response?.data?.message || 'Error');
            setNotification({message: errMsg, type: 'failed'});
          }
        })

        .finally(() => LoaderService.setLoading(false));
    },
    [...depends]
  );
};

export default useApiLoader;
