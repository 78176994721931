import {useMemo} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import STextEditor from './textEditor.styled';
import {Text} from '@/UI';

interface TextEditorProps {
  value: any;
  onChange: any;
  label?: string;
  height?: string;
  width?: string;
}
/** @toolbarOptions = [
 ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
 ['blockquote', 'code-block'],
 ['link', 'image', 'video'],
 [{ 'header': 1 }, { 'header': 2 }],               // custom button values
 [{ 'list': 'ordered'}, { 'list': 'bullet' }],
 [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
 [{ 'indent': '-1'}, { 'indent': '+1' }],          // out dent/indent
 [{ 'direction': 'rtl' }],                         // text direction

 [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
 [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

 [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
 [{ 'font': [] }],
 [{ 'align': [] }],

 ['clean']                                         // remove formatting button
 ];
 *  */

const TextEditor: React.FC<TextEditorProps> = props => {
  const {value, onChange, width, label, height} = props;
  const modules = useMemo(
    () => ({
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'link'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{size: ['small', false, 'large', 'huge']}],
        [{color: []}, {background: []}],
        [{align: []}],
        ['clean'],
      ],
    }),
    []
  );
  return (
    <STextEditor width={width} height={height}>
      <Text type="Text4">{label || 'Template'}</Text>
      <ReactQuill
        className="react-quill"
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        placeholder="Your text"
      />
    </STextEditor>
  );
};
export default TextEditor;
