import {Box} from '@/UI';
import styled from 'styled-components';

export const Component = styled(Box)`
  display: flex;
  border-top-left-radius: 0;
  box-shadow: none;
  gap: 5px;
  justify-content: space-between;
  height: 100%;
  padding: 15px;
`;
