import {useCallback} from 'react';
import {OrderType} from '@/API/Orders/types';
import {Avatar, Box, Div, SocialIcon, Text} from '@/UI';
import {useOpenTalent} from '@/Hooks';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import ColorService from '@/Services/color.service';
import {SocialsType} from '@/Services/social.service';

const OrderInfoHeader: React.FC<{order: OrderType}> = ({order}) => {
  const {orderPortalCallBack} = usePortalsStore().selectors.orders;
  const openTalent = useOpenTalent();
  const {setIsShowPortalOrder} = usePortalsStore().actions;
  const closeModal = useCallback(() => setIsShowPortalOrder(false), [setIsShowPortalOrder]);
  return (
    <Box style={{marginBottom: 10}} backgroundColor="Lime">
      <Div justifyContent="space-between" alignItems="flex-end">
        <Div gap={10}>
          <Avatar
            onClick={() => {
              openTalent(order.talent, orderPortalCallBack);
              closeModal();
            }}
            image={order.avatar}
            size="big+"
            borderColor={ColorService.getColorTypeByStatus(order.status)}
          />
          <Div flexDirection="column" justifyContent="space-evenly">
            <Div gap={5}>
              {order.social_platform && <SocialIcon isActive social={order.social_platform as SocialsType} />}
              <Text type="Text1" fontWeight={800}>
                {order.email}
              </Text>
            </Div>
            <Text type="Text3" color="MediumGray">
              {order.talent}
            </Text>
          </Div>
        </Div>

        <Div gap={50}>
          <Div flexDirection="column">
            <Text type="Text1">Price: </Text>
            <Text fontWeight={800} type={'Header4'} color="Orange">
              ${order.talent_price}
            </Text>
          </Div>
          <Div flexDirection="column">
            <Text type="Text1">Adv price: </Text>
            <Text fontWeight={800} type={'Header4'} color="Purple">
              ${order.advertiser_price || 0}
            </Text>
          </Div>
          <Div flexDirection="column">
            <Text type="Text1">Paid: </Text>
            <Text color={order.paid ? 'Success' : 'Alert'} type="Header4" fontWeight={800}>
              {order.paid ? 'YES' : 'NO'}
            </Text>
          </Div>
        </Div>
      </Div>
    </Box>
  );
};
export default OrderInfoHeader;
