import {OrderStatDetailedType, OrderStatType} from '@/API/Orders/types';
import {useReduceState} from '@/Hooks';
import {Div, ElementHeadline, Input, Modal} from '@/UI';
import {useMemo} from 'react';
import {useDeleteOrderStats, useUpdateOrderStat} from '../../Hooks';

type EditStatProps = {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
  stat: OrderStatDetailedType;
  setStat: Function;
};

const EditStat: React.FC<EditStatProps> = ({isShow, onChange, onClose, stat, setStat}) => {
  const updateStat = useUpdateOrderStat(stat, () => {
    onClose();
    onChange();
  });

  const handleChangeState = useReduceState<OrderStatDetailedType>(
    setStat as React.Dispatch<React.SetStateAction<OrderStatDetailedType>>
  );
  const deleteStat = useDeleteOrderStats(stat.id, () => {
    onClose();
    onChange();
  });
  const INPUTS_VALUES = useMemo(
    () =>
      [
        {placeholder: 'Impressions', field: 'impressions_num'},
        {placeholder: 'Likes', field: 'likes_num'},
        {placeholder: 'Comments', field: 'comments_num'},
        {placeholder: 'Clicks', field: 'clicks_num'},
        {placeholder: 'Shares', field: 'shares_num'},
        {placeholder: 'Saves', field: 'saves_num'},
        {placeholder: 'Actions', field: 'actions_num'},
      ] as Array<{placeholder: string; field: keyof OrderStatType}>,
    []
  );
  return (
    <Modal isShowModal={isShow}>
      <Div flexDirection="column" width={300} margin="0px 5px 30px">
        <ElementHeadline
          onClose={onClose}
          title={`Edit stat: ${stat.id}`}
          options={[{onSelect: deleteStat, option: 'Delete stat'}]}
          onSave={updateStat}
        />

        {INPUTS_VALUES.map(({field, placeholder}, i) => (
          <Input
            placeholder={placeholder}
            value={stat[field]}
            type={'number'}
            key={i}
            onChange={e => handleChangeState(field, e.target.value)}
          />
        ))}
      </Div>
    </Modal>
  );
};

export default EditStat;
