import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {OpenAIDialogDefaultStoreType} from './openaiDialog.types';

const initialState: OpenAIDialogDefaultStoreType = {
  isShowOpenAIDialog: false,
  dialogSettings: {
    titleBarText: 'AI Generator',
    prompt: 'Example of text...',
    acceptResult: (text: string) => alert(text),
  },
};
const slice = createSlice({
  name: 'openAIDialog',
  initialState: initialState as OpenAIDialogDefaultStoreType,
  reducers: {
    openOpenAIDialog(state, action: PayloadAction<OpenAIDialogDefaultStoreType['dialogSettings']>) {
      state.dialogSettings = action.payload;
      state.isShowOpenAIDialog = true;
    },
    closeOpenAIDialog(state) {
      state.dialogSettings = initialState.dialogSettings;
      state.isShowOpenAIDialog = false;
    },
  },
});
export const openAIReducer = slice.reducer;
export const openAIActions = slice.actions;
