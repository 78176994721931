import STableContainer from './tableContainer.styled';
import {Table} from '@material-ui/core';
import {ReactNode, useRef} from 'react';
import TCompactHead from '@/UI/Table/TSmartHead';
import {TBody} from '@/UI';
import {useScrollToTopOnUpdate} from '@/Hooks';
import {SelectedDataItems, SelectedDataItemsProps} from '@/Website/Components/Common';

interface TableContainerProps extends SelectedDataItemsProps {
  headers: Array<string>;
  children: ReactNode;
  isLoading?: boolean;
  isSmall?: boolean;
  stickyHeader?: boolean;
}
const TableContainer: React.FC<TableContainerProps> = props => {
  const {children, isLoading, headers, isSmall, stickyHeader, ...selectDataItemsProps} = props;
  const tableRef = useRef(null);
  useScrollToTopOnUpdate(tableRef, isLoading);
  return (
    <>
      <SelectedDataItems {...selectDataItemsProps} />
      <STableContainer className="TableContainer" ref={tableRef}>
        <Table aria-label="data table" stickyHeader={stickyHeader}>
          <TCompactHead headers={headers} isSmall={isSmall} />
          <TBody loader={{isShow: isLoading, colNumber: headers.length}}>{children}</TBody>
        </Table>
      </STableContainer>
    </>
  );
};
export default TableContainer;
