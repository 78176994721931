import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteTag = (tagID: number, onDelete: Function) => {
  return useApiLoader(
    async () =>
      await API.Tags.deleteTag(tagID).then(() => {
        onDelete();
      }),
    [onDelete, tagID]
  );
};
export default useDeleteTag;
