import styled, {css} from 'styled-components';
import {TableContainer} from '@material-ui/core';
import {memo} from 'react';

const STableContainer = styled(TableContainer)`
  ${({theme}) => css`
    margin-top: 8px;
    height: 100%;
    min-height: 300px;
    width: 100%;
    position: relative;
    z-index: 0;
    td {
      padding: 8px 13px 5px 8px;
    }

    th {
      padding: 13px 13px 13px 8px;
      background-color: ${theme.Color.DarkBlue};
      color: ${theme.Color.MediumGray};
      &:first-child {
        border-radius: 15px 0 0 15px;
      }

      &:last-child {
        border-radius: 0 15px 15px 0;
      }
    }

    ${theme.Presets.scroll}
    .MuiTableCell-stickyHeader {
    }

    .MuiTableCell-root {
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.8rem;
      border-bottom: 1px solid #f1f1f1;
    }

    .MuiTableRow-root:hover {
      background-color: #efefef;
      cursor: pointer;
    }

    .MuiCollapse-root {
      transition: height 0.1s ease !important;
    }
  `}
`;
export default memo(STableContainer);
