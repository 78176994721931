import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {TagType} from '@/API/Tags/types';

const useUpdateTag = (tag: TagType, onUpdate: Function) => {
  return useApiLoader(
    async () =>
      await API.Tags.updateTag(tag).then(() => {
        onUpdate();
      }),
    [tag, onUpdate]
  );
};
export default useUpdateTag;
