import {useEffect, useState} from 'react';
import useDebouncedValue from '@/Hooks/useDebouncedValue';
import {SelectOptionsType} from '@/UI/Select/select.styled';

const useSelectAsyncLoadingData = (
  loadOptions: (searchValue?: string) => Promise<{value: number; label: string}[]>
) => {
  const [options, setOptions] = useState<SelectOptionsType>([{label: '_', value: ''}]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const {debouncedValue} = useDebouncedValue(searchValue, 400);

  const handleInputChange = (search?: string) => setSearchValue(search);

  useEffect(() => {
    setIsLoading(true);
    void loadOptions(debouncedValue)
      .then(options => setOptions([{label: '_', value: ''}, ...options]))
      .finally(() => setIsLoading(false));
  }, [debouncedValue]);

  return {
    options,
    isLoading,
    handleInputChange,
  };
};
export default useSelectAsyncLoadingData;
