import styled from 'styled-components';
import {Modal, Text, Button, Div, ElementHeadline} from '@/UI';
import {useCallback} from 'react';
import {useRootStore} from '@/Store/root/root.selector';

const SConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px;
  gap: 20px;
  min-width: 300px;
`;

const Confirmation: React.FC = () => {
  const {setIsShowConfirmation} = useRootStore().actions;
  const {confirmation} = useRootStore().selectors;
  const {isShowConfirmation, confirmationMsg, confirmationCallbackPass} = confirmation;

  const handleCloseConfirmation = useCallback(() => {
    setIsShowConfirmation(false);
  }, [setIsShowConfirmation]);

  const handleConfirm = useCallback(() => {
    handleCloseConfirmation();
    confirmationCallbackPass();
  }, [confirmationCallbackPass, handleCloseConfirmation]);

  return (
    <Modal isShowModal={isShowConfirmation} onClose={handleCloseConfirmation}>
      <ElementHeadline title="Confirmation" />
      <SConfirmation className="Confirmation">
        <Text type="Header4" fontWeight={600}>
          {confirmationMsg}
        </Text>

        <Div flexDirection="column" justifyContent="space-between" gap={10}>
          <Button color="Success" onClick={handleConfirm}>
            YES
          </Button>
          <Button color="Alert" size="small" onClick={handleCloseConfirmation}>
            NO
          </Button>
        </Div>
      </SConfirmation>
    </Modal>
  );
};

export default Confirmation;
