import {dateToLocalDate} from '@/Services/date.service';
import {SocialService, SocialsType} from '@/Services/social.service';
import {Box, ElementHeadline, Grid, Select, SocialIcon, TCell, TRow, TableContainer, Text} from '@/UI';
import {useTalentInsights} from '@/Website/Components/Talent/Hooks';
import {memo, useMemo, useState} from 'react';

const TalentInsights: React.FC<{talentID: string}> = ({talentID}) => {
  const [social, setSocial] = useState<SocialsType>();
  const params = useMemo(() => ({userID: talentID, social}), [social]);
  const {insights} = useTalentInsights(params);
  const tableHeaders = useMemo(() => ['', 'Username', 'Followers', 'Posts', 'Created at'], []);
  return (
    <Box className="TalentInsight tab-preset">
      <ElementHeadline disableMarker title={`Founded: ${insights.length}`} />
      <Grid templateColumn="200px">
        <Select
          options={SocialService.SOCIALS_SELECT_OPTIONS}
          value={social}
          label="Social"
          onChange={value => setSocial(value)}
        />
      </Grid>
      <TableContainer isSmall headers={tableHeaders}>
        {insights.map((insight, i) => (
          <TRow key={i}>
            <TCell>
              <SocialIcon isActive social={insight.social_type} />
            </TCell>
            <TCell>
              <Text type="Text3">{insight.user_name}</Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Blue">
                {insight.followers_number}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Orange">
                {insight.followers_number}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success">
                {dateToLocalDate(insight.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default memo(TalentInsights);
