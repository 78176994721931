import {useCallback} from 'react';
import {useCommunityStore} from '@/Store/community/community.selector';

const useShowResetPassword = (userID: string) => {
  const {setIsShowResetPassword, setStoredTalent} = useCommunityStore().actions;
  return useCallback(() => {
    setStoredTalent({userID});
    setIsShowResetPassword(true);
  }, [setIsShowResetPassword, setStoredTalent, userID]);
};
export default useShowResetPassword;
