import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {SendingLogsResponse, SendingLogsSearchParams} from '@/API/Sender/sender.types';

const useSendingLogs = (params?: SendingLogsSearchParams) => {
  const [sendingLogs, setSendingLogs] = useState<SendingLogsResponse>({logs: [], count: 0});
  const fetch = useApiLoader(async () => {
    if (params) return await API.Sender.getSendingLogs(params).then(res => setSendingLogs(res));
  }, [params]);
  useEffect(() => void fetch(), [fetch]);
  return {
    sendingLogs,
  };
};

export default useSendingLogs;
