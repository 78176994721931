import styled, {css} from 'styled-components';

const Component = styled.div<{
  isNavOpen: boolean;
  isSectionOpened: boolean;
  isActiveSection: boolean;
}>`
  ${({theme, isNavOpen, isSectionOpened, isActiveSection}) => css`
    padding-bottom: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    cursor: pointer;
    border-bottom: 1px solid #343d56;

    ${!isNavOpen && `height: 60px;`}
    ${isActiveSection &&
    css`
      background-color: ${theme.Color.BlackOcean};
      border-bottom: 1px solid ${theme.Color.DarkGray};
      border-top: 1px solid ${theme.Color.DarkGray};
    `}
    .red-dot {
      position: absolute;
      ${!isNavOpen ? `left: 27px` : `left: 15px`};
      border-radius: 100%;
      ${!isNavOpen ? `margin-top: 13px` : `margin-top: 21px`};
      height: 10px;
      width: 10px;
      background-color: ${theme.Color.Alert};
    }
    a {
      display: flex;
      text-decoration: none;
      color: ${theme.Color.MediumGray};
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.8rem;
      margin: 5px 0;
      padding: 5px;
      border-radius: 15px;

      &::before {
        content: '';
        width: ${isSectionOpened || (!isSectionOpened && !isNavOpen) ? `3px` : `0px`};
        height: 3px;
        border-radius: 100%;
        position: absolute;
        left: ${isNavOpen ? `50px` : '30px'};
        margin-top: 10px;
        background-color: ${theme.Color.MediumGray};
      }
    }
    .active {
      color: white;
    }
    .links-nav-opened {
      display: flex;
      flex-direction: column;
      padding: 13px 15px 0 33px;

      .link-icon {
        position: absolute;
        right: 18px;
        margin-top: -25px;
        height: 15px;
        visibility: ${isSectionOpened ? `visible` : `hidden`};
        filter: ${theme.SVGColor.MediumGray};
        &:hover {
          filter: ${theme.SVGColor.White};
        }
      }
    }
    .links-nav-closed {
      position: absolute;
      left: 50px;
      margin-top: -40px;
      flex-direction: column;
      background-color: ${theme.Color.BlackOcean};
      padding: 15px 20px 20px 40px;
      display: none;
      z-index: 999;
      a {
        margin: 3px 0;
      }
      .link-icon {
        position: absolute;
        left: 11px;
        margin-top: -23px;
        height: 15px;
        filter: ${theme.SVGColor.MediumGray};
        &:hover {
          filter: ${theme.SVGColor.White};
        }
      }
    }
    .label {
      display: flex;
      flex-direction: column;
      max-height: ${isSectionOpened ? `500px` : '30px'};
      padding: ${isNavOpen ? `10px 0px 0 20px` : `10px 0px`};
      margin: 5px 20px 0 10px;
      color: ${isActiveSection ? 'white' : theme.Color.MediumGray};
      font-weight: 400;
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.9rem;
      transition: max-height 0.3s ease;
      .label-icon {
        filter: ${isActiveSection ? theme.SVGColor.White : theme.SVGColor.MediumGray};
        ${isNavOpen ? `height: 26px;` : `height: 25px;`}
        margin-right: 12px;
      }
      .label-head {
        display: flex;
        align-items: center;
      }

      .arrow {
        position: absolute;
        right: 31px;

        div:first-child,
        div:last-child {
          height: 1px;
          width: 5px;
          background-color: ${theme.Color.MediumGray};
          position: absolute;
        }
        div:first-child {
          transform: ${isSectionOpened ? `rotate(135deg)` : `rotate(45deg)`};
        }
        div:last-child {
          transform: ${isSectionOpened ? `rotate(45deg)` : `rotate(135deg)`};
          margin-left: 3px;
        }
      }
      .badged {
        font-size: 0.6rem;
        width: 25px;
        text-align: center;
        border-radius: 5px;
        color: black;
        position: absolute;
        font-weight: 600;
        height: 15px;
        ${isNavOpen
          ? css`
              right: 7px;
              margin-top: -27px;
              height: 15px;
            `
          : css`
              left: 8px;
              margin-top: -25px;
            `}
        ${isSectionOpened || !isNavOpen ? `display: block` : `display: none`};
        background-color: ${theme.Color.Alert};
      }
    }

    &:hover {
      background-color: ${theme.Color.BlackOcean};
      a:hover {
        color: white;
      }
      .label {
        color: white;
        .label-icon {
          filter: ${theme.SVGColor.White};
        }

        .arrow {
          div:first-child,
          div:last-child {
            background-color: #ffffff;
          }
        }
      }

      .links-nav-closed {
        display: flex;
      }
    }
  `}
`;
export default Component;
