import styled, {css} from 'styled-components';

export const Component = styled.div<{isDisabled?: boolean}>`
  ${({theme, isDisabled}) => css`
    width: 100%;
    margin: 8px 0px 14px;

    .range-box {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      input[type='range'] {
        -webkit-appearance: none;
        background-color: ${theme.Color.GrayLight};
        height: 2px;
        margin-top: 10px;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: ${theme.Color.Orange};
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid white;
        cursor: pointer;
        transition: transform 0.2s ease;
      }
      ​ input[type='range']::-webkit-slider-thumb:hover {
      }
      input[type='range']::-webkit-slider-thumb:active {
        transform: scale(1.6);
        border: 1px solid white;
      }
    }
    ${isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  `}
`;
