import {FirebaseAuthentication} from '@/App';
import {UserFirebaseCredentials} from '@/Hooks/Auth/types';
import {StorageService} from '@/Services/storage.service';
import {useRootStore} from '@/Store/root/root.selector';
import {signInWithEmailAndPassword} from 'firebase/auth';
import useSetAuthUser from './useSetAuthUser';

const useFirebaseLogin = () => {
  const setAuthUserByEmail = useSetAuthUser();
  const {setNotification} = useRootStore().actions;

  const firebaseLogin = async (credentials: {email: string; password: string}) => {
    await signInWithEmailAndPassword(FirebaseAuthentication, credentials.email, credentials.password)
      .then((authResponse: any) => {
        const tokenManager: UserFirebaseCredentials['user']['stsTokenManager'] =
          authResponse.user.stsTokenManager;
        StorageService.set('TOKEN', tokenManager.accessToken);
      })
      .then(async () => {
        await setAuthUserByEmail(credentials.email);
      })

      .catch(() => {
        setNotification({message: 'Invalid permissions', type: 'failed'});
      });
  };
  return {firebaseLogin};
};
export default useFirebaseLogin;
