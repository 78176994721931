import {useApiLoader} from '@/Hooks';
import {CustomEmailSending} from '@/API/Sender/sender.types';
import {API} from '@/API/API';

const useSendCustomEmail = () => {
  return useApiLoader(
    async (data: CustomEmailSending) => {
      return await API.Sender.sentCustomEmail(data);
    },
    [],
    {successMsg: 'Email sent'}
  );
};
export default useSendCustomEmail;
