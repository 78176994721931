import {useCampaignAnalytic} from '@/Hooks/Campaigns';
import {Analytics} from '@/Website/Components';
import {memo} from 'react';

const CampaignAnalytic = () => {
  const {isLoading, campaignAnalytic} = useCampaignAnalytic();
  return (
    <div className="campaign-tab">
      <Analytics isLoading={isLoading} analytics={campaignAnalytic} />
    </div>
  );
};
export default memo(CampaignAnalytic);
