import styled, {css} from 'styled-components';
import {DivProps} from './Div';

export const Component = styled.div<DivProps>`
  ${props => css`
    ${props.theme.Presets.flexible}
    ${props.margin &&
    `
         margin: ${props.margin};
      `}
    ${props.padding &&
    `
         padding: ${props.padding};
      `}
      ${props.width &&
    `
         width: ${props.width}px;
      `}
    ${props.height &&
    `
         height: ${props.height}px;
      `}
      ${props.borderColor &&
    `
         border: 1px solid ${props.theme.Color[props.borderColor]};
      `}
      ${props.backgroundColor &&
    `
         background-color: ${props.theme.Color[props.backgroundColor]};
      `}
      ${props.borderRadius &&
    `
         border-radius: ${props.borderRadius}px;
      `}
  `}
`;
