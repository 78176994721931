import styled, {css} from 'styled-components';
import {memo} from 'react';

const STopBar = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 11px;
    z-index: 99;
    top: 0;

    .bar {
      height: 420px;
      width: 450px;
      background-color: ${theme.Color.DarkBlue};
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 10px 5px;
      transition: height 0.3s;
      position: relative;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;

      svg {
        height: 23px;
        width: 23px;
        fill: ${theme.Color.MediumGray};

        &:hover {
          fill: ${theme.Color.White};
          cursor: pointer;
        }
      }
    }

    .collapsed-bar {
      height: 65px;
    }

    .buttons-panel {
      display: flex;

      border-top: 1px solid ${theme.Color.DarkGray};
      gap: 15px;
      padding-top: 5px;

      .likes-badge {
        position: absolute;
        bottom: 3px;
        margin-left: 10px;
        background-color: ${theme.Color.Alert};
        border-radius: 100%;
        padding: 1px 4px;
        font-size: 0.6rem;
        color: black;
      }

      .top-bar-coin-icon {
        fill: #ddbf3a;
      }

      .top-bar-security-icon {
        fill: #7ccbc4;
      }

      .top-bar-heart-icon {
        fill: #ea8282;
        stroke: #f26262;
      }
    }
  `}
`;
export default memo(STopBar);
