import {useCallback} from 'react';
import {OrderType} from '@/API/Orders/types';
import {usePortalsStore} from '@/Store/portals/portals.selector';

const useOpenOrder = () => {
  const {setPortalOrder, setIsShowPortalOrder} = usePortalsStore().actions;
  return useCallback((order: OrderType) => {
    setPortalOrder(order);
    setIsShowPortalOrder(true);
  }, []);
};
export default useOpenOrder;
