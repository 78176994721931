import {ReactComponent as ArrowIcon} from '@/Assets/Icons/arrow.svg';
import {ReactComponent as SortIcon} from '@/Assets/Icons/sort.svg';
import {useClickOut} from '@/Hooks';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {Tooltip} from '@/UI';
import {useCallback} from 'react';
import Div from '../Div/Div';
import Text from '../Text/Text';
import {SortService} from './sort.service';
import {SSort, SSortOptions} from './sort.styled';

export interface SortOptionType {
  label: string;
  value: [string, 'ASC' | 'DESC'];
}
interface SortProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange' | 'ref'
  > {
  sortOptions: Array<SortOptionType>;
  sortValue: SortOptionType;
  onSortChange: Function;
  limitValue?: number;
  onLimitChange: (limit: number) => void;
}

const Sort: React.FC<SortProps> = props => {
  const {sortOptions, onSortChange, limitValue = 30, onLimitChange, sortValue, ...restProps} = props;
  const ShowLimitOptions = useBooleanState();
  const ShowSortOptions = useBooleanState();
  const {ref} = useClickOut(() => {
    ShowSortOptions.turnOff(), ShowLimitOptions.turnOff();
  });

  const handleSortSelect = useCallback(
    (option: SortOptionType) => {
      if (option.label === sortValue.label) {
        const direction = sortValue.value[1] === 'ASC' ? 'DESC' : 'ASC';
        onSortChange({...option, value: [option.value[0], direction]});
      } else {
        onSortChange(option);
      }
    },
    [sortValue]
  );
  const handleChangeSortDirection = () => handleSortSelect(sortValue);

  const isASC = sortValue.value[1] === 'DESC';

  return (
    <SSort className="Sort" ref={ref} {...restProps}>
      {/* Limiting */}

      <Div alignItems="center" gap={5}>
        <Text color="Success" type="Text3" fontWeight={800} noWrap onClick={ShowLimitOptions.toggle}>
          show {limitValue}
        </Text>
        <ArrowIcon
          className={`arrow-icon ${ShowLimitOptions.isTurn ? `rotated-icon` : ''}`}
          onClick={ShowLimitOptions.toggle}
        />
        <SSortOptions isOpen={ShowLimitOptions.isTurn}>
          {SortService.limitOptions.map((limitOption, i) => (
            <span
              className={`option ${Number(limitOption.value) === limitValue ? `selected` : ``}`}
              key={i}
              onClick={() => {
                onLimitChange(Number(limitOption.value));
                ShowLimitOptions.turnOff();
              }}
            >
              {limitOption.label}
            </span>
          ))}
        </SSortOptions>
      </Div>

      {/* Sorting */}

      <Div alignItems="center" gap={5}>
        <Tooltip title="ASC / DESC" tooltipType="tip">
          <SortIcon
            className={`sort-icon ${isASC ? 'rotated-icon' : ''}`}
            onClick={handleChangeSortDirection}
          />
        </Tooltip>
        <Text color="Success" type="Text3" fontWeight={800} noWrap onClick={ShowSortOptions.toggle}>
          sort by {sortValue.label}
        </Text>
        <ArrowIcon
          className={`arrow-icon ${ShowSortOptions.isTurn ? `rotated-icon` : ''}`}
          onClick={ShowSortOptions.toggle}
        />
        <SSortOptions isOpen={ShowSortOptions.isTurn}>
          {sortOptions.map((option, i) => (
            <span
              className={`option ${option.label === sortValue.label ? `selected` : ``}`}
              key={i}
              onClick={() => {
                handleSortSelect(option);
                ShowSortOptions.turnOff();
              }}
            >
              {option.label}
            </span>
          ))}
        </SSortOptions>
      </Div>
    </SSort>
  );
};

export default Sort;
