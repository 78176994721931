import SAnalytics from './analytics.styled';
import {Box, Div, Text} from '@/UI';
import AnalyticsColumn from '@/Website/Components/Common/Analytics/AnalyticsColumn';
import {AnalyticsResponse} from '@/API/Orders/types';

interface AnalyticsProps {
  isLoading: boolean;
  analytics: AnalyticsResponse | undefined;
}
const Analytics: React.FC<AnalyticsProps> = ({analytics, isLoading}) => {
  const baseValues = [
    {label: 'Talent price', value: analytics?.base.talentPrice},
    {label: 'Advertiser price', value: analytics?.base.advertiserPrice},
    {label: 'Actions', value: analytics?.base.actions},
    {label: 'Saves', value: analytics?.base.saves},
    {label: 'Clicks', value: analytics?.base.clicks},
    {label: 'Shares', value: analytics?.base.shares},
    {label: 'Comments', value: analytics?.base.comments},
    {label: 'Impressions', value: analytics?.base.impressions},
    {label: 'Likes', value: analytics?.base.likes},
  ];
  return (
    <SAnalytics className="Analytics">
      <Box label="Base" borderColor="Orange">
        {baseValues.map((baseItem, i) => (
          <div className="base-analytic-column" key={i}>
            <Text type="Text1" fontWeight={800}>
              {baseItem.value}
            </Text>
            <Text type="Text3">{baseItem.label}</Text>
          </div>
        ))}
      </Box>
      <Div flexDirection="column" gap={10} justifyContent="space-between">
        <Box label="Based on talent price" borderColor="Success" backgroundColor="SuccessTransparent">
          <Div flexDirection="column" width={650}>
            <Div justifyContent="space-between">
              <AnalyticsColumn isLoading={isLoading} value={analytics?.talentPriceBase?.CPM} type="CPM" />
              <AnalyticsColumn isLoading={isLoading} value={analytics?.talentPriceBase?.CPC} type="CPC" />
              <AnalyticsColumn isLoading={isLoading} value={analytics?.talentPriceBase?.CPE} type="CPE" />
            </Div>
            <Div margin="20px 0 0" justifyContent="space-evenly">
              <AnalyticsColumn isLoading={isLoading} value={analytics?.talentPriceBase?.CPA} type="CPA" />
              <AnalyticsColumn isLoading={isLoading} value={analytics?.talentPriceBase?.CTR} type="CTR" />
            </Div>
          </Div>
        </Box>
        <Box label="Based on advertiser price" borderColor="Alert" backgroundColor="AlertTransparent">
          <Div flexDirection="column" width={650}>
            <Div justifyContent="space-between">
              <AnalyticsColumn isLoading={isLoading} value={analytics?.advertiserPriceBase?.CPM} type="CPM" />

              <AnalyticsColumn isLoading={isLoading} value={analytics?.advertiserPriceBase?.CPC} type="CPC" />

              <AnalyticsColumn isLoading={isLoading} value={analytics?.advertiserPriceBase?.CPE} type="CPE" />
            </Div>
            <Div margin="20px 0 0" justifyContent="space-evenly">
              <AnalyticsColumn isLoading={isLoading} value={analytics?.advertiserPriceBase?.CPA} type="CPA" />

              <AnalyticsColumn isLoading={isLoading} value={analytics?.advertiserPriceBase?.CTR} type="CTR" />
            </Div>
          </Div>
        </Box>
      </Div>
    </SAnalytics>
  );
};
export default Analytics;
