import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {OrdersSearchParams, OrderType} from '@/API/Orders/types';
import {useCallback, useEffect, useState} from 'react';
import LoaderService from '@/Services/loader.service';
import ExportService from '@/Services/excel.service';

const useGetOrders = (params?: OrdersSearchParams) => {
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [orderCount, setOrderCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(
    async (freeOptions?: OrdersSearchParams) => {
      if (params) {
        setIsLoading(true);
        return await API.Orders.getOrders({...params, ...freeOptions})
          .then(res => {
            setOrders(res.orders);
            setOrderCount(res.counts);
            return res;
          })
          .finally(() => setIsLoading(false));
      }
    },
    [params]
  );

  const exportOrderToExcel = useCallback(async () => {
    if (orderCount === 0) return;
    LoaderService.setLoading(true);
    await fetch({skip: 0, limit: 9999})
      .then(orders => {
        if (orders) {
          if (orders?.counts === 0) return;
          ExportService.exportToExl(orders.orders);
        }
      })
      .finally(() => LoaderService.setLoading(false));
  }, [fetch, orderCount]);

  useEffect(() => {
    void fetch();
  }, [fetch]);

  return {
    orders,
    orderCount,
    isLoading,
    reloadOrders: fetch,
    exportOrderToExcel,
  };
};
export default useGetOrders;
