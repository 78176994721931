import {StorageService} from '@/Services/storage.service';
import {useRootStore} from '@/Store/root/root.selector';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {getAuth} from 'firebase/auth';

const useLogout = () => {
  const navigate = useNavigate();
  const {setIsAuth} = useRootStore().actions;

  return useCallback(() => {
    StorageService.deleteAll();
    void getAuth().signOut();
    setIsAuth(false);
    navigate('/');
  }, [navigate, setIsAuth]);
};

export default useLogout;
