import {dateToLocalDate} from '@/Services/date.service';
import {Box, TableContainer, TCell, Text, TRow} from '@/UI';
import {useMemo} from 'react';
import {useGetOrderUTM} from '../Hooks';

const OrderUTM: React.FC<{orderID: number}> = ({orderID}) => {
  const {utm} = useGetOrderUTM(orderID);
  const headers = useMemo(() => ['IP address', 'Has stat', 'Created at'], []);
  return (
    <Box className="OrderUTM order-tab-preset">
      <TableContainer isSmall headers={headers}>
        {utm.map((utmItem, i) => (
          <TRow key={i}>
            <TCell>
              <Text type="Text3">{utmItem.ip_address}</Text>
            </TCell>
            <TCell>
              <Text type="Text3" color={utmItem.stats_added ? 'Success' : 'Alert'}>
                {utmItem.stats_added ? 'YES' : 'NO'}
              </Text>
            </TCell>
            <TCell>
              <Text type="Text3" color="Success">
                {dateToLocalDate(utmItem.created_at)}
              </Text>
            </TCell>
          </TRow>
        ))}
      </TableContainer>
    </Box>
  );
};
export default OrderUTM;
