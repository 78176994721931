import {useQuickResponseStore} from '@/Store/quickResponse/quickResponse.selector';

const useQuickResponse = () => {
  const {closeQuickResponse, setRecipient, handleChangeSendingData, openQuickResponse, resetState} =
    useQuickResponseStore().actions;
  const {isShowQuickResponse, sendingData, callBackOnSent} = useQuickResponseStore().selectors;

  return {
    isShowQuickResponse,
    openQuickResponse,
    closeQuickResponse,
    handleChangeSendingData,
    resetState,
    setRecipient,
    sendingData,
    callBackOnSent,
  };
};
export default useQuickResponse;
