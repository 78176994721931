import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {Div, Text} from '@/UI';
import {useState, useEffect, useCallback} from 'react';
import {Component} from './inputMultiRange.styled';
import {UtilService} from '@/Services/util.service';

type InputMultiRangeProps = {
  title: string;
  value: [number, number];
  step?: number;
  onChange: Function;
  isDisable?: boolean;
  minMax: [number, number];
  indexedValues?: Array<number>;
};
/** to use custom marks, provide array of marks & two adapters from <Service>*/
/** rangeIncomingDataAdapter - for incoming value to input*/
/** rangeOutputDataAdapter - for output value from OnChange callback*/
const InputMultiRange: React.FC<InputMultiRangeProps> = props => {
  const {title, value, step, onChange, indexedValues, isDisable, minMax} = props;
  const [minMaxValues, setMinMaxValues] = useState<[number, number]>([0, 100]);
  const handleInputChange = useCallback((inputValue: number | number[]) => onChange(inputValue), [onChange]);

  useEffect(() => (minMax ? setMinMaxValues(minMax) : setMinMaxValues([0, 100])), []);

  return (
    <Component className="InputMultiRange">
      <div className="label">{title}</div>

      <Slider
        range={true}
        allowCross={false}
        min={minMaxValues[0]}
        max={minMaxValues[1]}
        step={step}
        onChange={handleInputChange}
        value={value || minMaxValues}
        disabled={isDisable}
      />

      <Div justifyContent="space-between" margin="5px 0 0">
        {indexedValues ? (
          <>
            <Text type="Text3" fontWeight={800}>
              {UtilService.hundredSeparator(indexedValues[value[0]])}
            </Text>
            <Text type="Text3" fontWeight={800}>
              {UtilService.hundredSeparator(indexedValues[value[1]])}
              {indexedValues[indexedValues.length - 1] === indexedValues[value[1]] ? '+' : ''}
            </Text>
          </>
        ) : (
          <>
            <Text type="Text3" fontWeight={800}>
              {UtilService.hundredSeparator(value ? value[0] : minMaxValues[0])}
            </Text>
            <Text type="Text3" fontWeight={800}>
              {UtilService.hundredSeparator(value ? value[1] : minMaxValues[1])}
              {(value ? value[1] : minMaxValues[1]) === minMaxValues[1] && `+`}
            </Text>
          </>
        )}
      </Div>
    </Component>
  );
};

export default InputMultiRange;
