import {useCommunityStore} from '@/Store/community/community.selector';
import {useEffect, useState} from 'react';
import {TalentLastActivity} from '@/API/Talents/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useGetLastTalentActivity = () => {
  const {userID} = useCommunityStore().selectors.storedUser;
  const [lastActivity, setLastActivity] = useState<TalentLastActivity[]>([]);

  const fetch = useApiLoader(async () => {
    await API.Talents.getTalentLastActivity(userID).then(res => setLastActivity(res));
  }, [userID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    lastActivity,
  };
};
export default useGetLastTalentActivity;
