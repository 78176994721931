export enum ROUTES {
  /* App */
  default = '/',
  login = '/login',
  dashboard = '/dashboard',
  security = '/security',
  /* Incoming */
  approvals = '/approvals',
  onboarding = '/onboarding',

  /* Community */
  talentPortfolio = '/talent-portfolio',
  influencers = '/influencers',
  ugcCreators = '/ugc-creators',
  customers = '/customers',
  organizations = '/organizations',
  searchy = '/searchy',
  advertisers = '/advertisers',
  talentInfo = '/talent-info',
  helpRequests = '/help-requests',
  /* Campaign */
  campaigns = '/campaigns',
  automatedCampaignNotification = '/automated-campaign/notification',
  automatedCampaignOrder = '/automated-campaign/order',
  reminder = '/reminder',
  providers = '/providers',
  monitor = '/monitor',
  blocklist = '/blocklist',
  automatics = '/automatic-notifications',
  /* Category */
  categories = '/categories',

  orders = '/orders',
  ordersLogs = '/orders-logs',

  tags = '/tags',
  /* Logs */
  emailsLogs = '/emails-logs',
  sendingLogs = '/sending-logs',
}
