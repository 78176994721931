import {TalentDetailed} from '@/API/Talents/types';
import removeIcon from '@/Assets/Icons/remove.svg';
import useDeleteTagFromUser from '@/Hooks/Tags/useDeleteTagFromUser';
import useGetTagsByUser from '@/Hooks/Tags/useGetTagsByUser';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {ElementHeadline, Text} from '@/UI';
import OpenaiTags from '@/Website/Components/Talent/TalentCategoriesTags/TalentTags/OpenaiTags/OpenaiTags';
import {useCallback, useEffect} from 'react';
import STalentTags from './talentTags.styled';

type TalentTagsProps = {
  talent: TalentDetailed;
};

const TalentTags: React.FC<TalentTagsProps> = ({talent}) => {
  const ShowOpenaiTags = useBooleanState();
  const {addUsersToTagPortal, setTagPortalCallBack} = usePortalsStore().actions;
  const {tags, reloadTags} = useGetTagsByUser(talent.user_id);
  const handleDeleteTag = useDeleteTagFromUser();
  const handleAddNewTag = useCallback(
    () =>
      addUsersToTagPortal([
        {email: talent.email, name: talent.full_name || talent.email, userID: talent.user_id},
      ]),
    [addUsersToTagPortal, talent]
  );
  /** CallBack portal */
  useEffect(() => {
    setTagPortalCallBack(reloadTags);
  }, []);

  return (
    <STalentTags className="TalentTags">
      <ElementHeadline
        onSearch={ShowOpenaiTags.turnOn}
        title="Tags"
        disableMarker
        onCreateNew={handleAddNewTag}
        onSearchMsg="Generate tags with AI"
        onCreateNewMsg="Add tag to talent"
      />
      <div className="tags-list">
        {tags.length > 0 &&
          tags.map((tag, i) => (
            <div className="tags-item" key={i}>
              <img
                src={removeIcon}
                alt=""
                onClick={() => handleDeleteTag({callBack: reloadTags, tagID: tag.id, userID: talent.user_id})}
              />
              <Text type="Text3" color="Success">
                {tag.tag_title}
              </Text>
            </div>
          ))}
      </div>
      {ShowOpenaiTags.isTurn && (
        <OpenaiTags
          onClose={ShowOpenaiTags.turnOff}
          afterUpdateTags={reloadTags}
          textToSearchTags={talent?.bio + '.' + talent?.short_bio}
        />
      )}
    </STalentTags>
  );
};

export default TalentTags;
