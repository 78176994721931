import {TalentDetailed} from '@/API/Talents/types';
import {useCommunityStore} from '@/Store/community/community.selector';
import {Modal, Tabs} from '@/UI';
import {Panel} from '@/UI/Tabs/Tabs';
import {useCloseTalent} from '@/Website/Components/Talent/Hooks';
import useTalent from '@/Website/Components/Talent/Hooks/useTalent';
import TalentAnalytics from '@/Website/Components/Talent/TalentAnalytics/TalentAnalytics';
import TalentFinancialDocs from '@/Website/Components/Talent/TalentFinancialDocs/TalentFinancialDocs';
import TalentHeader from '@/Website/Components/Talent/TalentHeader/TalentHeader';
import TalentLastActivity from '@/Website/Components/Talent/TalentLastActivity/TalentLastActivity';
import TalentPassage from '@/Website/Components/Talent/TalentPassage/TalentPassage';
import TalentPreOrder from '@/Website/Components/Talent/TalentPreOrder/TalentPreOrder';
import {TalentEmails, TalentInsights} from '@/Website/Components/Talent/index';
import {useMemo, useState} from 'react';
import TalentDetail from './TalentDetail/TalentDetail';
import TalentOrders from './TalentOrders/TalentOrders';
import TalentSocial from './TalentSocial/TalentSocial';
import TalentWallet from './TalentWallet/TalentWallet';
import {TALENT_CONSTANTS} from './talent.service';
import {Component} from './talent.styled';

const Talent = () => {
  const {
    selectors: {
      storedUser: {isShowStoredUser},
    },
  } = useCommunityStore();

  const {setTalent, talent, reloadTalent} = useTalent();

  const [tabIndex, setTabIndex] = useState(0);
  const {tabs} = useMemo(() => TALENT_CONSTANTS, []);

  const closeTalent = useCloseTalent();

  return isShowStoredUser && talent ? (
    <Modal
      isShowModal={isShowStoredUser}
      maxModalWidth="1300px"
      style={{
        backgroundColor: !talent?.is_email_blocked ? '#ECF1F6' : '#FEE2E7',
        width: '1300px',
        height: 'max-content',
      }}
    >
      <Component>
        <TalentHeader talent={talent} onClose={closeTalent} />
        <TalentPassage talent={talent} onChange={reloadTalent} />
        <Tabs index={tabIndex} setIndex={setTabIndex} tabsHeaders={tabs}>
          <Panel>
            <TalentDetail
              talent={talent}
              setTalent={setTalent as React.Dispatch<React.SetStateAction<TalentDetailed>>}
            />
          </Panel>
          <Panel>
            <TalentSocial
              talent={talent}
              setTalent={setTalent as React.Dispatch<React.SetStateAction<TalentDetailed>>}
            />
          </Panel>
          <Panel>
            <TalentWallet />
          </Panel>
          <Panel>
            <TalentOrders talentID={talent.user_id} />
          </Panel>
          <Panel>
            <TalentPreOrder talentID={talent.user_id} />
          </Panel>
          <Panel>
            <TalentAnalytics />
          </Panel>
          <Panel>
            <TalentLastActivity />
          </Panel>
          <Panel>
            <TalentFinancialDocs />
          </Panel>
          <Panel>
            <TalentEmails email={talent.email} />
          </Panel>
          <Panel>
            <TalentInsights talentID={talent.user_id} />
          </Panel>
        </Tabs>
      </Component>
    </Modal>
  ) : null;
};
export default Talent;
