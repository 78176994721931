import {Tabs} from 'react-tabs';
import styled, {css} from 'styled-components';

export const Component = styled(Tabs)`
  ${({theme}) => css`
    .tab-list {
      margin-top: 10px;
      display: flex;
      list-style-type: none;
      gap: 5px;
      li {
        transition: background-color 0.2s ease;
        background-color: ${theme.Color.GrayLight};
        cursor: pointer;
        padding: 3px 14px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        font-family: ${theme.FontFamily.Outfit};
        font-size: 0.8rem;
        align-items: flex-end;
        svg {
          height: 17px;
          width: 17px;
          margin-right: 4px;
          fill: ${theme.Color.MediumGray};
        }
      }
      .active {
        background-color: white;
        svg {
          fill: black;
        }
      }
    }
  `}
`;
