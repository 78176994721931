import styled, {css} from 'styled-components';

const SUser = styled.div<{isNavOpen: boolean}>`
  ${({isNavOpen}) => css`
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    ${isNavOpen
      ? css`
          padding: 17px 0 0 27px;
          display: flex;
          flex-direction: column;
          gap: 7px;
        `
      : css`
          position: absolute;
          top: 119px;
          padding: 10px;
        `}
  `}
`;
export default SUser;
