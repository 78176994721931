import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteOrderStats = (statID: number, onChange: Function) => {
  return useApiLoader(
    async () => {
      await API.Orders.deleteOrderStat(statID).then(() => {
        onChange();
      });
    },
    [statID, onChange],
    {successMsg: 'Deleted'}
  );
};
export default useDeleteOrderStats;
