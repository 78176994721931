import {CampaignDetailsType} from '@/API/Campaign/types';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {UtilService} from '@/Services/util.service';
import {useOpenAIDialogStore} from '@/Store/openaiDialog/openaiDialog.selector';
import {Box, ElementHeadline, Grid, Input, TextEditor} from '@/UI';
import BrandLinks from '@/Website/Components/Portals/Campaign/Brand/BrandLinks';
import {memo, useMemo} from 'react';
import styled from 'styled-components';
import {CampaignService} from '../campaignEditor.service';

const Component = styled(Box)`
  border-top-left-radius: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;
interface BrandProps {
  campaign: CampaignDetailsType;
  onChange: (props: keyof CampaignDetailsType, value: any) => void;
}
const Brand: React.FC<BrandProps> = ({campaign, onChange}) => {
  const ShowLinks = useBooleanState();
  const {openOpenAIDialog} = useOpenAIDialogStore().actions;
  const prompt = useMemo(() => {
    const instructions = UtilService.removeHtmlTags(campaign.brand_description);
    return CampaignService.getPromptForCampaign(instructions);
  }, [campaign]);

  const titleBarOptions = useMemo(
    () => [
      {option: 'Links', onSelect: ShowLinks.turnOn},
      {
        option: 'Generate instructions with AI',
        onSelect: () =>
          openOpenAIDialog({
            maxTokens: 150,
            temperature: 7,
            prompt,
            titleBarText: 'Generate campaign instructions with AI',
            acceptResult: (value: string) => onChange('campaign_instructions', value),
          }),
      },
    ],
    [prompt]
  );

  return (
    <Component className="Brand campaign-tab">
      <ElementHeadline
        options={titleBarOptions}
        disableMarker
        fontSize="Text3"
        title="Manage brand details"
      />
      <Grid templateColumn="repeat(2, 1fr)" columnGap={10} alignItems="flex-end">
        <Input
          placeholder="Brand name"
          value={campaign.brand_name}
          autoFocus
          onChange={e => onChange('brand_name', e.target.value)}
        />
        <Input placeholder="Link" value={campaign.link} onChange={e => onChange('link', e.target.value)} />
      </Grid>
      <Grid templateColumn="1fr 1fr" columnGap={10}>
        <TextEditor
          height="400px"
          label="Brand description"
          value={campaign.brand_description || ''}
          onChange={(e: any) => onChange('brand_description', e)}
        />
        <TextEditor
          height="400px"
          label="Instructions"
          value={campaign.campaign_instructions || ''}
          onChange={(e: any) => onChange('campaign_instructions', e)}
        />
      </Grid>
      {ShowLinks.isTurn && (
        <BrandLinks
          onClose={ShowLinks.turnOff}
          isShow={ShowLinks.isTurn}
          links={campaign.links}
          setLinks={links => onChange('links', links)}
        />
      )}
    </Component>
  );
};

export default memo(Brand);
