import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AxiosInstance} from 'axios';
import {CompletionDTO} from './openai.types';

class OpenaiApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async generateTags(text: string): Promise<Array<string>> {
    return await this.api.post('/generate-tags', {text}).then(res => res.data);
  }
  async generateEmail(prompt: string): Promise<string> {
    return await this.api.post('/generate-email', {prompt}).then(res => res.data);
  }
  async freeDialog(completionDTO: CompletionDTO): Promise<string> {
    return await this.api.post('/free-dialog', completionDTO).then(res => res.data);
  }
}

export default OpenaiApi;
