import {useCallback, useEffect, useMemo, useState} from 'react';

const useGetPaginationConfig = (params: {limit: number; count: number}) => {
  const {count, limit} = params;

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);

  const setPage = useCallback((page: number) => setSelectedPage(page), []);

  const pagesToShow = 1;

  useEffect(() => {
    if (count !== 0 && limit !== 0) {
      setTotalPagesCount(Math.ceil(count / limit));
    }
  }, [count, limit]);

  const digits = useMemo(() => {
    let firstPage = Math.max(selectedPage - pagesToShow, 1);
    let lastPage = Math.min(selectedPage + pagesToShow, totalPagesCount);
    let pagesToDisplay = [];

    // add the first two pages
    if (firstPage > 2) {
      pagesToDisplay.push(1);
      pagesToDisplay.push('...');
    }
    for (let i = firstPage; i <= lastPage; i++) {
      pagesToDisplay.push(i);
    }
    // add the last two pages
    if (lastPage < totalPagesCount - 1) {
      pagesToDisplay.push('...');
      pagesToDisplay.push(totalPagesCount);
    }
    return pagesToDisplay;
  }, [totalPagesCount, selectedPage]);

  return {
    totalPagesCount,
    digits,
    setPage,
    selectedPage,
    setSelectedPage,
  };
};
export default useGetPaginationConfig;
