import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {UpdateDisputeDTO} from '@/API/Orders/types';

const useUpdateOrderDispute = (callBack?: Function) =>
  useApiLoader(
    async (updateDisputeDTO: UpdateDisputeDTO) => {
      return await API.Orders.updateOrderDispute(updateDisputeDTO).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Successfully updated'}
  );
export default useUpdateOrderDispute;
