import {useEffect, useState} from 'react';
import {OrderUTM} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useGetOrderUTM = (orderID: number) => {
  const [utm, setUtm] = useState<OrderUTM[]>([]);
  const fetch = useApiLoader(async () => {
    await API.Orders.getOrderUTM(orderID).then(res => setUtm(res));
  }, [orderID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    utm,
  };
};
export default useGetOrderUTM;
