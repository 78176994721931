import styled, {css} from 'styled-components';

export const SSort = styled.div`
  ${({theme}) => css`
    display: flex;

    position: relative;
    align-items: flex-start;
    gap: 5px;
    width: max-content;
    padding: 0px 5px;
    cursor: pointer;

    svg {
      height: 21px;
      width: 21px;
      fill: ${theme.Color.Success};
      transition: transform 0.3s ease;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .rotated-icon {
      transform: rotate(180deg);
    }
  `}
`;

export const SSortOptions = styled.div<{isOpen: boolean}>`
  ${({theme, isOpen}) => css`
    max-height: ${isOpen ? `300px` : `0px`};
    display: flex;
    position: absolute;
    z-index: 99;
    background-color: white;
    top: 22px;
    margin: 0 -20px 0;
    flex-direction: column;
    align-items: stretch;
    width: max-content;
    overflow: hidden;
    box-shadow: 1px 1px 1px ${theme.Color.GrayLight};
    transition: max-height 0.3s;
    ${isOpen &&
    css`
      border: 1px solid ${theme.Color.GrayLight};
      border-radius: 15px;
      overflow: visible;
    `}

    .option {
      font-family: ${theme.FontFamily.Outfit};
      font-size: 0.7rem;
      padding: 5px 25px;
      border-radius: 15px;
      white-space: nowrap;
      &:hover {
        background-color: #e8e8e8;

        cursor: pointer;
      }
    }
    .selected {
      background-color: ${theme.Color.Success};
      color: ${theme.Color.White};
      font-weight: 800;
      &:hover {
        background-color: ${theme.Color.Success};
        color: ${theme.Color.White};
      }
    }
  `}
`;
