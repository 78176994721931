import {useEffect, useState} from 'react';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {ITalentInfoCheckSocial} from '@/API/TalentInfo/types';
import {SocialsType} from '@/Services/social.service';
type iqReportStateType = {
  [key in SocialsType]: ITalentInfoCheckSocial;
};
const useTalentReports = (talentID?: string) => {
  const [iqReportsState, setIqReportsState] = useState<iqReportStateType>();

  const fetchTalentInfo = useApiLoader(async () => {
    if (talentID) {
      return await API.TalentInfo.checkTalentInfoSocials(talentID).then(res => {
        res.forEach(social =>
          setIqReportsState((prev: any) => ({...prev, [social.social_platform]: social}))
        );
      });
    }
  }, [talentID]);
  useEffect(() => {
    void fetchTalentInfo();
  }, [fetchTalentInfo]);
  return {
    iqReportsState,
    reloadIqReports: fetchTalentInfo,
    reports: iqReportsState ? Object.values(iqReportsState) : [],
  };
};
export default useTalentReports;
