import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import useApiLoader from '../useApiLoader';
import {dateToLocalDate} from '@/Services/date.service';
import {useCommunityStore} from '@/Store/community/community.selector';

const useOrderOptions = () => {
  const {
    storedUser: {userID},
  } = useCommunityStore().selectors;
  const [orderOptions, setOrderOptions] = useState<Array<{value: any; label: string}>>([
    {value: '', label: '-'},
  ]);
  const fetchOrders = useApiLoader(async () => {
    return await API.Orders.getOrders({
      skip: 0,
      userID: userID,
    }).then(res => {
      setOrderOptions(
        res.orders.map(el => ({
          value: el.id,
          label: `ID: ${el.id}: Created at: ${dateToLocalDate(el.created_at)}`,
        }))
      );
    });
  }, [userID]);

  useEffect(() => {
    void fetchOrders();
  }, [fetchOrders]);
  return orderOptions;
};

export default useOrderOptions;
