import {useEffect} from 'react';
import {useRootStore} from '@/Store/root/root.selector';
import {StorageService} from '@/Services/storage.service';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import LoaderService from '@/Services/loader.service';
import useSetAuthUser from './useSetAuthUser';

const useFirebaseInit = () => {
  const {setIsAuth} = useRootStore().actions;
  const setAuthUserByEmail = useSetAuthUser();
  useEffect(() => {
    const auth = getAuth();
    LoaderService.setLoading(true);
    onAuthStateChanged(auth, user => {
      if (user) {
        user.getIdToken(true).then(token => {
          StorageService.set('TOKEN', token);
          if (user.email) setAuthUserByEmail(user?.email);
        });
      } else {
        StorageService.deleteAll();
        setIsAuth(false);
      }
      LoaderService.setLoading(false);
    });
  }, []);
};
export default useFirebaseInit;
