import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

export const useChangeUserPassword = (userId: string, password: string) => {
  return useApiLoader(
    async () => {
      return await API.Users.resetUserPassword({userId, password}).then(res => res.data);
    },
    [userId, password],
    {successMsg: 'Password changed'}
  );
};
