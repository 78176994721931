import {useEffect, useState, useRef} from 'react';
import ReactSelect from 'react-select';
import {SSelect} from '@/UI/Select/select.styled';
import useSelectAsyncLoadingData from '@/UI/SelectAsync/selectAsync.hooks';

interface Props {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  label?: string;
  onChange?: (value: any) => void;
  isMulti?: boolean;
  value: any;
  defaultPlaceholder?: any;
  focus?: boolean;
  loadOptions: (searchValue?: string) => Promise<{value: number; label: string}[]>;
}
/** @param props.isMulti on that choice provides value like an array of {value: "", label: ""} */

const SelectAsync: React.FC<Props> = props => {
  const {label, onChange, loadOptions, focus, defaultPlaceholder, ...restProps} = props;
  const selectRef = useRef<any>(null);
  const [isCloseToBottom, setIsCloseToBottom] = useState<boolean>(false);
  const [inputPlaceHolder, setInputPlaceHolder] = useState<string>('');
  const {handleInputChange, options, isLoading} = useSelectAsyncLoadingData(loadOptions);
  const handleSelectChange = (e: any) => {
    setInputPlaceHolder(e.label);
    if (onChange) {
      restProps.isMulti ? onChange(e) : onChange(e.value);
    }
  };
  useEffect(() => {
    !restProps.value && setInputPlaceHolder(options[0].label);
  }, [restProps.value]);

  useEffect(() => {
    if (defaultPlaceholder) setInputPlaceHolder(defaultPlaceholder);
  }, [defaultPlaceholder]);

  useEffect(() => {
    const Y = selectRef.current.getBoundingClientRect().y;
    setIsCloseToBottom(window.screen.height - Y < 350);
  }, []);

  return (
    <SSelect className="SingleSelect" ref={selectRef} isCloseToBottom={isCloseToBottom}>
      <div className="select-label">{label}</div>
      <ReactSelect
        {...restProps}
        options={options}
        classNamePrefix="react-select"
        isLoading={isLoading}
        onChange={handleSelectChange}
        autoFocus={focus || false}
        placeholder={inputPlaceHolder || '-'}
        onInputChange={handleInputChange}
      />
    </SSelect>
  );
};

export default SelectAsync;
