import {Text} from '@/UI';
import {useEffect, useState} from 'react';
import {Component} from './inputRange.styled';

export interface InputRangeProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  options: Array<Array<string>>;
  title: string;
  onChangeRange: Function;
  value: any;
  isDisabled?: boolean;
}

const InputRange: React.FC<InputRangeProps> = props => {
  const {options, title, onChangeRange, className, value, isDisabled, ...restProps} = props;
  const [inputValue, setInputValue] = useState<number>(0);

  const inputChangeRangeHandler = (index: any) => {
    setInputValue(index);
    onChangeRange(options[index][1]);
  };

  useEffect(() => {
    if (value) {
      const index = options.findIndex(el => el[1] == value);
      inputChangeRangeHandler(index);
    } else setInputValue(0);
  }, [value]);
  return (
    //@ts-ignore
    <Component className="InputRange" {...restProps} isDisabled={isDisabled}>
      <div className="range-box">
        <Text type="Text3">
          {title}: <strong>{options[inputValue][0]}</strong>
        </Text>
        <input
          type={'range'}
          id="range-height"
          value={inputValue}
          min={0}
          max={options?.length - 1}
          onChange={e => inputChangeRangeHandler(e.target.value)}
        />
      </div>
    </Component>
  );
};

export default InputRange;
