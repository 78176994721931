import {useState, useCallback} from 'react';
import {useClickOut} from '@/Hooks';
import {ReactComponent as MoreIcon} from '@/Assets/Icons/more.svg';
import {Component} from './contextMenu.styled';
export type ContextOptionsType = Array<{option: string; onSelect: any; isDisabled?: boolean}>;

interface ContextMenuProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  options?: ContextOptionsType;
}
const ContextMenu: React.FC<ContextMenuProps> = props => {
  const {options, ref, ...restProps} = props;
  const [isOpenContextMenu, setIsOpenContextMenu] = useState<boolean>(false);
  const openMenuSwitcher = useCallback(
    () => setIsOpenContextMenu(!isOpenContextMenu),

    [isOpenContextMenu]
  );
  const {ref: ClickOutRef} = useClickOut(() => setIsOpenContextMenu(false));
  const handleSelect = useCallback(
    (onSelect: Function) => {
      onSelect();
      openMenuSwitcher();
    },
    [openMenuSwitcher]
  );
  return options?.length && options?.length > 0 ? (
    <Component className="ContextMenu" ref={ClickOutRef} {...restProps}>
      {isOpenContextMenu && (
        <div className="context-menu-wrapper">
          {options.map(({option, isDisabled, onSelect}, i) => (
            <span
              className={`context-menu-item ${isDisabled ? ` disable-menu-item` : ``}`}
              onClick={() => handleSelect(onSelect)}
              key={i}
            >
              {option}
            </span>
          ))}
        </div>
      )}
      {options?.length && <MoreIcon className="context-menu-icon" onClick={openMenuSwitcher} />}
    </Component>
  ) : null;
};
export default ContextMenu;
