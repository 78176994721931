import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useEffect, useState} from 'react';
import {AnalyticsResponse} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useGetOrderAnalytics = () => {
  const {orderInPortal} = usePortalsStore().selectors.orders;
  const [orderAnalytic, setOrderAnalytic] = useState<AnalyticsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = useApiLoader(
    async () => {
      setIsLoading(true);
      await API.Orders.getOrderAnalytics(orderInPortal.id)
        .then(res => setOrderAnalytic(res))
        .finally(() => setIsLoading(false));
    },
    [orderInPortal.id],
    {disableLoader: true}
  );
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    orderAnalytic,
    isLoading,
    reloadOrderAnalytic: fetch,
  };
};
export default useGetOrderAnalytics;
