import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCallback} from 'react';
type UploadFinancialDocType = {
  talentID: string;
  callBack?: Function;
  file: File;
  docType: string;
};
const useUploadFinancialDoc = () => {
  const upload = useApiLoader(
    async (payload: UploadFinancialDocType) => {
      const {callBack, docType, talentID, file} = payload;
      return await API.Wallet.uploadFinancialDoc(talentID, file, docType).then(() => callBack && callBack());
    },
    [],
    {successMsg: 'Document uploaded'}
  );
  return useCallback(async (payload: UploadFinancialDocType) => await upload(payload), []);
};
export default useUploadFinancialDoc;
