import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {SmsWhatsAppPushValues} from '@/Website/Components/Common/SmsWhatsAppPush/SmsWhatsAppPush';
import {AxiosInstance} from 'axios';
import {
  AddPreOrderTalentDTO,
  CampaignCreatePayload,
  CampaignDetailsType,
  CampaignEstimations,
  CampaignOrdersSummary,
  CampaignResponseType,
  CampaignSearchParams,
  CampaignSummary,
  Monitor,
  PreOrderSearchParams,
  PreOrderTalentType,
  PreOrderUpdatePayload,
  SendCampaignBase,
  SendReminderBase,
} from './types';

class CampaignsApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async getAllCampaigns(params: CampaignSearchParams): Promise<CampaignResponseType> {
    const sortBy = params.sortBy ? params.sortBy.value : undefined;
    return await this.api
      .get(`/list`, {
        params: {...params, sortBy},
      })
      .then(res => res.data);
  }
  //
  async sendCampaign(sendData: SendCampaignBase & SmsWhatsAppPushValues) {
    return await this.api.post('/campaign-sending', sendData);
  }
  //
  async sendReminder(sendData: SendReminderBase) {
    return await this.api.post('/reminder-sending', sendData);
  }
  //
  async getPreOrderTalents(searchParams: PreOrderSearchParams): Promise<PreOrderTalentType[]> {
    return await this.api
      .get(`/pre-order-talents`, {
        params: searchParams,
      })
      .then(res => res.data);
  }
  //
  async toggleCampaignAsDeleted(campaignID: number) {
    return await this.api.delete(`toggle-delete-campaign/${campaignID}`);
  }
  //
  async getCampaignSummary(campaignID: number): Promise<CampaignSummary> {
    return await this.api.get(`campaign-summary/${campaignID}`).then(res => res.data);
  }
  async deletePreOrderTalent(campaignID: number, talentInfoId: number) {
    return await this.api.delete(`/delete-pre-order-talent`, {
      params: {
        campaignID,
        talentInfoId,
      },
    });
  }
  async addPreOrderTalentsToCampaign(payload: AddPreOrderTalentDTO) {
    return await this.api.post(`/add-pre-order-talent`, payload);
  }
  //
  async getCampaignEstimations(campaignID: number): Promise<CampaignEstimations> {
    return await this.api
      .get(`/campaigns-api/performance/${campaignID}`, {baseURL: URL.ADMIN_MS})
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  //
  async deleteCampaign(campaignID: number): Promise<any> {
    return await this.api.delete(`/campaign/${campaignID}`);
  }
  //
  async resetCampaignOrdersPrices(campaignID: number): Promise<any> {
    return await this.api.patch(`/reset-prices/${campaignID}`).then(res => res.data);
  }
  //
  async createCampaign(payload: CampaignCreatePayload): Promise<CampaignDetailsType> {
    return await this.api.post(`/create`, payload).then(res => res.status === 201 && res.data);
  }
  //
  async getCampaign(campaignID: number): Promise<CampaignDetailsType> {
    return await this.api.get(`/campaign/${campaignID}`).then(res => res.data);
  }
  //
  async updateCampaign(campaignPayload: CampaignDetailsType) {
    return await this.api.patch(`/update-campaign`, campaignPayload).then(res => res.data);
  }
  //
  async getCampaignOrdersSummary(campaignID: number): Promise<CampaignOrdersSummary> {
    return await this.api.get(`/order-summary/${campaignID}`).then(res => res.data);
  }
  /**@returns file key(path) */
  async uploadCampaignLogo(file: File, campaignID: number): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    return await this.api.post(`/campaign-logo/${campaignID}`, formData).then(res => res.data);
  }
  /**@returns file key(path) */
  async uploadCampaignVideo(file: File, campaignID: number): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    return await this.api.post(`/campaign-video/${campaignID}`, formData).then(res => res.data);
  }
  /**@returns file key(path) */
  async uploadCampaignAttachments(files: File[], campaignID: number): Promise<string> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return await this.api.post(`/campaign-attachments/${campaignID}`, formData).then(res => res.data);
  }
  //
  async deleteCampaignLogo(campaignID: number) {
    return await this.api.delete(`/delete-campaign-logo`, {
      params: {campaignID},
    });
  }
  //
  async deleteCampaignVideo(campaignID: number) {
    return await this.api.delete(`/delete-campaign-video`, {
      params: {campaignID},
    });
  }
  //
  async deleteAttachment(campaignID: number, attachmentKey: string) {
    return await this.api.delete(`/campaign-attachments`, {
      params: {
        id: campaignID,
        attachmentKey,
      },
    });
  }
  async updatePreOrderTalent(payload: PreOrderUpdatePayload) {
    return await this.api.patch(`/update-pre-order-talent`, payload);
  }
  async getMonitor(): Promise<Monitor> {
    return await this.api.get(`/monitor`).then(res => res.data);
  }
  async getCampaignAnalytic(campaignID: number) {
    return await this.api.get(`/get-campaign-analytic/${campaignID}`).then(res => res.data);
  }
  async preOrdersToOrders(campaignID: number) {
    return await this.api.patch(`/pre-orders-to-orders/${campaignID}`).then(res => res.data);
  }
}
export default CampaignsApi;
