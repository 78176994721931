import {useEffect, useState} from 'react';
import {LogsResponse, LogsSearchParams} from '@/API/Sender/sender.types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useEmailLogs = (params?: LogsSearchParams) => {
  const [sendingLogs, setSendingLogs] = useState<LogsResponse>({logs: [], count: 0});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(
    async () => {
      if (params) {
        setIsLoading(true);
        await API.Sender.getEmailLogs(params)
          .then(res => setSendingLogs(res))
          .finally(() => setIsLoading(false));
      }
    },
    [params],
    {disableLoader: true}
  );
  useEffect(() => void fetch(), [fetch]);
  return {
    sendingLogs,
    isLoading,
    reloadSendingLogs: fetch,
  };
};
export default useEmailLogs;
