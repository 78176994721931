import {API} from '@/API/API';
import {TagTypePayload} from '@/API/Tags/types';
import {useApiLoader, useReduceState} from '@/Hooks';
import {Button, Input, TextArea} from '@/UI';
import {memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

const SNewTag = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

const NewTag = () => {
  const defaultPayload: TagTypePayload = useMemo(() => {
    return {
      added_money: 0,
      added_value: 0,
      tag_description: '',
      tag_title: '',
      users_number: 0,
    };
  }, []);
  const [payload, setPayload] = useState<TagTypePayload>(defaultPayload);

  const handleInputsChange = useReduceState<TagTypePayload>(setPayload);
  const onSuccessCreation = useCallback(() => {
    setPayload(defaultPayload);
  }, [defaultPayload]);

  const handleCreateClick = useApiLoader(
    async () =>
      await API.Tags.createTag(payload).then(() => {
        onSuccessCreation();
      }),
    [payload],
    {successMsg: 'Created'}
  );

  return (
    <SNewTag className="NewTag">
      <Input
        placeholder="Title"
        value={payload.tag_title}
        onChange={e => handleInputsChange('tag_title', e.target.value)}
      />
      <Input
        placeholder="Added money"
        type={'number'}
        value={payload.added_money}
        onChange={e => handleInputsChange('added_money', e.target.value)}
      />
      <Input
        placeholder="Added value"
        type={'number'}
        value={payload.added_value}
        onChange={e => handleInputsChange('added_value', e.target.value)}
      />
      <TextArea
        placeholder="Description"
        value={payload.tag_description || ''}
        onChange={(e: any) => handleInputsChange('tag_description', e.target.value)}
      />
      <Button
        color="Success"
        style={{marginTop: 20}}
        isDisabled={!payload.tag_title?.length}
        onClick={handleCreateClick}
      >
        Create
      </Button>
    </SNewTag>
  );
};

export default memo(NewTag);
