import styled from 'styled-components';
export const Component = styled.div`
position: relative;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    transform: translate(1px, 1px);
  }
  .popup {
    background-color: grey;
    padding: 2px 5px;
    border-radius: 10px;
    color: white;
    width: max-content;
    position: absolute;
    top: -17px;
    left: 0;
    opacity: 0;
  }
  .show {
   animation: popup 3s ease;
    @keyframes popup {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;