import {TalentDetailed} from '@/API/Talents/types';
import {dateToLocalDate} from '@/Services/date.service';
import {Box, ClipboardText, Div, Text} from '@/UI';

const AboutTalent: React.FC<{talent: TalentDetailed}> = ({talent}) => {
  return (
    <Box backgroundColor="Lime" label="About">
      <Div flexDirection="column" width={150} gap={40}>
        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            Email:{' '}
          </Text>
          <Text wordBreak="break-all" fontWeight={800} color="Orange" type="Text3">
            <ClipboardText textToCopy={talent.email}>{talent.email}</ClipboardText>
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            Username:
          </Text>
          <Text wordBreak="break-all" fontWeight={800} color="Blue" type="Text3">
            {talent.username}
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            ID:{' '}
          </Text>
          <Text fontWeight={800} color="MediumGray" type="Text3">
            <ClipboardText textToCopy={talent.user_id}>{talent.user_id}</ClipboardText>
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            Provider:
          </Text>
          <Text fontWeight={800} color="Blue" type="Text3">
            {talent.provider_id?.trim() ? talent.provider_id : '-'}
          </Text>
        </Div>

        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            Last login:
          </Text>
          <Text fontWeight={800} color="Success" type="Text3">
            {talent.last_login ? dateToLocalDate(talent.last_login) : '-'}
          </Text>
        </Div>
        <Div flexDirection="column">
          <Text type="Text2" fontWeight={800}>
            Joined:
          </Text>
          <Text fontWeight={800} color="Blue" type="Text3">
            {talent.date_joined ? dateToLocalDate(talent.date_joined) : '-'}
          </Text>
        </Div>
      </Div>
    </Box>
  );
};
export default AboutTalent;
