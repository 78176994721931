import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    padding-left: 1px;
    position: relative;
    .tab-preset {
      border-top-left-radius: 0;
      box-shadow: none;
      height: 90vh;
      max-height: 575px;
      padding-bottom: 10px;
    }

    .tab-list {
      margin-top: 10px;
      display: flex;
      list-style-type: none;
      gap: 5px;
      li {
        transition: background-color 0.2s ease;
        background-color: ${theme.Color.GrayLight};
        cursor: pointer;
        padding: 3px 14px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        font-family: ${theme.FontFamily.Outfit};
        font-size: 0.8rem;
        align-items: center;
        img {
          height: 15px;
          margin-right: 4px;
          filter: ${theme.SVGColor.MediumGray};
        }
      }
      .active {
        background-color: white;
        img {
          filter: none;
        }
      }
    }
  `}
`;
