import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {TalentDetailed} from '@/API/Talents/types';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';

const useTalent = () => {
  const {
    storedUser: {userID},
  } = useCommunityStore().selectors;
  const [talent, setTalent] = useState<TalentDetailed>();
  const fetch = useApiLoader(async () => {
    return await API.Talents.getTalentById(userID).then(talent => setTalent(talent));
  }, [userID]);
  useEffect(() => void fetch(), [fetch]);
  return {
    talent,
    setTalent,
    reloadTalent: fetch,
  };
};
export default useTalent;
