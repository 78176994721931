import {useCommunityStore} from '@/Store/community/community.selector';
import {useOpenAIDialogStore} from '@/Store/openaiDialog/openaiDialog.selector';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useQuickResponseStore} from '@/Store/quickResponse/quickResponse.selector';
import {useRootStore} from '@/Store/root/root.selector';
import {Confirmation, Loader, MediaPlayer, Notification} from '@/UI';
import {useSocketNotification} from '@/WebSockets';
import {
  Campaign,
  CategorySelector,
  EmailEvents,
  OpenAIDialog,
  Order,
  OrdersUpdater,
  TalentReport,
} from '@/Website/Components/Portals';
import ChangeUserPassword from '@/Website/Components/Portals/ChangeUserPassword/ChangeUserPassword';
import {QuickResponse} from '@/Website/Components/Portals/QuickResponse';
import {memo} from 'react';
import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import {TagPortal, Talent} from '../Components';
import AddPreOrder from '../Components/Portals/AddPreOrder/AddPreOrder';
import SideBar from './SideBar/Sidebar';
import TopBar from './TopBar/TopBar';

const Component = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  .outlet {
    flex: 1;
    height: 100%;
  }
`;

const Layout: React.FC = () => {
  const {
    confirmation: {isShowConfirmation},
    notification: {isShowNotification},
    mediaPlayer: {isShowMediaPlayer},
  } = useRootStore().selectors;
  const {isShowQuickResponse} = useQuickResponseStore().selectors;

  const {
    storedUser,
    isShowResetPassword,
    talentReportStore: {isShowTalentReport},
  } = useCommunityStore().selectors;
  const {
    categories: {isShowCategoryPortal},
    orders: {isShowOrderPortal},
    tagPortal: {isShowTagPortal},
    preOrderPortal: {isShowPreOrderPortal},
    campaignPortal: {isShowCampaignPortal},
    emailEvents: {isShowEmailEventsPortal},
    ordersUpdater: {isShowOrdersUpdater},
  } = usePortalsStore().selectors;
  const {isShowOpenAIDialog} = useOpenAIDialogStore().selectors;
  // useSocketNotification();

  return (
    <Component className="Layout" id="root-layout">
      <SideBar />
      <TopBar />

      <div className="outlet">
        <Outlet />
      </div>
      <Loader />

      {isShowNotification && <Notification />}
      {isShowQuickResponse && <QuickResponse />}
      {isShowEmailEventsPortal && <EmailEvents />}
      {isShowTalentReport && <TalentReport />}
      {isShowOrdersUpdater && <OrdersUpdater />}

      {isShowPreOrderPortal && <AddPreOrder />}
      {isShowCampaignPortal && <Campaign />}
      {isShowOpenAIDialog && <OpenAIDialog />}
      {storedUser.userID && <Talent />}
      {isShowTagPortal && <TagPortal />}
      {isShowCategoryPortal && <CategorySelector />}

      {isShowOrderPortal && <Order />}
      {isShowResetPassword && <ChangeUserPassword />}
      {isShowConfirmation && <Confirmation />}
      {isShowMediaPlayer && <MediaPlayer />}
    </Component>
  );
};

export default memo(Layout);
