import {useEffect, useState} from 'react';
import {FinancialDoc} from '@/API/Wallet/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useGetTalentFinancialDocs = (talentID: string) => {
  const [docs, setDocs] = useState<FinancialDoc[]>([]);
  const fetch = useApiLoader(async () => {
    return await API.Wallet.getTalentFinancialDocs(talentID).then(res => setDocs(res));
  }, [talentID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    docs,
    reloadDocs: fetch,
  };
};
export default useGetTalentFinancialDocs;
