import {memo} from 'react';
import styled, {useTheme} from 'styled-components';
import {Box, Text} from '@/UI';
import {UtilService} from '@/Services/util.service';
interface BudgetSpentProps {
  totalBudget: number;
  spentBudget: number;
}
const SBudgetSpent = styled(Box)`
  display: flex;
  .budget-label-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
  }
`;
const BudgetSpent: React.FC<BudgetSpentProps> = ({totalBudget, spentBudget}) => {
  const fillPercentage = spentBudget === 0 || totalBudget === 0 ? 0 : (spentBudget / totalBudget) * 100;
  const theme = useTheme();
  const strokeWidth = 9;
  const radius = 50;
  const viewBoxSize = radius * 2 + strokeWidth * 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (fillPercentage / 100) * circumference;
  const isCrossedBudget = spentBudget > totalBudget;
  return (
    <SBudgetSpent className="budget-ring" label="Budget spent">
      <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          fill="transparent"
          stroke={isCrossedBudget ? theme.Color.Alert : '#E0E0E0'}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          fill="transparent"
          stroke={isCrossedBudget ? theme.Color.Alert : theme.Color.Success}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(-90 ${radius + strokeWidth} ${radius + strokeWidth})`}
        />
      </svg>
      <div className="budget-label-ring">
        <Text type="Header3" color="BlackOcean" fontWeight={800}>
          ${UtilService.hundredSeparator(spentBudget)}
        </Text>{' '}
        <Text type="Text2" color="MediumGray">
          Out of ${UtilService.hundredSeparator(totalBudget)}
        </Text>
      </div>
    </SBudgetSpent>
  );
};
export default memo(BudgetSpent);
