import {memo} from 'react';
import {Analytics} from '@/Website/Components';
import {useGetOrderAnalytics} from '../Hooks';

const OrderAnalytic = () => {
  const {orderAnalytic, isLoading} = useGetOrderAnalytics();
  return (
    <div className="order-tab-preset">
      <Analytics isLoading={isLoading} analytics={orderAnalytic} />
    </div>
  );
};
export default memo(OrderAnalytic);
