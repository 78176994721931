import {TalentPortfolioType} from '@/API/Talents/types';
import {URL} from '@/API/urls';
import {memo} from 'react';
import styled, {css} from 'styled-components';

const SVideos = styled.div`
  ${({theme}) => css`
    overflow: auto;
    ${theme.Presets.scroll}
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 30px;
    padding: 5px;
    .portfolio-video {
      border-radius: 15px;
      position: relative;
      height: 300px;
      width: 200px;
      transition: transform 0.3s ease;
      &:hover {
        cursor: pointer;
        transform: translateY(-4px);
      }
    }
  `}
`;
const TalentPortfolioVideos: React.FC<{videos: TalentPortfolioType['materials']}> = ({videos}) => {
  return videos && videos.length ? (
    <SVideos>
      {videos.map((video, i) => (
        <video className="portfolio-video" controls src={URL.MEDIA_ROUTE + video.video} key={i} />
      ))}
    </SVideos>
  ) : null;
};
export default memo(TalentPortfolioVideos);
