import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';
//format string 'socialType,userName'
const useDownloadIQDataReport = (selectedSocial: string, userID: string, onSuccess?: Function) => {
  return useApiLoader(
    async () => {
      const userNameSocialType = selectedSocial?.split(',');
      await API.TalentInfo.findIQDataTalentReport({
        socialPlatform: userNameSocialType[0],
        url: userNameSocialType[1],
        userID: userID,
      }).then(() => {
        onSuccess && onSuccess();
      });
    },
    [selectedSocial],
    {successMsg: 'Report created'}
  );
};
export default useDownloadIQDataReport;
