import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {OrderStatDetailedType} from '@/API/Orders/types';

const useGetOrderStats = (orderID: number) => {
  const [stats, setStats] = useState<OrderStatDetailedType[]>([]);
  const fetch = useApiLoader(async () => {
    await API.Orders.getOrderStatsByOrderID(orderID).then(res => setStats(res));
  }, [orderID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    stats,
    reloadStats: fetch,
  };
};
export default useGetOrderStats;
