import {OrderType} from '@/API/Orders/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FavoriteUserType} from '../community/types';
import {InitialPortalStateType} from './types';
import {EmailEventsSearchParams} from '@/API/Sender/sender.types';

const initialState: InitialPortalStateType = {
  categories: {
    isShowCategoryPortal: false,
    userToCategoryPortal: '',
    categoryPortalCallBack: '',
  },
  orders: {
    isShowOrderPortal: false,
    orderInPortal: {} as OrderType,
    orderPortalCallBack: undefined,
  },
  campaignPortal: {
    isShowCampaignPortal: false,
    campaignPortalCallBack: undefined,
    campaignPortalID: undefined,
  },
  tagPortal: {
    isShowTagPortal: false,
    toTagPortal: [],
  },
  preOrderPortal: {
    isShowPreOrderPortal: false,
    toPreOrderPortal: [],
  },
  emailEvents: {
    isShowEmailEventsPortal: false,
    emailProps: {
      email: undefined,
      externalId: undefined,
    },
  },
  ordersUpdater: {
    isShowOrdersUpdater: false
  }
};

const slice = createSlice({
  name: 'community',
  initialState: initialState as InitialPortalStateType,
  reducers: {
    setIsShowCategoryPortal(state, action: PayloadAction<boolean>) {
      state.categories.isShowCategoryPortal = action.payload;
    },
    setPortalCategoryCallBack(state, action: PayloadAction<any>) {
      state.categories.categoryPortalCallBack = action.payload;
    },
    setUserToPortalCategory(state, action: PayloadAction<string>) {
      state.categories.userToCategoryPortal = action.payload;
    },
    setIsShowPortalOrder(state, action: PayloadAction<boolean>) {
      state.orders.isShowOrderPortal = action.payload;
    },
    setPortalOrder(state, action: PayloadAction<OrderType>) {
      state.orders.orderInPortal = action.payload;
    },
    setPortalOrderCallBack(state, action: PayloadAction<any>) {
      state.orders.orderPortalCallBack = action.payload;
    },
    setIsShowTagPortal(state, action: PayloadAction<boolean>) {
      state.tagPortal.isShowTagPortal = action.payload;
    },
    setEmptyTagPortal(state) {
      state.tagPortal.toTagPortal = [];
    },
    setTagPortalCallBack(state, action: PayloadAction<Function>) {
      state.tagPortal.tagPortalCallBack = action.payload;
    },
    addUsersToTagPortal(state, action: PayloadAction<FavoriteUserType[]>) {
      if (action.payload.length > 0) {
        state.tagPortal.toTagPortal = action.payload;
        state.tagPortal.isShowTagPortal = true;
      }
    },
    closePreOrderPortal(state) {
      state.preOrderPortal.isShowPreOrderPortal = false;
      state.preOrderPortal.toPreOrderPortal = [];
    },
    addUsersToPreOrderPortal(state, action: PayloadAction<FavoriteUserType[]>) {
      state.preOrderPortal.toPreOrderPortal = action.payload;
      state.preOrderPortal.isShowPreOrderPortal = true;
    },
    setCampaignPortalID(state, action: PayloadAction<number>) {
      state.campaignPortal.campaignPortalID = action.payload;
    },
    setIsShowCampaignPortal(state, action: PayloadAction<boolean>) {
      state.campaignPortal.isShowCampaignPortal = action.payload;
    },
    setCampaignPortalCallback(state, action: PayloadAction<any>) {
      state.campaignPortal.campaignPortalCallBack = action.payload;
    },
    openEmailEvents(state, action: PayloadAction<EmailEventsSearchParams>) {
      state.emailEvents.emailProps = action.payload;
      state.emailEvents.isShowEmailEventsPortal = true;
    },
    closeEmailEvents(state) {
      state.emailEvents = initialState.emailEvents;
    },
    setOrdersUpdater(state, action: PayloadAction<InitialPortalStateType['ordersUpdater']>) {
      state.ordersUpdater.isShowOrdersUpdater = action.payload.isShowOrdersUpdater
      state.ordersUpdater.callBackOnUpdate = action.payload.callBackOnUpdate
      if (action.payload.isShowOrdersUpdater && action.payload.ordersToUpdate?.length) {
        state.ordersUpdater.ordersToUpdate = action.payload.ordersToUpdate
      } else {
           state.ordersUpdater.ordersToUpdate = undefined
           state.ordersUpdater.callBackOnUpdate = undefined
      }
    }
  },
});
export const portalsReducer = slice.reducer;
export const portalsActions = slice.actions;
