import {Box, Button, Div, ElementHeadline, Modal, Select, Text} from '@/UI';
import {SelectOptionsType} from '@/UI/Select/select.styled';
import {
  useDownloadIQDataReport,
  useFindReportBySocial,
} from '@/Website/Components/Portals/TalentReport/Hooks';
import useOpenTalentReport from '@/Website/Components/Portals/TalentReport/Hooks/useOpenTalentReport';
import {useCallback, useState} from 'react';

type SearchInIQDataProps = {
  isShow: boolean;
  talentID: string;
  onClose: Function;
  onChange: Function;
  options: SelectOptionsType;
};
const SearchInIQData: React.FC<SearchInIQDataProps> = props => {
  //format string "username,social"
  const [selectedSocial, setSelectedSocials] = useState<string>('');
  const {existsSocial} = useFindReportBySocial(selectedSocial);
  const openReport = useOpenTalentReport();
  const {options, onClose, isShow, onChange, talentID} = props;
  const handleSearch = useDownloadIQDataReport(selectedSocial, talentID, () => {
    onChange();
    onClose();
  });
  const handleOpenExistsReport = useCallback(() => {
    if (existsSocial) {
      openReport({
        social: existsSocial.social_platform,
        talentID: existsSocial.user_id,
      });
    }
  }, [existsSocial]);
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline onClose={onClose} disableMarker title="Search in IQData per social" />
      <Div flexDirection="column" width={400} gap={20}>
        {existsSocial && (
          <Box flexDirection="column" backgroundColor="Alert">
            <Text fontWeight={800} type="Text2" color="White">
              Report already exists
            </Text>
            <Text
              fontWeight={800}
              type="Text3"
              onClick={handleOpenExistsReport}
              color="White"
              cursor="pointer"
            >
              {existsSocial.social_platform + '/' + existsSocial.user_id}
            </Text>
          </Box>
        )}

        <Select
          options={options}
          label="Socials"
          value={selectedSocial}
          onChange={(e: any) => setSelectedSocials(e)}
        />
        <Text type="Text4" italic>
          * if you don't see available networks in the list, make sure they are saved in the talent's profile
        </Text>
        <Button color="Orange" onClick={handleSearch} isDisabled={!selectedSocial || !!existsSocial}>
          SEARCH
        </Button>
      </Div>
    </Modal>
  );
};
export default SearchInIQData;
