import {ColorType} from '@/Styles/types';
import {ITalentReportDTO} from '@/API/TalentInfo/types';
import ColorService from '@/Services/color.service';
import {UtilService} from '@/Services/util.service';
import {ROUTES} from '@/Router/ROUTES';

export type TalentInfoColumnType = Array<{label: string; value: any; color: ColorType}>;
type TagColumnType = Array<string>;
type SectionType<T> = Array<{label: string; background: ColorType; data: T}>;

export const TalentReportService = {
  getReportPageURL: (userID: string, socialsType: string) => `${ROUTES.talentInfo}/${userID}/${socialsType}`,
};
export const GET_INFO = (socialInfo?: ITalentReportDTO) => {
  let SECTIONS: SectionType<TalentInfoColumnType> = [];
  let TAGS: SectionType<TagColumnType> = [];
  let MAIN: TalentInfoColumnType = [];
  if (socialInfo) {
    SECTIONS = [
      // {
      //   label: 'Prices',
      //   background: 'AlertLight',
      //   data: [
      //     {label: 'Ops price:', value: socialInfo?.ops_price || 0, color: 'Orange'},
      //     {label: 'Price:', value: socialInfo?.price_value_score || 0, color: 'Purple'},
      //     {label: 'Advertiser price:', value: socialInfo?.advertiser_price || 0, color: 'Alert'},
      //     {label: 'Price margin:', value: socialInfo?.price_margin || 0, color: 'Purple'},
      //     {label: 'System price:', value: socialInfo?.system_price || 0, color: 'Orange'},
      //   ],
      // },
      {
        label: 'Values',
        background: 'LightBG',
        data: [
          {label: 'ER:', value: socialInfo?.er || 0, color: 'Blue'},
          {
            label: 'Potential reach:',
            value: UtilService.hundredSeparator(socialInfo.followers * socialInfo?.credibility_score),
            color: 'Blue',
          },
          {
            label: 'Credibility:',
            value: socialInfo?.credibility_score || 0,
            color: 'Blue',
          },
          {
            label: 'Paid performance score:',
            value: socialInfo?.paid_post_performance || 0,
            color: 'Alert',
          },
        ],
      },
      // {
      //   label: 'Scores',
      //   background: 'InfoLight',
      //   data: [
      //     {label: 'Credibility score:', value: socialInfo?.credibility_score || 0, color: 'Alert'},
      //     {label: 'Responsive score:', value: socialInfo?.responsive_score || 0, color: 'Alert'},
      //     {label: 'Performance score:', value: socialInfo?.performance_score || 0, color: 'Alert'},
      //     {label: 'Delivery score:', value: socialInfo?.delivery_score || 0, color: 'Alert'},
      //     {label: 'Letstok score:', value: socialInfo?.letstok_score || 0, color: 'Alert'},
      //   ],
      // },
      {
        label: 'Predicted',
        background: 'LightBG',
        data: [
          {label: 'Avg likes:', value: UtilService.hundredSeparator(socialInfo?.avg_likes), color: 'Orange'},
          {label: 'Avg views:', value: UtilService.hundredSeparator(socialInfo?.avg_views), color: 'Orange'},
          {label: 'Avg comments:', value: socialInfo?.avg_comments || 0, color: 'Orange'},
          {label: 'Avg reels:', value: socialInfo?.avg_reels || 0, color: 'Orange'},
          // {label: 'Predicted likes:', value: socialInfo?.predicted_likes || 0, color: 'Orange'},
          // {label: 'Predicted actions:', value: socialInfo?.predicted_actions || 0, color: 'Orange'},
          // {
          //   label: 'Predicted comments:',
          //   value: socialInfo?.predicted_comments || 0,
          //   color: 'Orange',
          // },
          // {label: 'Predicted views:', value: socialInfo?.predicted_views || 0, color: 'Orange'},
          // {label: 'Predicted clicks:', value: socialInfo?.predicted_clicks || 0, color: 'Orange'},
        ],
      },
      {
        label: 'Audience',
        background: 'Lime',
        data: [
          {
            label: 'Reachability 500:',
            value: `${socialInfo?.reachability_500 || 0}%`,
            color: 'Success',
          },
          {
            label: 'Reachability 1000:',
            value: `${socialInfo?.reachability_1000 || 0}%`,
            color: 'Success',
          },
          {
            label: 'Reachability 1500:',
            value: `${socialInfo?.reachability_1500 || 0}%`,
            color: 'Success',
          },
          {
            label: 'Reachability 1500+:',
            value: `${socialInfo?.reachability_1500_plus || 0}%`,
            color: 'Success',
          },
          {label: 'Age group:', value: socialInfo?.age_group || 0, color: 'Success'},

          {label: 'Likes credibility:', value: socialInfo?.likers_credibility || 0, color: 'Blue'},
          {
            label: 'Likes not from followers:',
            value: socialInfo?.likes_not_from_followers || 0,
            color: 'Blue',
          },
          {label: 'Female cut:', value: socialInfo?.female_cut || 0, color: 'Blue'},
          {label: 'Male cut:', value: socialInfo?.male_cut || 0, color: 'Blue'},
          {
            label: 'Notable followers UR:',
            value: socialInfo?.notable_followers_users_ratio || 0,
            color: 'Blue',
          },
          // {
          //   label: 'Notable likes UR:',
          //   value: socialInfo?.notable_likes_users_ratio || 0,
          //   color: 'Blue',
          // },
        ],
      },
    ];
    MAIN = [
      {label: 'Username', value: socialInfo.user_name, color: 'DarkBlue'},
      {label: 'Country', value: socialInfo.user_country, color: 'Blue'},
      {label: 'Followers', value: UtilService.hundredSeparator(socialInfo.followers), color: 'Orange'},
      {label: 'Language', value: socialInfo.top_language, color: 'BlackOcean'},
      {label: 'Age group', value: socialInfo.age_group, color: 'Purple'},
      {
        label: 'Talent level',
        value: socialInfo.talent_level,
        color: ColorService.getColorTypeByStatus(socialInfo.talent_level),
      },
    ];
    TAGS = [
      {
        label: 'Top tags',
        background: 'BlueLight',
        data: socialInfo.top_tags ? socialInfo.top_tags.split(',') : [],
      },
      {
        label: 'Top hashtags',
        background: 'SuccessLight',
        data: socialInfo.top_hashtags ? socialInfo.top_hashtags.split(',') : [],
      },
      {
        label: 'Interests',
        background: 'PurpleLight',
        data: socialInfo.interests ? socialInfo.interests.split(',') : [],
      },
      {label: 'Our Categories', background: 'AlertLight', data: socialInfo.talent_categories},
    ];
  }
  return {
    SECTIONS,
    TAGS,
    MAIN,
  };
};
