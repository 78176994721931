import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConfirmationType, InitialRootStateType, NotificationType} from './types';
import {EmployeeBase} from '@/API/Security/Employees/types';

const defaultState: InitialRootStateType = {
  isAuth: false,
  user: undefined,
  mediaPlayer: {
    isShowMediaPlayer: false,
    mediaPath: undefined,
  },
  notification: {
    type: 'failed',
    message: 'Successfully',
    isShowNotification: false,
  },
  confirmation: {
    isShowConfirmation: false,
    confirmationMsg: 'Are you sure?',
    confirmationCallbackPass: () => {},
  },
};

const slice = createSlice({
  name: 'root',
  initialState: defaultState as InitialRootStateType,
  reducers: {
    setShowNotification(state, action: PayloadAction<boolean>) {
      state.notification.isShowNotification = action.payload;
    },
    setNotification(state, action: PayloadAction<NotificationType>) {
      state.notification = {
        ...action.payload,
        isShowNotification: true,
        type: action.payload.type || 'success',
      };
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setUser(state, action: PayloadAction<EmployeeBase>) {
      state.user = action.payload;
    },
    setConfirmation(state, action: PayloadAction<Omit<ConfirmationType, 'isShowConfirmation'>>) {
      state.confirmation = {...action.payload, isShowConfirmation: true};
    },
    setIsShowConfirmation(state, action: PayloadAction<boolean>) {
      state.confirmation.isShowConfirmation = action.payload;
    },
    openMediaPlayer(state, action: PayloadAction<string>) {
      state.mediaPlayer.mediaPath = action.payload;
      state.mediaPlayer.isShowMediaPlayer = true;
    },
    closeMediaPlayer(state) {
      state.mediaPlayer.mediaPath = '';
      state.mediaPlayer.isShowMediaPlayer = false;
    },
  },
});

export const rootReducer = slice.reducer;
export const rootActions = slice.actions;
