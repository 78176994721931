import {useMemo} from 'react';
import {Bar} from 'react-chartjs-2';
import {Box} from '@/UI';
import {CampaignSummary} from '@/API/Campaign/types';
import {
  BarElement,
  TooltipItem,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ColorService from '@/Services/color.service';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InvitationsChart: React.FC<{orders: CampaignSummary['orders_statuses']}> = ({orders}) => {
  const data = useMemo(() => {
    if (!orders || !orders.length) return [];
    const sorted = orders.sort((a, b) => b.count - a.count);
    return sorted.map(el => ({
      label: `${el.status} : ${el.count}`,
      data: [el.count],
      backgroundColor: ColorService.getColorByStatus(el.status),
      categoryPercentage: 0.4,
      barPercentage: 0.7,
    }));
  }, [orders]);
  const invitationsCount = useMemo(() => {
    let count = 0;
    if (!orders || !orders.length) return count;
    orders.forEach(el => (count += el.count));
    return count;
  }, []);
  return (
    <Box label={`Invitations: ${invitationsCount}`}>
      <Bar
        options={{
          responsive: true,
          // indexAxis: 'y',
          maintainAspectRatio: false,

          scales: {
            x: {
              ticks: {
                font: {
                  size: 13,
                  weight: '600',
                  family: 'Outfit',
                },
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                font: {size: 13, family: 'Outfit'},
              },
            },
            tooltip: {
              callbacks: {
                label(tooltipItem: TooltipItem<any>): string | string[] {
                  const label = tooltipItem.dataset.label || '';
                  if (label) {
                    return `${label}`;
                  } else {
                    return tooltipItem.parsed.y;
                  }
                },
              },
            },
          },
        }}
        data={{
          labels: [''],
          datasets: data,
        }}
      />
    </Box>
  );
};
export default InvitationsChart;
