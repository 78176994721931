import styled from 'styled-components';
import {memo} from 'react';

const SDatePicker = styled.div`
  display: flex;
  gap: 10px;
  width: max-content;
  align-items: flex-end;

  input[type='date']::before {
    content: attr(placeholder);
    position: absolute;
    color: #999999;
  }

  input[type='date'] {
    color: transparent;
  }

  input[type='date']:focus,
  input[type='date']:valid {
    color: #666666;
  }

  input[type='date']:focus::before,
  input[type='date']:valid::before {
    content: '';
  }
`;
export default memo(SDatePicker);
