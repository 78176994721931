import {CurtainConfig} from '@/UI/Curtain/Curtain';

export const QuickResponseService = {
  curtainConfig: {
    width: '630px',
    height: '100vh',
    top: '0px',
    right: '0px',
    disableClickOut: true,
    disableAnimation: true,
  } as CurtainConfig,
  defaultResponseState: {
    from: 'yana.ltcampaigns@letstok.com',
    subject: 'Dispute',
  },
};
