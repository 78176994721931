import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useResetCampaignOrdersPrices = (callBack?: Function) => {
  return useApiLoader(
    async (campaignID: number) => {
      return await API.Campaigns.resetCampaignOrdersPrices(campaignID).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Prices reset'}
  );
};
export default useResetCampaignOrdersPrices;
