import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useAppSelector} from '@/Hooks';
import {AppDispatchType} from '../types';
import {rootActions} from './root.slice';

const useRootSelector = () => {
  return useAppSelector(state => state.root);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(rootActions, dispatch);
};
export const useRootStore = () => {
  return {
    actions: useActions(),
    selectors: useRootSelector(),
  };
};
