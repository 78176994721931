import {Div, Text} from '@/UI';
import {ColorType} from '@/Styles/types';

interface Props {
  direction?: 'row' | 'column';
  title: string;
  titleColor?: ColorType;
  text?: string | number;
  textColor?: ColorType;
  isLoading?: boolean;
}
const TitledText: React.FC<Props> = props => {
  const {direction, textColor, isLoading, title, text, titleColor} = props;
  return (
    <Div flexDirection={direction || 'column'}>
      <Text type="Text3" color={titleColor || 'MediumGray'}>
        {title}
      </Text>
      <Text isLoading={isLoading} type="Text2" fontWeight={800} color={textColor || 'MediumGray'}>
        {text}
      </Text>
    </Div>
  );
};
export default TitledText;
