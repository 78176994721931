import styled, {css} from 'styled-components';
import {Box} from '@/UI';
import {Props} from './modal.interface';

export const Component = styled.div<{fadeColor?: string}>`
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
  background-color: ${props => props.fadeColor || 'rgba(0, 0, 0, 0.4)'};
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    opacity: 0;
    animation: slideDown 0.8s ease;
  }
  .ref-wrapper {
    position: absolute;
  }
  @keyframes slideDown {
    0% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
`;
export const FormBox = styled(Box)<Props>`
  ${props => {
    return css`
      max-width: ${props.maxModalWidth || 'fit-content'};

      height: max-content;
      border: 1px solid #d9d9d9;
      padding: 15px 25px 15px !important;
      .close-icon {
        height: 21px;
        padding: 0 5px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;

        &:active {
          transform: scale(0.7);
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    `;
  }}
`;
