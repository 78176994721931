import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useEffect, useState} from 'react';
import {CategoryUsers} from '@/API/Categories/types';

const useCategoryUsers = (params: {searchValue?: string; categoryID: number | undefined}) => {
  const [{count, users}, setUsersWithCount] = useState<CategoryUsers>({count: 0, users: []});

  const fetch = useApiLoader(async () => {
    if (params.categoryID && params.searchValue !== undefined) {
      await API.Categories.getCategoryUsers(params.categoryID, params.searchValue).then(res =>
        setUsersWithCount(res)
      );
    }
  }, [params.searchValue, params.categoryID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {count, users, reloadUsers: fetch};
};
export default useCategoryUsers;
