import {useSendCustomEmail} from '@/Hooks/Sender';
import {Button, Curtain, Grid, Input, TextEditor} from '@/UI';
import {ServiceProviderPicker} from '@/Website/Components/Common';
import useQuickResponse from '@/Website/Components/Portals/QuickResponse/Hooks/useQuickResponse';
import {QuickResponseService} from '@/Website/Components/Portals/QuickResponse/quickRespose.service';
import {useCallback, useMemo} from 'react';

const QuickResponse = () => {
  const {closeQuickResponse, handleChangeSendingData, resetState, sendingData} = useQuickResponse();
  const config = useMemo(() => QuickResponseService.curtainConfig, []);
  const sendEmail = useSendCustomEmail();

  const handleSend = useCallback(async () => {
    await sendEmail({
      from: sendingData.from,
      html: sendingData.html,
      name: sendingData.recipient.name,
      recipient: sendingData.recipient.email,
      sendType: sendingData.sendType,
      subject: sendingData.subject,
      service_provider: sendingData.service_provider,
    }).then(() => closeQuickResponse());
  }, [sendingData]);
  return (
    <Curtain
      curtainConfig={config}
      headLineProps={{
        title: 'Quick response',
        fontSize: 'Text3',
        disableMarker: true,
        onClose: closeQuickResponse,
        onClear: resetState,
      }}
    >
      <Grid templateColumn="1fr 1fr" columnGap={10}>
        <Input
          placeholder="From"
          value={sendingData.from}
          onChange={e => handleChangeSendingData({prop: 'from', value: e.target.value})}
        />
        <Input
          placeholder="Subject"
          value={sendingData.subject}
          onChange={e => handleChangeSendingData({prop: 'subject', value: e.target.value})}
        />
        <Input
          placeholder="Recipient email"
          value={sendingData.recipient.email}
          onChange={e =>
            handleChangeSendingData({
              prop: 'recipient',
              value: {email: e.target.value, name: sendingData.recipient.name},
            })
          }
        />
        <Input
          placeholder="Recipient name"
          value={sendingData.recipient.name}
          onChange={e =>
            handleChangeSendingData({
              prop: 'recipient',
              value: {name: e.target.value, email: sendingData.recipient.name},
            })
          }
        />
      </Grid>
      <ServiceProviderPicker
        onChange={value => handleChangeSendingData({value, prop: 'service_provider'})}
        service={sendingData.service_provider}
        label="Email service provider"
      />
      <TextEditor
        value={sendingData.html}
        onChange={(e: any) => handleChangeSendingData({prop: 'html', value: e})}
      />
      <Button onClick={handleSend} size="small" color="SuccessLight" fontColor="BlackOcean">
        SEND
      </Button>
    </Curtain>
  );
};
export default QuickResponse;
