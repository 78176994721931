import styled, {css} from 'styled-components';

const SOrderStats = styled.div`
  ${({theme}) => css`
    position: relative;
    padding: 15px 10px 5px 10px;
    background-color: ${theme.Color.White};
    display: flex;
    flex-direction: column;
    width: 1000px;
    }
  `}
`;

export default SOrderStats;
