import {useEffect, useState} from 'react';
import {OrderInsight} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {SocialsType} from '@/Services/social.service';

const useOrderInsights = (params: {order_id: number; social?: SocialsType}) => {
  const [insights, setInsights] = useState<OrderInsight[]>([]);
  const fetch = useApiLoader(async () => {
    return await API.Orders.getOrderInsights(params).then(res => setInsights(res));
  }, [params]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    insights,
  };
};
export default useOrderInsights;
