import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CreateDisputeDTO} from '@/API/Orders/types';

const useCreateOrderDispute = (callBack?: Function) =>
  useApiLoader(
    async (createDisputeDTO: CreateDisputeDTO) => {
      return await API.Orders.createOrderDispute(createDisputeDTO).then(() => callBack && callBack());
    },
    [callBack],
    {successMsg: 'Dispute created'}
  );
export default useCreateOrderDispute;
