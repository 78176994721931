import {useBooleanState} from '@/Hooks/useBooleanState';
import {useRootStore} from '@/Store/root/root.selector';
import {Avatar, Div, Text} from '@/UI';
import UserDetail from '@/Website/Layout/SideBar/User/UserDetail/UserDetail';
import {memo} from 'react';
import SUser from './user.styled';

const User: React.FC<{isNavOpen: boolean}> = ({isNavOpen}) => {
  const UserData = useRootStore().selectors.user;
  const ShowUserDetail = useBooleanState();

  return UserData ? (
    <SUser className="NavUser" isNavOpen={isNavOpen}>
      <Div onClick={ShowUserDetail.turnOn} alignItems="center" gap={5}>
        {isNavOpen ? (
          <>
            <Avatar image={''} size="medium" />
            <Div flexDirection="column">
              <Text type="Text2" fontWeight={800} color="White">
                {UserData.full_name}
              </Text>
              <Text type="Text4" fontWeight={600} color="MediumGray">
                @{UserData.email.split('@')[0]}
              </Text>
            </Div>
          </>
        ) : (
          <Avatar image={''} size="medium" />
        )}
      </Div>
      {ShowUserDetail.isTurn && (
        <UserDetail user={UserData} onClose={ShowUserDetail.turnOff} isShow={ShowUserDetail.isTurn} />
      )}
    </SUser>
  ) : null;
};
export default memo(User);
