import {useCallback, useMemo, useState} from 'react';
import {ElementHeadline, Modal, Tabs} from '@/UI';
import {usePortalsStore} from '@/Store/portals/portals.selector';
import {Panel} from '@/UI/Tabs/Tabs';
import {OrderService} from './order.service';
import {OrderType} from '@/API/Orders/types';
import SOrderDetails from './order.styled';
import {
  OrderAnalytic,
  OrderDisputeRevision,
  OrderInfo,
  OrderInsights,
  OrderMaterials,
  OrderStats,
  OrderUTM,
} from './index';
import {useDeleteOrder, useUpdateOrder} from './Hooks';

const Order: React.FC = () => {
  const {setIsShowPortalOrder} = usePortalsStore().actions;

  const {
    orders: {isShowOrderPortal, orderInPortal},
  } = usePortalsStore().selectors;

  const closeModal = useCallback(() => setIsShowPortalOrder(false), [setIsShowPortalOrder]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {tabs} = useMemo(() => OrderService, []);
  const [order, setOrder] = useState<OrderType>(orderInPortal);
  const deleteOrder = useDeleteOrder(order.id, closeModal);
  const updateOrder = useUpdateOrder(order);

  return (
    <Modal isShowModal={isShowOrderPortal} style={{backgroundColor: '#e7f1f8'}}>
      <ElementHeadline
        title={`Order: ${order.id}`}
        fontSize="Header4"
        onSave={updateOrder}
        onClose={closeModal}
        options={[{option: 'Delete order', onSelect: deleteOrder}]}
      />
      <SOrderDetails className="OrderDetails">
        <Tabs
          index={tabIndex}
          setIndex={index => setTabIndex(index)}
          tabsHeaders={tabs}
          className="order-details-tabs"
        >
          <Panel>
            <OrderInfo order={order} setOrder={setOrder as React.Dispatch<React.SetStateAction<OrderType>>} />
          </Panel>
          <Panel>
            <OrderMaterials orderID={order.id} />
          </Panel>
          <Panel>
            <OrderUTM orderID={order.id} />
          </Panel>
          <Panel>
            <OrderDisputeRevision orderID={order.id} />
          </Panel>
          <Panel>
            <OrderStats orderID={order.id} />
          </Panel>
          <Panel>
            <OrderAnalytic />
          </Panel>
          <Panel>
            <OrderInsights orderID={order.id} />
          </Panel>
        </Tabs>
      </SOrderDetails>
    </Modal>
  );
};
export default Order;
