import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteTalentVideo = (talentVideo: string | undefined) => {
  const {userID} = useCommunityStore().selectors.storedUser;
  return useApiLoader(async () => {
    return await API.Users.deleteVideo(userID, talentVideo);
  }, [talentVideo]);
};

export default useDeleteTalentVideo;
