import {useMemo, useState} from 'react';
import {Div, ElementHeadline, Input, Modal, TextArea} from '@/UI';
import {OrderDispute, UpdateDisputeDTO} from '@/API/Orders/types';
import {useDeleteOrderDispute, useUpdateOrderDispute} from '../../Hooks';
import {useReduceState} from '@/Hooks';

interface DisputeEditorProps {
  isShow: boolean;
  onClose: Function;
  onChange: Function;
  dispute: OrderDispute;
}
const DisputeEditor: React.FC<DisputeEditorProps> = props => {
  const {isShow, onClose, onChange, dispute} = props;
  const [updateDTO, setUpdateDTO] = useState<UpdateDisputeDTO>(dispute);
  const deleteDispute = useDeleteOrderDispute(() => {
    onChange();
    onClose();
  });
  const updateDispute = useUpdateOrderDispute(() => {
    onChange();
    onClose();
  });
  const handleInputs = useReduceState(setUpdateDTO);

  const options = useMemo(
    () => [{option: 'Delete', onSelect: () => deleteDispute({id: dispute.id, order_id: dispute.order_id})}],
    []
  );
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline
        onClose={onClose}
        options={options}
        title="Edit dispute"
        onSave={() => updateDispute(updateDTO)}
      />
      <Div width={400} flexDirection="column" gap={20}>
        <Input
          placeholder="Reason"
          value={updateDTO.reason}
          onChange={e => handleInputs('reason', e.target.value)}
        />
        <TextArea
          placeholder="Dissatisfaction details"
          value={updateDTO.dissatisfaction_details}
          row={15}
          onChange={e => handleInputs('dissatisfaction_details', e.target.value)}
        />
      </Div>
    </Modal>
  );
};
export default DisputeEditor;
