import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {BatchOrdersStatusesUpdateDTO} from '@/API/Orders/types';

const useUpdateCampaignOrderStatuses = () => {
  return useApiLoader(
    async (payload: BatchOrdersStatusesUpdateDTO) => {
      await API.Orders.batchCampaignOrdersStatusesUpdater(payload);
    },
    [],
    {
      successMsg: 'Statuses updated',
    }
  );
};
export default useUpdateCampaignOrderStatuses;
