import {AxiosRequestConfig} from 'axios';
import {StorageService} from '@/Services/storage.service';

const AxiosRequestInterceptor = (request: AxiosRequestConfig) => {
  const token = StorageService.get('TOKEN');
  const user = StorageService.get('USER');
  const UID = user ? JSON.parse(user) : '';
  if (!token) return Promise.reject('Token not found');

  request.headers = {
    'x-access-token': token,
    Authorization: token,
    language: 'En',
    country: 'US',
    currency: 'USD',
    platform: 'Admin',
    uid: UID?.email || 'no uid',
    apiKey: process.env.REACT_APP_ADMIN_SERVER_KEY as string,
  };
  request.timeout = 60000;
  return request;
};
export default AxiosRequestInterceptor;
