import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {CheckBoxStorageType, CheckBoxStoreType} from './checkBox.types';

const initialState: CheckBoxStoreType = {
  orders: []
};
const slice = createSlice({
  name: 'openAIDialog',
  initialState: initialState as CheckBoxStoreType,
  reducers: {
    clearBox(state, action: PayloadAction<CheckBoxStorageType>) {
      state[action.payload] = []
    },
    addToCheckBox(state, action: PayloadAction<{items: Array<any>, storageType: CheckBoxStorageType}>) {
      state[action.payload.storageType] = action.payload.items
    }
  },
});
export const checkBoxReducer = slice.reducer;
export const checkBoxActions = slice.actions;
