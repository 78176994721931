import {useEffect} from 'react';
import {createPortal} from 'react-dom';

const WithPortal = ({children}: any) => {
  const portal = document.createElement('div');
  const layout = document.getElementById('root-layout') as HTMLElement;
  useEffect(() => {
    layout.appendChild(portal);
    return () => {
      layout.removeChild(portal);
    };
  }, [portal, layout, children]);

  return createPortal(children, portal);
};

export default WithPortal;
