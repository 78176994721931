import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useCommunityStore} from '@/Store/community/community.selector';
import {useCallback} from 'react';
import {useDeleteTalentVideo} from '@/Website/Components/Talent/Hooks';

const useUploadTalentVideo = (currentVideo?: string) => {
  const {userID} = useCommunityStore().selectors.storedUser;
  const deleteTalentVideo = useDeleteTalentVideo(currentVideo);

  const upload = useApiLoader(
    async (uploadedVideo: any) => {
      if (currentVideo) await deleteTalentVideo();
      return await API.Users.uploadVideo(userID, uploadedVideo);
    },
    [userID, currentVideo]
  );
  return useCallback(async (uploadedVideo: any) => await upload(uploadedVideo), [upload]);
};
export default useUploadTalentVideo;
