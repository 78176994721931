export const dateInputAdapter = (incomingDate?: any): string => {
  let someDate = incomingDate ? new Date(incomingDate) : new Date();
  let numberOfDaysToAdd = 0;
  let date = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  return new Date(date).toISOString().split('T')[0];
};
export const createCustomDate = (options: {date: Date; months?: number; days?: number}) => {
  let date = options.date;
  if (options.months) {
    date.setMonth(date.getMonth() + options.months);
  }
  if (options.days) {
    date.setDate(date.getDate() + options.days);
  }
  date.setHours(0, 0, 0, 0);
  return date.toLocaleDateString('en-CA');
};
export const dateToLocalDate = (date?: string) => {
  try {
    if (date) {
      const resultDate = new Date(date).toLocaleDateString('en-GB');
      return resultDate === 'Invalid Date' ? date : resultDate;
    }
  } catch (e) {
    return '-';
  }
  return '-';
};
