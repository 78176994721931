import {useEffect, useState} from 'react';
import {OrderDispute, OrderRevision} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useOrderDisputes = (orderID: number) => {
  const [disputes, setDisputes] = useState<OrderDispute[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(async () => {
    if (orderID) {
      setIsLoading(true);
      await API.Orders.getOrdersDisputes(orderID)
        .then(res => setDisputes(res))
        .finally(() => setIsLoading(false));
    }
  }, []);
  useEffect(() => void fetch(), []);
  return {
    isLoading,
    disputes,
    reloadDisputes: fetch,
  };
};

export default useOrderDisputes;
