const AGE_GROUPS = [
  {label: '-', value: ''},
  {label: '13-17', value: '13-17'},
  {label: '18-24', value: '18-24'},
  {label: '25-34', value: '25-34'},
  {label: '35-44', value: '35-44'},
  {label: '45-64', value: '45-64'},
];

const AGE_GROUPS_RANGE = [
  ['All', ''],
  ['13-17', '13-17'],
  ['18-24', '18-24'],
  ['25-34', '25-34'],
  ['35-44', '35-44'],
  ['45-54', '45-54'],
  ['55-64', '55-64'],
  ['65+', '65'],
];
const ER_GROUPS_RANGE = [
  ['All', ''],
  ['0.2+', '0.2'],
  ['0.3+', '0.3'],
  ['0.4+', '0.4'],
  ['0.5+', '0.5'],
  ['0.7+', '0.7'],
  ['1+', '1'],
  ['2+', '2'],
  ['5+', '5'],
  ['7+', '7'],
  ['10+', '10'],
];

const COUNTRIES = [
  {label: '_', value: ''},
  {label: 'Afghanistan', value: 'AF'},
  {label: 'Åland Islands', value: 'AX'},
  {label: 'Albania', value: 'AL'},
  {label: 'Algeria', value: 'DZ'},
  {label: 'American Samoa', value: 'AS'},
  {label: 'Andorra', value: 'AD'},
  {label: 'Angola', value: 'AO'},
  {label: 'Anguilla', value: 'AI'},
  {label: 'Antarctica', value: 'AQ'},
  {label: 'Antigua and Barbuda', value: 'AG'},
  {label: 'Argentina', value: 'AR'},
  {label: 'Armenia', value: 'AM'},
  {label: 'Aruba', value: 'AW'},
  {label: 'Australia', value: 'AU'},
  {label: 'Austria', value: 'AT'},
  {label: 'Azerbaijan', value: 'AZ'},
  {label: 'Bahamas', value: 'BS'},
  {label: 'Bahrain', value: 'BH'},
  {label: 'Bangladesh', value: 'BD'},
  {label: 'Barbados', value: 'BB'},
  {label: 'Belarus', value: 'BY'},
  {label: 'Belgium', value: 'BE'},
  {label: 'Belize', value: 'BZ'},
  {label: 'Benin', value: 'BJ'},
  {label: 'Bermuda', value: 'BM'},
  {label: 'Bhutan', value: 'BT'},
  {label: 'Bolivia', value: 'BO'},
  {label: 'Bosnia and Herzegovina', value: 'BA'},
  {label: 'Botswana', value: 'BW'},
  {label: 'Bouvet Island', value: 'BV'},
  {label: 'Brazil', value: 'BR'},
  {label: 'British Indian Ocean Territory', value: 'IO'},
  {label: 'Brunei Darussalam', value: 'BN'},
  {label: 'Bulgaria', value: 'BG'},
  {label: 'Burkina Faso', value: 'BF'},
  {label: 'Burundi', value: 'BI'},
  {label: 'Cambodia', value: 'KH'},
  {label: 'Cameroon', value: 'CM'},
  {label: 'Canada', value: 'CA'},
  {label: 'Cape Verde', value: 'CV'},
  {label: 'Cayman Islands', value: 'KY'},
  {label: 'Central African Republic', value: 'CF'},
  {label: 'Chad', value: 'TD'},
  {label: 'Chile', value: 'CL'},
  {label: 'China', value: 'CN'},
  {label: 'Christmas Island', value: 'CX'},
  {label: 'Cocos (Keeling) Islands', value: 'CC'},
  {label: 'Colombia', value: 'CO'},
  {label: 'Comoros', value: 'KM'},
  {label: 'Congo', value: 'CG'},
  {label: 'Congo, The Democratic Republic of the', value: 'CD'},
  {label: 'Cook Islands', value: 'CK'},
  {label: 'Costa Rica', value: 'CR'},
  {label: "Cote D'Ivoire", value: 'CI'},
  {label: 'Croatia', value: 'HR'},
  {label: 'Cuba', value: 'CU'},
  {label: 'Cyprus', value: 'CY'},
  {label: 'Czech Republic', value: 'CZ'},
  {label: 'Denmark', value: 'DK'},
  {label: 'Djibouti', value: 'DJ'},
  {label: 'Dominica', value: 'DM'},
  {label: 'Dominican Republic', value: 'DO'},
  {label: 'Ecuador', value: 'EC'},
  {label: 'Egypt', value: 'EG'},
  {label: 'El Salvador', value: 'SV'},
  {label: 'Equatorial Guinea', value: 'GQ'},
  {label: 'Eritrea', value: 'ER'},
  {label: 'Estonia', value: 'EE'},
  {label: 'Ethiopia', value: 'ET'},
  {label: 'Falkland Islands (Malvinas)', value: 'FK'},
  {label: 'Faroe Islands', value: 'FO'},
  {label: 'Fiji', value: 'FJ'},
  {label: 'Finland', value: 'FI'},
  {label: 'France', value: 'FR'},
  {label: 'French Guiana', value: 'GF'},
  {label: 'French Polynesia', value: 'PF'},
  {label: 'French Southern Territories', value: 'TF'},
  {label: 'Gabon', value: 'GA'},
  {label: 'Gambia', value: 'GM'},
  {label: 'Georgia', value: 'GE'},
  {label: 'Germany', value: 'DE'},
  {label: 'Ghana', value: 'GH'},
  {label: 'Gibraltar', value: 'GI'},
  {label: 'Greece', value: 'GR'},
  {label: 'Greenland', value: 'GL'},
  {label: 'Grenada', value: 'GD'},
  {label: 'Guadeloupe', value: 'GP'},
  {label: 'Guam', value: 'GU'},
  {label: 'Guatemala', value: 'GT'},
  {label: 'Guernsey', value: 'GG'},
  {label: 'Guinea', value: 'GN'},
  {label: 'Guinea-Bissau', value: 'GW'},
  {label: 'Guyana', value: 'GY'},
  {label: 'Haiti', value: 'HT'},
  {label: 'Heard Island and Mcdonald Islands', value: 'HM'},
  {label: 'Holy See (Vatican City State)', value: 'VA'},
  {label: 'Honduras', value: 'HN'},
  {label: 'Hong Kong', value: 'HK'},
  {label: 'Hungary', value: 'HU'},
  {label: 'Iceland', value: 'IS'},
  {label: 'India', value: 'IN'},
  {label: 'Indonesia', value: 'ID'},
  {label: 'Iran, Islamic Republic Of', value: 'IR'},
  {label: 'Iraq', value: 'IQ'},
  {label: 'Ireland', value: 'IE'},
  {label: 'Isle of Man', value: 'IM'},
  {label: 'Israel', value: 'IL'},
  {label: 'Italy', value: 'IT'},
  {label: 'Jamaica', value: 'JM'},
  {label: 'Japan', value: 'JP'},
  {label: 'Jersey', value: 'JE'},
  {label: 'Jordan', value: 'JO'},
  {label: 'Kazakhstan', value: 'KZ'},
  {label: 'Kenya', value: 'KE'},
  {label: 'Kiribati', value: 'KI'},
  {label: "Korea, Democratic People'S Republic of", value: 'KP'},
  {label: 'Korea, Republic of', value: 'KR'},
  {label: 'Kuwait', value: 'KW'},
  {label: 'Kyrgyzstan', value: 'KG'},
  {label: "Lao People'S Democratic Republic", value: 'LA'},
  {label: 'Latvia', value: 'LV'},
  {label: 'Lebanon', value: 'LB'},
  {label: 'Lesotho', value: 'LS'},
  {label: 'Liberia', value: 'LR'},
  {label: 'Libyan Arab Jamahiriya', value: 'LY'},
  {label: 'Liechtenstein', value: 'LI'},
  {label: 'Lithuania', value: 'LT'},
  {label: 'Luxembourg', value: 'LU'},
  {label: 'Macao', value: 'MO'},
  {label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'},
  {label: 'Madagascar', value: 'MG'},
  {label: 'Malawi', value: 'MW'},
  {label: 'Malaysia', value: 'MY'},
  {label: 'Maldives', value: 'MV'},
  {label: 'Mali', value: 'ML'},
  {label: 'Malta', value: 'MT'},
  {label: 'Marshall Islands', value: 'MH'},
  {label: 'Martinique', value: 'MQ'},
  {label: 'Mauritania', value: 'MR'},
  {label: 'Mauritius', value: 'MU'},
  {label: 'Mayotte', value: 'YT'},
  {label: 'Mexico', value: 'MX'},
  {label: 'Micronesia, Federated States of', value: 'FM'},
  {label: 'Moldova, Republic of', value: 'MD'},
  {label: 'Monaco', value: 'MC'},
  {label: 'Mongolia', value: 'MN'},
  {label: 'Montserrat', value: 'MS'},
  {label: 'Morocco', value: 'MA'},
  {label: 'Mozambique', value: 'MZ'},
  {label: 'Myanmar', value: 'MM'},
  {label: 'Namibia', value: 'NA'},
  {label: 'Nauru', value: 'NR'},
  {label: 'Nepal', value: 'NP'},
  {label: 'Netherlands', value: 'NL'},
  {label: 'Netherlands Antilles', value: 'AN'},
  {label: 'New Caledonia', value: 'NC'},
  {label: 'New Zealand', value: 'NZ'},
  {label: 'Nicaragua', value: 'NI'},
  {label: 'Niger', value: 'NE'},
  {label: 'Nigeria', value: 'NG'},
  {label: 'Niue', value: 'NU'},
  {label: 'Norfolk Island', value: 'NF'},
  {label: 'Northern Mariana Islands', value: 'MP'},
  {label: 'Norway', value: 'NO'},
  {label: 'Oman', value: 'OM'},
  {label: 'Pakistan', value: 'PK'},
  {label: 'Palau', value: 'PW'},
  {label: 'Palestinian Territory, Occupied', value: 'PS'},
  {label: 'Panama', value: 'PA'},
  {label: 'Papua New Guinea', value: 'PG'},
  {label: 'Paraguay', value: 'PY'},
  {label: 'Peru', value: 'PE'},
  {label: 'Philippines', value: 'PH'},
  {label: 'Pitcairn', value: 'PN'},
  {label: 'Poland', value: 'PL'},
  {label: 'Portugal', value: 'PT'},
  {label: 'Puerto Rico', value: 'PR'},
  {label: 'Qatar', value: 'QA'},
  {label: 'Reunion', value: 'RE'},
  {label: 'Romania', value: 'RO'},
  {label: 'Russian Federation', value: 'RU'},
  {label: 'RWANDA', value: 'RW'},
  {label: 'Saint Helena', value: 'SH'},
  {label: 'Saint Kitts and Nevis', value: 'KN'},
  {label: 'Saint Lucia', value: 'LC'},
  {label: 'Saint Pierre and Miquelon', value: 'PM'},
  {label: 'Saint Vincent and the Grenadines', value: 'VC'},
  {label: 'Samoa', value: 'WS'},
  {label: 'San Marino', value: 'SM'},
  {label: 'Sao Tome and Principe', value: 'ST'},
  {label: 'Saudi Arabia', value: 'SA'},
  {label: 'Senegal', value: 'SN'},
  {label: 'Serbia and Montenegro', value: 'CS'},
  {label: 'Seychelles', value: 'SC'},
  {label: 'Sierra Leone', value: 'SL'},
  {label: 'Singapore', value: 'SG'},
  {label: 'Slovakia', value: 'SK'},
  {label: 'Slovenia', value: 'SI'},
  {label: 'Solomon Islands', value: 'SB'},
  {label: 'Somalia', value: 'SO'},
  {label: 'South Africa', value: 'ZA'},
  {label: 'South Georgia and the South Sandwich Islands', value: 'GS'},
  {label: 'Spain', value: 'ES'},
  {label: 'Sri Lanka', value: 'LK'},
  {label: 'Sudan', value: 'SD'},
  {label: 'Surilabel', value: 'SR'},
  {label: 'Svalbard and Jan Mayen', value: 'SJ'},
  {label: 'Swaziland', value: 'SZ'},
  {label: 'Sweden', value: 'SE'},
  {label: 'Switzerland', value: 'CH'},
  {label: 'Syrian Arab Republic', value: 'SY'},
  {label: 'Taiwan, Province of China', value: 'TW'},
  {label: 'Tajikistan', value: 'TJ'},
  {label: 'Tanzania, United Republic of', value: 'TZ'},
  {label: 'Thailand', value: 'TH'},
  {label: 'Timor-Leste', value: 'TL'},
  {label: 'Togo', value: 'TG'},
  {label: 'Tokelau', value: 'TK'},
  {label: 'Tonga', value: 'TO'},
  {label: 'Trinidad and Tobago', value: 'TT'},
  {label: 'Tunisia', value: 'TN'},
  {label: 'Turkey', value: 'TR'},
  {label: 'Turkmenistan', value: 'TM'},
  {label: 'Turks and Caicos Islands', value: 'TC'},
  {label: 'Tuvalu', value: 'TV'},
  {label: 'Uganda', value: 'UG'},
  {label: 'Ukraine', value: 'UA'},
  {label: 'United Arab Emirates', value: 'AE'},
  {label: 'United Kingdom', value: 'GB'},
  {label: 'United States', value: 'US'},
  {label: 'United States Minor Outlying Islands', value: 'UM'},
  {label: 'Uruguay', value: 'UY'},
  {label: 'Uzbekistan', value: 'UZ'},
  {label: 'Vanuatu', value: 'VU'},
  {label: 'Venezuela', value: 'VE'},
  {label: 'Viet Nam', value: 'VN'},
  {label: 'Virgin Islands, British', value: 'VG'},
  {label: 'Virgin Islands, U.S.', value: 'VI'},
  {label: 'Wallis and Futuna', value: 'WF'},
  {label: 'Western Sahara', value: 'EH'},
  {label: 'Yemen', value: 'YE'},
  {label: 'Zambia', value: 'ZM'},
  {label: 'Zimbabwe', value: 'ZW'},
];
const GENDER = [
  {name: 'All', value: ''},
  {name: 'Male', value: 'Male'},
  {name: 'Female', value: 'Female'},
];

export const LANGUAGE = [
  {label: '_', value: ''},
  {label: 'English', value: 'English'},
  {label: 'Spanish', value: 'Spanish'},
  {label: 'Portuguese', value: 'Portuguese'},
  {label: 'French', value: 'French'},
  {label: 'Russian', value: 'Russian'},
  {label: 'Italian', value: 'Italian'},
  {label: 'German', value: 'German'},
  {label: 'Persian', value: 'Persian'},
  {label: 'Indonesian', value: 'Indonesian'},
  {label: 'Turkish', value: 'Turkish'},
  {label: 'Japanese', value: 'Japanese'},
  {label: 'Polish', value: 'Polish'},
  {label: 'Thai', value: 'Thai'},
  {label: 'Chinese', value: 'Chinese'},
  {label: 'Hindi', value: 'Hindi'},
  {label: 'Malay', value: 'Malay'},
  {label: 'Korean', value: 'Korean'},
  {label: 'Ukrainian', value: 'Ukrainian'},
  {label: 'Dutch', value: 'Dutch'},
  {label: 'Arabic', value: 'Arabic'},
  {label: 'Nepali', value: 'Nepali'},
  {label: 'Azerbaijani', value: 'Azerbaijani'},
  {label: 'Panjabi', value: 'Panjabi'},
  {label: 'Gujarati', value: 'Gujarati'},
  {label: 'Swedish', value: 'Swedish'},
  {label: 'Kazakh', value: 'Kazakh'},
  {label: 'Hebrew', value: 'Hebrew'},
  {label: 'Romanian', value: 'Romanian'},
  {label: 'Kurdish', value: 'Kurdish'},
  {label: 'Czech', value: 'Czech'},
  {label: 'Vietnamese', value: 'Vietnamese'},
  {label: 'Urdu', value: 'Urdu'},
  {label: 'Kirghiz', value: 'Kirghiz'},
  {label: 'Hungarian', value: 'Hungarian'},
  {label: 'Greek', value: 'Greek'},
  {label: 'Malayalam', value: 'Malayalam'},
  {label: 'Bengali', value: 'Bengali'},
  {label: 'Catalan', value: 'Catalan'},
  {label: 'Finnish', value: 'Finnish'},
  {label: 'Danish', value: 'Danish'},
  {label: 'Norwegian', value: 'Norwegian'},
  {label: 'Bulgarian', value: 'Bulgarian'},
  {label: 'Serbian', value: 'Serbian'},
  {label: 'Swahili', value: 'Swahili'},
  {label: 'Croatian', value: 'Croatian'},
  {label: 'Albanian', value: 'Albanian'},
  {label: 'Tagalog', value: 'Tagalog'},
  {label: 'Slovak', value: 'Slovak'},
  {label: 'Tamil', value: 'Tamil'},
  {label: 'SerboCroatian', value: 'Serbo-Croatian'},
  {label: 'Slovenian', value: 'Slovenian'},
  {label: 'Pushto', value: 'Pushto'},
  {label: 'Macedonian', value: 'Macedonian'},
  {label: 'CentralKhmer', value: 'Central Khmer'},
  {label: 'Armenian', value: 'Armenian'},
  {label: 'Kannada', value: 'Kannada'},
  {label: 'Uzbek', value: 'Uzbek'},
  {label: 'Galician', value: 'Galician'},
  {label: 'Chechen', value: 'Chechen'},
  {label: 'Afrikaans', value: 'Afrikaans'},
  {label: 'Lithuanian', value: 'Lithuanian'},
  {label: 'Cebuano', value: 'Cebuano'},
  {label: 'Sinhala', value: 'Sinhala'},
  {label: 'Estonian', value: 'Estonian'},
  {label: 'Tajik', value: 'Tajik'},
  {label: 'Tatar', value: 'Tatar'},
  {label: 'Bosnian', value: 'Bosnian'},
  {label: 'Assamese', value: 'Assamese'},
  {label: 'Georgian', value: 'Georgian'},
  {label: 'Latvian', value: 'Latvian'},
  {label: 'Lao', value: 'SloLaovak'},
  {label: 'Amharic', value: 'Amharic'},
  {label: 'Telugu', value: 'Telugu'},
  {label: 'Burmese', value: 'Burmese'},
  {label: 'Mongolian', value: 'Mongolian'},
  {label: 'Icelandic', value: 'Icelandic'},
  {label: 'Sakha', value: 'Sakha'},
  {label: 'Mazanderani', value: 'Mazanderani'},
  {label: 'Sindhi', value: 'Sindhi'},
  {label: 'Oriya', value: 'Oriya'},
  {label: 'Gaelic', value: 'Gaelic'},
  {label: 'Somali', value: 'Somali'},
  {label: 'Belarusian', value: 'Belarusian'},
  {label: 'Waray', value: 'Waray'},
  {label: 'Mari', value: 'Mari'},
  {label: 'Interlingue', value: 'Interlingue'},
  {label: 'Bashkir', value: 'Bashkir'},
  {label: 'Turkmen', value: 'Turkmen'},
  {label: 'Javanese', value: 'Javanese'},
  {label: 'Divehi', value: 'Divehi'},
  {label: 'Lojban', value: 'Lojban'},
];
const TALENT_LEVELS = [
  {label: '-', value: ''},
  {label: 'None', value: 'None'},
  {label: 'Nano', value: 'Nano'},
  {label: 'Micro', value: 'Micro'},
  {label: 'Mid-Tier', value: 'Mid-Tier'},
  {label: 'Macro', value: 'Macro'},
  {label: 'Mega', value: 'Mega'},
];
const LANGUAGES = [
  {
    label: 'Afar',
    value: 'Aa',
  },
  {
    label: 'Abkhazian',
    value: 'Ab',
  },
  {
    label: 'Afrikaans',
    value: 'Af',
  },
  {
    label: 'Akan',
    value: 'Ak',
  },
  {
    label: 'Amharic',
    value: 'Am',
  },
  {
    label: 'Arabic',
    value: 'Ar',
  },
  {
    label: 'Assamese',
    value: 'As',
  },
  {
    label: 'Awadhi',
    value: 'Aw',
  },
  {
    label: 'Aymara',
    value: 'Ay',
  },
  {
    label: 'Azerbaijani',
    value: 'Az',
  },
  {
    label: 'Bhojpuri',
    value: 'B1',
  },
  {
    label: 'Maithili',
    value: 'B2',
  },
  {
    label: 'Bashkir',
    value: 'Ba',
  },
  {
    label: 'Belarussian',
    value: 'Be',
  },
  {
    label: 'Bulgarian',
    value: 'Bg',
  },
  {
    label: 'Bihari',
    value: 'Bh',
  },
  {
    label: 'Bislama',
    value: 'Bi',
  },
  {
    label: 'Balochi',
    value: 'Bl',
  },
  {
    label: 'Bengali',
    value: 'Bn',
  },
  {
    label: 'Tibetan',
    value: 'Bo',
  },
  {
    label: 'Breton',
    value: 'Br',
  },
  {
    label: 'Catalan',
    value: 'Ca',
  },
  {
    label: 'Cebuano',
    value: 'Cb',
  },
  {
    label: 'Chechen',
    value: 'Ce',
  },
  {
    label: 'Corsican',
    value: 'Co',
  },
  {
    label: 'Czech',
    value: 'Cs',
  },
  {
    label: 'Welsh',
    value: 'Cy',
  },
  {
    label: 'Danish',
    value: 'Da',
  },
  {
    label: 'German',
    value: 'De',
  },
  {
    label: 'Dakhini',
    value: 'Dk',
  },
  {
    label: 'Bhutani',
    value: 'Dz',
  },
  {
    label: 'Greek',
    value: 'El',
  },
  {
    label: 'English',
    value: 'En',
  },
  {
    label: 'Esperanto',
    value: 'Eo',
  },
  {
    label: 'Spanish',
    value: 'Es',
  },
  {
    label: 'Estonian',
    value: 'Et',
  },
  {
    label: 'Basque',
    value: 'Eu',
  },
  {
    label: 'Persian',
    value: 'Fa',
  },
  {
    label: 'Finnish',
    value: 'Fi',
  },
  {
    label: 'Fiji',
    value: 'Fj',
  },
  {
    label: 'Faeroese',
    value: 'Fo',
  },
  {
    label: 'French',
    value: 'Fr',
  },
  {
    label: 'Frisian',
    value: 'Fy',
  },
  {
    label: 'Irish',
    value: 'Ga',
  },
  {
    label: 'Scottish Gaelic',
    value: 'Gd',
  },
  {
    label: 'Galician',
    value: 'Gl',
  },
  {
    label: 'Guarani',
    value: 'Gn',
  },
  {
    label: 'Gujarati',
    value: 'Gu',
  },
  {
    label: 'Hausa',
    value: 'Ha',
  },
  {
    label: 'Hindi',
    value: 'Hi',
  },
  {
    label: 'Croatian',
    value: 'Hr',
  },
  {
    label: 'Haitian Creole',
    value: 'Ht',
  },
  {
    label: 'Hungarian',
    value: 'Hu',
  },
  {
    label: 'Armenian',
    value: 'Hy',
  },
  {
    label: 'Interlingua',
    value: 'Ia',
  },
  {
    label: 'Interlingue',
    value: 'Ie',
  },
  {
    label: 'Inupiak',
    value: 'Ik',
  },
  {
    label: 'Indonesian',
    value: 'In',
  },
  {
    label: 'Icelandic',
    value: 'Is',
  },
  {
    label: 'Italian',
    value: 'It',
  },
  {
    label: 'Hebrew',
    value: 'Iw',
  },
  {
    label: 'Japanese',
    value: 'Ja',
  },
  {
    label: 'Yiddish',
    value: 'Ji',
  },
  {
    label: 'Javanese',
    value: 'Jw',
  },
  {
    label: 'Georgian',
    value: 'Ka',
  },
  {
    label: 'Kabyle',
    value: 'Kb',
  },
  {
    label: 'Konkani',
    value: 'Ki',
  },
  {
    label: 'Kazakh',
    value: 'Kk',
  },
  {
    label: 'Greenlandic',
    value: 'Kl',
  },
  {
    label: 'Khmer',
    value: 'Km',
  },
  {
    label: 'Kannada',
    value: 'Kn',
  },
  {
    label: 'Korean',
    value: 'Ko',
  },
  {
    label: 'Kashmiri',
    value: 'Ks',
  },
  {
    label: 'Kurdish',
    value: 'Ku',
  },
  {
    label: 'Kirghiz',
    value: 'Ky',
  },
  {
    label: 'Latin',
    value: 'La',
  },
  {
    label: 'Luxembourgish',
    value: 'Lb',
  },
  {
    label: 'Lombard',
    value: 'Lm',
  },
  {
    label: 'Lingala',
    value: 'Ln',
  },
  {
    label: 'Laothian',
    value: 'Lo',
  },
  {
    label: 'Lithuanian',
    value: 'Lt',
  },
  {
    label: 'Latvian',
    value: 'Lv',
  },
  {
    label: 'Malagasy',
    value: 'Mg',
  },
  {
    label: 'Maori',
    value: 'Mi',
  },
  {
    label: 'Macedonian',
    value: 'Mk',
  },
  {
    label: 'Malayalam',
    value: 'Ml',
  },
  {
    label: 'Mongolian',
    value: 'Mn',
  },
  {
    label: 'Moldavian',
    value: 'Mo',
  },
  {
    label: 'Marathi',
    value: 'Mr',
  },
  {
    label: 'Malay',
    value: 'Ms',
  },
  {
    label: 'Maltese',
    value: 'Mt',
  },
  {
    label: 'Makhuwa',
    value: 'Mu',
  },
  {
    label: 'Marwari',
    value: 'Mw',
  },
  {
    label: 'Burmese',
    value: 'My',
  },
  {
    label: 'Nauru',
    value: 'Na',
  },
  {
    label: 'Nepali',
    value: 'Ne',
  },
  {
    label: 'Dutch',
    value: 'Nl',
  },
  {
    label: 'Norwegian',
    value: 'No',
  },
  {
    label: 'Occitan',
    value: 'Oc',
  },
  {
    label: 'Oromo',
    value: 'Om',
  },
  {
    label: 'Oriya',
    value: 'Or',
  },
  {
    label: 'Punjabi',
    value: 'Pa',
  },
  {
    label: 'Polish',
    value: 'Pl',
  },
  {
    label: 'Pashto',
    value: 'Ps',
  },
  {
    label: 'Portuguese',
    value: 'Pt',
  },
  {
    label: 'Quechua',
    value: 'Qu',
  },
  {
    label: 'Rifian',
    value: 'Ri',
  },
  {
    label: 'Rhaeto-Romance',
    value: 'Rm',
  },
  {
    label: 'Kirundi',
    value: 'Rn',
  },
  {
    label: 'Romanian',
    value: 'Ro',
  },
  {
    label: 'Russian',
    value: 'Ru',
  },
  {
    label: 'Kinyarwanda',
    value: 'Rw',
  },
  {
    label: 'Sanskrit',
    value: 'Sa',
  },
  {
    label: 'Sindhi',
    value: 'Sd',
  },
  {
    label: 'Sangro',
    value: 'Sg',
  },
  {
    label: 'Serbo-Croatian',
    value: 'Sh',
  },
  {
    label: 'Sinhalese',
    value: 'Si',
  },
  {
    label: 'Slovak',
    value: 'Sk',
  },
  {
    label: 'Slovenian',
    value: 'Sl',
  },
  {
    label: 'Samoan',
    value: 'Sm',
  },
  {
    label: 'Shona',
    value: 'Sn',
  },
  {
    label: 'Somali',
    value: 'So',
  },
  {
    label: 'Albanian',
    value: 'Sq',
  },
  {
    label: 'Serbian',
    value: 'Sr',
  },
  {
    label: 'Siswati',
    value: 'Ss',
  },
  {
    label: 'Sesotho',
    value: 'St',
  },
  {
    label: 'Sundanese',
    value: 'Su',
  },
  {
    label: 'Swedish',
    value: 'Sv',
  },
  {
    label: 'Swahili',
    value: 'Sw',
  },
  {
    label: 'Tamil',
    value: 'Ta',
  },
  {
    label: 'Telugu',
    value: 'Te',
  },
  {
    label: 'Tajik',
    value: 'Tg',
  },
  {
    label: 'Thai',
    value: 'Th',
  },
  {
    label: 'Tigrinya',
    value: 'Ti',
  },
  {
    label: 'Turkmen',
    value: 'Tk',
  },
  {
    label: 'Tagalog',
    value: 'Tl',
  },
  {
    label: 'Tuareg',
    value: 'Tm',
  },
  {
    label: 'Setswana',
    value: 'Tn',
  },
  {
    label: 'Tonga',
    value: 'To',
  },
  {
    label: 'Turkish',
    value: 'Tr',
  },
  {
    label: 'Tsonga',
    value: 'Ts',
  },
  {
    label: 'Tatar',
    value: 'Tt',
  },
  {
    label: 'Twi',
    value: 'Tw',
  },
  {
    label: 'Tamazight',
    value: 'Tz',
  },
  {
    label: 'Uyghur',
    value: 'Ug',
  },
  {
    label: 'Ukrainian',
    value: 'Uk',
  },
  {
    label: 'Urdu',
    value: 'Ur',
  },
  {
    label: 'Uzbek',
    value: 'Uz',
  },
  {
    label: 'Vietnamese',
    value: 'Vi',
  },
  {
    label: 'Volapuk',
    value: 'Vo',
  },
  {
    label: 'Wolof',
    value: 'Wo',
  },
  {
    label: 'Xhosa',
    value: 'Xh',
  },
  {
    label: 'Yoruba',
    value: 'Yo',
  },
  {
    label: 'Chinese',
    value: 'Zh',
  },
  {
    label: 'Zulu',
    value: 'Zu',
  },
];
const RANGE_ZERO_ONE_HUNDRED: Array<Array<string>> = Array(100)
  .fill('')
  .map((el, i) => {
    return [(i + 1).toString(), ((i + 1) / 100).toString()];
  });
const TABLE_NAMES = {
  TALENT_INFO_TABLE: 'public.talent_info',
  TALENT_USERS_TABLE: 'public.users',
  CAMPAIGNS_TABLE: 'public.campaigns',
  TALENT_PROFILE_TABLE: 'public.talent_profile',
  PROVIDERS_TABLE: 'public.providers',
  VISITORS_TABLE: 'public.visitors',
};
export const UTIL_CONSTANTS = {
  COUNTRIES,
  AGE_GROUPS_RANGE,
  TABLE_NAMES,
  GENDER,
  LANGUAGE,
  LANGUAGES,
  TALENT_LEVELS,
  RANGE_ZERO_ONE_HUNDRED,
  AGE_GROUPS,
  ER_GROUPS_RANGE,
};
