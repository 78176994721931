import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {OrderStatType} from '@/API/Orders/types';

const useCreateOrderStat = (createState: OrderStatType, onCreate: Function) => {
  return useApiLoader(
    async () => {
      await API.Orders.createOrderStat(createState).then(async () => {
        await onCreate();
      });
    },
    [createState, onCreate],
    {successMsg: 'Created'}
  );
};
export default useCreateOrderStat;
