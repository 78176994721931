import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {BaseCreateReportDTO} from '@/API/TalentInfo/types';

const useCreateManuallyTalentReport = (payload: BaseCreateReportDTO, onCreate: Function) => {
  return useApiLoader(
    async () => {
      await API.TalentInfo.createTalentReportManually(payload).then(() => {
        onCreate();
      });
    },
    [payload, onCreate],
    {successMsg: 'Created'}
  );
};
export default useCreateManuallyTalentReport;
