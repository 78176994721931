import { useCheckBoxStore } from '@/Store/checkBox/checkBox.selector';
import { CheckBoxStorageType } from '@/Store/checkBox/checkBox.types';
import {useCallback} from 'react';

const useCheckBox = <T>(propToCompare: keyof T, storageType: CheckBoxStorageType) => {

  const storage = useCheckBoxStore().selectors
  const {clearBox,addToCheckBox } = useCheckBoxStore().actions

  const unCheckAll = useCallback(() => clearBox(storageType), [storageType]);
  const checkItem = useCallback(
    (item: T) => {
      let index = undefined;
      let isChecked = false;
      const checkedItems = storage[storageType]
   
      if (checkedItems.length) {
        checkedItems.some((checkedItem: any, i) => {
          if (checkedItem[propToCompare] == item[propToCompare]) {
            index = i;
            isChecked = true;
            return true;
          }
          return false;
        });
      }

      return {
        itemIndex: index,
        isChecked,
      };
    },
    [storage[storageType]]
  );

  const toggleCheck = useCallback(
    (item: T) => {
      const {itemIndex, isChecked} = checkItem(item);
      if (!isChecked) {
        addToCheckBox({ items: [...storage[storageType], item] , storageType});
      } else {
        if (itemIndex !== undefined) {
          const tmp = [...storage[storageType]];
          tmp.splice(itemIndex, 1);
          addToCheckBox({ items: tmp , storageType});
        }
      }
    },
    [storage]
  );

  return {
    checkedItems: storage[storageType],
    checkedItemsLength: storage[storageType].length ,
    checkItem,
    toggleCheck,
    unCheckAll,
  };
};
export default useCheckBox;
