import {ProviderCreateDTO, ProvidersSearchParam, ProviderResponse} from './types';
import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class ProvidersApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  //
  async getProviders(query: ProvidersSearchParam): Promise<ProviderResponse> {
    return await this.api
      .get(`/get-providers`, {
        params: {...query, sortBy: query.sortBy?.value},
      })
      .then(res => res.data);
  }
  //
  async createProvider(payload: ProviderCreateDTO): Promise<any> {
    return await this.api.post(`/add-provider`, payload);
  }
  async updateProvider(payload: {
    title: string;
    description: string;
    tags?: Array<any>;
    providerID: number;
  }): Promise<any> {
    return await this.api.patch(`/update-provider`, payload);
  }
  //
  async deleteProvider(providerID: number): Promise<any> {
    return await this.api.delete(`/delete-provider/${providerID}`);
  }
}
export default ProvidersApi;
