import {OrderType} from '@/API/Orders/types';
import {ReactComponent as NewTabIcon} from '@/Assets/Icons/new_tab.svg';
import {useReduceState} from '@/Hooks';
import {Div, Grid, Input, Line, Select, Text, TextArea} from '@/UI';
import {OrderPromoCode} from '@/Website/Components/Portals/Order';
import OrderInfoHeader from '@/Website/Components/Portals/Order/OrderInfo/OrderInfoHeader/OrderInfoHeader';
import OrderInfoSubHeader from '@/Website/Components/Portals/Order/OrderInfo/OrderInfoSubHeader/OrderInfoSubHeader';
import {OrderService} from '@/Website/Components/Portals/Order/order.service';
import {OrderStatusType, OrdersService} from '@/Website/Pages/Orders/orders.service';
import {useCallback, useMemo} from 'react';
import SOrderInfo from './orderInfo.styled';

interface OrderInfoProps {
  order: OrderType;
  setOrder: React.Dispatch<React.SetStateAction<OrderType>>;
}
const OrderInfo: React.FC<OrderInfoProps> = ({order, setOrder}) => {
  const statuses = useMemo(() => OrdersService.ORDER_STATUSES, []);

  const handleChangeInputsState = useReduceState<OrderType>(setOrder);
  const URL = useMemo(() => `https://letstok.com/utm/${order.id}`, [order.id]);
  const handleClickUTM = useCallback(() => window.open(URL, '_blank'), [URL]);
  const subStatuses = useMemo(
    () => OrdersService.getOrderSubStatuses(order.status as OrderStatusType),
    [order.status]
  );

  return (
    <SOrderInfo className="OrderInfo order-tab-preset">
      <OrderInfoHeader order={order} />
      <OrderInfoSubHeader order={order} />
      <Grid justifyContent="space-between" templateColumn="200px repeat(2,300px)" columnGap={20}>
        <Input
          placeholder="Price"
          value={order.talent_price || 0}
          type="number"
          onChange={e => {
            handleChangeInputsState('talent_price', e.target.value);
            handleChangeInputsState(
              'advertiser_price',
              OrderService.calculateAdvertiserPrice(e.target.value)
            );
          }}
        />
        <Select
          options={statuses}
          value={order.status}
          onChange={value => {
            handleChangeInputsState('sub_status', '');
            handleChangeInputsState('status', value);
          }}
          label="Status"
          defaultPlaceholder={order.status}
        />
        {subStatuses.length > 0 && (
          <Select
            options={subStatuses}
            value={order.sub_status}
            onChange={value => handleChangeInputsState('sub_status', value)}
            label="Sub status"
            defaultPlaceholder={order.sub_status}
          />
        )}
      </Grid>
      <Line />
      <Grid templateColumn="300px 1fr" columnGap={20}>
        <Div flexDirection="column">
          <Div gap={10} onClick={handleClickUTM} alignItems={'center'}>
            <Text cursor="pointer" type="Text4" color="Success" fontWeight={600}>
              {URL} - UTM
            </Text>
            <NewTabIcon className="utm-icon" />
          </Div>
          <Input
            placeholder="Link post"
            value={order.link_post}
            onChange={e => handleChangeInputsState('link_post', e.target.value)}
          />
          <OrderPromoCode
            promoCode={order.promo_code}
            onUpdate={code => handleChangeInputsState('promo_code', code)}
          />
        </Div>
        <TextArea
          placeholder="Notes"
          row={10}
          value={order.notes || ''}
          onChange={e => handleChangeInputsState('notes', e.target.value)}
        />
      </Grid>
    </SOrderInfo>
  );
};
export default OrderInfo;
