import {ITalentReportDTO} from '@/API/TalentInfo/types';
import {Box, Grid, TableContainer, TCell, Text, TRow} from '@/UI';
import AudienceReportService from '@/Website/Components/Portals/TalentReport/AudienceReport/audienceReport.service';
import {memo, ReactNode} from 'react';

type Audience = {
  audience: Pick<
    ITalentReportDTO,
    'audience_ethnicity' | 'audience_countries' | 'audience_languages' | 'audience_genders' | 'audience_ages'
  >;
};
type Props = Partial<Audience> & {};
const AudienceReport: React.FC<Props> = ({audience}) => {
  const RenderTable: React.FC<{children: ReactNode; label: string}> = ({label, children}) => {
    return (
      <Box label={label}>
        <TableContainer headers={['Label', 'Percent']} isSmall>
          {children}
        </TableContainer>
      </Box>
    );
  };
  return (
    <Grid justifyContent="space-between" templateColumn="repeat(5, minmax(250px, 280px))">
      <RenderTable label="Audience gender">
        {audience?.audience_genders &&
          audience.audience_genders.map((stat, i) => (
            <TRow key={i}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {stat.code}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3">{AudienceReportService.convertWeight(stat.weight)}</Text>
              </TCell>
            </TRow>
          ))}
      </RenderTable>
      <RenderTable label="Audience ages">
        {audience?.audience_ages &&
          audience.audience_ages.map((stat, i) => (
            <TRow key={i}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {stat.code}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3">{AudienceReportService.convertWeight(stat.weight)}</Text>
              </TCell>
            </TRow>
          ))}
      </RenderTable>
      <RenderTable label="Audience ethnicity">
        {audience?.audience_ethnicity &&
          audience.audience_ethnicity.map((stat, i) => (
            <TRow key={i}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {stat.name}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3">{AudienceReportService.convertWeight(stat.weight)}</Text>
              </TCell>
            </TRow>
          ))}
      </RenderTable>
      <RenderTable label="Audience languages">
        {audience?.audience_languages &&
          audience.audience_languages.map((stat, i) => (
            <TRow key={i}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {stat.name}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3">{AudienceReportService.convertWeight(stat.weight)}</Text>
              </TCell>
            </TRow>
          ))}
      </RenderTable>
      <RenderTable label="Audience countries">
        {audience?.audience_countries &&
          audience.audience_countries.map((stat, i) => (
            <TRow key={i}>
              <TCell>
                <Text fontWeight={800} type="Text3">
                  {stat.name}
                </Text>
              </TCell>
              <TCell>
                <Text type="Text3">{AudienceReportService.convertWeight(stat.weight)}</Text>
              </TCell>
            </TRow>
          ))}
      </RenderTable>
    </Grid>
  );
};
export default memo(AudienceReport);
