import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {CreateOrderDTO} from '@/API/Orders/types';

const useCreateOrder = (payload: CreateOrderDTO, callBack?: (...args: any) => Promise<any>) => {
  return useApiLoader(
    async () => {
      await API.Orders.createOrder({
        talent: payload.talent,
        advertiser_price: payload.advertiser_price,
        status: 'offered',
        campaign_id: payload.campaign_id,
        talent_price: payload.talent_price,
        talent_info_id: payload.talent_info_id,
      }).then(async () => callBack && (await callBack()));
    },
    [payload, callBack],
    {successMsg: 'Order created'}
  );
};
export default useCreateOrder;
