import styled, {css} from 'styled-components';

export const Component = styled.div`
  ${({theme}) => css`
    display: flex;
    position: relative;
    background-color: ${theme.Color.White};
    padding: 10px;
    border-radius: 15px;
    flex-direction: column;
    .favorites-list {
      display: flex;
      flex-direction: column;
      height: 290px;
      overflow-y: auto;
      overflow-x: hidden;
      ${theme.Presets.scroll}
      .email-item {
        border-bottom: 1px solid ${theme.Color.GrayLight};
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px 0 2px;
        gap: 10px;
        &:hover {
          background-color: #f1f1f1;
          .price-input {
            background-color: #f1f1f1;
          }
        }
        .price-input {
          border: none;
          width: 50px;
          font-family: ${theme.FontFamily.Outfit};
          font-weight: 800;
          float: right;
          text-align: right;
          color: ${theme.Color.Orange};
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &:hover {
            background-color: #e7e7e7;
          }
        }
      }

      font-size: 0.7rem;
    }
  `}
`;
