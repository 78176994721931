import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '@/Router/ROUTES';

const useDeleteReport = (reportID: number) => {
  const navigate = useNavigate();
  return useApiLoader(async () => {
    if (reportID) await API.TalentInfo.deleteReport(reportID).then(() => navigate(ROUTES.searchy));
  }, [reportID]);
};

export default useDeleteReport;
