import {quickResponseReducer} from '@/Store/quickResponse/quickResponse.slice';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {communityReducer} from './community/community.slice';
import {openAIReducer} from './openaiDialog/openaiDialog.slice';
import {portalsReducer} from './portals/portals.slice';
import {rootReducer} from './root/root.slice';
import { checkBoxReducer } from './checkBox/checkBox.slice';

export const appReducer = combineReducers({
  root: rootReducer,
  community: communityReducer,
  portals: portalsReducer,
  openAIDialog: openAIReducer,
  quickResponse: quickResponseReducer,
  checkBox: checkBoxReducer,
});

export const STORE = configureStore({
  reducer: appReducer,
  middleware: [thunk],
});
