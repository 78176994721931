import {useAppSelector} from '@/Hooks';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AppDispatchType} from '../types';
import {portalsActions} from './portals.slice';

const usePortalsSelector = () => {
  return useAppSelector(state => state.portals);
};
const useActions = () => {
  const dispatch = useDispatch<AppDispatchType>();
  return bindActionCreators(portalsActions, dispatch);
};

export const usePortalsStore = () => {
  return {
    actions: useActions(),
    selectors: usePortalsSelector(),
  };
};
