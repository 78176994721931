import styled, {css} from 'styled-components';

const SLike = styled.div<{isLiked: boolean}>`
  ${({theme, isLiked}) => css`
    cursor: pointer;
    svg {
      height: 17px;
      width: 17px;
      fill: ${isLiked ? theme.Color.Alert : theme.Color.GrayLight};
      fill-rule: evenodd;
    }
  `}
`;
export default SLike;
