import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CommunicationServiceProviders, SenderSendingType} from '@/API/Sender/sender.types';
import {QueryResponseDefaultStore} from '@/Store/quickResponse/queryResponse.types';

const initialState: QueryResponseDefaultStore = {
  isShowQuickResponse: false,
  sendingData: {
    from: 'yana.ltcampaigns@letstok.com',
    subject: 'Subject',
    html: 'This is an example! <b>and</b> <a href="https://link_here.com">this is a link</a>.',
    recipient: {
      email: '',
      name: '',
    },
    service_provider: '' as CommunicationServiceProviders,
    sendType: 'reminder',
  },
  callBackOnSent: undefined,
};
const slice = createSlice({
  name: 'quickResponse',
  initialState: initialState as QueryResponseDefaultStore,
  reducers: {
    openQuickResponse(state) {
      state.isShowQuickResponse = true;
    },
    closeQuickResponse(state) {
      state.isShowQuickResponse = false;
    },
    setRecipient(
      state,
      action: PayloadAction<{sendType: SenderSendingType; email: string; name: string; subject: string}>
    ) {
      state.sendingData.recipient.email = action.payload.email;
      state.sendingData.recipient.name = action.payload.name;
      state.sendingData.subject = action.payload.subject;
      state.sendingData.sendType = action.payload.sendType;
      state.isShowQuickResponse = true;
    },
    handleChangeSendingData(
      state,
      action: PayloadAction<{prop: keyof QueryResponseDefaultStore['sendingData']; value: any}>
    ) {
      // @ts-ignore
      state.sendingData[action.payload.prop] = action.payload.value;
    },
    resetState(state) {
      state.sendingData = initialState.sendingData;
    },
  },
});
export const quickResponseReducer = slice.reducer;
export const quickResponseActions = slice.actions;
