import {useCallback, useEffect, useState} from 'react';
import useGetPaginationConfig from '@/UI/Pagination/pagination.hooks';
import {Button, Div} from '@/UI';
import {SPagination, SPaginationNumber} from '@/UI/Pagination/pagination.styled';
import {SortOptionType} from '@/UI/Sort/Sort';

export type Paginated = {
  limit?: number;
  skip?: number;
  sortBy?: SortOptionType;
};
export type PaginationConfig = {count: number; limit: number; setSkip: (toSkip: number) => void};
export const useSkip = <T extends {skip?: number}>(setter: React.Dispatch<React.SetStateAction<T>>) => {
  return useCallback((skip: number) => setter(prev => ({...prev, skip: skip || 0})), [setter]);
};

interface PaginationProps {
  pagination: PaginationConfig;
}
/** Dont forget to reset Skip on main filter handler!
 * after each filters changes
 * */
const Pagination: React.FC<PaginationProps> = ({pagination}) => {
  const {count, setSkip, limit} = pagination;
  const [customPage, setCustomPage] = useState<number>(0);

  const {setPage, totalPagesCount, selectedPage, digits} = useGetPaginationConfig({
    count,
    limit,
  });
  const handleClick = useCallback((page: number) => {
    setSkip(limit * (page - 1));
    setPage(page);
    setCustomPage(page);
  }, []);

  const handleGoClick = useCallback(() => handleClick(customPage), [customPage]);
  const handleInputChange = useCallback(
    (e: any) => {
      let num = Number(e.target.value);

      const res = num > totalPagesCount ? totalPagesCount : num;
      setCustomPage(res);
    },
    [totalPagesCount]
  );
  /** refresh states */
  useEffect(() => {
    setCustomPage(1);
    setPage(1);
  }, [limit, count]);

  return totalPagesCount > 1 ? (
    <SPagination className="TPagination">
      {digits.map((digitOrDots, i) =>
        typeof digitOrDots === 'number' ? (
          <SPaginationNumber
            isActiveNumber={digitOrDots === selectedPage}
            className="pagination-number"
            onClick={() => handleClick(digitOrDots)}
            key={i}
          >
            {digitOrDots}
          </SPaginationNumber>
        ) : (
          <div key={i}>{digitOrDots}</div>
        )
      )}
      {totalPagesCount > 6 && (
        <Div alignItems="flex-end">
          <input
            type="number"
            min={0}
            max={totalPagesCount}
            value={customPage}
            onChange={e => handleInputChange(e)}
            className="pagination-input"
          />
          <Button
            isDisabled={customPage === selectedPage}
            color="BlackOcean"
            className="pagination-button"
            size="small"
            onClick={() => handleGoClick()}
          >
            GO
          </Button>
        </Div>
      )}
    </SPagination>
  ) : null;
};
export default Pagination;
