import {TalentDetailed} from '@/API/Talents/types';
import {Button, Div} from '@/UI';
import {useApproveTalent, useRejectTalent} from '@/Website/Components/Talent/Hooks';
import {ApprovalsService} from '@/Website/Pages/Approvals/approvals.service';
import {memo, useMemo} from 'react';

const TalentPassage: React.FC<{talent: TalentDetailed; onChange: Function}> = ({talent, onChange}) => {
  const {toApprove, toReject} = useMemo(() => ApprovalsService.getTalentPassageInfo(talent), [talent]);
  const handleApprove = useApproveTalent(toApprove, onChange);
  const handleReject = useRejectTalent(toReject, onChange);

  return talent?.status === 'pending' ? (
    <Div gap={10}>
      <Button color="Alert" size="small" onClick={handleReject}>
        Reject
      </Button>
      <Button color="Success" size="small" onClick={handleApprove}>
        Approve
      </Button>
    </Div>
  ) : null;
};
export default memo(TalentPassage);
