import {URL} from '@/API/urls';
import {FinancialDoc} from '@/API/Wallet/types';
import {useCommunityStore} from '@/Store/community/community.selector';
import {ContextMenu, TableContainer, TCell, Text, TRow} from '@/UI';
import {useDeleteFinancialDoc} from '@/Website/Components/Talent/Hooks';
import {useCallback, useMemo} from 'react';

const FinancialDocsTable: React.FC<{docs: FinancialDoc[]; onChange: Function}> = ({onChange, docs}) => {
  const headers = useMemo(() => ['ID', 'Doc type', 'Doc path', ''], []);
  const handleOpenDoc = useCallback((doc: string) => window.open(URL.MEDIA_ROUTE + doc, '_blank'), []);
  const {userID} = useCommunityStore().selectors.storedUser;
  const handleDeleteDoc = useDeleteFinancialDoc(userID, onChange);
  return (
    <TableContainer headers={headers} isSmall>
      {docs.map((doc, i) => (
        <TRow key={i}>
          <TCell>
            <Text type="Text3">{doc.id}</Text>
          </TCell>
          <TCell>
            <Text type="Text3" color="Purple">
              {doc.doc_type}
            </Text>
          </TCell>
          <TCell>
            <Text type="Text3">{doc.doc_path}</Text>
          </TCell>
          <TCell>
            <ContextMenu
              options={[
                {onSelect: () => handleOpenDoc(doc.doc_path), option: 'Open'},
                {onSelect: () => handleDeleteDoc(doc.id), option: 'Delete'},
              ]}
            />
          </TCell>
        </TRow>
      ))}
    </TableContainer>
  );
};
export default FinancialDocsTable;
