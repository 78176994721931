import {
  CampaignsStatsType,
  GenerateStatsType,
  GlobalStatsType,
  OrdersStatsType,
} from '@/API/Statistics/statistics.types';
import {Statistics} from '@/API/Statistics/types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {DatePickerStateType} from '@/UI/DatePicker/DatePicker';
import {AxiosInstance} from 'axios';

class StatisticsApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getGlobalStats(): Promise<GlobalStatsType> {
    return await this.api.get(`/global`).then(res => res.data);
  }
  async getStatistics(): Promise<Statistics> {
    return await this.api.get(`statistics`).then(res => res.data);
  }
  async getGenerateStats(dates: DatePickerStateType): Promise<GenerateStatsType> {
    return await this.api
      .get(`/general-stats`, {
        params: dates,
      })
      .then(res => res.data);
  }

  async getCampaignsStats(dates: DatePickerStateType): Promise<CampaignsStatsType> {
    return await this.api
      .get(`/campaigns`, {
        params: dates,
      })
      .then(res => res.data);
  }
  async getOrdersStats(dates: DatePickerStateType): Promise<OrdersStatsType> {
    return await this.api
      .get(`/orders`, {
        params: dates,
      })
      .then(res => res.data);
  }
}
export default StatisticsApi;
