import {useCommunityStore} from '@/Store/community/community.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteTalentPhoto = (avatar: string | undefined) => {
  const {userID} = useCommunityStore().selectors.storedUser;
  return useApiLoader(async () => {
    return await API.Users.deletePhoto(userID, avatar);
  }, [userID, avatar]);
};
export default useDeleteTalentPhoto;
