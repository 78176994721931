import {SocialService, SocialsType} from '@/Services/social.service';
import styled, {css} from 'styled-components';

interface SocialIconProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  social: SocialsType;
  size?: 'small' | 'medium' | 'big' | 'large';
  isActive?: boolean;
}
const SSocialIcon = styled.img<Pick<SocialIconProps, 'isActive' | 'size'>>`
  ${props => css`
    height: 20px;
    width: 20px;
    ${!props.isActive &&
    css`
      pointer-events: none;
      opacity: 0.15;
    `}
    ${props.size === 'big' &&
    css`
      height: 30px;
      width: 30px;
    `}
    ${props.size === 'large' &&
    css`
      height: 40px;
      width: 40px;
    `}
  `}
`;

const SocialIcon: React.FC<SocialIconProps> = props => {
  const {social, ...rest} = props;
  //@ts-ignore
  return <SSocialIcon {...rest} src={SocialService.getIconBySocialName(social)} />;
};
export default SocialIcon;
