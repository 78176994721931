import {useEffect, useState} from 'react';
import {CampaignOrdersSummary} from '@/API/Campaign/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useCampaignOrdersSummary = (campaignID: number) => {
  const [ordersSummary, setOrdersSummary] = useState<CampaignOrdersSummary>({
    orders: 0,
    orders_statuses: [],
    sum_price: 0,
  });
  const getSummary = useApiLoader(
    async () => {
      await API.Campaigns.getCampaignOrdersSummary(campaignID).then(res => {
        let orders_statuses: CampaignOrdersSummary['orders_statuses'] = [];
        if (res.orders_statuses) {
          orders_statuses = res.orders_statuses.sort((a, b) => b.count - a.count);
        }
        setOrdersSummary({...res, orders_statuses});
      });
    },
    [campaignID],
    {disableLoader: true}
  );

  useEffect(() => {
    void getSummary();
  }, [getSummary]);
  return {
    ordersSummary,
  };
};
export default useCampaignOrdersSummary;
