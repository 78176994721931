import {OrderStatType} from '@/API/Orders/types';
import {useReduceState} from '@/Hooks';
import {Button, ElementHeadline, Grid, Input, Modal} from '@/UI';
import {useMemo, useState} from 'react';
import {useCreateOrderStat} from '../../Hooks';
import {defaultCreateStatState} from '../../order.service';

interface NewStatProps {
  onChange: Function;
  orderID: number;
  isShow: boolean;
  onClose: Function;
}
const NewStat: React.FC<NewStatProps> = props => {
  const {onChange, orderID, onClose, isShow} = props;
  const defaultState = useMemo(() => defaultCreateStatState, []);
  const [createState, setCreateState] = useState<OrderStatType>({...defaultState, order_id: orderID});
  const handleChangeState = useReduceState<OrderStatType>(setCreateState);
  const createNewStat = useCreateOrderStat(createState, () => {
    onChange();
    onClose();
  });
  const INPUTS_VALUES = useMemo(
    () =>
      [
        {placeholder: 'Impressions', field: 'impressions_num'},
        {placeholder: 'Likes', field: 'likes_num'},
        {placeholder: 'Comments', field: 'comments_num'},
        {placeholder: 'Clicks', field: 'clicks_num'},
        {placeholder: 'Shares', field: 'shares_num'},
        {placeholder: 'Saves', field: 'saves_num'},
        {placeholder: 'Actions', field: 'actions_num'},
      ] as Array<{placeholder: string; field: keyof OrderStatType}>,
    []
  );
  return (
    <Modal isShowModal={isShow}>
      <ElementHeadline title="Create new stat" onClose={onClose} />

      <Grid width="500px" templateColumn="1fr 1fr" columnGap={5}>
        {INPUTS_VALUES.map(({field, placeholder}, i) => (
          <Input
            key={i}
            placeholder={placeholder}
            value={createState[field]}
            type={'number'}
            onChange={e => handleChangeState(field, e.target.value)}
          />
        ))}
        <Input
          placeholder="Create date"
          value={createState.created_at}
          type={'date'}
          onChange={(e: any) => handleChangeState('created_at', e.target.value)}
        />
      </Grid>

      <Button color="Success" size="large" style={{marginTop: 20}} onClick={createNewStat}>
        ADD
      </Button>
    </Modal>
  );
};
export default NewStat;
