import {memo, ReactNode} from 'react';
import {Component} from './clipboardText.styled';

interface ClipboardTextProps {
  children?: ReactNode;
  textToCopy: string;
}

const ClipboardText: React.FC<ClipboardTextProps> = ({children, textToCopy}) => {
  const randomNum = Math.random().toFixed(5);

  const clickHandler = async (e: any) => {
    e.stopPropagation();
    document.getElementById(`pop${randomNum}`)?.classList.remove('show');
    await navigator.clipboard.writeText(textToCopy);
    document.getElementById(`pop${randomNum}`)?.classList.add('show');
  };

  return (
    <Component className="ClipboardText" onClick={clickHandler}>
      <div className="popup" id={`pop${randomNum}`}>
        Copied
      </div>
      <div className="children">{children}</div>
    </Component>
  );
};
export default memo(ClipboardText);
