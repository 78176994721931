import {
  AnalyticsResponse,
  BatchOrdersStatusesUpdateDTO,
  CreateDisputeDTO,
  CreateOrderDTO,
  CreateRevisionDTO,
  DeleteDisputeRevisionParams,
  OrderBaseType,
  OrderDispute,
  OrderInsight,
  OrderMaterialType,
  OrderPatchPayloadType,
  OrderRevision,
  OrdersLogsResponse,
  OrdersLogsSearchParams,
  OrdersSearchParams,
  OrderStatDetailedType,
  OrderStatType,
  OrderType,
  OrderTypeResponseType,
  OrderUTM,
  UpdateDisputeDTO,
  UpdateRevisionDTO,
  UpdateSelectedOrderStatuses,
} from './types';
import {URL} from '@/API/urls';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {AxiosInstance} from 'axios';
import {SocialsType} from '@/Services/social.service';

class OrdersApi {
  readonly api: AxiosInstance;
  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }
  async getOrders(orderParams: OrdersSearchParams): Promise<OrderTypeResponseType> {
    return await this.api
      .get(`/`, {
        params: orderParams,
      })
      .then(res => res.data);
  }
  async getOrderMaterials(orderID: number): Promise<OrderMaterialType[]> {
    return await this.api.get(`/materials/${orderID}`).then(res => res.data);
  }
  async updateOrder(payload: OrderPatchPayloadType): Promise<OrderTypeResponseType> {
    return await this.api.patch(`/update-order`, payload).then(res => res.data);
  }
  async deleteOrder(orderId: number): Promise<any> {
    return await this.api.delete(`/${orderId}`).then(res => res);
  }
  async uploadVideoOrder(orderId: number, videos: File[]): Promise<string> {
    const formData = new FormData();
    for (let i = 0; i < videos.length; i++) {
      formData.append('files', videos[i]);
    }
    return await this.api.post(`/upload-materials/` + orderId, formData).then(res => res.data);
  }
  async deleteVideoOrder(deleteParams: {id: number; key: string}): Promise<OrderType> {
    return await this.api.patch(`/deleteVideo`, deleteParams).then(res => res.data);
  }
  async getOrdersByTalentID(talentID: string): Promise<OrderBaseType[]> {
    return await this.api.get(`/talent-orders/` + talentID).then(res => res.data);
  }
  async batchCampaignOrdersStatusesUpdater(payload: BatchOrdersStatusesUpdateDTO): Promise<any> {
    return await this.api.post('batch-statuses-updated', payload);
  }
  async getOrdersDisputes(orderID: number): Promise<OrderDispute[]> {
    return await this.api.get(`/get-disputes/${orderID}`).then(res => res.data);
  }
  async createOrderDispute(createDisputeDTO: CreateDisputeDTO): Promise<any> {
    return await this.api.post('/create-dispute', createDisputeDTO);
  }
  async updateOrderDispute(updateDisputeDTO: UpdateDisputeDTO): Promise<any> {
    return await this.api.patch('/update-dispute', updateDisputeDTO);
  }
  async deleteOrderDispute(params: DeleteDisputeRevisionParams): Promise<any> {
    return await this.api.delete(`/delete-dispute/${params.order_id}/${params.id}`);
  }
  async deleteOrderRevision(params: DeleteDisputeRevisionParams): Promise<any> {
    return await this.api.delete(`/delete-revision/${params.order_id}/${params.id}`);
  }
  async createOrderRevision(createRevisionDTO: CreateRevisionDTO): Promise<any> {
    return await this.api.post('/create-revision', createRevisionDTO);
  }
  async updateOrderRevision(updateRevisionDTO: UpdateRevisionDTO): Promise<any> {
    return await this.api.patch('/update-revision', updateRevisionDTO);
  }
  async getOrderInsights(params: {order_id: number; social?: SocialsType}): Promise<OrderInsight[]> {
    return await this.api.get('order-insights', {params}).then(res => res.data);
  }
  async getOrdersRevisions(orderID: number): Promise<OrderRevision[]> {
    return await this.api.get(`/get-revisions/${orderID}`).then(res => res.data);
  }
  async getOrderStatsByOrderID(orderID: number): Promise<OrderStatDetailedType[]> {
    return await this.api.get(`/get-stats/${orderID}`).then(res => res.data);
  }
  async createOrderStat(stat: OrderStatType): Promise<any> {
    return await this.api.post(`/add-stat`, stat);
  }
  async updateOrderStat(stat: OrderStatDetailedType): Promise<any> {
    return await this.api.patch(`/update-stat`, stat);
  }
  async deleteOrderStat(statID: any): Promise<any> {
    return await this.api.delete(`/delete-stat/${statID}`);
  }
  async createOrder(createOrderDTO: CreateOrderDTO) {
    return await this.api.post(`/create`, createOrderDTO);
  }
  async getOrderAnalytics(orderID: number): Promise<AnalyticsResponse> {
    return await this.api.get(`/order-analytics/${orderID}`).then(res => res.data);
  }
  async getOrderUTM(orderID: number): Promise<OrderUTM[]> {
    return await this.api.get(`/order-utm/${orderID}`).then(res => res.data);
  }
  async getOrdersLogs(params: OrdersLogsSearchParams): Promise<OrdersLogsResponse> {
    return await this.api
      .get(`/orders-logs`, {
        params,
      })
      .then(res => res.data);
  }
  async updateSelectedOrdersStatuses(payload: UpdateSelectedOrderStatuses): Promise<any> {
    return await this.api.post('/updateOrdersStatuses', payload).then(res => res.data);
  }
}
export default OrdersApi;
