import {memo} from 'react';
import styled, {css} from 'styled-components';

const SOrderDetails = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    justify-content: space-between;
    .order-tab-preset {
      width: 1000px;
      height: 620px;
      padding: 20px 15px 15px;
      background-color: ${theme.Color.White};
      border-radius: 0 15px 15px;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      gap: 20px;
    }

    .order-details-tabs {
      width: 100%;
    }
  `}
`;
export default memo(SOrderDetails);
