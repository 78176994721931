import {useEffect, useState} from 'react';
import {OrderMaterialType} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useOrderMaterials = (orderID: number) => {
  const [materialsList, setMaterialsList] = useState<OrderMaterialType[]>([]);
  const fetch = useApiLoader(async () => {
    await API.Orders.getOrderMaterials(orderID).then(res => setMaterialsList(res));
  }, [orderID]);
  useEffect(() => {
    void fetch();
  }, [fetch]);
  return {
    materialsList,
    setMaterialsList,
    refreshMaterials: fetch,
  };
};
export default useOrderMaterials;
