import {TableCell} from '@material-ui/core';
import styled, {css} from 'styled-components';

interface TCellProps {
  width?: string;
}
const TCell = styled(TableCell)<TCellProps>`
  ${props => css`
    ${props.width && `width: ${props.width};`}
  `}
`;
export default TCell;
