import {useEffect, useState} from 'react';
import {OrderRevision} from '@/API/Orders/types';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useOrderRevisions = (orderID: number) => {
  const [revisions, setRevisions] = useState<OrderRevision[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetch = useApiLoader(async () => {
    if (orderID) {
      setIsLoading(true);
      await API.Orders.getOrdersRevisions(orderID)
        .then(res => setRevisions(res))
        .finally(() => setIsLoading(false));
    }
  }, []);
  useEffect(() => void fetch(), []);
  return {
    isLoading,
    revisions,
    reloadRevisions: fetch,
  };
};

export default useOrderRevisions;
