export const Controllers = {
  Admin: {
    orders: 'orders',
    advertisers: 'advertisers',
    automatedCampaignsLogs: 'automated-campaigns-logs',
    campaigns: 'campaigns',
    users: 'users',
    categories: 'categories',
    onboarding: 'onboarding',
    organizations: 'organizations',
    providers: 'providers',
    statistics: 'statistics',
    tags: 'tags',
    infoTalent: 'info-talent',
    wallet: 'wallet',
    talents: 'talents',
    sender: 'sender',
    openai: 'openai',
  },
  Discovery: {
    reports: 'reports',
  },
};
