import {useCallback, useEffect, useRef} from 'react';
import deleteIcon from '@/Assets/Icons/trash.svg';
import videoIcon from '@/Assets/Icons/video.svg';
import photoIcon from '@/Assets/Icons/photo.svg';
import {Component} from './inputMedia.styled';

interface InputMediaProps {
  mediaFile?: string;
  onDelete: Function;
  onUpload: Function;
  type: 'video' | 'photo';
  label?: string;
}

const InputMedia: React.FC<InputMediaProps> = props => {
  const {mediaFile, onDelete, onUpload, type, label} = props;
  const MediaRef = useRef<any>(null);
  const InputRef = useRef<any>(null);
  useEffect(() => {
    if (mediaFile && type === 'video') MediaRef.current.play();
  }, [mediaFile]);

  const handleUploadClick = useCallback(() => {
    const input = InputRef.current as HTMLInputElement;
    if (input) input.value = '';
    input?.click();
  }, [InputRef]);

  const handleClickDelete = useCallback(() => onDelete(), [onDelete]);
  return (
    <Component className="InputMedia" isUploadedMedia={mediaFile ? true : false}>
      {label && <div className="label">{label}</div>}
      <div className="media-wrapper">
        {mediaFile && type === 'video' && (
          <video ref={MediaRef} poster="image" controls autoPlay muted src={mediaFile} />
        )}

        {mediaFile && type === 'photo' && <img src={mediaFile} className="uploaded-photo" alt="" />}
      </div>
      <div className="buttons-group">
        <div>
          {mediaFile && <img src={deleteIcon} alt="" className="icons-control" onClick={handleClickDelete} />}
        </div>

        <div className="upload-container" onClick={handleUploadClick}>
          {!mediaFile && <span>{`UPLOAD ${type.toUpperCase()}`}</span>}

          {type === 'video' ? (
            <img src={videoIcon} alt="" className="icons-control" />
          ) : (
            <img src={photoIcon} alt="" className="icons-control" />
          )}
        </div>

        <input
          ref={InputRef}
          type="file"
          name="Upload"
          accept={type === 'video' ? 'video/*' : 'image/*'}
          className="upload-input-media"
          onChange={e => onUpload(e.target.files![0])}
        />
      </div>
    </Component>
  );
};

export default InputMedia;
