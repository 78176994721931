import {API} from '@/API/API';
import {useApiLoader} from '@/Hooks';
import {useCommunityStore} from '@/Store/community/community.selector';
import {useEffect} from 'react';

const useSetOnboardingCounters = () => {
  const {setBadges} = useCommunityStore().actions;
  const fetchOnboarding = useApiLoader(async () => {
    await API.Onboarding.getOnboardingCounter().then(res =>
      setBadges({badget: 'onboarding', value: res?.lead || 0})
    );
  }, []);
  const fetchApprovements = useApiLoader(async () => {
    await API.Talents.getApprovementCounter().then(res =>
      setBadges({badget: 'approvals', value: res?.count || 0})
    );
  }, []);

  useEffect(() => {
    void fetchApprovements();
    void fetchOnboarding();
  }, [fetch]);
};
export default useSetOnboardingCounters;
