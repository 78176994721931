import {usePortalsStore} from '@/Store/portals/portals.selector';
import {useApiLoader} from '@/Hooks';
import {API} from '@/API/API';

const useDeleteOrder = (orderID: number, onSuccess: Function) => {
  const {
    orders: {orderPortalCallBack},
  } = usePortalsStore().selectors;
  return useApiLoader(
    async () => {
      await API.Orders.deleteOrder(orderID).then(() => {
        onSuccess();
        orderPortalCallBack();
      });
    },
    [orderID],
    {successMsg: 'Order deleted'}
  );
};
export default useDeleteOrder;
