import {UTIL_CONSTANTS} from '@/Util/constants';
import {SortOptionType} from '@/UI/Sort/Sort';
import {TalentDetailed} from '@/API/Talents/types';
import {TalentPassage} from '@/Website/Components/Talent/types';

const sortOptions = [
  {label: 'date joined', value: [`${UTIL_CONSTANTS.TABLE_NAMES.TALENT_USERS_TABLE}.date_joined`, 'DESC']},
  {label: 'full name', value: [`${UTIL_CONSTANTS.TABLE_NAMES.TALENT_USERS_TABLE}.full_name`, 'DESC']},
] as SortOptionType[];

export const ApprovalsService = {
  sortOptions,
  getTalentPassageInfo: (talent: TalentDetailed) => {
    const toApprove: TalentPassage = {
      full_name: talent.full_name || talent.email,
      user_id: talent.user_id,
      language: 'EN',
      email_contact: talent.email,
      er: 1,
      profile_photo: talent.avatar,
      user_name: talent.username || talent.email,
      opsPrice: 100,
      marginPrice: 40,
      bio: talent.bio || '',
      followers: 1000,
      country: talent.country || 'INT',
      age_group: '18-24',
      socials: {
        instagram: talent.instagram,
        youtube: talent.youtube,
        tiktok: talent.tiktok,
        twitter: talent.twitter,
        twitch: talent.twitch,
        facebook: talent.facebook,
        linkedin: talent.linkedin,
        discord: talent.discord,
      },
    };

    const toReject = {
      user_id: talent.user_id,
      email: talent.email,
      full_name: talent.full_name || talent.email,
    };
    return {toReject, toApprove};
  },
  defaultFilterState: {
    searchQuery: '',
    limit: 30,
    skip: 0,
    noSocial: '',
    socials: [],
    sortBy: sortOptions[0] as SortOptionType,
  },
};
