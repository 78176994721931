import {Box, Text, ElementHeadline} from '@/UI';
import styled from 'styled-components';
import {memo} from 'react';
import Transactions from './Transactions/Transactions';
import NewOperation from './NewOperation/NewOperation';
import {useBooleanState} from '@/Hooks/useBooleanState';
import {useGetTalentWallet} from '@/Website/Components/Talent/Hooks';

const Component = styled(Box)`
  overflow: hidden;
  .wallet-header {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const TalentWallet = () => {
  const {wallet, reloadWallet, isLoading} = useGetTalentWallet();
  const ShowNewOperation = useBooleanState();

  return (
    <Component className="TalentWallet tab-preset">
      <ElementHeadline
        title={`Total transactions: ${wallet?.transactions.length}`}
        onCreateNew={ShowNewOperation.turnOn}
        disableMarker
      />
      <Box className="wallet-header" backgroundColor="AntiqueWhiteLight">
        <Text type="Text3">Total current income: {wallet?.total_income || 0}</Text>
        <Text type="Text3">
          Total approved income: {(wallet?.total_income || 0) - (wallet?.waiting_for_approve || 0) || 0}
        </Text>
        <Text type="Text3">Available for withdrawal: {wallet?.available_withdrawal || 0}</Text>
        <Text type="Text3">Total withdrawn: {wallet?.total_withdrawal || 0}</Text>
        <Text type="Text3">Waiting for approvement : {wallet?.waiting_for_approve || 0}</Text>
      </Box>
      {wallet?.transactions.length > 0 && (
        <Transactions isLoading={isLoading} refreshWallet={reloadWallet} transactions={wallet.transactions} />
      )}

      {ShowNewOperation.isTurn && (
        <NewOperation
          isShow={ShowNewOperation.isTurn}
          onClose={ShowNewOperation.turnOff}
          refreshWallet={reloadWallet}
        />
      )}
    </Component>
  );
};

export default memo(TalentWallet);
