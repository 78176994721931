import {Div, Text} from '@/UI';
import {memo, useMemo} from 'react';
import {Analytic} from '@/API/Orders/types';
import {ColorType} from '@/Styles/types';
interface AnalyticsColumnProps {
  type: keyof Analytic;
  value: any;
  isLoading: boolean;
}
const AnalyticsColumn: React.FC<AnalyticsColumnProps> = ({type, isLoading, value}) => {
  const DefinedColor: ColorType = useMemo(() => {
    let color: ColorType = 'MediumGray';
    if (value) {
      if (type === 'CPM') {
        color = value > 100 ? 'Alert' : value < 50 ? 'Success' : 'Orange';
      }
      if (type === 'CPC') {
        color = value > 10 ? 'Alert' : value < 5 ? 'Success' : 'Orange';
      }
      if (type === 'CPE') {
        color = value > 1 ? 'Alert' : value < 0.35 ? 'Success' : 'Orange';
      }
      if (type === 'CPA') {
        color = value > 20 ? 'Alert' : value < 10 ? 'Success' : 'Orange';
      }
      if (type === 'CPA') {
        color = value > 3 ? 'Success' : value < 1 ? 'Success' : 'Orange';
      }
    }
    return color;
  }, [type, value]);
  return (
    <Div flexDirection="column" alignItems="center">
      <Text isLoading={isLoading} type="Header2" fontWeight={800} color={DefinedColor}>
        {type === 'CTR' ? '%' : '$'}
        {value?.toFixed(2)}
      </Text>
      <Text type="Text1" color="BlackOcean" fontWeight={800}>
        {type}
      </Text>
    </Div>
  );
};

export default memo(AnalyticsColumn);
